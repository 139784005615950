<template>
  <div>
    <SecureKeypad
      v-if="isKeypadOpen"
      :has-header="true"
      :has-pwd="true"
      :max-try-count="maxTryCount"
      :try-count="tryCount"
      :state="state"
      title="결제 비밀번호 확인"
      :sub-title="subTitle"
      @find-pwd="findPwd()"
      @hide-keypad="keypadToggle()"
    />
  </div>
</template>

<script>
import SecureKeypad from '@/components/tvpoint/common/SecureKeypad';
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';

export default {
  name: 'Index',
  components: {
    SecureKeypad,
  },
  props: {
    payment: {
      default: '',
      type: String,
    },
  },

  emits: ['hasOrderPath'],

  data() {
    return {
      state: 'CERT', // REG, RE, FAIL
      isKeypadOpen: false,
      title: '결제 비밀번호 확인',
      subTitle: '',
      reg1: '보안상 생일 4자리, 휴대폰번호 뒤 4자리,<br/>동일/연속 숫자 4자리(예 : 0000/1234)는 설정하실 수 없습니다.',
      reg2: '설정하신 TV페이 비밀번호 확인을 위하여<br/>비밀번호를 한 번 더 입력해주세요!',
      reg3: '입력하신 비밀번호가 동일 하지 않습니다.<br/>다시 입력해주세요!',
      tryCount: 0,
      maxTryCount: 5,
      pwd: '',
      rePwd: '',
      idx: 0, // 카드 순번
    };
  },

  computed: {
    ...mapGetters(['getUserBirthDate', 'getUserCel', 'getCardList', 'getRpSession', 'getViewAccountDetail', 'getMobileUserYN']),
  },
  watch: {
    isKeypadOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.pageYOffset = window.scrollY;
          document.getElementById('app').style.width = '-webkit-fill-available';
          document.getElementById('app').style.position = 'fixed';
          document.getElementById('app').style.top = '-' + this.pageYOffset + 'px';
          document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
          // const container = document.querySelector(".container2");

          // const winSize = document.documentElement.clientHeight * 0.8;
          // const comSize = container.children[0].clientHeigh
          // if (comSize >= winSize) {
          //   container.style.height = winSize + "px";
          //   this.isScroll = true;
          // } else {
          //   this.isScroll = false;
          // }
        });
      } else {
        document.getElementsByTagName('body')[0].style.overflowY = '';
        document.getElementById('app').style.width = '100%';
        document.getElementById('app').style.position = '';
        window.scrollTo({ top: this.pageYOffset });
        this.$emit('hasOrderPath', false); // 인증팝업 닫힐때 false 주문서로 emit mjkim
      }
    },
  },
  created() {
    // 해상도 설정
    this.setResolution();
  },
  unmounted() {
    document.getElementsByTagName('body')[0].style.overflowY = '';
    document.getElementById('app').style.width = '100%';
    document.getElementById('app').style.position = '';
    window.scrollTo({ top: this.pageYOffset });
    this.$emit('hasOrderPath', false); // 인증팝업 닫힐때 false 주문서로 emit mjkim
  },
  methods: {
    setPwd(pwd) {
      const _vue = this;
      this.pwd = pwd;
      // this.$utils.printLog(this.state);
      // this.$utils.printLog(pwd);
      this.$store.commit('loading', true);

      // 빠숍에서 구매일 경우(통합 비밀번호 Y).
      if (this.payment === 'bbarshop') {
        this.$utils.printLog('SecureKeypadPassPage pinYN == Y-------> bbarshop');
        this.$utils.sequenceEx([
          this.verifyUserPin,
          function () {
            bus.$emit('end:loading');
            _vue.keypadToggle();
            _vue.$parent.goNextPwd();
          },
        ]);
        // TV포인트 구매일 경우.
      } else if (this.getCardList[this.idx].pinYN === 'N') {
        // 선택한 카드의 비밀번호가 없는 경우
        this.$utils.printLog('SecureKeypadPassPage pinYN == N------->', this.getCardList[this.idx].pinYN);
        this.$utils.sequenceEx([
          this.setUserPin,
          function () {
            bus.$emit('end:loading');
            _vue.keypadToggle();
            _vue.$parent.goNextPwd();
          },
        ]);
      } else {
        // 선택한 카드의 비밀번호가 있는 경우
        this.$utils.printLog('SecureKeypadPassPage  pinYN == Y------->', this.getCardList[this.idx].pinYN);
        this.$utils.sequenceEx([
          this.verifyUserPin,
          function () {
            bus.$emit('end:loading');
            _vue.keypadToggle();
            _vue.$parent.goNextPwd();
          },
        ]);
      }

      // 비밀번호 검증을 여기서 하고 카운트 올려줘야 함
      // this.$utils.sequenceEx([
      //   this.verifyUserPin,
      //   function () {
      //     _vue.keypadToggle();
      //     _vue.$parent.goNextPwd();
      //   },
      // ]);
    },
    // 비밀번호 설정
    setUserPin() {
      const _vue = this;
      _vue.$utils.printLog('setUserPin');
      const deferred = this.$utils.defer();
      const params = {
        tvpaySubUrl: `/mobileCert/setUserPin.mv;jsessionid=${_vue.getRpSession}`,
        userPin: _vue.pwd,
        userNo: _vue.getViewAccountDetail.userNo,
        acntNo: _vue.getViewAccountDetail.acntNo,
      };
      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('setUserPin resultCode', data.resultCode);
        _vue.$utils.printLog('setUserPin data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('setUserPin resultCode');
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    /**
     * CCC : 비밀번호 유효성 검사(연속된숫자, 동일한 숫자, 생년월일, 휴대폰 번호)
     * _phone 형식은 "01012345678"이고 _birth 형식은 "19940731" 이다
     *
     * @param _pwd  유효성 검사할 비밀번호
     * @param _phone 유효성 검사할 핸드폰번호
     * @param _birth 유효성 검사할 생년월일
     * @returns {boolean}
     */
    regPwd() {
      const _pwd = this.pwd;
      const _phone = this.getUserCel;
      const _birth = this.getUserBirthDate;
      this.$utils.printLog('_pwd = ' + _pwd + ', _phone = ' + _phone + ', _birth = ' + _birth);

      // 연속된 숫자, 동일한 숫자
      const _reg1 = /(\w)\1\1/g;
      const _reg2 = '0123456789';
      const _reg3 = '9876543210';

      // 동일한 숫자 체크 ex) 1111,2222 ....
      if (!this.$utils.isNull(_pwd) && _reg1.test(_pwd)) {
        this.$utils.printLog('동일한 숫자 체크');
        return true;
        // 연속된 숫자 체크 1234,4321,9876 ....
      } else if ((!this.$utils.isNull(_pwd) && _reg2.includes(_pwd)) || _reg3.includes(_pwd)) {
        this.$utils.printLog('연속된 숫자 체크');
        return true;
      }

      if (!this.$utils.isNull(_phone)) {
        // 휴대폰 번호
        const _cel1 = String(_phone).substr(3, 4);
        const _cel2 = String(_phone).length > 10 ? String(_phone).substr(7, 4) : String(_phone).substr(6, 4);

        // 생년월일
        if (!this.$utils.isNull(_pwd) && (_cel1.includes(_pwd) || _cel2.includes(_pwd))) {
          this.$utils.printLog('휴대폰번호 체크');
          return true;
        }
      }

      if (!this.$utils.isNull(_birth)) {
        // 생년월일
        const _bir1 = String(_birth).substr(4, 4);
        if (_bir1.includes(_pwd)) {
          this.$utils.printLog('생년월일 체크');
          return true;
        }
      }

      this.$utils.printLog('유효성 검사 정상');
      return false;
    },
    setContent(content) {
      this.subTitle = this[content];
    },
    keypadToggle(idx) {
      // 키패드 open / close 토글
      this.state = 'CERT';
      this.pwd = '';
      this.rePwd = '';
      this.idx = this.$utils.isNull(idx) ? 0 : idx;
      this.isKeypadOpen = !this.isKeypadOpen;
      this.title = '결제 비밀번호 설정';
      // this.tryCount = 0;
      this.pwd = '';
    },
    findPwd() {
      this.$store.commit('setSetPwdState', 'pointSetPwd');
      this.$router.push('/user/SelectAuthPage');
      // 기존 시나리오
      // const _vue = this;
      //
      // const pinYnPopup = {
      //   title: "TV페이",
      //   content: "TV페이 비밀번호가 설정되지 않아, 휴대폰번호(" + this.$utils.phoneNoFormat(this.$store.getters.getUserCel) + ")로 본인인증이 필요합니다. 인증번호를 전송하시겠어요?",
      //   sub_content: "휴대폰번호가 변경되었어요",
      // };
      //
      // const authDetail = {
      //   userNo: _vue.getCardList[_vue.idx].userNo,
      //   acntNo: _vue.getCardList[_vue.idx].acntNo,
      // };
      //
      // this.$utils.sequenceEx([
      //   this.viewAccount,
      //   function () {
      //     _vue.$store.commit("loading", false);
      //     _vue.$store.commit("alertPopup2", pinYnPopup);
      //     _vue.$store.commit("setAuthDetail", authDetail);
      //   },
      // ]);
    },
    setResolution() {
      const viewport = document.getElementsByName('viewport')[0];
      const scale = screen.width / 720;

      viewport.setAttribute('content', 'width=device-width,initial-scale=' + scale + ',user-scalable=no');
      viewport.setAttribute('HandheldFriendly', true);
    },
    pwdInputComplete(params) {
      // 여기 smartconfim 같이 비밀번호 확인하는 api붙이면 됨
      this.$store.dispatch('pwdDecrypt', params).then(data => {
        if (data.resultCode !== '0000') {
          // 성공 시 처리
          // this.keypadToggle(); // 키패드 닫기
        } else if (this.maxTryCount - 2 >= this.tryCount) {
          this.state = 'FAIL';
          // this.tryCount += 1;
          // 실패 시 처리(maxTryCount초과시나리오 등 정책에 맞게 처리할것)
        } else {
          // 비밀번호 실패 팝업
        }
      });
    },
    verifyUserPin() {
      const _vue = this;
      const deferred = this.$utils.defer();

      _vue.$utils.printLog('verifyMobileUserPin');

      const params = {
        userPin: _vue.pwd,
        // acntNo: _vue.getViewAccountDetail.acntNo,
        // userNo: _vue.getViewAccountDetail.userNo,
        // termId: _vue.getViewAccountDetail.termId,
        paramTermIdYN: 'Y',
      };

      if (this.payment === 'bbarshop') {
        // 빠숍에서 구매일 경우(통합비밀번호 Y)
        params.acntNo = _vue.$store.state.orderPayment.cardSelected.acntId;
        params.userNo = _vue.$store.state.orderPayment.cardSelected.userNo;
        params.termId = _vue.$store.state.tvpoint.termId;
        params.tvpaySubUrl = `/api/verifyMobileUserPin.mv;jsessionid=${this.getRpSession}`;
      } else {
        // TV포인트 구매일 경우
        params.acntNo = _vue.getViewAccountDetail.acntNo;
        params.userNo = _vue.getViewAccountDetail.userNo;
        params.termId = _vue.getViewAccountDetail.termId;
        params.tvpaySubUrl =
          _vue.getViewAccountDetail.isMobileUser === 'Y'
            ? `/api/verifyMobileUserPin.mv;jsessionid=${this.getRpSession}`
            : `/api/verifyUserPin.mv;jsessionid=${this.getRpSession}`;
      }

      // if (_vue.getViewAccountDetail.isMobileUser === 'Y') {
      // 	params.tvpaySubUrl = `/api/verifyMobileUserPin.mv;jsessionid=${this.getRpSession}`;
      // } else {
      // 	params.tvpaySubUrl = `/api/verifyUserPin.mv;jsessionid=${this.getRpSession}`;
      // }

      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('verifyUserPin resultCode', data.resultCode);
        _vue.$utils.printLog('verifyUserPin data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('verifyUserPin resultCode');
          // _vue.$store.commit("setViewCardDetail", data.resultData);
          deferred.resolve();
        } else if (parseInt(data.resultCode) === 10) {
          _vue.$utils.printLog('verifyUserPin 비밀번호 오류');
          _vue.tryCount = _vue.$utils.makeInteger(data.resultData.tryCount);
          _vue.curPwd = '';
          deferred.reject([_vue, data, 'verifyUserPinFail']);
          // deferred.reject([_vue, data, 'verifyUserPinFail']);
        } else if (parseInt(data.resultCode) === 5719) {
          _vue.$utils.printLog('verifyUserPin 비밀번호 5회 오류');
          _vue.$store.commit('setSetPwdState', 'pointSetPwd');
          _vue.tryCount = _vue.$utils.makeInteger(data.resultData.tryCount);
          deferred.reject([_vue, data, 'verifyUserPinFive']);
        } else {
          _vue.$utils.printLog('verifyUserPin fail');
          deferred.reject([_vue, data]);
        }
        bus.$emit('end:loading');
      });
      return deferred.promise;
    },
  },
};
</script>
<style scoped>
.btn_keypad_toggle {
  width: 100%;
  height: 200px;
  font-size: 50px;
  background-color: #eeeeee;
  border: none;
}
</style>
