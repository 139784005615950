<template>
  <div class="popup-wrap">
    <div class="popup-title">{{ title }}</div>
    <div class="popup-content" :style="$utils.isNull(title) ? '' : 'margin-top: 40px;'" v-html="content"></div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="confirm">확인</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
export default {
  name: 'LoginPopup',
  props: {
    title: {
      type: String,
      require: false,
      default: '',
    },
    content: {
      type: String,
      require: true,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['getSetAuthType', 'getUserCel', 'getSelfAuthData', 'getPopupData', 'getAlertPopup', 'getSetPwdState', 'isLogin']),
  },
  methods: {
    confirm() {
      if (!this.$utils.isNull(this.getAlertPopup.callType) && this.getAlertPopup.callType === 'permission') {
        this.$store.commit('setPopupData', { permission: true });
      }

      this.$commons.printLog(this.getAlertPopup);
      // 로그인 해제 시 로그인 페이지로 이동 처리
      if (this.getAlertPopup.resultCode === '-2010') {
        this.$router.push('/user/login');
      }

      if (this.getSetAuthType === 'changephone') {
        this.$store.commit('setPopupData', { inquireInfo: true, name: this.getPopupData.name });
      }

      // if (this.getSetPwdState === 'tvpaySetPwd' || this.getSetPwdState === 'pointSetPwd') {
      if (this.getSetPwdState === 'pointSetPwd' && this.getAlertPopup.count === 5) {
        this.$router.push('/user/SelectAuthPage');
      }

      //dh
      if (this.getAlertPopup.popupDelete === true) {
        // 조건문 최근일때 찜일때 삭제 api 통신하기
        if (this.isLogin) {
          if (this.getAlertPopup.componentType === 'zzim') {
            this.$store
              .dispatch('zzimProductDelete', {
                id: this.getAlertPopup.productId,
                like_product: {
                  like_product: {
                    company_id: this.getAlertPopup.deleteCompanyId,
                  },
                },
              })
              .then(data => {
                bus.$emit('deleteEventBus', 1); // recentViewDelete 삭제 성공 1 보냄
              });
          } else if (this.getAlertPopup.componentType === 'recentView') {
            this.$store.dispatch('recentViewDelete', this.getAlertPopup.deleteId).then(data => {
              bus.$emit('deleteEventBus', 1); // recentViewDelete 삭제 성공 1 보냄
            });
          }
        } else {
          if (this.getAlertPopup.componentType === 'zzim') {
            const zzimProductList = JSON.parse(localStorage.getItem('zzimProductList')) || [];
            const updatedZzimProductList = zzimProductList.filter(register => register.id !== this.getAlertPopup.deleteId);
            localStorage.setItem('zzimProductList', JSON.stringify(updatedZzimProductList));
          } else if (this.getAlertPopup.componentType === 'recentView') {
            const recentViewRegisters = JSON.parse(localStorage.getItem('recentViewRegisters')) || [];
            const updatedRecentViewRegisters = recentViewRegisters.filter(register => register !== this.getAlertPopup.deleteId);
            localStorage.setItem('recentViewRegisters', JSON.stringify(updatedRecentViewRegisters));
          }
        }
      }
      this.$store.commit('alertPopup', {});

      // const _vue = this;
      // _vue.$store.commit('alertPopup', {});
      // if (_vue.getSetAuthType === 'changephone') {
      // 	_vue.$store.commit('setPopupData', { inquireInfo: true, name: this.getPopupData.name });
      // }
    },
  },
};
</script>

<style scoped>
.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.popup-content {
  /*margin-top: 40px;*/
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  z-index: 7000;
  padding: 40px;
}
</style>
