<template>
  <div>
    <CommonRadiusButton v-for="(radio, idx) in radioData" :key="idx" height="110px">
      <div class="d-flex c-center r-center">
        <PaiBenefitRadio v-model="radioIdx" :index="idx" radio-name="paiBenefit" @click-event="clickEvent" />
        <CommonText font-size="30" font-color="#22" spacing="-1.2" class="padding-left-20">{{ radio.name }}</CommonText>
      </div>
    </CommonRadiusButton>
  </div>
</template>

<script>
import CommonRadiusButton from '@/components/common/button/CommonRadiusButton';
import PaiBenefitRadio from '@/components/common/popup/slidingPopupContent/pai/setting/importData/PaiBenefitRadio';
import CommonText from '@/components/common/text/CommonText';
import { mapGetters } from 'vuex';
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiBenefitWrapper',
  components: { CommonText, PaiBenefitRadio, CommonRadiusButton },
  data() {
    return {
      radioIdx: -1,
      radioData: [{ name: '전체 채널 보기' }, { name: '숨김 채널 관리' }],
    };
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
  methods: {
    clickEvent(sort, data) {
      switch (sort) {
        case 'radio':
          this.radioIdx = data;
          if (data === 0) {
            this.$router.push('/pai/channelList');
          } else {
            if (this.isLogin) {
              this.$store.commit('fitSelectType', 'hide');
              this.$router.push('/pai/hiddenChannelManagement');
              // this.$router.push('/pai/storedBenefit');
            } else {
              this.$store.commit('alertPopup', { popupType: 'LoginPopup' });
            }
          }
          this.$store.commit('setSlideUpPopup', { showSlide: false, pages: '', height: '' });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
