// jhkim: 추가

const FTBridgeAosService = (function () {
  // 싱글톤
  let _shared;

  function init() {
    function _createGetter(command) {
      const promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
          const message = {
            command: command,
          };
          const json = JSON.stringify(message);
          const params = command.split(':');

          const response = window.bridge.postMessage(json);
          let result = JSON.parse(response);
          resolve(result[params[1]]);
        }, 1);
      });

      return promise;
    }

    function _createSetter(command, payload) {
      const promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
          const message = {
            command: command,
            payload: payload,
          };
          const json = JSON.stringify(message);
          const params = command.split(':');

          const response = window.bridge.postMessage(json);
          let result = JSON.parse(response);
          resolve(result);
        }, 1);
      });

      return promise;
    }

    return {
      deviceId() {
        return _createGetter('get:deviceId');
      },

      versionName() {
        return _createGetter('get:versionName');
      },

      pushToken() {
        return _createGetter('get:pushToken');
      },

      isFirstLoad() {
        return _createGetter('is:firstLoad:bool');
      },

      userId() {
        return _createGetter('get:userId');
      },
      setUserId(value) {
        _createSetter('set:userId', value).then(result => {});
      },

      isAutoLogin() {
        return _createGetter('is:autoLogin');
      },
      setAutoLogin(value) {
        _createSetter('set:autoLogin', value).then(result => {});
      },

      preferences() {
        return _createGetter('get:preferences');
      },

      boolValue(key) {
        return _createGetter('get:' + key + ':bool');
      },
      setBoolValue(key, value) {
        _createSetter('set:' + key + ':bool', value).then(result => {});
      },

      stringValue(key) {
        return _createGetter('get:' + key + ':string');
      },
      setStringValue(key, value) {
        _createSetter('set:' + key + ':string', value).then(result => {});
      },

      objectValue(key) {
        return _createGetter('get:' + key + ':object');
      },
      setObjectValue(key, value) {
        _createSetter('set:' + key + ':object', value).then(result => {});
      },

      call(method, param = null) {
        _createSetter('call:' + method, param).then(result => {});
      },

      exec(callback) {
        callback();
      },

      splashOff() {
        _createSetter('call:splashOff', null).then(result => {});
      },

      clear() {
        _shared.setStringValue('userPhone', '');
        _shared.setStringValue('userId', '');
        _shared.setStringValue('session', '');
        _shared.setStringValue('reqDate', '');
        _shared.setStringValue('access_token', '');
        _shared.setStringValue('renewal_token', '');
        _shared.setStringValue('autoLoginYn', 'N');
        _shared.setStringValue('mUserNo', ''); // mjkim 230615
      },

      console(content) {
        _createSetter('call:console', { log: content }).then(result => {});
      },
    };
  }

  return {
    shared: function () {
      if (_shared) {
        return _shared;
      }

      _shared = init();

      return _shared;
    },
  };
})();

export default FTBridgeAosService;
