const FTSettings = {
  // 로그 출력 유무
  isLog: false,
  // LOCAL, 개발 : DEV, 스테이징 : STG, 운영 : PRD
  deployMode: 'PRD',
  // 클라이언트 Context
  // context: 'LIVE_TEST_01',
  context: 'TVPMV2',

  // jhkim: 추가
  logger: {
    level: -1, // 3: debug, 2: info, 1: warn, 0: error
  },
};

module.exports = FTSettings;
