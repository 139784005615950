const mutations = {
  setFitcolList(state, payload) {
    state.fitcolList = payload;
  },
  setMagazineAList(state, payload) {
    state.magazineAList = payload;
  },
  setMagazineBList(state, payload) {
    state.magazineBList = payload;
  },
  setExhibitionAList(state, payload) {
    state.exhibitionAList = payload;
  },
  setFitcolDetail(state, payload) {
    state.fitcolDetail = payload;
  },
};

export default mutations;
