<template>
  <div>
    <div v-if="getProductDetail.video_url" class="video_main_container" :style="`top: ${getProductDetail.paddingHeight}px`">
      <figure id="videoContainer">
        <video
          ref="video"
          playsinline
          preload="metadata"
          loop
          width="100%"
          height="100%"
          :src="getProductDetail.video_url"
          @click="onClick_video"
        >
          <source :src="getProductDetail.video_url" type="video/mp4" @error="handleEvent" />
          <source :src="getProductDetail.video_url" type="video/webm" @error="handleEvent" />
          <source :src="getProductDetail.video_url" type="video/ogg" @error="handleEvent" />
          <source :src="getProductDetail.video_url" type="application/x-mpegURL" @error="handleEvent" />
        </video>
      </figure>
      <transition name="fade">
        <div v-show="isShowControls" class="player_controls" @click="onClick_controls_bg">
          <div>
            <button class="btn_controls btn_play" :class="playState ? 'btn_pause' : 'btn_play'" @click.prevent.stop="onClick_play"></button>
            <!--TODO : IOS deviceType 추가 작업 필요-->
          </div>

          <button class="close-btn" @click="close"></button>
          <div class="progress-bar">
            <input type="range" min="0" :max="maxVideo" :value="currentTime" @change="seek" @input="seek" />
          </div>

          <div class="progress-timer d-flex c-center r-space-between">
            <CommonText font-size="22" font-color="#ffffff">{{ video ? secondToFulltime(video.currentTime) : '0.00' }}</CommonText>
            <CommonText font-size="22" font-color="#ffffff">{{
              video ? secondToFulltime(video.duration - video.currentTime) : '0.00'
            }}</CommonText>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<!--TODO : 실시간 동영상 재생 시 필요한 js 파일-->
<!--<script src="https://cdn.jsdelivr.net/npm/hls.js@latest"></script>-->
<script>
import CommonText from '@/components/common/text/CommonText';
export default {
  components: { CommonText },
  props: {},
  data() {
    return {
      deviceType: 1,
      OS: '',
      // getProductDetail: {
      // 	// thumbnail_images: 'http://img.w-shopping.co.kr/goods/012/10805012_g.jpg',
      // 	// url: 'http://m.w-shopping.co.kr/display/goods/10805012?mediaCode=MC63',
      // 	// video_url: 'https://e-commerce.kollus.com/wshoping/xifGHndr?mode=sr',
      // },
      // swiperOption : {
      //   freeMode : true,
      //   slidesPerView: 'auto',
      //   spaceBetween: 52,
      // },
      // videoUrl: null,
      // videoUrl: 'http://media.w3.org/2010/05/bunny/movie.ogv',
      // videoUrl: 'http://cdnwow.kshop.co.kr:8080/vod/_definst_/mp4:202009/KTH000435971_HD.mp4/playlist.m3u8',
      video: null,
      // 동영상 길이
      maxVideo: 0.1,
      isLoading: true,
      isShowControls: true,
      currentTime: '0',
      duration: '0',
      controlsTimeout: null,
      controlsTimmer: 3000, //비디오 컨트롤러가 자동으로 사라지는 시간
      playState: false, //영상 재생 여부      true: play, false: pause
      muteState: false, //음소거 여부         true: on, false: off
      screenState: false, //스크린 모드        true: landscape, false: portrait

      zoom: 0.5,
    };
  },
  computed: {
    getProductDetail() {
      return this.$store.state.common.productDetail;
    },
    isFullScreen() {
      this.$commons.printLog(
        '[CustomVideo] computed is full screen ? ',
        !!(
          document.fullscreen ||
          document.webkitIsFullScreen ||
          document.mozFullScreen ||
          document.msFullscreenElement ||
          document.fullscreenElement
        ),
      );
      return !!(
        document.fullscreen ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement ||
        document.fullscreenElement
      );
      // this.$commons.printLog("[CustomVideo] computed is full screen ? ", !!(this.video.fullscreen || this.video.webkitIsFullScreen || this.video.mozFullScreen || this.video.msFullscreenElement || this.video.fullscreenElement))
      // return !!(this.video.fullscreen || this.video.webkitIsFullScreen || this.video.mozFullScreen || this.video.msFullscreenElement || this.video.fullscreenElement)
    },
  },
  watch: {
    getProductDetail: {
      deep: true,
      handler(newVal) {
        if (newVal.video_url) {
          setTimeout(() => {
            this.$commons.printLog('[CustomVideo] video : ', this.$refs.video);
            this.video = this.$refs.video;
            this.video.addEventListener('loadstart', this.handleEvent);
            this.video.addEventListener('progress', this.handleEvent);
            this.video.addEventListener('canplay', this.handleEvent);
            this.video.addEventListener('canplaythrough', this.handleEvent);
            // this.video.addEventListener('loadeddata', this.handleEvent);
            this.video.addEventListener('play', this.handleEvent);
            this.video.addEventListener('pause', this.handleEvent);
            this.video.addEventListener('volumechange', this.handleEvent);
            this.video.addEventListener('timeupdate', this.handleEvent);
            this.video.addEventListener('durationchange', this.handleEvent);
            this.video.addEventListener('error', this.handleEvent);
            this.video.addEventListener('emptied', this.handleEvent);
            this.video.addEventListener('stalled', this.handleEvent);
            // this.video.onerror = function(e){
            //   this.$commons.printLog("CustomVideo test!!!!!", e)
            // }
          }, 50);
        }
      },
    },
  },
  created() {
    // if (this.getProductDetail.video_url) {
    // 	this.$commons.printLog(this.getProductDetail.video_url);
    // 	this.$commons.printLog('[CustomVideo] video : ', this.$refs.video);
    // 	this.video = this.$refs.video;
    // 	this.video.addEventListener('loadstart', this.handleEvent);
    // 	this.video.addEventListener('progress', this.handleEvent);
    // 	this.video.addEventListener('canplay', this.handleEvent);
    // 	this.video.addEventListener('canplaythrough', this.handleEvent);
    // 	// this.video.addEventListener('loadeddata', this.handleEvent);
    // 	this.video.addEventListener('play', this.handleEvent);
    // 	this.video.addEventListener('pause', this.handleEvent);
    // 	this.video.addEventListener('volumechange', this.handleEvent);
    // 	this.video.addEventListener('timeupdate', this.handleEvent);
    // 	this.video.addEventListener('durationchange', this.handleEvent);
    // 	this.video.addEventListener('error', this.handleEvent);
    // 	this.video.addEventListener('emptied', this.handleEvent);
    // 	this.video.addEventListener('stalled', this.handleEvent);
    // 	// this.video.onerror = function(e){
    // 	//   this.$commons.printLog("CustomVideo test!!!!!", e)
    // 	// }
    // 	window.addEventListener('resize', this.onResize);
    // }

    this.$store.commit('setProductDetail', { thumbnail_images: '', url: '', video_url: '', paddingHeight: 0 });
  },
  mounted() {},

  methods: {
    close() {
      this.$store.commit('setProductDetail', { thumbnail_images: '', url: '', video_url: '', paddingHeight: 0 });
    },
    formatDate(seconds) {
      const secondsNumber = parseInt(seconds, 10);
      let hours = Math.floor(secondsNumber / 3600);
      let minutes = Math.floor((secondsNumber - hours * 3600) / 60);
      let totalSeconds = secondsNumber - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (totalSeconds < 10) {
        totalSeconds = `0${totalSeconds}`;
      }
      return `${hours}:${minutes}:${totalSeconds}`;
    },
    seek(ev) {
      this.video.currentTime = ev.target.value;
    },
    //video layer를 클릭했을때(video controls off상태)
    onClick_video() {
      this.$commons.printLog('[CustomVideo] onClick_video isShowControls : ', this.isShowControls);
      this.isShowControls = true;
      //재생중일때만 자동으로 사라지도록 함
      if (this.playState) {
        this.controlsTimeout = setTimeout(() => {
          this.isShowControls = false;
        }, this.controlsTimmer);
      }
    },
    onClick_controls_bg() {
      this.$commons.printLog('[CustomVideo] onClick_cover timeout number : ', this.controlsTimeout);

      clearTimeout(this.controlsTimeout);
      this.isShowControls = false;
    },

    onClick_play() {
      if (this.playState) {
        this.video.pause();
      } else {
        this.video.play();
      }
    },
    onClick_volume() {
      this.$commons.printLog('[CustomVideo] onClick_volume', this.muteState);
      this.video.muted = !this.video.muted;
    },

    //초를 HH:mm:ss 포맷으로 바꿈
    secondToFulltime(second) {
      if (isNaN(second)) return '0:00';

      const hour = Math.floor(second / 3600);
      const minutes = Math.floor(second / 60);
      const seconds = (second % 60).toFixed(0);
      return (hour < 1 ? '' : hour + ':') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    },
    //Video Event Handler
    handleEvent(event) {
      // let eventType = `${event.type}`
      // this.$commons.printLog("[CustomVideo] handleEvent", event)
      // this.$commons.printLog("[CustomVideo] handleEvent : " , event.type)
      switch (event.type) {
        case 'error':
          switch (event.target.error.code) {
            case event.target.error.MEDIA_ERR_ABORTED:
              alert('You aborted the video playback.');
              break;
            case event.target.error.MEDIA_ERR_NETWORK:
              alert('A network error caused the video download to fail part-way.');
              break;
            case event.target.error.MEDIA_ERR_DECODE:
              alert(
                'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.',
              );
              break;
            case event.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
              // alert('The video could not be loaded, either because the server or network failed or because the format is not supported.');
              // alert('The video could not be loaded, either because the server or network failed or because the format is not supported.');
              //TODO - 22-01-28 hchwang 현재 사용되지 않아 일단 주석
              // if (Hls.isSupported()) {
              // 	let hls = new Hls();
              // 	hls.loadSource(this.getProductDetail.video_url);
              // 	hls.attachMedia(this.video);
              // } else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
              // 	this.video.src = this.getProductDetail.video_url;
              // } else {
              // 	alert('The video could not be loaded, either because the server or network failed or because the format is not supported.');
              // }
              break;
            default:
              alert('An unknown error occurred.');
              break;
          }
          break;
        case 'loadstart':
          break;
        case 'progress':
          break;

        case 'loadeddata':
          break;

        /* 전채 재생시간 변경 */
        case 'durationchange':
          this.duration = this.video.duration;
          break;

        /* 비디오가 두 프레임 이상 재생 가능 */
        case 'canplay':
          this.duration = this.video.duration;
          this.isLoading = false; //비디오 로딩 프로그래스 바 제거
          break;

        /* 비디오 전체를 현재속도로 재생하기에 문제 없음.  */
        case 'canplaythrough':
          break;

        /* 재생 */
        case 'play':
          this.playState = true;
          this.maxVideo = this.video.duration;
          //재생할 경우 컨트롤러가 3초후 사라지도록 함
          this.controlsTimeout = setTimeout(() => {
            this.isShowControls = false;
          }, this.controlsTimmer);
          break;

        /* 일시정지 */
        case 'pause':
          this.playState = false;
          //일시정지 할 경우 컨트롤러가 사라지지 않도록 함
          if (this.controlsTimeout) {
            clearTimeout(this.controlsTimeout);
          }
          break;

        /* 볼륨 변경 */
        case 'volumechange':
          this.$commons.printLog('[CustomVideo2] volumeChange muted :', this.video.muted);
          this.$commons.printLog('[CustomVideo2] volumeChange volume :', this.video.volume);
          //muted 상태일경우에는 true, 아닐경우 공백으로 체크
          if (this.video.muted) {
            this.muteState = true;
          } else {
            this.muteState = false;
          }
          break;

        /* 현재 재생시간 변경 */
        case 'timeupdate':
          this.currentTime = this.video.currentTime;
          // this.$commons.printLog('진행시간:' + (this.video.duration - this.video.currentTime));

          document.documentElement.style.setProperty('--videoProgress', `${(event.target.currentTime / event.target.duration) * 100}%`);
          break;
        default:
          break;
      }
    },
    startFS(element) {
      this.$commons.printLog('[CustomVideo] startFs Call', element);

      //IOS
      if (this.video.webkitSetPresentationMode) {
        this.video.webkitSetPresentationMode('fullscreen');
      }
      //나머지(카카오톡 브라우저에서 정상동작 안됨)
      else {
        if (element.requestFullscreen) element.requestFullscreen();
        else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
        else if (element.webkitRequestFullScreen) element.webkitRequestFullScreen();
        else if (element.msRequestFullscreen) element.msRequestFullscreen();
        // if (this.video.requestFullscreen) this.video.requestFullscreen()
        // else if (this.video.mozRequestFullScreen) this.video.mozRequestFullScreen()
        // else if (this.video.webkitRequestFullScreen) this.video.webkitRequestFullScreen()
        // else if (this.video.msRequestFullscreen) this.video.msRequestFullscreen()
        if (screen.orientation && screen.orientation.lock) {
          screen.orientation.lock('landscape');
        }
        this.videoScale = 'full';
      }
    },
    exitFS() {
      this.$commons.printLog('[CustomVideo] exitFS Call');
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      // if (this.video.exitFullscreen) this.video.exitFullscreen();
      // else if (this.video.mozCancelFullScreen) this.video.mozCancelFullScreen();
      // else if (this.video.webkitCancelFullScreen) this.video.webkitCancelFullScreen();
      // else if (this.video.msExitFullscreen) this.video.msExitFullscreen();
      this.videoScale = 'normal';
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  //display: flex;
  //height: 10px;
  //margin-top: 300px;
  margin: 300px 40px 0;

  input[type='range'] {
    -webkit-appearance: none;
    overflow: hidden;
    width: 100%;
    height: 4px;
    background: transparent;
    cursor: pointer;
    background: #e5e4e3;
    border-radius: 0; /* iOS */
  }

  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw dodgerblue;
    border: 0.1px solid dodgerblue;
    cursor: pointer;
  }
}

.progress-timer {
  width: 640px;
  margin: 0 40px;
}

.fixed {
  position: fixed;
  z-index: 15;
}
.transition {
  transition: 0.5s all ease;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
}
.video_main_container {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 405px;
  background-color: rgba(34, 34, 34, 0.88);
}
.video_main_container_fold {
  width: 100% !important;
  height: 160px !important;
}

#videoContainer {
  width: 100%;
  height: 405px;
  margin: 0px;
  transform-origin: top right;
  background-color: rgba(34, 34, 34, 0.88);
}
.video_container_fold {
  position: absolute;
  transform: translate(-8px, 8px) scale(0.34);
  // transform:matrix(0.34, 0, 464px, 0, 0.34, 8px, 0)
}
.btn_back {
  width: 31px;
  height: 60px;
  //background: url('../../../assets/images/btn_back_white.png') no-repeat;
  background-position: center center;
}
.active {
  opacity: 1;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1.28px;
}
.player_controls {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.5);
  width: inherit;
  height: inherit;
}
.btn_controls {
  //position: absolute;
  //object-fit: contain;
}
.btn_play {
  position: absolute;
  width: 104px;
  height: 104px;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  background: url('../../assets/img/fit/btn_play.svg') no-repeat center center;
}
.btn_pause {
  position: absolute;
  width: 104px;
  height: 104px;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  background: url('../../assets/img/fit/btn_play.svg') no-repeat center center;
}

.close-btn {
  position: absolute;
  width: 64px;
  height: 64px;
  left: 32px;
  top: 32px;
  //position: relative;
  //left: 650px;
  //top: 280px;
  background: url('../../assets/img/fit/btn_video_clse.svg') no-repeat center center;
}

.btn_volume_on {
  width: 40px;
  height: 40px;
  //position: relative;
  //left: 650px;
  //top: 280px;
  position: absolute;
  left: 92%;
  top: 60%;
  //background: url('../../assets/img/fit/btn_sound_on.png') no-repeat center center;
}
.btn_volume_off {
  width: 40px;
  height: 40px;
  //position: relative;
  //left: 650px;
  //top: 280px;
  position: absolute;
  left: 92%;
  top: 60%;
  //background: url('../../assets/img/fit/btn_sound_off.png') no-repeat center center;
}
.btn_fullscreen_on {
  width: 40px;
  height: 40px;
  right: 30px;
  bottom: 60px;
  //background: url('../../assets/img/fit/btn_sound_on.png') no-repeat center center;
}
.btn_fullscreen_off {
  width: 40px;
  height: 40px;
  right: 30px;
  bottom: 60px;
  //background: url('../../assets/img/fit/btn_video_small.png') no-repeat center center;
}

.rotate {
  transform: rotate(90deg) translateY(-100%);
  transform-origin: top left;
  height: 100vw;
  width: 100vh;
}
video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls {
  display: none !important;
}

/* Old shadow dom for play button */

video::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
#either-gif-or-video video {
  display: none;
}
@media (-webkit-video-playable-inline) {
  #either-gif-or-video img {
    display: none;
  }
  #either-gif-or-video video {
    display: initial;
  }
}

.fade-enter-active {
  transition: all 0.5s;
}
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.call_order_btn_new_portrait {
  background: #333333;
  z-index: 20;
  text-align: center;
  font-size: 20px;
  line-height: 1.85;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  border-right: solid 2px #555555;
  border-radius: 0;
}

.call_order_btn_new_landscape {
  background: #333333;
  z-index: 20;
  text-align: center;
  font-size: 26px;
  line-height: 1.42;
  color: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  border-right: solid 2px #555555;
  border-radius: 0;
}

.img-header {
  position: relative;
}

.video-header {
  position: absolute;
  z-index: 10;
  top: 0;
}
</style>
