//menu create
import $store from '@/store';

const FTInitSetUp = {
  makeMenus(serviceType) {
    const pageData = $store.state.fit.pageDepth1.filter(data => data.menu_type === serviceType);
    const menuList = [];
    const componentTypeOf = function (uri) {
      let result = '';

      // jhkim: Tvpoint, Pai, Tvpay 동적으로 구성
      // 주의: 현재 uri로만 분기가 가능한 상황이므로, 화면이 출력 안 될 경우 서버에서 uri 변경 여부 체크
      if (uri.endsWith('/pages/point')) {
        result = 'Tvpoint';
      } else if (uri.endsWith('/pages/pai')) {
        result = 'Pai';
      } else if (uri.endsWith('/pages/pay')) {
        result = 'Tvpay';
      } else {
        result = 'BsIndex';
      }

      return result;
    };

    // jhkim: idx, depth, uri 추가
    for (let i in pageData) {
      menuList.push({
        idx: i,
        id: pageData[i].id,
        title: pageData[i].title,
        depth: pageData[i].depth,
        order: pageData[i].order,
        uri: pageData[i].uri,
        componentType: componentTypeOf(pageData[i].uri),
        staticTemplateList: [], // 정적
        dynamicTemplateList: [], // 동적
        rollingBanner: {}, // jhkim: 2023.07.17: 화면 잔상 제거 위해 각 페이지별 관리. 메모리상으로만 존재.
      });
    }

    return menuList;
  },
};
export default FTInitSetUp;
