<template>
  <div class="popup-wrap">
    <div class="popup-content">
      로그인이 필요한 서비스 입니다.<br />앱카드 로그인 시 결제 카드 등록과 로그인을 동시에 진행할 수 있습니다.
    </div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="confirm">취소</button>
      <button class="popup-button-yes" @click="goLogin">로그인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderLoginPopup',
  methods: {
    async goLogin() {
      await this.$store.commit('alertPopup', {});
      // await this.$router.push('/user/LoginPage');
      await this.$router.push('/user/login');
    },
    confirm() {
      this.$store.commit('alertPopup', {});
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px 40px 42px 40px;
}
</style>
