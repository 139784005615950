import $store from '@/store';

const tvpay = [
  {
    path: '/tvpay/management',
    name: 'tvpayManagement',
    component: () => import('@/views/tvpay/management'),
    async beforeEnter(to, from, next) {
      if (
        from.path === '/user/SelectAuthPage' &&
        $store.state.tvpay.cardManagementTitleHistory === 0 &&
        $store.state.tvpoint.mobileUserYN === 'N'
      ) {
        //들어오기 이전이 모바일 리스트 였고 리스트가 없을 경우에 티비쪽 리스트 노출 방지 위해
        $store.commit('setCardResult', '');
      }
      if (parseInt($store.state.tvpay.cardResult.listCount) === 0) await $store.dispatch('setTVCardListCount');
      next();
    },
  },
  {
    path: '/tvpay/tvpayUseDetail',
    name: 'tvpayUseDetail',
    component: () => import('@/views/common/toUse'),
    async beforeEnter(to, from, next) {
      await $store.dispatch('fitPointUseDetail', 2);
      next();
    },
  },
  {
    path: '/tvpay/paymentDetail',
    name: 'tvpayPaymentDetail',
    component: () => import('@/views/tvpay/paymentDetail'),
  },
  {
    path: '/tvpay/paymentHistory',
    name: 'paymentHistory',
    component: () => import('@/views/tvpay/paymentHistory'),
  },
  {
    path: '/tvpay/register',
    name: 'registerCard',
    component: () => import('@/views/tvpay/register/index.vue'),
  },
  {
    path: '/tvpay/register/PaySelfAuth',
    name: 'payselfauth',
    component: () => import('@/views/tvpay/register/PaySelfAuth'),
  },
  {
    path: '/tvpay/register/CardPhoneChange',
    name: 'CardPhoneChange',
    component: () => import('@/views/tvpay/register/CardPhoneChange'),
  },
  {
    path: '/tvpay/register/PaySelfAuthPhone',
    name: 'PaySelfAuthPhone',
    component: () => import('@/views/tvpay/register/PaySelfAuthPhone'),
  },
  {
    path: '/tvpay/recurringPaymentPass',
    name: 'RecurringPaymentPass',
    component: () => import('@/views/tvpay/recurringPaymentPass'),
  },
  {
    path: '/tvpay/recurringPaymentPassDetail',
    name: 'RecurringPaymentPassDetail',
    component: () => import('@/views/tvpay/recurringPaymentPassDetail'),
  },
  {
    path: '/melon/success',
    name: 'success',
    component: () => import('@/views/tvpay/subscriptionPurchase/SubscriptionPurchaseCompleted'),
  },
  {
    path: '/melon/fail',
    name: 'fail',
    component: () => import('@/views/tvpay/subscriptionPurchase/SubscriptionPurchaseFail'),
  },
  {
    path: '/tvpay/subscriptionPurchase/AppCardAuthComplete',
    name: 'AppCardAuthComplete',
    component: () => import('@/views/tvpay/subscriptionPurchase/AppCardAuthComplete'),
  },
  {
    path: '/tvpay/cancelSubscription',
    name: 'cancelSubscription',
    component: () => import('@/views/tvpay/cancelSubscription'),
  },
  {
    path: '/tvpay/subscriptionPurchase/PaymentFail',
    name: 'PaymentFail',
    component: () => import('@/views/tvpay/subscriptionPurchase/PaymentFail'),
  },
];

export default tvpay;
