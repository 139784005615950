<template>
  <div class="popup-wrap">
    <div class="popup-title" v-html="title"></div>
    <div class="popup-content" v-html="content"></div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <!-- <button class="popup-button-no" @click="confirm">취소</button> -->
      <button class="popup-button-yes" @click="confirm">확인</button>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus';
import { fitMixin } from '@/utils/fitMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'EndCancelMelonPopup',
  mixins: [fitMixin],
  props: {
    title: {
      type: String,
      default: '',
      require: false,
    },
    content: {
      type: String,
      default: '',
      require: true,
    },
  },

  computed: {
    ...mapGetters(['getAlertPopup']),
  },

  methods: {
    confirm() {
      this.$store.commit('alertPopup', {});
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
  margin-bottom: 30px;
}
.popup-wrap {
  padding: 40px 40px 42px 40px;
}
</style>
