import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import common from '@/store/common';
import fit from '@/store/fit';
import tvpoint from '@/store/tvpoint';
import tvpay from '@/store/tvpay';
import pai from '@/store/pai';
import bbarshop from '@/store/bbarshop';
import orderPayment from '@/store/orderPayment';
import fitcol from '@/store/fitcol';

const store = createStore({
  modules: {
    common,
    fit,
    tvpoint,
    tvpay,
    pai,
    fitcol,
    bbarshop,
    orderPayment,
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});

export default store;
