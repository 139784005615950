<template>
  <div class="check-family-wrap common-padding">
    <div class="float-setting">
      <div>
        <input id="terms" ref="termsCheckbox" v-model="checkTerms" type="checkbox" name="" class="check-family-terms" />
        <label for="terms"></label>
      </div>
      <div class="pay-terms-right">가족회원 포인트 조회 동의</div>
    </div>
    <div class="check-terms-detail">본인은 가족회원으로부터 포인트 조회에 대한 동의를 받고 가족회원의 본인인증을 대리로 진행합니다.</div>
    <button class="pay-terms-submit submit-btn" :class="checkTerms ? 'focus' : ''" @click="goConfirm">가족회원 포인트 조회하기</button>
  </div>
</template>

<script>
export default {
  name: 'CheckFamily',
  data() {
    return {
      checkTerms: true,
    };
  },
  methods: {
    async goConfirm() {
      if (this.checkTerms) {
        await this.$store.commit('slideShow', { slideShow: false, slideType: '' });
        await this.$router.push('/tvpoint/family/check');
      }
    },
  },
};
</script>

<style scoped>
.pay-terms-submit {
  width: 100%;
  height: 100px;
  margin: 122px 0 40px 0;
  border-radius: 10px;

  font-size: 32px;
  line-height: 1.31;
  letter-spacing: -1.6px;
  text-align: center;
}

.check-terms-detail {
  padding-top: 70px;
  font-size: 30px;
  line-height: 1.47;
  letter-spacing: -1.5px;
  text-align: left;
  color: #222222;
}

.check-family-wrap {
  margin-top: 47px;
}
.check-family-terms + label {
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  background: url('../../../assets/img/common/check_circle_off.svg') no-repeat;
  box-sizing: border-box;
}

.check-family-terms {
  display: none;
  float: left;
  box-sizing: border-box;
}

.check-family-terms:checked + label {
  float: left;
  display: inline-block;
  margin-top: 2px;
  width: 42px;
  height: 42px;
  background: url('../../../assets/img/common/check_circle_on.svg') no-repeat;
  box-sizing: border-box;
}

.pay-terms-right {
  display: inline-block;
  width: 450px;
  margin-top: 6px;
  margin-left: 18px;
  height: 30px;
  line-height: 30px;

  font-size: 34px;
  font-weight: bold;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
</style>
