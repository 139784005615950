<template>
  <div class="popup-wrap">
    <div class="popup-title">이용권 해지</div>
    <div class="popup-content">이용권을 해지하시겠습니까?<br />사용 중인 이용권은 유효기간까지 이용가능합니다.</div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="cancel">취소</button>
      <button class="popup-button-yes" @click="agree">해지하기</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CancelPassPopup',

  computed: {
    ...mapGetters(['getAlertPopup']),
    subscriptionId() {
      return this.getAlertPopup.subscriptionId;
    },
    mUserNo() {
      return this.getAlertPopup.mUserNo;
    },
    storeId() {
      return this.getAlertPopup.storeId;
    },
  },
  methods: {
    cancel() {
      this.$store.commit('alertPopup', { transAgree: 'N' });
    },
    agree() {
      //이용권 취소
      const params = {
        mUserNo: this.mUserNo,
        storeId: this.storeId,
        subscriptionId: this.subscriptionId,
      };
      this.$store.dispatch('cancelSpotvService', params).then(res => {
        if (res.rewultCode === '0000') {
          this.$store.commit('alertPopup', { transAgree: 'N' });
          this.$store.commit('alertPopup', {
            popupType: 'CancelPassAgreePopup',
          });
        } else {
          this.$utils.errorMessage(this, res);
        }
        // this.$store.commit('alertPopup', { transAgree: 'N' });
        // this.$store.commit('alertPopup', {
        // 	popupType: 'CancelPassAgreePopup',
        // });
      });
    },
  },
};
</script>

<style scoped>
.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
  margin-bottom: 20px;
}
.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px 40px 42px 40px;
}
</style>
