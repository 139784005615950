<template>
  <div v-if="isShowNavbar" id="mainHeader" class="header">
    <div class="header__wrap d-flex">
      <div @click="clickEvent('menu')">
        <img :src="require('@/assets/img/common/btn_tab.svg')" class="header__wrap__menu" alt="" />
      </div>
      <div class="header__right d-flex">
        <div @click="clickEvent('search')">
          <img
            v-if="$route.fullPath === '/bbarshop'"
            :src="require('@/assets/img/bbarshop/btn_search.svg')"
            class="header__search"
            alt=""
          />
        </div>
        <div @click="clickEvent('heart')">
          <img v-if="$route.fullPath === '/bbarshop'" :src="require('@/assets/img/bbarshop/btn_heart.svg')" class="header__heart" alt="" />
        </div>
        <div v-if="isLogin || !(!isLogin && getDeviceType === 2)" @click="clickEvent('noti')">
          <img :src="require('@/assets/img/common/ic_alarm.svg')" class="alarmWrapper" alt="" />
          <span v-if="isLogin && $store.state.pai.alarmCount > 0" class="alarm">{{ `${$store.state.pai.alarmCount}` }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// jhkim: 20230720: 추가
import bus from '@/utils/bus';
import FTTimer from '@/modules/ft-timer';
import Logger from '@/modules/ft-logger';

import { mapGetters } from 'vuex';

export default {
  name: 'MainHeader',

  emits: ['scrollShow'],

  data() {
    return {
      scrollTop: 0,
      isShowNavbar: true,
      delta: 100,
      isCall: false,
      // jhkim: 20230720: 추가
      updateTime: 0,
      isLockScroll: false,
      timer: null,
    };
  },

  computed: {
    ...mapGetters(['isLogin', 'getDeviceType']),
    mainIdx() {
      return this.$store.state.common.mainIdx;
    },
  },

  created() {
    window.addEventListener('scroll', this.handleScrollarrow);
    // jhkim: 20230720: 추가
    bus.$on('lockScroll', this.lockScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScrollarrow);
    // jhkim: 20230720: 추가
    bus.$off('lockScroll');
  },

  methods: {
    goPage(to) {
      this.$store.commit('setMainPage', to);
    },
    // jhkim: 20230720: 추가
    lockScroll(isLock) {
      Logger.debug('lock: main header: ', isLock);
      this.isLockScroll = isLock;
    },
    // jhkim: 20230720: 추가
    fireScrollEvent(isShow) {
      if (this.isShowNavbar == isShow) {
        return;
      }

      this.isShowNavbar = isShow;
      this.$emit('scrollShow', isShow);
    },
    // jhkim: 20230720: 추가
    updateLastScrollPosition() {
      setTimeout(() => {
        const element = document.documentElement;
        if (element.scrollTop >= 0) {
          this.$store.commit('setLastScrollPosition', element.scrollTop);
        } else {
          this.$store.commit('setLastScrollPosition', 0);
        }
        // this.$store.commit('setLastScrollPosition', element.scrollTop);
      }, 10);
    },
    // jhkim: 20230720: 추가
    onScroll() {
      const element = document.documentElement;
      const lastScrollPosition = this.$store.state.common.lastScrollPosition;
      const scrollDiff = lastScrollPosition - element.scrollTop;

      // 해더 최소 이동 크기 지정
      // 스크롤 길이와 화면 크기가 헤더 크기보다 작은 경우 이동X
      if (Math.abs(element.clientHeight - element.scrollHeight) < 100) {
        Logger.debug('scroll: main header: small: ');
        return;
      }

      if (scrollDiff >= 0 && (scrollDiff >= 80 || element.scrollTop <= 10)) {
        Logger.debug('scroll: main header: down: %d, %d, %d', lastScrollPosition, element.scrollTop, scrollDiff);
        this.updateLastScrollPosition();
        this.fireScrollEvent(true);
      } else if (scrollDiff <= 0 && (scrollDiff <= -80 || element.scrollTop >= element.scrollHeight - element.clientHeight - 10)) {
        Logger.debug('scroll: main header: up: %d, %d, %d', lastScrollPosition, element.scrollTop, scrollDiff);
        this.updateLastScrollPosition();
        this.fireScrollEvent(false);
      }
    },
    handleScrollarrow() {
      // jhkim: 20230720: 추가
      if (this.isLockScroll) {
        return;
      }

      if (this.timer == null) {
        this.timer = new FTTimer();
      }

      if (this.isCall) {
        this.timer.start(this.onScroll, 150);
        return;
      }

      this.isCall = true;
      setTimeout(() => {
        this.isCall = false;
      }, 100);

      const time = new Date();
      const currentTime = time.getTime();
      const element = document.documentElement;
      Logger.debug(
        'scroll: main header: %d, %d, %d, %d, %d',
        element.clientHeight,
        element.scrollHeight,
        this.$store.state.common.lastScrollPosition,
        element.scrollTop,
        currentTime - this.updateTime,
      );
      this.updateTime = currentTime;

      // jhkim: 20230720:
      this.timer.cancel();
      this.onScroll();

      // 아이폰 위로 스크롤 팅김 방지
      // if (element.scrollTop <= 10) {
      // 	Logger.debug('scroll: main header: 1: ', element.scrollTop);
      // 	// jhkim: 20230720:
      // 	this.updateLastScrollPosition();
      // 	this.fireScrollEvent(true);
      // 	return;
      // }

      // ios 팅기는 부분 방지
      // 팅겼을 때 스크롤 이벤트 막기
      // if (document.body.scrollHeight - element.scrollTop <= window.innerHeight && element.scrollTop > 0) {
      // 	Logger.debug('scroll: main header: 2: ', element.scrollTop);
      // 	// jhkim: 20230720:
      // 	this.updateLastScrollPosition();
      // 	this.fireScrollEvent(false);
      // 	return;
      // }
    },
    clickEvent(sort) {
      switch (sort) {
        case 'noti':
          this.$router.push('/notifications');
          break;
        case 'menu':
          this.$router.push('/menu');
          break;
        case 'search':
          this.$router.push('/bbarshop/search');
          break;
        case 'heart':
          this.$router.push({
            name: 'zzim',
            query: { type: 0 },
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  width: calc(100%);
  height: 120px;
  z-index: 15;
  box-sizing: border-box;
  background-color: #fff;

  &.darkHeader {
    background-color: #2b2d2f;
  }

  #{&}__wrap {
    width: calc(100% - 80px);
    margin: 0 40px;
    z-index: 15;
    height: 50px;
    justify-content: space-between;
    padding-top: 40px;

    #{&}__menu {
      width: 44px;
      height: 44px;
    }
  }
  #{&}__right {
    //width: 160px;
    justify-content: space-between;
    align-items: center;
  }

  .header__search {
    width: 50px;
    height: 50px;
    margin-right: 24px;
  }

  .header__heart {
    width: 50px;
    height: 50px;
  }
  .alarmWrapper {
    width: 50px;
    height: 50px;
    margin-left: 24px;
  }
  .alarm {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    position: absolute;
    top: 35px;
    right: 35px;
    background-color: #e42028;
    color: white;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 30px;
  }
}
</style>
