<template>
  <div>
    <select
      v-if="selectType === 'card'"
      ref="optionMore"
      v-model="selectCard"
      class="column__title options-wrap"
      :disabled="activeCheck"
      :class="newSelect ? 'add-card-btn' : 'discount-btn'"
    >
      <option v-for="(item, idx) in cardList" :key="idx" :value="`${idx}`" :selected="idx === 0" style="font-size: 20px">
        <CommonText font-size="28" font-color="#2b2d2f">{{ item.cardName }}</CommonText>
      </option>
    </select>
    <select
      v-if="selectType === 'discount'"
      ref="optionDiscount"
      v-model="selectDiscount"
      class="column__title options-wrap"
      :disabled="activeCheck"
      :class="newSelect ? 'add-card-btn' : 'discount-btn'"
    >
      <option v-for="(item, idx) in optionList" :key="idx" :value="idx" style="font-size: 20px">
        <CommonText font-size="28" font-color="#2b2d2f">{{ item.name }}</CommonText>
      </option>
    </select>
  </div>
</template>

<script>
import ColumnHeader from '@/components/menu/ColumnHeader';
import CommonText from '@/components/common/text/CommonText';
export default {
  name: 'CardSelect',
  components: {
    // ColumnHeader,
    CommonText,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: Number,
      default: 0,
    },
    cardList: {
      type: Array,
      default: () => [],
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    newSelect: {
      type: Boolean,
      default: false,
    },
    selectType: {
      type: String,
      default: 'discount',
    },
    activeCheck: {
      type: Boolean,
      default: false,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedOptions: [],
      // selectDiscount: 0,
      selectCard: '0',
    };
  },
  computed: {
    selectDiscount: {
      get() {
        return 0;
      },
      set(newValue) {
        this.$store.commit('setSelectDiscount', this.optionList[newValue]);
        return newValue;
      },
    },
  },
  watch: {
    selectCard(newVal) {
      if (!this.$commons.isNull(this.cardList)) {
        this.$store.commit('setSelectCard', this.cardList[newVal]);
      }
    },
    activeIndex(newVal) {
      this.selectDiscount = 0;
      // this.computedSelectDiscount = 0;
    },
  },
  created() {
    this.$store.state.orderPayment.selectDiscount = { instType: '1', name: '일시불', installment: '00' };
  },
};
</script>

<style lang="scss" scoped>
.column__title {
  margin: 0;
  border-radius: 8px;
  padding-left: 14px;
  border: none;
}
option {
  background-color: black;
  color: white;
}
.options-wrap {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  margin-right: 14px;
  font-size: 24px;
  line-height: 1.71;
  letter-spacing: -0.96px;
}
.discount-btn {
  text-align: left;
  color: #f2f3f5;
  background: rgba(0, 0, 0, 0.1) url('../../assets/img/bbarshop/img_arr_low2_white.svg') no-repeat right 14px center;

  &click {
    // background: url('../../assets/img/bbarshop/img_arr_up2_white.svg') no-repeat;
  }
}
.add-card-btn {
  color: #5d5f61;
  background: #f2f3f5 url('../../assets/img/bbarshop/img_arr_low2.svg') no-repeat right 14px center;

  &click {
    background: url('../../assets/img/bbarshop/img_arr_up2.svg') no-repeat;
  }
}
</style>
