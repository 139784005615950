<template>
  <div class="popup-wrap">
    <div class="popup-title">알림</div>
    <div class="popup-content">
      {{ content }} 조회/사용에 대한 동의가<br />최초 1회 필요합니다.<br />{{ content }} 조회/사용에 필요한 비밀번호를<br />TV포인트
      비밀번호로 사용함에 동의하십니까?
    </div>
    <div class="popup-button-wrap">
      <button class="popup-button-no" @click="confirm">아니오</button>
      <button class="popup-button-yes" @click="setTransAgree">네</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters(['getPartnerShip']),
  },
  methods: {
    confirm() {
      this.$store.commit('alertPopup', { transAgree: 'N' });
    },
    setTransAgree() {
      const data = this.$store.dispatch('setTransAgree', { transAgreeYN: 'Y', partnershipId: this.getPartnerShip.partnerShipId });
      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          if (this.type == 'single') {
            this.$store.commit('alertPopup', {});
            this.$router.push('/tvpoint/exchange/SingleTransView');
          } else {
            bus.$emit('end:loading');
            this.$store.commit('alertPopup', { transAgree: 'Y' });
            // this.$store.commit("slideShow", { slideShow: true, slideType: "PointPassword" });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-button-yes {
  margin-left: 44px;
  font-size: 30px;
  letter-spacing: -1.5px;
  font-weight: 500;
  color: #648cff;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #2b2d2f;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  margin-top: 40px;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #2b2d2f;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px;
}
</style>
