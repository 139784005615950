<template>
  <div class="content-container">
    <!--메인화면 헤더-->
    <!--		<keep-alive>-->
    <MainHeader v-if="headerView" @scroll-show="scrollShow" />
    <!--		</keep-alive>-->
    <EmpayPaddingBar v-if="headerView && isShowScrollNavbar" :padding-height="120" />
    <!--메인화면 상단 메뉴 슬라이더-->
    <HeaderSwiper
      v-if="headerView"
      ref="headerSwiper"
      :value="$store.state.common.mainIdx"
      :is-show-scroll-navbar="isShowMenuNav"
      @change-page="changePage"
    />
    <!--		<EmptyPaddingBar v-if="isSubHeader" :padding-height="114"></EmptyPaddingBar>-->
    <!--    BODY-->
    <EmpayPaddingBar v-if="$route.fullPath !== '/menu' && headerView" :padding-height="69" />
    <!--비디오 재생 화면-->
    <CustomVideo />
    <!--모달-->

    <!--토스트팝업-->
    <ToastPopup />

    <!-- <keep-alive :include="['MenuIndex']"> -->
    <!-- jhkim: vue3: -->
    <router-view v-slot="{ Component }" @init="onInit">
      <keep-alive :include="['InitPageIndex', 'BbarShopInitIndex']">
        <component :is="Component" ref="child" />
      </keep-alive>
    </router-view>

    <!-- TODO - 홈빠, 빠숍 적용 될때 사용-->
    <EmpayPaddingBar v-if="footerView" :padding-height="135" />
    <FooterBtn v-show="footerView" @change-service="changeService" />

    <!-- 컴포넌트 모달 팝업 - 이벤트 리스트, 혜택리스트 -->
    <ComponentPopup @close="clickEvent" />
    <TutorialModal />
  </div>
</template>

<script>
import Logger from '@/modules/ft-logger';
import CustomVideo from '@/components/pai/CustomVideo';
import HeaderSwiper from '@/components/nav/header/swiper/HeaderSwiper';
import MainHeader from '@/components/nav/header/MainHeader';
import bus from '@/utils/bus';
import EmpayPaddingBar from '@/components/common/EmptyPaddingBar';
import ToastPopup from '@/components/common/popup/ToastPopup';
import ComponentPopup from '@/components/common/popup/ComponentPopup';
import TutorialModal from '@/components/common/popup/TutorialModal';
import FooterBtn from '@/components/nav/footer/FooterBtn';

/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'FitMainIndex',
  components: {
    TutorialModal,
    FooterBtn,
    ToastPopup,
    EmpayPaddingBar,
    CustomVideo,
    HeaderSwiper,
    MainHeader,
    ComponentPopup,
  },

  emits: ['onInit'],

  data() {
    return {
      isSlidingPopup: false,
      isLoading: false,
      allIdx: 5,
      isShowScrollNavbar: true,
      isMainHeader: false,
      // isSubHeader: false,
      isFooter: false,
      isRewardHeader: false,
      showMoreBtn: false,
      // jhkim: 20230720
      isLockScroll: false,
    };
  },

  computed: {
    headerView() {
      // return this.isMainHeader && (this.$route.name === 'initPage' || this.$route.name === 'bbarshop');
      return this.$route.name === 'initPage' || this.$route.name === 'bbarshop';
    },
    footerView() {
      // return this.isFooter && (this.$route.name === 'initPage' || this.$route.name === 'bbarshop');
      return this.$route.name === 'initPage' || this.$route.name === 'bbarshop';
    },
    // jhkim: 20230720
    isShowMenuNav() {
      return this.isShowScrollNavbar;
    },
  },

  created() {
    bus.$emit('start:loading');
    // jhkim: 20230720: 핏, 빠숍 홈에서 생성시 사용하고 있어 복구
    bus.$on('show:mainHeader', this.showMainHeader);
    // bus.$on('hide:mainHeader', this.hideMainHeader);
    // bus.$on('show:footer', this.showFooter);
    // bus.$on('hide:footer', this.hideFooter);
    bus.$on('set:init', this.initData);
  },

  beforeMount() {
    if (window.location.href.indexOf('sourceId') > -1) {
      let sourceId = window.location.href.split('sourceId=')[1].split('&')[0];
      if (sourceId) {
        this.$store.commit('setSourceId', sourceId);
        // this.$router.replace({ query: {} }); // pushType commit 후 초기화
      }
    } else {
      this.$store.commit('setSourceId', '');
    }

    if (window.location.href.indexOf('eventId') > -1) {
      let sourceId = window.location.href.split('eventId=')[1].split('&')[0];
      if (sourceId) {
        this.$store.commit('setEventId', sourceId);
        // this.$router.replace({ query: {} }); // pushType commit 후 초기화
      }
    } else {
      this.$store.commit('setEventId', '');
    }

    if (window.location.href.indexOf('fitcolEventId') > -1) {
      let sourceId = window.location.href.split('fitcolEventId=')[1].split('&')[0];
      if (sourceId) {
        this.$store.commit('setEventId', sourceId);
        // this.$router.replace({ query: {} }); // pushType commit 후 초기화
      }
    } else {
      this.$store.commit('setEventId', '');
    }
  },

  mounted() {
    this.onInit();
  },

  beforeUnmount() {
    // jhkim: 20230720: 핏, 빠숍 홈에서 생성시 사용하고 있어 복구
    bus.$off('show:mainHeader');
    // bus.$off('hide:mainHeader');
    // bus.$off('show:footer');
    // bus.$off('hide:footer');
  },

  methods: {
    onInit() {
      this.$emit('onInit');
    },
    clickEvent(sort) {
      switch (sort) {
        case 'closeSlidingPopup':
          this.isSlidingPopup = !this.isSlidingPopup;
          break;
        case 'closeModal':
          this.$store.commit('setComponentPopup', {});
          break;
      }
    },
    scrollShow(isShowScrollNavbar) {
      // jhkim: 20230720
      if (this.isLockScroll) {
        return;
      }

      this.isShowScrollNavbar = isShowScrollNavbar;
    },

    changePage(idx) {
      // jhkim: 20230720
      this.isLockScroll = true;

      //initPage/index 로 이동
      this.$refs.child.changePage(idx);

      // jhkim: 20230720
      setTimeout(() => {
        this.isLockScroll = false;
      }, 500);
    },
    changeHeader(type) {
      if (type === 'main') {
        this.isMainHeader = true;
        // this.isSubHeader = true;
        // this.isSubHeader = false;
      } else {
        this.isMainHeader = false;
        this.isShowScrollNavbar = false;
        // this.isSubHeader = true;
      }
    },
    // jhkim: vue3: 서비스 변경시 header swiper 초기화
    changeService(type) {
      if (this.$refs.headerSwiper) {
        this.$refs.headerSwiper.init();
      }
    },
    showMainHeader() {
      Logger.debug('show main header: main: ');
      this.isMainHeader = true;
    },
    hideMainHeader() {
      this.isMainHeader = false;
    },
    showFooter() {
      this.isFooter = true;
    },
    hideFooter() {
      this.isFooter = false;
    },
    initData() {
      this.isSlidingPopup = false;
      this.allIdx = 5;
      this.isShowScrollNavbar = true;
      this.isMainHeader = true;
      // this.isSubHeader = false;
      this.isFooter = true;
      this.isRewardHeader = false;
      this.showMoreBtn = false;
    },
  },
};
</script>
<style scoped>
.content-container {
  width: 100%;
  height: 100%;
}
</style>
