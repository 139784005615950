<template>
  <div style="padding: 24px 40px">
    <div class="text" @click="callCs(telNo.storeCSTelNo)">고객센터</div>
    <div class="text" @click="callCs(telNo.storeTelNo)">기타 연락처</div>
  </div>
</template>

<script>
export default {
  name: 'CallSliderPopup',

  data() {
    return {};
  },

  computed: {
    telNo() {
      return this.$store.state.orderPayment.callPopup;
    },
  },

  methods: {
    callCs(telNo) {
      this.$commons.printLog('☆ callCS :', JSON.stringify(this.$store.state.common.permission));

      if (window.Android) {
        if (this.$store.state.common.permission.resultData.READ_PHONE_NUMBERS) {
          location.href = `tel:${telNo}`;
        } else {
          if (localStorage.getItem('permissionCount') === '2') {
            this.$store.commit('alertPopup', {
              popupType: 'ErrorPopup',
              popupContent:
                '전화 연결 시 [전화]접근 권한의 허용이<br>필요합니다. 아래 경로를 통해 권한을<br>허용해주세요.<br>※ 설정 > 애플리케이션 > FIT > 권한 ><br>[전화] 허용',
            });
          } else {
            this.$bridge.call('permission', 'SERVICE_CENTER');
          }
        }
      } else {
        // ios & web
        location.href = `tel:${telNo}`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  width: auto;
  height: 110px;
  font-size: 30px;
  line-height: 110px;
  letter-spacing: -1.2px;
  text-align: left;
  color: #222;
}
</style>
