<template>
  <transition :name="name">
    <div v-if="showModal" ref="modal-mask" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div v-if="icon === 'close'" class="close-modal d-flex" @click="clickEvent">
            <img :src="require('@/assets/img/fit/btn_close_white_b.svg')" alt="" />
          </div>
          <div v-else class="d-flex c-center r-center" @click="clickEvent">
            <div class="close-more d-flex c-center r-center">
              <img :src="require('@/assets/img/fit/img_arr_up.svg')" alt="" />
            </div>
          </div>

          <div class="modal-body">
            <component :is="pages" :show-more="showMore" name="componentBody" />
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import HomeShowList from '@/components/home/HomeShowList';
import EventPopupBannerList from '@/components/common/banner/EventPopupBannerList';

import { fitMixin } from '@/utils/fitMixin';

/**
 * 컴포넌트 설명
 this.$store.commit('setComponentPopup', {
						show: true,
						pages: 'HomeShowList',
						showMore: false,
						icon: 'arrow',
						name: 'slide-up',
					});
 */
export default {
  name: 'ComponentPopup',

  components: {
    HomeShowList,
    EventPopupBannerList,
  },
  mixins: [fitMixin],
  emits: ['close'],

  data() {
    return {
      pages: String,
      icon: String,
      showMore: Boolean,
      scrollY: 0,
    };
  },

  computed: {
    showModal() {
      const data = this.$store.state.common.componentPopup.show;
      const pages = this.$store.state.common.componentPopup.pages;

      // jhkim: vue3: ISSUE: computed 내에서 async로 동작시키면 에러
      //   this.$nextTick(() => {
      //     if (!this.$commons.isNull(pages)) {
      //       this.pages = pages;
      //       this.icon = this.$store.state.common.componentPopup.icon;
      //       this.showMore = this.$store.state.common.componentPopup.showMore;

      //       if (pages === 'HomeShowList') this.$refs['modal-mask'].style.background = '#2b2d2f';
      //     }
      //   });

      return data;
    },
    name() {
      return this.$store.state.common.componentPopup.name;
    },
  },

  watch: {
    showModal(flag) {
      if (this.$store.state.common.mainIdx !== 0) {
        flag ? this.lockParentScroll() : this.unLockParentScroll();
      }
    },
  },

  methods: {
    clickEvent() {
      this.$emit('close', 'closeModal');
    },
    lockParentScroll() {
      this.scrollY = window.scrollY;
      document.getElementsByTagName('body')[0].style = 'width:100%; position:fixed; top: -' + this.scrollY + 'px';
    },
    //팝업 종료 시 부모창 스크롤 잠금해제
    unLockParentScroll() {
      document.getElementsByTagName('body')[0].style = '';
      window.scrollTo(0, this.scrollY);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  border-radius: 2px;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.close-modal {
  flex-direction: row-reverse;
  margin: 35px 0;
}

.modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

.modal-default-button {
  font-size: 50px;
  color: white;
  background: none;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.slide-up {
  //transition: all 0.25s;
  transition: all 0.25s;
}

.slide-up-enter-active {
  transition: all 0.25s ease;
}
.slide-up-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-up-enter,
.slide-up-leave-active {
  opacity: 0;
  transform: translateY(100%);
}

.fades-enter-active {
  animation: fadeEnter 0.25s;
}

@keyframes fadeEnter {
  from {
    background: #fff;
  }
  to {
    background: #000000;
  }
}

.slides-up-enter-active {
  animation: slideYEnter 0.25s;
}

@keyframes slideYEnter {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.slide-down-enter-active {
  animation: slideYLeave 0.25s;
}

@keyframes slideYLeave {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}

.close-more {
  background: #555759;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  transform: rotate(180deg);
  margin: 42px 0;
}
</style>
