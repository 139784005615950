<template>
  <div class="popup-wrap">
    <!--    <div class="popup-title"></div>-->
    <!--		<div class="popup-content">고객센터 전화연결<br />{{ content }}</div>-->
    <div class="popup-content">{{ $filters.phoneFormatter(content) }}</div>
    <div class="popup-button-wrap">
      <button class="popup-button-no" @click="cancel">취소</button>
      <button class="popup-button-yes" @click="callCs">통화</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters(['getAlertPopup']),
  },

  methods: {
    cancel() {
      this.$store.commit('alertPopup', {});
    },
    callCs() {
      this.$commons.printLog('☆ callCS :', JSON.stringify(this.$store.state.common.permission));

      if (window.Android) {
        if (this.$store.state.common.permission.resultData.READ_PHONE_NUMBERS) {
          location.href = `tel:${this.content}`;
        } else {
          if (localStorage.getItem('permissionCount') === '2') {
            this.$store.commit('alertPopup', {
              popupType: 'ErrorPopup',
              popupContent:
                '전화 연결 시 [전화]접근 권한의 허용이<br>필요합니다. 아래 경로를 통해 권한을<br>허용해주세요.<br>※ 설정 > 애플리케이션 > FIT > 권한 ><br>[전화] 허용',
            });
          } else {
            this.$bridge.call('permission', 'SERVICE_CENTER');
          }
        }
      } else {
        // ios & web
        location.href = `tel:${this.content}`;
      }
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 44px;
  font-size: 30px;
  letter-spacing: -1.5px;
  font-weight: 500;
  color: #648cff;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #2b2d2f;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  /*margin-top: 63px;*/
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #2b2d2f;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px;
}
</style>
