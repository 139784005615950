export default {
  loading: state => state.loading,
  getHeaderMode: state => state.headerMode,
  getHeaderText: state => state.headerText,
  getSubText: state => state.subText,
  getBannerInfo: state => state.bannerInfo,
  getAlertPopup: state => state.alertPopup,
  getToastPopup: state => state.toastPopup,
  alertPopup2: state => state.alertPopup2,
  showSummary: state => state.showSummary,
  slideShow: state => state.slideShow,
  slideShowBtn: state => state.slideShowBtn,
  slideType: state => state.slideType,
  telecomCode: state => state.telecomCode,
  terms: state => state.terms,
  newTerms: state => state.newTerms,
  lastScrollTop: state => state.lastScrollTop,
  isUp: state => state.isUp,
  showNavbar: state => state.showNavbar,
  getEventDetail: state => state.eventDetail,
  getPopupData: state => state.slidePopupData,
  getInit: state => state.init,
  existingMemberPopup: state => state.existingMemberPopup,
  nonMemberPopup: state => state.nonMemberPopup,
  getAmountPopupData: state => state.amountPopupData,
  getDatePopupData: state => state.datePopupData,
  // getIsFirst: state => state.isFirst,
  getIsMarketing: state => state.isMarketing,
  // getToken: state => state.token,
  getDeviceName: state => state.deviceName,
  // getPermission: state => state.permission,
  getPushPopupStep: state => state.pushPopupStep,
  getAppVersion: state => state.appVersion,
  isHeader: state => state.isHeader,
  endPopupFlag: state => state.endPopupFlag,
  startPopupFlag: state => state.startPopupFlag,
  getSetPwdState: state => state.setPwdState,
  getTermType: state => state.termType,
  getNoticeList: state => state.noticeList,
  getMainTabType: state => state.mainTabType,
  getMW0100: state => state.MW0100,
  getMW0102: state => state.MW0102,
  getMW0205: state => state.MW0205,
  getMW0307: state => state.MW0307,
  getTermsText: state => state.termsText,
  getSlideContent: state => state.slideContent,
  getRedirect: state => state.redirect,
  getSetAuthType: state => state.setAuthType,
  getNoticeDetail: state => state.noticeDetail,
  getAutoLoginYn: state => state.autoLoginYn,
  getQnaTypeList: state => state.qnaTypeList,
  getIsInit: state => state.isInit,
  getUserInfo: state => state.userInfo,
  getConfirmPopup: state => state.confirmPopup,
  getTvInfo: state => state.tvInfo,
  getHistoryTab: state => state.historyTab,
  getIsBack: state => state.isBack,
  getNoticeId: state => state.noticeId,
  getTabStatus: state => state.tabStatus,
  getPrevPage: state => state.prevPage,
  getHeaderColor: state => state.headerColor,
  getInitialTerms: state => state.initialTerms,
  getTvLogin: state => state.tvLogin,
  getPoint: state => state.point,
  isSaved: state => state.isSaved,
  getOrderDetail: state => state.orderDetail,
  getTmoDetail: state => state.tmoDetail,
  getPartnerShip: state => state.partnerShip,
  getCertDetail: state => state.certDetail,
  getFamilyDetail: state => state.familyDetail,
  getStoreList: state => state.storeList,
  getCheckStore: state => state.checkStore,
  getExchangeDetail: state => state.exchangeDetail,
  getSaleDetail: state => state.saleDetail,
  getChargeDetail: state => state.chargeDetail,
  getMemberYN: state => state.memberYN,
  getSaleContent: state => state.saleContent,
  getFamilyPointDetail: state => state.familyPointDetail,
  getCouponPrice: state => state.couponPrice,
  getUseBannerDetail: state => state.useBannerDetail,
  getPartnerShipList: state => state.partnerShipList,
  getTransferInfo: state => state.transferInfo,
  getPointChange: state => state.pointChange,
  getPointCouponList: state => state.pointCouponList,
  getSkpmallDetail: state => state.skpmallDetail,
  getAutoChargeInfo: state => state.autoChargeInfo,
  getBuyPoint: state => state.buyPoint,
  buzzPoint: state => state.buzzPoint,
  getVodPoint: state => state.vodPoint,
  getPurchaseInfo: state => state.purchaseInfo,
  getGiftMessage: state => state.giftMessage,
  getTransablePoint: state => state.transablePoint,
  getCardInfo: state => state.cardInfo,
  getMobileUserYN: state => state.mobileUserYN,
  getAppCardType: state => state.appCardType,
  getCardRegType: state => state.cardRegType,
  getTermsTitleList: state => state.termsTitleList,
  getTermId: state => state.termId,
  getStoreId: state => state.storeId,
  getRpSession: state => state.rpSession,
  getCardList: state => state.cardList,
  getViewAccountDetail: state => state.viewAccountDetail,
  getAuthDetail: state => state.authDetail,
  getChangePhoneDetail: state => state.changePhoneDetail,
  ssKey: state => state.ssKey,
  userDetailInfo: state => state.userDetailInfo,
  isLogin: state => state.isLogin,
  isAutoLogin: state => state.isAutoLogin,
  isAutoCharge: state => state.isAutoCharge,
  isAutoTrans: state => state.isAutoTrans,
  getUserCel: state => state.userCel,
  getUserName: state => state.userName,
  getUserPoint: state => state.pointBalance,
  getExpPoint: state => state.expirationWillPoint,
  getUserNo: state => state.userNo,
  getUserShaPwd: state => state.userShaPwd,
  getUserEmail: state => state.userEmail,
  getSelfAuthData: state => state.selfAuthData,
  getPresentUser: state => state.presentUser,
  getGenderCode: state => state.genderCode,
  getUserBirthDate: state => state.userBirthDate,
  getUserBirthDay: state => state.userBirthDay,
  getAutoTransDetail: state => state.autoTransDetail,
  getAutoChargeDetail: state => state.autoChargeDetail,
  getDirects: state => state.directs,
  getUserNoticeList: state => state.userNoticeList,
  getAgrmntList: state => state.agrmntList,
  getFamilyInfo: state => state.familyInfo,
  getNoticeCount: state => state.noticeCount,
  getMemberType: state => state.memberType,
  getSessionId: state => state.jsessionId,
  getSession: state => state.jsessionId,
  getPwdType: state => state.pwdType,
  getPwdYN: state => state.pwdYN,
};
