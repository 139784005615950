<template>
  <div class="cardList-wrap common-padding">
    <div class="cardList-title">카드 선택</div>
    <EmptyPaddingBar :padding-height="128" />
    <div class="cardList-container">
      <div v-for="(card, idx) in getCardList" :key="idx" class="card">
        <input
          :id="'radio' + card.acntNo"
          type="radio"
          class="card-radio"
          name="card"
          :idx="idx"
          :checked="!$utils.isNull(getPopupData) && getPopupData.acntNo == card.acntNo ? true : false"
          :cardName="card.cardName"
          :acntName="card.acntName"
          :cardCode="card.cardCode"
          :acntNo="card.acntNo"
          :pinYN="card.pinYN"
          @click="cardSelected"
        />
        <label :for="'radio' + card.acntNo" class="label"
          ><i class="radio-img"></i>{{ card.cardName }}&nbsp;&nbsp;|&nbsp;&nbsp;{{ card.acntName }}
        </label>
        <span class="card-info ellipse-row-1"
          >{{ '(' + card.userName + ')' }} {{ $utils.isNull(card.goodsName) ? '' : card.goodsName }}</span
        >
      </div>
      <div class="new-card" :class="isScroll ? 'scroll-padding' : ''">
        <input id="newCard" type="radio" class="card-radio" name="card" @click="cardSelected" />
        <label for="newCard" class="label">
          <i class="radio-img"></i>
          <span>새로운 카드 추가 </span>
        </label>
        <!--        <span class="card-info">{{ "(" + card.userName + ")" }} {{ $utils.isNull(card.goodsName) ? "" : card.goodsName }}</span>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmptyPaddingBar from '@/components/common/EmptyPaddingBar';

export default {
  components: {
    EmptyPaddingBar,
  },

  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getCardList', 'getPopupData']),
  },

  created() {},

  methods: {
    async cardSelected($event) {
      let idx = $event.target.getAttribute('idx');
      const acntNo = $event.target.getAttribute('acntNo');
      // 신규카드 선택 시 처리
      if (this.$utils.isNull(idx)) {
        idx = 9999;
      }
      await this.$store.commit('setPopupData', { idx, acntNo });
      // }
      await this.$store.commit('slideShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}
.cardList-wrap {
  height: inherit;
  overflow: scroll;
}
.cardList-title {
  height: 128px;
  line-height: 128px;
  font-size: 34px;
  letter-spacing: -1.7px;
  font-weight: 500;
  color: #222222;
  width: 640px;
  background-color: white;
  position: fixed;
}
.cardList-container {
  height: 100%;
  overflow: scroll;
  padding-bottom: 40px;
}
.card {
  height: 130px;
  box-sizing: border-box;
  padding-bottom: 22px;
  padding-top: 28px;
}
.new-card {
  height: 110px;
  line-height: 40px;
}
.new-card label {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #2b2d2f;
  display: flex;
  padding: 32.5px 0;
  box-sizing: border-box;
  height: 110px;
}
.card-radio {
  display: none;
}
.card label {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #2b2d2f;
  height: 45px;
  display: flex;
  line-height: 35px;
}
.card-info {
  width: 500px;
  display: block;
  margin-left: 60px;
  font-size: 28px;
  letter-spacing: -1.4px;
  color: #a8aaac;
}
.card-radio:checked + label > i {
  background: url('../../../../assets/img/common/btn_radio_on.svg') no-repeat;
}
.radio-img {
  width: 40px;
  padding-right: 20px;
  background: url('../../../../assets/img/common/btn_radio_off.svg') no-repeat;
}
</style>
