import getDefaultState from '@/store/tvpoint/state';
import mutations from '@/store/tvpoint/mutations';
import actions from '@/store/tvpoint/actions';
import getters from '@/store/tvpoint/getters';

const state = Object.assign({}, getDefaultState);

//defaultState 는 초기화 할때 한번에 선언하기 위해서.
export default {
  defaultState: getDefaultState,
  state,
  mutations,
  actions,
  getters,
};
