import config from '@/config/ft-settings';
import urlConfig from '@/config/urlConfig';
import bus from '@/utils/bus';
import $commons from '@/utils/commons';
import commonApis from '@/apis/commonApis';
import $bridge from '@/utils/FTBridgeShared';
import $router from '@/router';

// import $date from '@/plugins/date.js'
const utils = {
  // console log 찍기
  printLog(str, obj) {
    if (config.isLog) {
      console.log(str, obj || '');
    }
  },
  // object를 url query 형태로 변경
  toQueryString(object) {
    return Object.keys(object)
      .map(key => (object[key] ? key + '=' + object[key] : ''))
      .join('&');
  },

  getComponentName(that) {
    if (that) {
      return that.$options._componentTag;
    }
    return '';
  },

  // null 체크 함수
  isNull(object) {
    // jhkim: vue3:
    // return !!(object == null || object == '' || this.isUndefined(object));
    if (Array.isArray(object)) {
      return !!(object.length === 0);
    } else {
      return !!(object === null || object === '' || object === undefined);
    }
  },
  isUndefined(object) {
    return typeof object === 'undefined';
  },
  // 오브젝트 비교
  isEqual(obj1, obj2) {
    for (const p in obj1) {
      if (Object.hasOwnProperty.call(obj1, p)) {
        if (obj1[p] !== obj2[p]) {
          return false;
        }
      }
    }
    for (const p in obj2) {
      if (Object.hasOwnProperty.call(obj2, p)) {
        if (obj1[p] !== obj2[p]) {
          return false;
        }
      }
    }
    return true;
  },
  // 핸드폰 번호 분리
  splitPhoneNo(phoneNo) {
    const result = [];

    result.push(phoneNo.substr(0, 3));

    if (phoneNo.length == 11) {
      result.push(phoneNo.substr(3, 4));
      result.push(phoneNo.substr(7));
    } else {
      result.push(phoneNo.substr(6));
    }
    return result;
  },
  phoneNoFormat(num) {
    let formatNum = '';
    try {
      if (num.length < 4) {
        formatNum = num;
      } else if (num.length < 7) {
        formatNum = num.substring(0, 3) + '-' + num.substring(3);
      } else if (num.length < 11) {
        formatNum = num.substring(0, 3) + '-' + num.substring(3, 6) + '-' + num.substring(6);
      } else {
        formatNum = num.substring(0, 3) + '-' + num.substring(3, 7) + '-' + num.substring(7);
      }
      // if (num.length == 11) {
      //   formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      // } else {
      //   formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      // }
    } catch (e) {
      formatNum = num;
    }
    return formatNum;
  },
  // input number maxlength 체크 함수 component 에 this를 넘겨야한다 !
  // Vue component 상의 data 이름 값과 input 태그의 id 값이 같아야한다.
  checkLength($event, component) {
    const length = $event.target.maxLength;
    const valLen = $event.target.value.length;
    const id = $event.target.getAttribute('id');
    if (typeof component[id] == 'number') {
      component[id] = $event.target.value;
    }
    if (length < valLen) {
      component[id] = component[id].substr(0, length);
    }
  },

  checkPointUnit(view, partnerShip) {
    const input = parseInt(view.$utils.makeNumber(view.inputPoint));
    const balance = parseInt(partnerShip.avlBal);
    const transUnit = parseInt(partnerShip.transUnit);
    if (input > balance) {
      return false;
    }
    if (input >= transUnit) {
      return true;
    }
    return false;
  },

  // 숫자만
  makeInteger(inputData) {
    return this.isNull(inputData) ? 0 : parseInt(inputData.replace(/[^0-9]/g, ''));
  },
  makeNumber(inputData) {
    return this.isNull(inputData) ? '' : inputData.replace(/[^0-9]/g, '');
  },
  // 문자열 앞과 뒤 공백 제거
  makeString(data) {
    return this.isNull(data) ? '' : data.replace(/(^\s*)|(\s*$)/g, '');
  },
  /**
   * 숫자포맷으로 출력(3자리마다, 붙이기)
   *
   * @param data 숫자포맷 객체
   * @returns {string|number}
   */
  formatComma(data) {
    if (this.isNull(data)) {
      return '0';
    }
    return String(data)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  formatCommaInput(data) {
    if (this.isNull(data)) {
      return '';
    }
    return String(data)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  scrollChange() {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  },
  // deffer 객체 반환
  defer() {
    const deferred = {};
    deferred.promise = new Promise(function (resolve, reject) {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    return deferred;
  },
  sequenceEx(_fn) {
    const _utils = this;
    const d = this.defer();
    let p = d.promise;

    while (_fn.length > 0) {
      p = p.then(_fn.shift());
    }
    // for (const i in _fn) {
    //   p = p.then(_fn[i]);
    // }

    p.then(
      function () {
        _utils.printLog('sequenceEx success');
        bus.$emit('end:loading');
      },
      function (data) {
        // 에러 팝업 띄우기
        // jhkim: vue3:
        // if (!_utils.isNull(data) && _utils.isNull(data[2])) {
        if (!_utils.isNull(data) && data.length == 2) {
          _utils.printLog('errorMessage !_utils.isNull(data) && _utils.isNull(data[2])');
          _utils.printLog(data);
          const _vue = data[0];
          const _data = data[1];
          bus.$emit('end:loading');
          _utils.errorMessage(_vue, _data);

          // // store loadingend용
          // // data 에러 멘트 처리용
          // const errorTitle = "알림";
          // const errorMessage = _utils.isNull(_data.resultMessage) ? "알려지지 않은 오류" : _data.resultMessage;
          //
          // _vue.$store.commit("loading", false);
          // _vue.$store.commit("alertPopup", { popupType: "ErrorPopup", popupTitle: errorTitle, popupContent: errorMessage });
        } else if (!_utils.isNull(data) && !_utils.isNull(data[0])) {
          // 다음 시퀀스 실행
          // data 에러 멘트 처리용
          _utils.continueSequence(data);
        }
      },
    );

    d.resolve();
  },
  continueSequence(data) {
    this.printLog(data);
    const _utils = this;
    _utils.printLog('continueSequence');
    _utils.printLog(data);
    _utils.printLog(data[0]);
    _utils.printLog(data[1]);
    _utils.printLog(data[2]);
    const _vue = data[0];
    const _data = data[1];
    const _name = data[2];
    let popupTitle = '';
    let popupContent = '';

    switch (String(_name)) {
      case 'checkUserExist':
        _utils.sequenceEx([_vue.checkUserExist]);
        break;
      case 'sendCode':
        _utils.sequenceEx([
          _vue.sendCode,
          function (params) {
            _vue.$store.commit('setUserCel', _vue.userInfo.phoneNo);
            _vue.$router.push('/user/UserAuthPage');
          },
        ]);
        break;

      case 'certifyUserAuth':
        _utils.sequenceEx([
          _vue.certifyUserAuth,
          function (params) {
            _vue.$store.commit('setUserCel', _vue.userInfo.phoneNo);
            _vue.$router.push('/user/UserAuthPage');
          },
        ]);
        break;
      // 마케팅 동의를 받지 않은경우 공지사항을 띄워주지 않고 다음 화면으로 이동
      case 'checkMarketing':
        _utils.sequenceEx([this.getMW0100, this.getMW0102, this.getMW0205, this.getMW0307]);
        break;
      // 자동 로그인 처리
      case 'autoLogin':
        _utils.printLog('자동 로그인 진입');
        _utils.sequenceEx([
          _vue.checkUserDevice,
          _vue.login,
          _vue.manageAutoTransfer,
          // _vue.transTermId,
          function () {
            _vue.$router.push(_data);
          },
        ]);
        break;
      // 신규 공지사항 동의 체크
      case 'setAgrmntNewTerms':
        bus.$emit('end:loading');
        _vue.$store.commit('slideShow', {
          slideShow: true,
          slideType: 'NewTermsSlideComponent',
        });
        break;
      // 비밀번호 검증 오류
      case 'verifyUserPinFail':
        bus.$emit('end:loading');
        // 비밀번호 5회 오류 처리
        _utils.printLog(_vue.tryCount);
        _utils.printLog(_vue.getViewAccountDetail);
        if (_vue.tryCount < 5) {
          popupTitle = 'TV페이 비밀번호 오류 (' + _vue.tryCount + '/5)';
          popupContent = 'TV페이(간편결제) 비밀번호가 일치하지 않습니다. 다시 시도해주세요.';
          _vue.$store.commit('alertPopup', {
            popupType: 'ErrorPopup',
            popupTitle,
            popupContent,
          });
        }
        break;
      // 비밀번호 검증 5회 오류
      case 'verifyUserPinFive':
        bus.$emit('end:loading');

        _utils.printLog(_vue.tryCount);
        _utils.printLog(_vue.getViewAccountDetail);

        popupTitle = 'TV페이 비밀번호 오류(' + _vue.tryCount + '/5)';
        popupContent = 'TV페이(간편결제) 비밀번호가 일치하지 않습니다. 본인인증이 필요합니다.';
        _vue.$store.commit('alertPopup', {
          popupType: 'ErrorPopup',
          popupTitle,
          popupContent,
          count: _vue.tryCount,
        });
        break;
      case 'notTvuser':
        _utils.sequenceEx([
          _vue.manageAutoTransfer,
          // _vue.transTermId,
          _vue.getAgrmntList,
          function () {
            const tvInfo = _vue.$store.getters.getTvInfo;

            _vue.$store.commit('setTvInfo', {});
            bus.$emit('end:loading');
            _vue.$utils.printLog(_vue.$store.getters.getUserCel);

            if (!_vue.$utils.isNull(tvInfo.redirectPage)) {
              if (tvInfo.redirectPage !== '/tvmobile/inquireinfo') {
                _vue.$store.commit('setIsBack', true);
                _vue.$router.go(-1);
              } else {
                _vue.$router.push('/initPage');
              }
            }
          },
        ]);
        break;
      case 'indexAppRedirect':
        _vue.saveFirstInit(false);
        break;
      // 자체 인증 보내기
      case 'sendAuthCode':
        _vue.$utils.sequenceEx([_vue.checkUser, _vue.sendCode]);
        break;
      case 'end':
        break;
      default:
        break;
    }
  },
  /**
   * 주민등록번호 뒷 첫자리(성별)로 가맹점 성별코드 획득
   *
   * @param 성별
   */
  getStoreGenderCode(gender) {
    let resultGender = '';

    if (!this.isNull(gender)) {
      if (gender === '0') resultGender = '1';
      else resultGender = gender > 1 && gender % 2 === 0 ? '2' : '1';
    }

    return resultGender;
  },

  getNationCode(nation) {
    const nationCode = Number(nation);
    let resultNation = '';
    switch (nationCode) {
      case 1:
      case 2:
      case 3:
      case 4:
        resultNation = '0';
        break;
      case 5:
      case 6:
      case 7:
      case 8:
        resultNation = '1';
        break;
    }
    return resultNation;
  },

  fullBirthDate(ssn) {
    const gender = parseInt(ssn.substr(ssn.length - 1));
    let result = null;
    switch (gender) {
      case 1:
      case 2:
      case 5:
      case 6:
        result = '19' + ssn.substr(0, 6);
        break;
      case 3:
      case 4:
      case 7:
      case 8:
        result = '20' + ssn.substr(0, 6);
        break;
    }
    return result;
  },
  checkLogin(vue) {
    if (vue.$store.getters.isLogin) {
      return true;
    } else {
      // 팝업 띄우기
      // vue.$store.commit("alertPopup", { popupType: "LoginPopup" });
      return false;
    }
  },
  async errorMessage(vue, data) {
    // -2010

    if (data.resultCode === '-2010') {
      // 스토어 정보 날리기
      await vue.$store.commit('resetPointState');
      await vue.$store.commit('resetRpState');
      await vue.$store.commit('resetUserState');
    }

    this.printLog('errorMessage');
    // store loadingend용
    // data 에러 멘트 처리용
    const errorTitle = '알림';
    let errorMessage = this.isNull(data.resultMessage) ? '알려지지 않은 오류' : data.resultMessage;

    // 통합RP 에러멘트 처리
    if (this.isNull(errorMessage)) {
      // 통합RP 에러멘트 처리
      errorMessage = this.isNull(data.resultData) ? errorMessage : data.resultData.contents;
    }

    if (!this.isNull(data.resultData) && !this.isNull(data.resultData.message)) {
      // 통합RP 에러멘트 처리
      errorMessage = data.resultData.message;
    }
    if (!this.isNull(data.resultData) && !this.isNull(data.resultData.contents)) {
      // 통합RP 에러멘트 처리
      errorMessage = data.resultData.contents;
    }

    bus.$emit('end:loading');
    // vue.$store.commit('alertPopup', {
    //   popupType: 'ErrorPopup',
    //   popupTitle: errorTitle,
    //   popupContent: errorMessage,
    //   resultCode: data.resultCode,
    // });

    if (data.goPage === 'goFailPage') {
      $router.push({ name: 'PaymentFail', query: { errorMessage: errorMessage } });
    } else {
      vue.$store.commit('alertPopup', {
        popupType: 'ErrorPopup',
        popupTitle: errorTitle,
        popupContent: errorMessage,
        resultCode: data.resultCode,
      });
    }

    this.printLog(data);
  },
  getCookie(cookieName) {
    let cookieValue = null;
    const array = document.cookie.split(escape(cookieName) + '=');

    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }

    return cookieValue;
  },

  setCookie(cookieName, cookieValue, expiredays) {
    if (this.isNull(expiredays)) {
      document.cookie = cookieName + '=' + escape(cookieValue) + '; path=/;';
    } else {
      const date = new Date();
      // 개발서버에서 테스트 확인을 위해 버즈빌 툴팁 2분으로 설정, jslim.
      if (cookieName === 'buzvill') {
        date.setMinutes(date.getDate() + expiredays);
        this.printLog('buzvill Date', date);
        document.cookie = escape(cookieName) + '=' + escape(cookieValue) + '; expires=' + date.toUTCString();
      } else {
        date.setDate(date.getDate() + expiredays);
        document.cookie = escape(cookieName) + '=' + escape(cookieValue) + '; expires=' + date.toUTCString();
      }
    }

    // if(cookieName == "autologin"){
    //   document.cookie = cookieName + "=" + escape( cookieValue ) + "; path=/; secure";
    // }else{
    //   document.cookie = cookieName + "=" + escape( cookieValue ) + "; path=/;";
    // }
  },

  deleteCookie(cookieName) {
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/;';
  },
  getDate(type) {
    const date = new Date();
    const year = date.getFullYear();
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let returnString = '';

    // 한자리수일 경우 0을 채워준다.
    if (month.length === 1) {
      month = '0' + month;
    }

    if (day.length === 1) {
      day = '0' + day;
    }

    switch (type) {
      case 'bar':
        returnString = year + '-' + month + '-' + day;
        break;
      case 'kor':
        returnString = year + '년 ' + month + '월 ' + day + '일';
        break;
      default:
        break;
    }
    return returnString;
  },
  bannerInfoList(_vue, menuId) {
    const params = { menuId, clientType: 'WEB', termId: '' };
    // const params = { menuId, clientType: _vue.$store.getters.getDeviceType, termId: '' };

    const data = _vue.$store.dispatch('getBannerInfoList', params);

    const subBannerList = ['MWBN03', 'MWBN05', 'MWBN08', 'MWBN10', 'MWBN11', 'MWBN12'];

    data.then(data => {
      if (parseInt(data.resultCode) === 0) {
        if (subBannerList.includes(menuId)) {
          _vue.subBannerInfo = data;
          _vue.$store.commit('setSubBannerInfo', { ...data.resultData, menuId });
        } else {
          _vue.bannerInfo = data;
        }
      } else {
        this.printLog();
      }
    });

    return data;
  },
  /**
   *
   * @param _vue : this vue 객체
   * @param linkType :  string 해당 링크 유형(PROMOTION : 일반 링크, ACTIVITY 앱호출, MENU: , URL:, function : 앱 펑션 호출
   * @param loginCheck : string 로그인 필요 여부
   * @param menuId    : string 메뉴아이디(MWXX)
   * @param operationId : string 해당 프로모션 동작 아이디
   * @param linkInfo : string ACTIVITY 링크 정보
   */
  checkLinkType(_vue, linkType, loginCheck, menuId, operationId, linkInfo) {
    this.printLog(_vue);
    this.printLog(linkType);
    this.printLog(loginCheck);
    this.printLog(menuId);
    this.printLog(operationId);
    this.printLog(linkInfo);
    const _utils = this;
    let data = '';

    switch (linkType) {
      case 'PROMOTION':
        if (loginCheck === 'Y') {
          if (!_vue.$store.getters.isLogin) {
            _vue.$store.commit('alertPopup', { popupType: 'LoginPopup' });
            return;
          }
        }

        data = _vue.$store.dispatch('getPromotionInfo', {
          menuId,
          operationId,
        });

        data.then(data => {
          if (parseInt(data.resultCode) === 0) {
            _utils.printLog('getPromotionInfo success');
            _vue.$store.commit('setUseBannerDetail', data);

            _utils.printLog(data);
            _vue.$router.push('/tvpoint/banner');
          } else {
            _utils.errorMessage(_vue, data);
          }
        });

        break;

      case 'SHOP':
        if (_vue.$store.getters.getDeviceName === 'WEB') {
          this.alertPopup(_vue, '모바일에서만 사용 가능한 서비스 입니다.', '알림');
          return;
        }

        if (loginCheck === 'Y') {
          if (!_vue.$store.getters.isLogin) {
            _vue.$store.commit('alertPopup', { popupType: 'LoginPopup' });
            return;
          }
        }
        // TV포인트 기프티콘 임시처리
        // linkInfo가 구서버를 바라보고 있기 때문에 skpMall이 들어가는경우 기프티콘 화면 호출
        if (linkInfo.indexOf('skpMall') > 0) {
          // if (_vue.$store.getters.getDeviceType === "APP") {
          //   _vue.$store.commit("alertPopup", { popupType: "CouponPopup" });
          // } else {
          _utils.callSkpAuth(_vue);
          // }
        } else {
          _utils.printLog('else');
        }

        break;
      case 'FUNCTION':
        // TV포인트 프렌즈
        // linkInfo가 구서버 function을 바라보고 있기 때문에 수정 필요요
        if (linkInfo.indexOf('Offerwall') > 0) {
          _utils.toFriends(_vue);
        } else {
          _utils.printLog('else');
        }
        break;
      case 'URL':
        // location.href = linkInfo;
        window.open(linkInfo, '_blank');
        commonApis.informEvent(linkInfo);
        break;
      default:
        break;
    }
  },
  /**
   * 기프티콘 화면 이동
   * @param _vue :this vue 객체
   */
  callSkpAuth(_vue) {
    // 현재 기프티콘 앱의 경우 웹으로 보내서 처리
    // 추후 앱에서 바로 사용 가능하도록 변경
    // if (_vue.$store.getters.getDeviceType === "APP") {
    //   _vue.$store.commit("alertPopup", { popupType: "CouponPopup" });
    //   return;
    // }

    if (_vue.$store.getters.getDeviceName === 'WEB') {
      this.alertPopup(_vue, '모바일에서만 사용 가능한 서비스 입니다.', '알림');
      return;
    }

    let aosVersion = '';
    if (_vue.$store.getters.getDeviceType === 1) {
      /**
       * 2.0.1버전부터 앱에서 바로 사용 가능
       * */
      let curVersion = '';
      $bridge
        .shared()
        .versionName()
        .then(data => {
          curVersion = data.split('.');
          const appVersion = `${curVersion[0]}.${curVersion[1]}.${curVersion[2]}`;
          this.aosVersion = appVersion;
          if (appVersion === '2.0.0') {
            // 현재 버전이 2.0.0인경우 alert창 띄운 후 업데이트 유도
            this.$store.commit('alertPopup', { popupType: 'UpdateConfirm' });
          }
        });
    }

    bus.$emit('start:loading');

    const _utils = this;
    const data = _vue.$store.dispatch('callSkpAuth', {
      userNo: _vue.$store.getters.getUserNo,
    });

    data.then(data => {
      if (parseInt(data.resultCode) === 0) {
        _utils.printLog('callSkpAuth success');
        _utils.printLog(urlConfig.SKP_BASE_URL);

        const authKey = data.AUTH_KEY;
        const pocId = data.POC_ID;

        const form = document.createElement('form');
        let authKeyObj;
        let pocIdObj;
        authKeyObj = document.createElement('input');
        pocIdObj = document.createElement('input');

        authKeyObj.setAttribute('type', 'hidden');
        authKeyObj.setAttribute('name', 'AUTH_KEY');
        authKeyObj.setAttribute('value', authKey);
        pocIdObj.setAttribute('type', 'hidden');
        pocIdObj.setAttribute('name', 'POC_ID');
        pocIdObj.setAttribute('value', pocId);
        form.appendChild(authKeyObj);
        form.appendChild(pocIdObj);
        if (_vue.$store.getters.getDeviceType === 1) {
          // 2.2.1보다 버전이 낮을 경우에는 플레이스토어로 이동하도록 수정.
          if (this.aosVersion < '2.2.1') {
            location.href = 'https://play.google.com/store/apps/details?id=com.tvpoint';
          } else {
            // 웹뷰 방식으로 띄우기
            window.Android.callSubWebViewSkpmall(urlConfig.SKP_BASE_URL, authKey, pocId);
          }
          // 웹뷰 방식으로 띄우기
          // window.Android.callSubWebViewSkpmall(urlConfig.SKP_BASE_URL, authKey, pocId);

          // 웹 방식을 띄우기
          // const gsWin = window.open("about:blank", "pageViewer");
          // form.setAttribute("target", "pageViewer");
        } else if (_vue.$store.getters.getDeviceType === 3) {
          // ios에서 서브 웹뷰로 띄우기 위해 url 전달  mjkim
          let url = urlConfig.SKP_BASE_URL + 'm/auth/auth.do?' + 'AUTH_KEY=' + authKey + '&POC_ID=' + pocId;
          _vue.$bridge.call('callSubWebView', url);
        } else {
          form.setAttribute('method', 'post');
          form.setAttribute('action', urlConfig.SKP_BASE_URL + 'm/auth/auth.do');
          document.body.appendChild(form);
          form.submit();
        }
        // if (_vue.getDeviceType === "APP") {
        //   const gsWin = window.open("about:blank", "pageViewer");
        //   form.setAttribute("target", "pageViewer");
        // }
        //
        // form.setAttribute("method", "post");
        // form.setAttribute("action", urlConfig.SKP_BASE_URL + "m/auth/auth.do");
        //
        // document.body.appendChild(form);
        // form.submit();
        bus.$emit('end:loading');
      } else if (data.resultCode === '-2010') {
        this.errorMessage(_vue, data);
      }
      bus.$emit('end:loading');
    });
  },
  /**
   * TV포인트 프렌즈 화면 이동
   * @param _vue :this vue 객체
   * @returns {*}
   */
  toFriends(_vue) {
    if (_vue.$store.getters.getDeviceName === 'WEB') {
      this.alertPopup(_vue, '모바일에서만 사용 가능한 서비스 입니다.', '알림');
      return;
    }

    const _utils = this;
    // 앱
    const deviceName = _vue.$store.getters.getDeviceName;
    const userNo = _vue.$store.getters.getUserNo;

    if (_utils.isNull(userNo)) {
      this.$store.commit('alertPopup', { popupType: 'LoginPopup' });

      // return alert("문제가 발생하였습니다. 잠시 후에 다시 시도해주세요.");
    } else {
      // 앱
      if (!_utils.isNull(window.Android)) {
        // // 2020-01-14 업데이트 확인 여부
        // if (typeof window.Android.openOfferwall === "undefined") {
        //   // 60100,NEED_A_APP_UPDATE,최신 버전의 APP에서 이용할 수 있는 서비스 입니다.<br>이동되는 화면에서  APP을 업데이트 해주세요.,확인,#F37221,
        //   alert("최신 버전의 APP에서 이용할 수 있는 서비스 입니다.<br>이동되는 화면에서  APP을 업데이트 해주세요.");
        //   $(".one_button").click(function () {
        //     // 앱이 설치되어있는지 유무 확인 후 각각 앱 실행/store로 이동한다 (확인)
        //     location.href = "http://play.google.com/store/apps/details?id=com.tvpoint";
        //   });
        //   return;
        // }
        return window.Android.openOfferwall(userNo);
      }
      // 웹
      if (deviceName === 'ANDROID') {
        // 50200,CALL_OFFERWALL_APP,APP에서 더 많은 포인트를 획득할 수 있어요! TV포인트 APP으로 이동하시겠어요?,"취소, 확인","#C8C8C8, #F37221",
        // confirm("APP에서 더 많은 포인트를 획득할 수 있어요! TV포인트 APP으로 이동하시겠어요?");
        _vue.$store.commit('alertPopup', { popupType: 'FriendsPopup' });
        // $(".two_button.button_submit").click(function () {
        // 앱이 설치되어있는지 유무 확인 후 각각 앱 실행/store로 이동한다 (확인)
        // location.href = "intent://intro_host#Intent;scheme=intro_scheme;package=com.tvpoint;end";
        // });
        // $(".two_button.popup_close").click(function () {
        // this.$router.push("/test");
        // });
      } else {
        // ISSUE: 시나리오 확인
        // iOS
        if (_vue.$store.getters.getDeviceType === 3) {
          // ios app : 웹뷰로 띄울 경우 뒤로가기 불가하므로 새창으로 수정 mjkim
          let url =
            'https://api.flexplatform.net/offerwall/?offercode=FF46270B77A2BF4FB473F1C46FA91296&userkey' +
            _vue.$store.getters.getUserNo +
            '&media=tvhub_agent';
          window.open(url);
        } else {
          // ios web
          const form = document.createElement('form');
          let userkey;
          let media;
          let offercode;
          userkey = document.createElement('input');
          media = document.createElement('input');
          offercode = document.createElement('input');

          // const userkey = this.getUserNo;
          // const media = "tvhub_agent";
          userkey.setAttribute('type', 'hidden');
          userkey.setAttribute('name', 'userkey');
          userkey.setAttribute('value', _vue.$store.getters.getUserNo);
          media.setAttribute('type', 'hidden');
          media.setAttribute('name', 'media');
          media.setAttribute('value', 'tvhub_agent');
          offercode.setAttribute('type', 'hidden');
          offercode.setAttribute('name', 'offercode');
          offercode.setAttribute('value', 'FF46270B77A2BF4FB473F1C46FA91296');
          form.appendChild(userkey);
          form.appendChild(media);
          form.appendChild(offercode);
          form.setAttribute('method', 'post');
          form.setAttribute('action', 'https://api.flexplatform.net/offerwall/');

          document.body.appendChild(form);
          form.submit();
        }
      }
    }
  },
  // 홈빠 베스트오브 베스트 이동 화면
  goHombbar(_vue, url) {
    url = this.isNull(url) ? 'https://tvhubhomebbar.page.link/best' : url;
    if (_vue.$store.getters.getDeviceType === 1 || _vue.$store.getters.getDeviceType === 3) {
      // window.Android.callSubWebView(url);
      // location.href = url;
      window.open(url);
    } else {
      location.href = url;
    }
  },
  /**
   * 홈빠 상품 상세 이동 화면 숏링크 생성
   * @param _vue :this vue 객체
   * @param goodsId : 상품아이디
   * @param broadId : 방송아이디
   * @returns {*}
   */
  goHombbarGoods(_vue, goodsId, broadId) {
    // createShortLinkForWeb(parameters, imageLink, title){
    // ★parameters = "?redirectPage=/productDetail&product_id="+상품아이디+"&broadcast_id="+방송아이디
    // 상품아이디나 방송 아이디가 없느경우 홈빠로 이동
    // if (this.isNull(goodsId) || this.isNull(broadId)) {
    //   return "";
    // }

    const redirectParameters = '?redirectPage=/productDetail&product_id=' + goodsId + '&broadcast_id=' + broadId;
    const redirectUrl = 'https://www.homebbar.com/hband/index.html' + redirectParameters;
    const parameters = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://tvhubhomebbar.page.link', // 동적링크 프리픽스 설정(파이어베이스 페이지에서 설정한 값 고정)
        link: redirectUrl, // 사용자에게 실제로 띄워줄 링크
        androidInfo: {
          // 안드로이드 파라미터 설정
          androidPackageName: 'com.homebbar', // 패키지명 셋팅(패키지명 별로 파이어베이스 앱 설정 추가되어있어야 마켓 리다이렉션 가능)
          androidFallbackLink: redirectUrl, // 마켓이동이 아닌 경우 모바일 웹으로 보낼 시
          androidMinPackageVersionCode: '10', // 앱 호출할 최소 앱 버전(기능이 추가되어 해당 메소드가 변경되어야 할경우 업데이트할 버전으로 올려줄것)
        },
      },
    };
    const data = _vue.$store.dispatch('createShortLinkForWeb', parameters);

    data.then(data => {
      let url = data.shortLink;
      url = this.isNull(url) ? 'https://tvhubhomebbar.page.link/best' : url;
      if (_vue.$store.getters.getDeviceType === 1 || _vue.$store.getters.getDeviceType === 3) {
        // window.Android.callSubWebView(url);
        // location.href = url;
        window.open(url);
      } else {
        location.href = url;
      }
    });

    // const parameters = "?redirectPage=/productDetail&product_id=" + goodsId + "&broadcast_id=" + broadId;
    // const redirectUrl = "https://www.homebbar.com/hband/index.html" + parameters;
    // axios.defaults.headers.post["Content-Type"] = "application/json";
    // axios
    //   .post("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyA7hwLXKQpnzFiwxsOTuv6lcyZJGULJwCM", {
    //     dynamicLinkInfo: {
    //       domainUriPrefix: "https://tvhubhomebbar.page.link", // 동적링크 프리픽스 설정(파이어베이스 페이지에서 설정한 값 고정)
    //       link: redirectUrl, // 사용자에게 실제로 띄워줄 링크
    //       androidInfo: {
    //         // 안드로이드 파라미터 설정
    //         androidPackageName: "com.homebbar", // 패키지명 셋팅(패키지명 별로 파이어베이스 앱 설정 추가되어있어야 마켓 리다이렉션 가능)
    //         androidFallbackLink: redirectUrl, // 마켓이동이 아닌 경우 모바일 웹으로 보낼 시
    //         androidMinPackageVersionCode: "10", // 앱 호출할 최소 앱 버전(기능이 추가되어 해당 메소드가 변경되어야 할경우 업데이트할 버전으로 올려줄것)
    //       },
    //     },
    //   })
    //   .then(res => {
    //     // ★res.data.shortLink 값을 받아서 각 상품별로 셋팅해주면 됩니다!!
    //   });
  },
  saveContentsLog(_vue, params) {
    _vue.$store.dispatch('saveContentsLog', params);
  },
  // 줄바꿈 구분자를 넘기면 해당 구분자를 전부 <br> 태그로 치환
  switchLine(data, delimeter) {
    // new RegExp(`ReGeX${testVar}ReGeX`);
    const delimeters = new RegExp(delimeter, 'g');
    return String(data).toString().replace(delimeters, '<br>');
  },

  // id 값을 참조하여 해당 인풋의 마스킹 처리 부분을 지움
  deleteMask(vue, event) {
    const checker = ['-', ',', '/', ' '];
    const value = event.target.value;
    const bind = event.target.getAttribute('id');
    // input 마지막이 checker에 포함 될때
    if (checker.includes(value.charAt(value.length - 1))) {
      vue[bind] = vue[bind].substr(0, vue[bind].length - 1);
    }
  },
  isCellPhone(phoneNo) {
    phoneNo = phoneNo.split(' ').join('');
    const regPhone = /^(01[0|1|6|7|8|9])(\d{3}|\d{4})(\d{4}$)/;
    return regPhone.test(phoneNo);
  },
  checkBirthDate(birthDate) {
    if (birthDate.length == 7) {
      birthDate = this.fullBirthDate(birthDate);
    }
    const regBirth = /^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

    return regBirth.test(birthDate);
  },
  alertPopup(vue, content, title) {
    vue.$store.commit('alertPopup', {
      popupType: 'ErrorPopup',
      popupContent: content,
      popupTitle: title,
    });
    bus.$emit('end:loading');
  },
  /**
   * 마스킹 처리
   *
   * @param vue this vue 객체
   * @param event event 변경 될 이벤트 객체
   */
  fn_mask(vue, event) {
    // 안드로이드는 event의 key값을 가져오지 못하므로 target.value의 마지막 값을 가져와서 처리 필요

    try {
      const literalPattern = /[0*●]/;
      const escapePattern = /[-,\\/]/;
      let newValue = '';
      const name = event.target.getAttribute('id');
      const nameText = event.target.getAttribute('id') + 'Text';
      const nameTextLength = event.target.getAttribute('id') + 'TextLength';
      const mask = event.target.getAttribute('data-mask');
      const maxlength = event.target.getAttribute('maxlength');
      const lastChar = '' + event.target.value.charAt(event.target.value.length - 1);

      this.printLog(nameText);
      this.printLog(nameTextLength);
      this.printLog(vue[name]);
      this.printLog(vue[nameText]);
      this.printLog(vue[nameTextLength]);

      if (event.key === 'Backspace') {
        if (!this.isNull(lastChar.match(escapePattern))) {
          vue[name] = vue[name].slice(0, -1);
        }
        vue[nameText] = vue[nameText].slice(0, -1);
      } else if (vue[nameText].length < parseInt(vue[nameTextLength])) {
        vue[nameText] = vue[nameText] + lastChar;
      }

      if (this.isNull(vue[nameText])) {
        return;
      }

      const value = vue[name];

      for (let vId = 0, mId = 0, len = mask.length; mId < len; mId++, vId++) {
        if (mId >= value.length) {
          break;
        }

        // 마스킹 이외 특수 값 넣기
        while (this.isNull(mask[mId].match(literalPattern))) {
          if (value[vId] === mask[mId]) {
            break;
          }
          newValue += mask[mId++];
        }

        if (value[vId] === '-' && mask[mId] === '0') vId++;

        if (mask[mId] !== '0' && mId < value.length - 1) {
          newValue += mask[mId];
          continue;
        } else if (mask[mId] !== '0' && mId === mask.length - 1) {
          newValue += mask[mId];
          continue;
        }

        newValue += value[vId];
      }
      vue[name] = newValue;
      vue.checkLength(event);
    } catch (err) {
      $commons.printLog(err);
    }
  },
  /**
   * 마스킹 처리
   *
   * @param vue this vue 객체
   * @param event event 변경 될 이벤트 객체
   */
  fn_mask_keypad(vue, ref, deleteYn) {
    // 안드로이드는 event의 key값을 가져오지 못하므로 target.value의 마지막 값을 가져와서 처리 필요
    try {
      this.printLog('fn_mask_ref >> ' + ref + deleteYn);
      const literalPattern = /[0*●]/;
      const escapePattern = /[-,\\/]/;
      let newValue = '';
      const name = vue.$refs[ref].getAttribute('id');
      const nameText = vue.$refs[ref].getAttribute('id') + 'Text';
      const nameTextLength = vue.$refs[ref].getAttribute('id') + 'TextLength';
      const mask = vue.$refs[ref].getAttribute('data-mask');
      const maxlength = vue.$refs[ref].getAttribute('maxlength');
      const lastChar = '' + vue[name].charAt(vue[name].length - 1);

      this.printLog(nameText);
      this.printLog(nameTextLength);
      this.printLog(vue[name]);
      this.printLog(vue[nameText]);
      this.printLog(vue[nameTextLength]);
      this.printLog('lastChar>>' + lastChar);

      if (deleteYn) {
        if (!this.isNull(vue[name].charAt(vue[name].length - 2).match(escapePattern))) {
          vue[name] = vue[name].slice(0, -1);
        }
        vue[name] = vue[name].slice(0, -1);
        vue[nameText] = vue[nameText].slice(0, -1);
      } else if (vue[nameText].length < parseInt(vue[nameTextLength])) {
        this.printLog('vue-name=--' + vue[nameText]);
        this.printLog(lastChar);
        vue[nameText] = vue[nameText] + lastChar;
      }
      this.printLog('실제 값 >>' + vue[nameText]);

      if (this.isNull(vue[nameText])) {
        return;
      }

      const value = vue[name];

      for (let vId = 0, mId = 0, len = mask.length; mId < len; mId++, vId++) {
        if (mId >= value.length) {
          break;
        }

        // 마스킹 이외 특수 값 넣기
        while (this.isNull(mask[mId].match(literalPattern))) {
          if (value[vId] === mask[mId]) {
            break;
          }
          newValue += mask[mId++];
        }

        if (value[vId] === '-' && mask[mId] === '0') vId++;

        if (mask[mId] !== '0' && mId < value.length - 1) {
          newValue += mask[mId];
          continue;
        } else if (mask[mId] !== '0' && mId === mask.length - 1) {
          newValue += mask[mId];
          continue;
        }

        newValue += value[vId];
      }
      this.printLog('결과 값 >>' + newValue);

      vue[name] = newValue;
      vue.checkLengthId(vue.curFocus);
    } catch (err) {
      $commons.printLog(err);
    }
  },
  /**
   * 마스킹 된 값을 리턴값으로 전달
   *
   * @param value 마스킹 되야하는 값
   * @param mask 마스킹 패턴
   * @returns {string}
   */
  fn_maskReturn(value, mask) {
    value = String(value).trim();

    const literalPattern = /[0*●]/;
    let newValue = '';

    for (let vId = 0, mId = 0, len = mask.length; mId < len; mId++, vId++) {
      while (this.isNull(mask[mId].match(literalPattern))) {
        if (value[vId] === mask[mId]) {
          break;
        }
        newValue += mask[mId++];
      }

      if (mask[mId] !== '0' && mId < value.length - 1) {
        newValue += mask[mId];
        continue;
      } else if (mask[mId] !== '0' && mId === mask.length - 1) {
        newValue += mask[mId];
        continue;
      }

      newValue += value[vId];
    }

    return newValue;
  },
  openBtn(_vue, seq) {
    // const next = $event.target.nextElementSibling;
    // if (!this.isNull(next)) {
    //   next.style.visibility = "visible";
    // }
    setTimeout(() => {
      _vue.active_input = seq;
    }, 10);
  },
  closeBtn($event) {
    const next = $event.target.nextElementSibling;
    if (!this.isNull(next)) {
      setTimeout(() => {
        next.style.visibility = 'hidden';
      }, 10);
    }
  },
  /**
   * 이름 중간 글자 마스킹
   * @param _name : 이름
   * @returns {string} : 마스킹 된 이름
   */
  setNameMasking(_name) {
    let tmp = '';
    let result = '';

    if (_name.length >= 3) {
      for (let i = 1; i < _name.length - 1; i++) {
        tmp += '*';
      }
      result = _name.substr(0, 1) + tmp + _name.substr(_name.length - 1);
    } else if (_name.length == 2) {
      result = _name.substr(0, 1) + '*';
    }
    return result;
  },
};

export default utils;
