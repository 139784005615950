<template>
  <div class="popup-wrap">
    <div class="popup-title">사용처 별 사용가능 포인트</div>
    <!--    <div class="popup-title">{{ title }}</div>-->
    <div class="popup-content">
      <div v-for="(list, idx) in content" :key="idx" class="d-flex c-center r-space-between">
        <div>{{ $utils.formatComma(list.platformName) }}</div>
        <div>{{ $utils.formatComma(list.pointBalance) }} P</div>
      </div>
    </div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="confirm">확인</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'LoginPopup',
  props: {
    title: {
      type: String,
      default: '알림',
      require: false,
    },
    content: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['getSetAuthType', 'getUserCel', 'getSelfAuthData', 'getPopupData', 'getAlertPopup', 'getSetPwdState']),
  },
  methods: {
    confirm() {
      this.$store.commit('alertPopup', {});
    },
  },
};
</script>

<style scoped>
.popup-button-no {
  font-size: 30px;
  letter-spacing: -1.5px;
  text-align: right;
  color: #648cff;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.popup-content {
  margin-top: 40px;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.6px;
  text-align: left;
  color: #2b2d2f;
}
.popup-wrap {
  z-index: 7000;
  padding: 40px;
}
</style>
