import $commons from '@/utils/commons';
import { stringify } from 'qs';

String.prototype.string = function (len) {
  var s = '',
    i = 0;
  while (i++ < len) {
    s += this;
  }
  return s;
};

String.prototype.zf = function (len) {
  return '0'.string(len - this.length) + this;
};

Number.prototype.zf = function (len) {
  return this.toString().zf(len);
};

// Date.prototype.format = function (f)
export default {
  format(date, format) {
    if (!date.valueOf()) return ' ';

    var weekKorName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];

    var weekKorShortName = ['일', '월', '화', '수', '목', '금', '토'];

    var weekEngName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    var weekEngShortName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return format.replace(/(yyyy|yy|MM|M|dd|d|KS|KL|ES|EL|HH|hh|mm|ss|a\/p)/gi, function ($1) {
      switch ($1) {
        case 'yyyy':
          return date.getFullYear(); // 년 (4자리)

        case 'yy':
          return (date.getFullYear() % 1000).zf(2); // 년 (2자리)

        case 'MM':
          return (date.getMonth() + 1).zf(2); // 월 (2자리)

        case 'M':
          return (date.getMonth() + 1).zf(1); // 월 (1자리)

        case 'dd':
          return date.getDate().zf(2); // 일 (2자리)

        case 'd':
          return date.getDate().zf(1); // 일 (1자리)

        case 'KS':
          return weekKorShortName[date.getDay()]; // 요일 (짧은 한글)

        case 'KL':
          return weekKorName[date.getDay()]; // 요일 (긴 한글)

        case 'ES':
          return weekEngShortName[date.getDay()]; // 요일 (짧은 영어)

        case 'EL':
          return weekEngName[date.getDay()]; // 요일 (긴 영어)

        case 'HH':
          return date.getHours().zf(2); // 시간 (24시간 기준, 2자리)

        case 'hh':
          var h = date.getHours() % 12;
          return (h ? h : 12).zf(2); // 시간 (12시간 기준, 2자리)

        case 'mm':
          return date.getMinutes().zf(2); // 분 (2자리)

        case 'ss':
          return date.getSeconds().zf(2); // 초 (2자리)

        case 'a/p':
          return date.getHours() < 12 ? '오전' : '오후'; // 오전/오후 구분

        default:
          return $1;
      }
    });
  },

  getWorldTime(tzOffset) {
    // 24시간제
    var now = new Date();
    var tz = now.getTime() + now.getTimezoneOffset() * 60000 + tzOffset * 3600000;
    now.setTime(tz);

    return now;
  },

  //오늘로 부터 몇개월 뒤, 몇년뒤 날짜 구하기 위한 로직
  dateHistory(value, type) {
    const today = new Date();
    let date;

    switch (type) {
      case 'month': {
        const monthAgo = new Date(today.setMonth(today.getMonth() - value));
        const year = monthAgo.getFullYear();
        let monthSimple = String(monthAgo.getMonth() + 1);
        monthSimple.length === 1 ? (monthSimple = `0${monthSimple}`) : monthSimple;

        date = `${year}-${monthSimple}`;
        break;
      }
      case 'year': {
        const yearAgo = new Date(today.setFullYear(today.getFullYear() - value));
        const year = yearAgo.getFullYear();
        let monthSimple = String(yearAgo.getMonth() + 1);
        monthSimple.length === 1 ? (monthSimple = `0${monthSimple}`) : monthSimple;

        date = `${year}-${monthSimple}`;
        break;
      }
    }

    return date;
  },

  //날짜 구하기
  fetchDate(type) {
    const date = new Date();
    const year = date.getFullYear();
    let month = String(date.getMonth() + 1);
    let day = String(date.getDate());
    let returnString = '';

    // 한자리수일 경우 0을 채워준다.
    if (month.length === 1) {
      month = '0' + month;
    }

    if (day.length === 1) {
      day = '0' + day;
    }

    switch (type) {
      case 'bar':
        returnString = year + '-' + month + '-' + day;
        break;
      case 'kor':
        returnString = year + '년 ' + month + '월 ' + day + '일';
        break;
      case 'year-month':
        returnString = `${year}-${month}`;
        break;
      default:
        break;
    }
    return returnString;
  },

  //ex 오전 7:15
  timeFormat(time) {
    //time = 23:59:00 형식
    const splitTime = time.split(':');
    const hour = splitTime[0];
    const min = splitTime[1];

    if (parseInt(hour) > 12) {
      const newHour = Math.abs(hour - 12).toString();
      const add = [newHour, min].join(':');
      return `오후 ${add}`;
    } else {
      const add = [hour, min].join(':');
      return `오전 ${add}`;
    }
  },

  //ex 어제 오전 7:15
  timeFormat2(time) {
    //어제,오늘 까지 추가된 시간함수
    const splitDay = time.split('T');
    const splitTime = splitDay[1].split(':');
    const days = splitDay[0].split('-');
    const hour = splitTime[0];
    const min = splitTime[1];

    const end = new Date();
    const start = new Date(time);

    const diff = end - start;
    const elapsedTime = diff / (1000 * 60 * 60);
    const combineTime = Math.ceil(elapsedTime) + Number(hour); //경과시간 올림+메시지 등록 시간

    if (elapsedTime < 12) {
      if (elapsedTime < 1) {
        return '방금 전';
      } else {
        return `${parseInt(elapsedTime)}시간 전`;
      }
    } else if (elapsedTime >= 12 && elapsedTime < 24 && Number(end.getDate()) - Number(days[2]) === 0) {
      if (parseInt(hour) > 12) {
        const newHour = Math.abs(hour - 12).toString();
        const add = [newHour, min].join(':');
        return `오늘 오후 ${add}`;
      } else {
        const add = [hour, min].join(':');
        return `오늘 오전 ${add}`;
      }
    } else if (combineTime >= 24 && combineTime <= 48 && Math.ceil(elapsedTime) < 48) {
      if (parseInt(hour) > 12) {
        const newHour = Math.abs(hour - 12).toString();
        const add = [newHour, min].join(':');
        return `어제 오후 ${add}`;
      } else {
        const add = [hour, min].join(':');
        return `어제 오전 ${add}`;
      }
    } else {
      return `${days[1]}월 ${days[2]}일`;
    }
  },
};
