// jhkim: 추가
// console wrapper
// 로그 레벨 설정 가능

const FTLogger = (function () {
  // 싱글톤
  let _sharedInstance;

  function init() {
    // trace: 4
    // debug: 3
    // info: 2
    // warn: 1
    // error: 0
    let _level = -1;

    function _empty(message, ...args) {}

    return {
      get level() {
        return _level;
      },

      set level(value) {
        if (value > 4) {
          return;
        }

        _level = value;
        _sharedInstance.apply();
      },
      assert: null,
      trace: null,
      debug: null,
      info: null,
      warn: null,
      error: null,

      apply() {
        _sharedInstance.assert = console.assert;
        _sharedInstance.trace = _level >= 4 ? console.trace : _empty;
        _sharedInstance.debug = _level >= 3 ? console.debug : _empty;
        _sharedInstance.info = _level >= 2 ? console.info : _empty;
        _sharedInstance.warn = _level >= 1 ? console.warn : _empty;
        _sharedInstance.error = _level >= 0 ? console.error : _empty;
      },
    };
  }

  return {
    sharedInstance: function () {
      if (_sharedInstance) {
        return _sharedInstance;
      }

      _sharedInstance = init();
      _sharedInstance.apply();

      return _sharedInstance;
    },
  };
})().sharedInstance();

export default FTLogger;
