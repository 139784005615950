<template>
  <div style="padding-top: 40px">
    <div class="common-padding d-flex" style="flex-direction: column; align-items: center; text-align: left">
      <b class="title_type1"> 잠깐, 구매 전 확인해 주세요!</b>
      <div v-if="userType === 1" class="title_type2" style="margin-top: 64px">
        TV포인트를 적립 받으시려면<br />상품을 구매하신 후 <b>'홈빠 > 마이홈빠'</b> 화면에서<br />60일 내 주문번호를 입력해 주세요!
      </div>
      <div v-if="userType === 2" class="title_type2" style="margin-top: 64px">
        주문 후 TV포인트를 적립 받으시려면<br />
        핏 로그인이 필요합니다.
      </div>
      <div style="margin-top: 12px; width: 100%">
        <CommonText font-size="24" font-color="#8f9193" spacing="-0.72" line-height="40"
          >※ TV포인트 적립신청은 '홈빠'에서만 가능합니다.</CommonText
        >
      </div>
      <img v-if="userType === 1" :src="require('@/assets/img/common/img_bbarshop_popup1.svg')" alt="" />
    </div>
    <div class="d-flex c-center common-padding" :style="userType === 1 ? 'margin: 32px 0 40px 0' : 'margin: 56px 0 40px 0'">
      <input id="check" v-model="ongoingChecked" type="checkbox" class="terms-check" />
      <label for="check"></label>
      <CommonText font-size="28" font-color="#76787a" spacing="-1.12">일주일간 보지 않기</CommonText>
      <CommonText font-size="20" font-color="#dadcde" spacing="-1.12" style="margin: 0 16px">|</CommonText>
      <CommonText font-size="28" font-color="#76787a" spacing="-1.12" @click="clickEvent('close')">닫기</CommonText>
    </div>
    <div v-if="userType === 1" class="btn-box">
      <CommonText font-size="32" font-color="#ffffff" spacing="-1.6" line-height="110" @click="clickEvent('continue')"
        >쇼핑 계속하기</CommonText
      >
    </div>
    <div v-if="userType === 2">
      <div class="btn-box2">
        <CommonText font-size="30" font-color="#ffffff" spacing="-1.6" line-height="88" @click="clickEvent('login')"
          >로그인하고 TV포인트 적립 받기</CommonText
        >
      </div>
      <CommonText
        font-size="30"
        font-color="#2b2d2f"
        spacing="-0.6"
        line-height="90"
        style="width: 100%; text-align: center; margin-bottom: 18px"
        @click="clickEvent('continue')"
        >쇼핑 계속하기</CommonText
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CommonText from '@/components/common/text/CommonText';

export default {
  components: { CommonText },
  props: {},
  emits: ['closePopup'],

  data() {
    return {
      scrollY: 0,
      ongoingChecked: false,
    };
  },
  computed: {
    ...mapGetters(['isLogin']),
    userType() {
      return this.isLogin ? 1 : 2;
    },
  },

  methods: {
    //팝업 호출 시 부모창 스크롤 잠금
    lockParentScroll() {
      this.scrollY = window.scrollY;
      document.getElementsByTagName('body')[0].style = 'width:100%; position:fixed; top: -' + this.scrollY + 'px';
    },
    //팝업 종료 시 부모창 스크롤 잠금해제
    unLockParentScroll() {
      document.getElementsByTagName('body')[0].style = '';
      window.scrollTo(0, this.scrollY);
    },

    //일주일간 보지않기 처리용 팝업 타입 구분 함수
    userTypeChecker() {
      return this.isLogin ? 1 : 2;
    },

    async closePopup() {
      if (this.ongoingChecked === true) {
        // let type = 'hide' + this.userTypeChecker();
        // let today = new Date();
        // localStorage.setItem(type, new Date(Date.parse(today) + 7 * 1000 * 60 * 60 * 24));
        if (this.$deviceType === 1 || this.$deviceType === 3) {
          // 앱은 오늘 하루 안보기를 구현
          this.$bridge.setStringValue('stopSee', this.$utils.getDate('bar'));
          // 튜토리얼 show 유무
          // if ((await this.$bridge.stringValue('0')) !== 'home') {
          //   this.$store.commit('setTutorialModal', {
          //     show: true,
          //     pages: 'AppTutorial',
          //   });
          //   this.$bridge.setStringValue('0', 'home');
          // }
        } else {
          // 쿠키 설정
          this.$utils.setCookie('tvInfoPopup', 'N', 7);
        }
      }
      this.$emit('closePopup');
    },

    clickEvent(sort, idx) {
      switch (sort) {
        case 'close':
          this.$commons.printLog('click', sort);
          this.closePopup();
          break;
        case 'continue':
          this.$commons.printLog('click', sort);
          this.closePopup();
          break;
        case 'login':
          this.$commons.printLog('click', sort);
          this.$router.push('/user/login');
          this.closePopup();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup_wrapper {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
  background-color: #ffffff;
  z-index: 101;
}

.title_type1 {
  width: 100%;
  display: block;
  font-size: 42px;
  color: #2b2d2f;
  letter-spacing: -1.68px;
  font-weight: bold;
}

.title_type2 {
  width: 100%;
  display: block;
  font-size: 30px;
  line-height: 42px;
  color: #2b2d2f;
  letter-spacing: -1.2px;
}
input[type='checkbox'] {
  display: none;
  box-sizing: border-box;
}

input[type='checkbox'] + label {
  margin-top: 3px;
  margin-right: 10px;
  width: 42px;
  height: 42px;
  background-image: url('../../../assets/img/common/check_circle_off.svg');
  background-repeat: no-repeat;
}

input[type='checkbox']:checked + label {
  margin-top: 3px;
  margin-right: 10px;
  width: 42px;
  height: 42px;
  background-image: url('../../../assets/img/common/check_circle_on.svg');
  background-repeat: no-repeat;
}
.btn-box {
  width: 100%;
  height: 110px;
  background-color: #2b2d2f;
  text-align: center;
}
.btn-box2 {
  width: 640px;
  height: 88px;
  background-color: #2b2d2f;
  text-align: center;
  border-radius: 8px;
  margin: 0 auto;
}
</style>
