<template>
  <div class="search-order-wrapper">
    <PaiSearchTitle style="height: 58px">
      <template #left>
        <span>정렬</span>
      </template>
    </PaiSearchTitle>

    <CommonRadiusButton border="1px #dadcde" height="65px" radius="8" class="common-radius-wrapper"
      ><CommonRadioButton
        v-model="order"
        :data="radioData"
        radio-name="orderRadio"
        height="64px"
        :width="isLogin ? '215px' : '320px'"
        @click-event="clickEvent"
    /></CommonRadiusButton>
  </div>
</template>

<script>
import CommonRadiusButton from '@/components/common/button/CommonRadiusButton';
import CommonRadioButton from '@/components/common/button/CommonRadioButton';
import PaiSearchTitle from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchTitle';
import { mapGetters } from 'vuex';
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiSearchOrder',
  components: { PaiSearchTitle, CommonRadioButton, CommonRadiusButton },
  data() {
    return {
      order: 0,
      radioData: ['최신순', '안 읽은순', '구독순'],
    };
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
  created() {
    if (!this.isLogin) {
      this.radioData = ['최신순', '안 읽은 순'];
    }
  },
  methods: {
    clickEvent(sort, data) {
      switch (sort) {
        case 'order':
          this.order = data;
          this.$commons.printLog('paiSearch> ', this.order);
          // this.$emit('clickEvent', 'eventDetail', this.order);
          break;
      }
    },
    refresh() {
      this.order = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-order-wrapper {
  padding-top: 16px;
}

.common-radius-wrapper {
  margin-top: 28px;
}
</style>
