const getDefaultState = () => {
  return {
    fitcolList: [],
    magazineAList: {},
    magazineBList: {},
    exhibitionAList: {},
    fitcolDetail: {},
  };
};

export default getDefaultState();
