<template>
  <div class="terms-wrap common-padding">
    <div class="terms-title">앗,고객님은<br />아직 회원이 아닙니다!</div>
    <div class="terms-container">
      <div
        v-for="(term, idx) in terms"
        :id="'termsCheck' + idx"
        :key="term.title"
        class="term"
        :style="idx == terms.length - 1 ? 'margin-bottom:48px' : ''"
      >
        <!-- <input :id="'termsCheck' + idx" type="checkbox" name="" class="terms-check" checked /> -->
        <!-- <label :for="'termsCheck' + idx"></label> -->
        <!-- <img :src="termCheck ? term.img_on : term.img_off" class="term-img" @click="showCheck" /> -->
        <span class="term-text" :for="'termsCheck' + idx" :data-id="idx" @click="showCheck">[필수]{{ term.title }}</span>
      </div>
      <button class="terms-agree-btn" @click="goNext">전체 약관에 동의하고 계속하기</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';

export default {
  data() {
    return {
      terms: [
        // {
        // 	id: 'new_terms',
        // 	name: '[필수] 핏콜라보 통합 이용약관',
        // 	img_on: require('@/assets/img/tvpoint/check_on.svg'),
        // 	img_off: require('@/assets/img/tvpoint/check_off.svg'),
        // },
        // {
        // 	id: 'new_third_party_terms',
        // 	name: '[필수] 개인정보 수집 및 이용 동의',
        // 	img_on: require('@/assets/img/tvpoint/check_on.svg'),
        // 	img_off: require('@/assets/img/tvpoint/check_off.svg'),
        // },
        // {
        // 	id: 'new_terms_of_privacy',
        // 	name: '[필수] 개인정보 제 3자 제공에 대한 동의',
        // 	img_on: require('@/assets/img/tvpoint/check_on.svg'),
        // 	img_off: require('@/assets/img/tvpoint/check_off.svg'),
        // },
      ],
    };
  },

  computed: {
    ...mapGetters(['getRpSession']),
  },

  created() {
    this.saveData();
  },

  methods: {
    saveData() {
      const _vue = this;
      _vue.$utils.printLog('saveData');
      const deferred = this.$utils.defer();
      const params = {
        tvpaySubUrl: '/api/saveData.mv',
        // termId: 'CI200519U3S',
        storeId: 'MOBILETP01',
        termId: _vue.getTermId,
        // storeId: _vue.getStoreId,
      };

      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('saveData resultCode', data.resultCode);
        _vue.$utils.printLog('saveData data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('saveData resultCode');
          _vue.$store.commit('setRpSession', data.resultData.jSession);
          this.getOrderTerms();
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    async getOrderTerms() {
      const _vue = this;
      const deferred = this.$utils.defer();
      const params = {
        tvpaySubUrl: `/api/getOrderTerms.mv;jsessionid=${_vue.getRpSession}`,
        cardCode: '1011',
        methodType: 'SMART_PAY',
        userType: 'mobile_pay',
        formType: 'tv_card_new',
        clientType: 'mobile',
      };
      const data = _vue.$store.dispatch('callRelayRpServer', params);

      await data.then(data => {
        _vue.$utils.printLog('getOrderTerms resultCode', data.resultCode);
        _vue.$utils.printLog('getOrderTerms data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('getOrderTerms resultData', data.resultData);
          for (let i = 0; i < data.resultData.list.length; i++) {
            if (data.resultData.list[i].userType == `mobile_pay`) {
              _vue.terms.push(data.resultData.list[i]);
            }
          }
          _vue.$utils.printLog('terms', _vue.terms);
          bus.$emit('end:loading');
          // deferred.resolve();
        } else {
          // deferred.reject([_vue, data]);
          _vue.$utils.errorMessage(_vue, data);
        }
      });
    },
    showCheck($event) {
      const idx = $event.currentTarget.getAttribute('data-id');
      let termsTitle = '';
      let termsContent = '';

      this.$utils.printLog("$event.currentTarget.getAttribute('data-id')", $event.currentTarget.getAttribute('data-id'));

      const _vue = this;
      _vue.$utils.printLog('getOrderTerms');
      const params = {
        tvpaySubUrl: `/api/getCardTerms.mv;jsessionid=${_vue.getRpSession}`,
        methodType: this.terms[idx].methodId,
        userType: this.terms[idx].userType,
        termType: this.terms[idx].termType,
      };
      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('getCardTerms resultCode', data.resultCode);
        _vue.$utils.printLog('getCardTerms data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('getCardTerms resultData', data.resultData);
          bus.$emit('end:loading');
          termsTitle = data.resultData.termsTitle;
          termsContent = data.resultData.termsContents;
          this.$store.commit('slideShow', {
            slideShow: true,
            slideType: 'TelecomBasicTermsComponent',
            slideContent: { termsTitle, termsContent },
          });
        } else {
          _vue.$utils.errorMessage(_vue, data);
        }
      });
    },
    goNext() {
      this.$store.commit('setSetPwdState', 'joinPwd');
      this.$router.push('/user/setpwdpage');
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-wrap {
  height: 100%;
}
.terms-title {
  height: 172px;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -1.7px;
  color: #343534;
  font-weight: bold;
  margin-bottom: 38px;
  display: table-cell;
  vertical-align: middle;
}
.terms.container {
  height: 100%;
  overflow: scroll;
}
.term {
  margin-bottom: 29px;
}
.term-img {
  display: inline-block;
  vertical-align: middle;
}
.terms-check {
  display: none;
  float: left;
  box-sizing: border-box;
}
.terms-check-all {
  display: none;
  float: left;
  box-sizing: border-box;
}
.terms-check-all + label {
  float: left;
  width: 42px;
  height: 42px;
  line-height: 42px;
  margin-top: 5px;
  background: url('../../../../assets/img/tvpoint/check_circle_on.svg') no-repeat;
  box-sizing: border-box;
}
.terms-check-all:checked + label {
  display: inline-block;
  // float: left;
  width: 42px;
  height: 42px;
  line-height: 42px;
  margin-top: 5px;
  padding-bottom: 2px;
  background: url('../../../../assets/img/tvpoint/check_box_on.svg') no-repeat;
  box-sizing: border-box;
}
.terms-check + label {
  float: left;
  width: 42px;
  height: 42px;
  line-height: 42px;
  padding-bottom: 2px;
  background: url('../../../../assets/img/tvpoint/check_off.svg') no-repeat;
  box-sizing: border-box;
}
.terms-check:checked + label {
  display: inline-block;
  // float: left;
  width: 42px;
  height: 42px;
  line-height: 42px;
  padding-bottom: 2px;
  background: url('../../../../assets/img/tvpoint/check_on.svg') no-repeat;
  box-sizing: border-box;
}
.term-text {
  height: 27px;
  line-height: 27px;
  font-size: 26px;
  letter-spacing: -1.3px;
  color: #343534;
  vertical-align: middle;
  border-bottom: 1px solid #343534;
}
.terms-agree-btn {
  background-color: #e42028;
  height: 100px;
  width: 100%;
  font-size: 32px;
  line-height: 100px;
  color: #ffffff;
  text-align: center;
  letter-spacing: -1.6px;
  margin-bottom: 40px;
}
</style>
