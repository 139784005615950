<template>
  <transition name="fade">
    <div v-if="nonMemberPopup" id="layerWrap" class="layer-wrap">
      <div class="pop-layer">
        <div class="pop-container">
          <div class="pop-conts">
            <div class="contents-container">
              <div class="txt_contents">고객님은 아직 회원이 아닙니다.<br />회원가입 후 서비스를 이용하시겠습니까?</div>
            </div>

            <div class="botton-container d-flex">
              <div class="btn_cancel">
                <span @click="cancel">취소</span>
              </div>
              <div class="btn_confirm">
                <span @click="confirm">회원가입</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['nonMemberPopup', 'getSelfAuthData', 'getSetAuthType']),
  },

  watch: {
    nonMemberPopup(val) {
      if (val) {
        window.removeEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      } else {
        window.addEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      }
    },
  },

  methods: {
    cancel() {
      this.$store.commit('nonMemberPopup', false);
    },
    confirm() {
      this.$store.commit('nonMemberPopup', false);
      this.$router.push('/user/SignUpPage');
    },
  },
};
</script>

<style lang="scss" scoped>
.layer-wrap {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  // margin-right: -.25em;
}
.pop-layer {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 140px);
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 12.5px;
}
.contents-container {
  padding: 40px;
}
.botton-container {
  border-radius: 16px;
  background-color: #ffffff;
  padding-right: 40px;
  padding-bottom: 43px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}
.txt_contents {
  width: 100%;
  height: auto;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
.btn_cancel {
  font-size: 30px;
  line-height: 1.7;
  letter-spacing: -1.5px;
  text-align: right;
  color: #343534;
}
.btn_confirm {
  font-size: 30px;
  line-height: 1.7;
  letter-spacing: -1.5px;
  text-align: right;
  color: #648cff;
  font-weight: 500;
  padding-left: 44px;
}
.btn_cancel span {
  display: contents;
  margin-left: 206px;
}
.btn_confirm span {
  display: inline-block;
}
</style>
