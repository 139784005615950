import axios from './common/apiConfig';
import urlConfig from '@/config/urlConfig';
import $commons from '@/utils/commons';
import $store from '@/store';

//pai 로그인
function paiLogin(params) {
  return axios.post(`${urlConfig.PAI_BASE_URL}guest/login`, params);
}

//로그인 renew
function renewToken() {
  return axios.post(`${urlConfig.PAI_BASE_URL}guest/session/renew`);
}

//파이 로그아웃
function paiLogout(params) {
  return axios.post(`${urlConfig.PAI_BASE_URL}logout`, params);
}

//전체 채널 목록 조회
function paiChannelList() {
  return axios.get(`${urlConfig.PAI_BASE_URL}channels?all=true`);
}
//채널 상세 조회
function channelDetailInfo(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}channels/${params}`);
}
//숨김 채널 목록 조회
function hiddenChannelList() {
  return axios.get(`${urlConfig.PAI_BASE_URL}subscribe/channels?type=HID`);
}
//숨김 채널 해제
function channelUnHide(params) {
  return axios.update(`${urlConfig.PAI_BASE_URL}subscribe/channels/${params.id}`, params.params);
}
//채널 숨김
function channelHide(params) {
  return axios.post(`${urlConfig.PAI_BASE_URL}subscribe/channels`, params);
}
//저장 혜택 목록 조회
function storedBenefitList(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}subscribe/contents?active=${params}`);
}
//해택 메시지 저장
function storeBenefit(params) {
  return axios.post(`${urlConfig.PAI_BASE_URL}subscribe/contents`, params);
}
//해택 메시지 삭제
function deleteBenefit(params) {
  return axios.update(`${urlConfig.PAI_BASE_URL}subscribe/contents/${params.id}`, params.params);
}
//알림설정 조회
function notificationList() {
  // return axios.get(`${urlConfig.PAI_BASE_URL}operations/alarm_config/12345`);
  return axios.get(`${urlConfig.PAI_BASE_URL}operations/alarm_config/1`);
}

//채널소식 설정 조회
function channelPostSetting(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}subscribe/channels?ch_id=${params}&action=alarm`);
}

// 채널 구독 상태 조회
function subChannelState(id) {
  return axios.get(`${urlConfig.PAI_BASE_URL}subscribe/channels?ch_id=${id}&action=status`);
}

//채널구독
function channelSub(params, id) {
  return axios.postQs(`${urlConfig.PAI_BASE_URL}subscribe/channels`, params);
}

//구독 채널 해제
function channelUnSub(params) {
  let { subs_channel, id } = params;
  return axios.update(`${urlConfig.PAI_BASE_URL}subscribe/channels/${id}`, { subs_channel });
}

//구독 채널 목록 조회
function subChannelList() {
  return axios.get(`${urlConfig.PAI_BASE_URL}subscribe/channels?type=SUB`);
}

//키워드 구독
function keySub(params, id) {
  return axios.postQs(`${urlConfig.PAI_BASE_URL}/subscribe/keywords`, params);
}

//구독 키워드 해제
function keyUnSub(params) {
  let { subs_key, id } = params;
  return axios.update(`${urlConfig.PAI_BASE_URL}/subscribe/keywords/${id}`, { subs_key });
}

//구독 키워드 목록 조회
function subKeyList() {
  return axios.get(`${urlConfig.PAI_BASE_URL}subscribe/keywords`);
}

//채널 사용처 목록 조회
function channelUseList(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}channels/${params}/stores`);
}

//채널목록 조회-내포인트 쿠폰조회
function fetchHiddenAsset() {
  return axios.get(`${urlConfig.PAI_BASE_URL}channels?point=true`);
}

//혜택 메시지 목록 조회
function paiBenefitList(params) {
  return axios.get(
    `${urlConfig.PAI_BASE_URL}list_contents?order_type=${params.order_type}&info_type=${params.info_type}&category=${params.category}`,
  );
}
//혜택 메시지 목록 조회(필터링 조건 걸고 조회 시)
function paiBenefitListFilter(params) {
  return axios.get(
    `${urlConfig.PAI_BASE_URL}list_contents?order_type=${params.order_type}&info_type=[${params.info_type}]&category=[${params.category}]`,
  );
}

//필터링 조건 조회
function getFilter() {
  return axios.get(`${urlConfig.PAI_BASE_URL}filter_conditions`);
  // return axios.get(`${urlConfig.PAI_BASE_URL}filter_conditions?order_type=${params.order_type}&info_type=["${params.info_type}"]`);
}

//채널 메시지 목록 조회
function storeRoomInfo(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}channels/${params}/contents?sort_order=asc`);
}

//채널별 뱃지 카운트 목록 조회
function contentsCount(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}contents/count`);
}

//구독키워드별 뱃지 카운트 목록 조회
function subscriptionCount(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}keywords/count`);
}

//전체읽음 설정
function readAllList(params) {
  return axios.update(`${urlConfig.PAI_BASE_URL}last_reads/read_all`, params);
}

//비회원 및 비로그인 혜택 메시지 목록 조회
function guestBenefitList(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}/list_contents?info_type=${params}`);
}

function pushAlarmList() {
  return axios.get(`${urlConfig.PAI_BASE_URL}push_alarms`);
}

//채널 상단 고정
function setChannelTop(params) {
  return axios.postJson(`${urlConfig.PAI_BASE_URL}subscribe/channels`, params);
}

//채널 상단 고정 해제
function unSetChannelTop(payload) {
  const { id, state } = payload;
  const params = {
    subs_channel: {
      state: state,
    },
  };
  return axios.update(`${urlConfig.PAI_BASE_URL}subscribe/channels/${id}`, params);
}

//상단 고정 채널 목록 조회
function checkChannelTop() {
  return axios.get(`${urlConfig.PAI_BASE_URL}subscribe/channels?type=TOP`);
}

//제휴사 포인트 조회
function fetchAssociatePoint(params) {
  return axios.post(`${urlConfig.PAI_BASE_URL}channels/get_point`, params);
}

//신규 푸시알림 카운트 조회
function alarmCount(params) {
  // return axios.get(`${urlConfig.PAI_BASE_URL}alarms/count?type=${params}`);
  return axios.get(`${urlConfig.PAI_BASE_URL}push_alarms/count`);
}

//신규 혜택 카운트 조회
function newCount() {
  return axios.get(`${urlConfig.PAI_BASE_URL}contents/new_count`);
}

//수신 알림 수정
function updateAlarms(params) {
  let { status, key } = params;

  $commons.printLog(status);
  $commons.printLog(key);
  return axios.update(`${urlConfig.PAI_BASE_URL}user_alarms/${key}`, { status });
}

//수신 알림 삭제
function deleteAlarms(params) {
  return axios.post(`${urlConfig.PAI_BASE_URL}/user_alarms/delete`, params);
}

//수신 알림 목록 조회
function getAlarmsList(params) {
  // return axios.get(`${urlConfig.PAI_BASE_URL}user_alarms?type=${params}`);
  return axios.get(`${urlConfig.PAI_BASE_URL}user_alarms?type=`);
}

//신규 수신 알림 카운트 조회
function getAlarmsCount(params) {
  return axios.get(`${urlConfig.PAI_BASE_URL}alarms/count?type=${params}`);
}

//채널소식 설정 변경
function setPostSettings(params) {
  return axios.update(`${urlConfig.PAI_BASE_URL}subscribe/channels/${params.id}`, params.params);
}

//구독채널 알림설정(일괄처리)
function setSubsChannelsAlarm(params) {
  $commons.printLog(params);
  return axios.update(`${urlConfig.PAI_BASE_URL}subscribe/channels/all`, params);
}

//코드 조회 - 카테고리 메인
function getCodeCategoryMain() {
  if ($store.state.tvpoint.isLogin) {
    return axios.get(`${urlConfig.PAI_BASE_URL}guest/codes?group=CATEGORY_MAIN`);
  } else {
    return axios.get(`${urlConfig.PAI_BASE_URL}codes?group=CATEGORY_MAIN`);
  }
}
//코드 조회 - 혜택유형
function getCodeContentInfo() {
  if ($store.state.tvpoint.isLogin) {
    return axios.get(`${urlConfig.PAI_BASE_URL}guest/codes?group=CONTENT_INFO`);
  } else {
    return axios.get(`${urlConfig.PAI_BASE_URL}codes?group=CONTENT_INFO`);
  }
}

export {
  paiLogin,
  renewToken,
  paiLogout,
  paiChannelList,
  channelDetailInfo,
  channelUseList,
  fetchHiddenAsset,
  paiBenefitList,
  getFilter,
  subChannelList,
  subKeyList,
  hiddenChannelList,
  channelUnHide,
  channelHide,
  storeRoomInfo,
  contentsCount,
  subscriptionCount,
  readAllList,
  storedBenefitList,
  storeBenefit,
  deleteBenefit,
  channelSub,
  channelUnSub,
  keySub,
  keyUnSub,
  subChannelState,
  notificationList,
  channelPostSetting,
  guestBenefitList,
  pushAlarmList,
  setChannelTop,
  unSetChannelTop,
  checkChannelTop,
  fetchAssociatePoint,
  alarmCount,
  newCount,
  updateAlarms,
  deleteAlarms,
  getAlarmsList,
  getAlarmsCount,
  paiBenefitListFilter,
  setPostSettings,
  setSubsChannelsAlarm,
  getCodeCategoryMain,
  getCodeContentInfo,
};
