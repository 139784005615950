<template>
  <div>
    <div ref="content" class="sliding-wrap" style="overflow: scroll; position: relative">
      <slot name="content"></slot>
    </div>
    <slot name="button"></slot>
  </div>
</template>

<script>
export default {
  name: 'SlidingContentWrapper',
  components: {},
  props: {
    paddingTop: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      // 부모창의 크기
      // const popupHeight = this.$parent.$refs.contentWrap.clientHeight;
      const popupHeight = document.getElementsByClassName('content-wrap')[0].clientHeight;
      // title 크기
      let titleHeight = 0;
      // if (this.$parent.$refs.title) {
      // 	titleHeight = this.$parent.$refs.title.clientHeight;
      // }

      if (document.getElementsByClassName('content-title')[0])
        titleHeight = document.getElementsByClassName('content-title')[0].clientHeight;

      // padding bottom 확인
      const marginTop = window.getComputedStyle(document.getElementsByClassName('sliding-wrap')[0]).marginTop;
      const marginBottom = window.getComputedStyle(document.getElementsByClassName('sliding-wrap')[0]).marginBottom;
      const paddingBottom = window.getComputedStyle(document.getElementsByClassName('sliding-wrap')[0]).paddingBottom;

      this.$commons.printLog('popupHeight', popupHeight);
      this.$commons.printLog('titleHeight', titleHeight);
      this.$commons.printLog('marginBottom', marginBottom);
      this.$commons.printLog(popupHeight - titleHeight - parseInt(marginBottom) + 'px');

      this.$refs.content.style.height =
        popupHeight - titleHeight - parseInt(marginTop) - parseInt(marginBottom) - parseInt(paddingBottom) + 'px';
    });
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
