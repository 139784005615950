const getDefaultState = () => {
  return {
    prevPage: [], // 이전 페이지 관리
    initialTerms: false, // 최초 약관 설정 여부
    tvLogin: false, // 티비to모바일 로그인 접속 여부
    tvInfo: {}, // 티비to모바일 정보 termType, termId
    termType: 'M002002', // 고정값
    mainTabType: 'MW0601', // 고정값
    pushPopupStep: null, // 최초 동의 팝업 안내 단계
    // isFirst: false, // 안드로이드 최초 실행 여부
    isInit: false, // 메인페이지 진행 여부
    isMarketing: false, // 안드로이드 마케팅 수신동의 여부
    deviceName: '', // 안드로이드 아이폰 web 구분  ANDROID, IOS, WEB
    // permission: {}, // permission 동의여부 조회 결과
    // token: '', // firebase token
    wifiState: null, // 와이파이 상태
    autoLoginFail: null, // 자동로그인 실패시 메세지 저장
    endPopupFlag: '', // 앱에서 실행 중 뒤로가기를 눌렀을 경우 안드로에드에서 호출
    startPopupFlag: '', // 최초 실행 팝업 안내 창
    redirect: '', // 로그인 페이지로 오는 경우 되돌아가야 하는 페이지
    autoLoginYn: false, // 오토로그인 여부 설정
    tabStatus: '', // 이전으로 가는경우 tab 종류 확인
    appVersion: '',
    loading: false,
    alertPopupType: '',
    alertPopupTitle: '',
    alertPopupContent: '',
    popupTransType: '',
    alertPopup: {},
    toastPopup: {},
    confirmPopup: {},
    alertPopup2: null,
    test: '123',
    headerMode: '',
    headerText: '',
    subText: '',
    bannerInfo: {},
    subBannerInfo: {},
    showSummary: false,
    slideShow: false,
    slideShowBtn: false,
    slideContent: {},
    slideType: '',
    telecomCode: '',
    lastScrollTop: 0,
    isUp: true,
    isHeader: false, // 헤더 뒤로가기
    isBack: false, // 여러 화면 뒤로 가는경우 뒤로가기 체크해서 history go -1을 해줘야 함
    terms: '',
    newTerms: '',
    noticeDetail: {},
    eventDetail: {},
    slidePopupData: {},
    existingMemberPopup: false,
    nonMemberPopup: false,
    amountPopupData: {},
    setPwdState: '',
    datePopupData: {},
    noticeList: [],
    noticeId: '', // 공지사항 클릭 시 공지사항 상세로 가기 위한 id 값
    MW0100: [],
    MW0102: [],
    MW0205: [],
    MW0307: [],
    MW0601: [],
    termsText: '',
    setAuthType: '',
    qnaTypeList: [],
    userInfo: '',
    historyTab: '',
    headerColor: '', // 헤더 색상 바뀔 경우
    point: '12344342312',
    isSaved: false,
    pointChange: false, // 포인트 전환 여부
    buyPoint: {}, // 구매예정 포인트 및 선택한 카드 idx
    transablePoint: 0, // 전환 가능 포인트
    buzzPoint: 0, // 버즈빌 포인트
    useBannerDetail: {}, // TV포인트 사용 배너 클릭 시 내용
    giftMessage: '', // 선물하기 카카오톡 메시지 정조
    orderDetail: {},
    partnerShip: {},
    certDetail: {},
    familyDetail: {},
    exchangeDetail: {},
    tmoDetail: {},
    skpmallDetail: {}, // 기프티콘 정보
    checkStore: true,
    storeList: [],
    page: true,
    saleDetail: {},
    saleContent: {},
    familyPointDetail: {},
    chargeDetail: {},
    autoChargeInfo: {}, // 완료 페이지에 넘길 데이터
    memberYN: '',
    couponPrice: [],
    partnerShipList: [],
    transferInfo: null,
    pointCouponList: [], // 상품권 리스트
    purchaseInfo: {}, // smartconfirm 후 전달 할 데이타
    vodPoint: '',
    cardInfo: {},
    mobileUserYN: '', // 신규 모바일 계정
    appCardType: '', // TV페이, 포인트 구매 진입 구분 값
    cardRegType: '',
    termsTitleList: [],
    // loading:false,
    storeId: 'MOBILETP01', // 가맹점 아이디
    termId: 'A1298616210', // 모바일 단말 아이디
    // termId: '', // 가상 단말 아이디
    rpSession: '', // RP 세션아이디
    cardList: '', // 카드 리스트 관리
    authDetail: {}, // 인증에 필요한 정보들 userNo , acntNo ...
    viewAccountDetail: {}, // viewAccount 결과
    changePhoneDetail: {}, // 휴대폰 번호변경
    ssKey: '', // insSessionData 후 받아온 키값
    userDetailInfo: {}, // 유저 민감 정보
    isLogin: false, // 로그인
    isAutoLogin: false, // 자동 로그인
    isAutoCharge: 'N', // 자동 충전
    isAutoTrans: 'N', // 자동 전환
    autoTransDetail: {}, // 자동 전환 데이터
    autoChargeDetail: {}, // 자동 충전 데이터
    directs: [], // 사용자 바로가기 리스트
    userNoticeList: {}, // 회원 알리 목록 리스트
    agrmntList: {}, // 화면 기준 사용자의 동의 정보 목록
    noticeCount: '', // 알림 갯수
    jsessionId: '', // sessionid 유지
    agreementYN: '',
    expirationWillPoint: '',
    genderCode: '',
    pointBalance: '',
    userBirthDate: '',
    userCel: '',
    userName: '',
    userNo: '',
    userShaPwd: '',
    userEmail: '',
    selfAuthData: {},
    // celNo: '',
    foreignerYn: '',
    userBirthDay: '',
    userGender: '',
    presentUser: null,
    familyInfo: { requestList: [], familyList: [] },
    memberType: '',
    pwdType: '',
    pwdYN: '',
    transferYn: '',
    lastShareUrl: '',
    buzvillToolTip: '',
    totalPoint: '', // 총 TV포인트(TV포인트 모바일 + TV이벤트 적립)
    platFormList: [], // 사용처 별 사용가능 포인트 리스트
    skipPwd: '', // TV포인트 모바일 비밀번호 슬라이딩 팝업에서 전환 시 비밀번호 생략 버튼 유,무 체크값
    userRegType: '', // 휴대폰번호 회원가입 or 앱카드 회원가입
    loginToken: '',
  };
};

export default getDefaultState();
