<template>
  <div class="d-flex c-center r-center common-wrapper">
    <div
      :key="index"
      class="d-flex base-line c-center r-center"
      :style="`padding:${padding}; height: ${height}; width: ${width}`"
      style="text-align: center"
    >
      <input :id="`radio${radioName}${index}`" v-model="selectTab" type="radio" :name="radioName" :value="index" :hidden="true" />
      <label ref="test" :for="`radio${radioName}${index}`" class="test" :style="` height: ${height}; width: ${width}`">
        <img v-if="selectTab === index" :src="require(`@/assets/${backgroundImageOn}.svg`)" />
        <img v-else :src="require(`@/assets/${backgroundImageOff}.svg`)" />
        {{ labelName }}</label
      >
    </div>
  </div>
</template>

<script>
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiBenefitRadio',
  props: {
    // 높이
    height: {
      type: String,
      default: '40px',
    },
    // 너비
    width: {
      type: String,
      default: '40px',
    },
    // 라디오 버튼 이름
    // 곂치지 않은 이름이여야 함
    radioName: {
      type: String,
      default: 'common',
    },
    // 라벨에 텍스트가 들어갈 경우
    // 라벨 이름
    labelName: {
      type: String,
      default: '',
    },
    // 라디오 버튼 순서
    // 라디오 버튼 index값
    index: {
      type: Number,
      default: 0,
    },
    // 라디오 선택 값
    value: {
      type: Number,
      default: 0,
    },
    // 패딩
    padding: {
      type: String,
      default: '0 0 0 0',
    },
    // 체크 된 이미지
    backgroundImageOn: {
      type: String,
      default: 'img/common/btn_radio_on',
    },
    // 미 체크 이미지
    backgroundImageOff: {
      type: String,
      default: 'img/common/btn_radio_off',
    },
  },
  emits: ['clickEvent'],
  data() {
    return {
      selectTab: -1,
    };
  },
  watch: {
    value(newVal) {
      this.selectTab = newVal;
    },
    selectTab(newVal) {
      this.$emit('clickEvent', 'radio', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  border-radius: 8px;
  box-sizing: border-box;
  line-height: 2.46;
}

input[type='radio'] + label {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

input[type='radio']:checked + label {
  background-repeat: no-repeat;
  background-position: center;
}
</style>
