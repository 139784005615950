import {
  mobileCardList,
  tvCardList,
  payHistory,
  payDetailHistory,
  removePaymentHistory,
  tvPayMakeTid,
  tvPayRemoveAccount,
  prepareTid,
  tvpaySmartConfirm,
  getAutoPaymentList,
  cancelSpotvService,
  getMelonJoin,
  tvpayReqAuthData,
  setMelonJoin,
  getCancelMelonYN,
  setCancelMelon,
  setEndMelon,
  setEndCancelMelon,
  getMelonPrice,
  authPass,
  getXGolfPrice,
  setXGolfJoin,
  getXGolfUser,
  getXGolfPromotionYN,
} from '@/apis/tvpay';

export default {
  async setMobileCardList({ commit }, params) {
    const { data } = await mobileCardList(params);
    commit('setCardResult', data);
    commit('setMoCardList', data);
    commit('setMobileUserYN', data.pinYn);
    // commit('setCardListCount', data.listCount);
    return data;
  },

  async setTVCardList({ commit }) {
    const { data } = await tvCardList();
    commit('setCardResult', data);
    commit('setCardListCount', data.listCount);
  },

  async setTVCardListCount({ commit }) {
    const { data } = await tvCardList();
    commit('setCardResult', data);
    commit('setCardListCount', data.listCount);
  },

  async setPaymentHistory({ commit }, params) {
    const { data } = await payHistory(params);

    if (params.type === 'Y' || params.type === 'N') data.paymentList = data.paymentList.filter(data => data.monthPayYn === params.type);
    delete params['type'];

    commit('setPaymentList', data);
  },

  async setPaymentDetailHistory({ commit }, params) {
    const { data } = await payDetailHistory(params);

    commit('setPaymentDetailList', data);
  },

  async removePaymentHistory({ commit }, params) {
    const { data } = await removePaymentHistory(params);

    return data;
  },

  // 앱카드 결제서버 거래번호(TID) 발급 요청
  async tvPayMakeTid({ commit }, params) {
    const { data } = await tvPayMakeTid(params);

    return data;
  },

  async tvPayRemoveAccount({ commit }, params) {
    const { data } = await tvPayRemoveAccount(params);

    return data;
  },

  // 통합RP 거래번호(TID) 발급 요청
  async prepareTid({ commit }, params) {
    const { data } = await prepareTid(params);

    return data;
  },

  // 모바일 간편결제 승인
  async tvpaySmartConfirm({ commit }, params) {
    const { data } = await tvpaySmartConfirm(params);

    return data;
  },
  async getAutoPaymentList({ commit }, params) {
    const { data } = await getAutoPaymentList(params);
    return data;
  },

  async cancelSpotvService({ commit }, params) {
    const { data } = await cancelSpotvService(params);
    return data;
  },

  //멜론 이용권 가입가능여부
  async getMelonJoin({ commit }, params) {
    const { data } = await getMelonJoin(params);
    return data;
  },

  async tvpayReqAuthData({ commit }, params) {
    const { data } = await tvpayReqAuthData(params);
    return data;
  },

  //멜론 이용권 중도 해지 가능여부
  async setMelonJoin({ commit }, params) {
    const { data } = await setMelonJoin(params);
    return data;
  },

  async getCancelMelonYN({ commit }, params) {
    const { data } = await getCancelMelonYN(params);
    return data;
  },

  async setCancelMelon({ commit }, params) {
    const { data } = await setCancelMelon(params);
    return data;
  },

  async setEndMelon({ commit }, params) {
    const { data } = await setEndMelon(params);
    return data;
  },

  async setEndCancelMelon({ commit }, params) {
    const { data } = await setEndCancelMelon(params);
    return data;
  },
  async getMelonPrice({ commit }, params) {
    const { data } = await getMelonPrice(params);
    return data;
  },
  async authPass({ commit }, params) {
    const { data } = await authPass(params);
    return data;
  },
  async getXGolfPrice({ commit }, params) {
    const { data } = await getXGolfPrice(params);
    return data;
  },
  async setXGolfJoin({ commit }, params) {
    const { data } = await setXGolfJoin(params);
    return data;
  },
  async getXGolfUser({ commit }, params) {
    const { data } = await getXGolfUser(params);
    return data;
  },
  async getXGolfPromotionYN({ commit }, params) {
    const { data } = await getXGolfPromotionYN(params);
    return data;
  },
};
