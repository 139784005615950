const getDefaultState = () => {
  return {
    cardResult: {},
    cardListCount: 0,
    cardManagementTitleHistory: 0,
    payHistoryResult: {},
    payDetailResult: {},
    payNo: '',
    storeId: '',
    previousPage: '',
    authType: '',
    newAccount: '',
    autoPaymentList: {},
    autoPaymentDetailIdx: 0,
    isErrorPage: false,
    eventId: '',
    autoPaymentDetail: {},
    endMelon: {},
    cancelMelon: {},
    moCardList: {},
  };
};

export default getDefaultState();
