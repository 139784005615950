<template>
  <SlidingContentWrapper>
    <template #content>
      <div class="common-padding padding-top-24">
        <CommonText font-size="28" font-color="#666" spacing="-1.12"
          >(TV/쇼핑)포인트 전환 시<br />
          발생되는 전환 수수료에 대한 안내입니다.</CommonText
        >

        <div class="margin-top-40">
          <CommonRadiusButton
            v-for="(fee, idx) in feeData"
            :key="idx"
            class="fee-wrapper"
            width="100%"
            height="120"
            color="#f8f8f8"
            radius="8"
            :style="idx !== 0 ? 'margin-top:26px' : ''"
          >
            <CommonText font-size="28" font-color="#343534" spacing="-1.12"><span v-html="fee.feeInfo"></span></CommonText>
          </CommonRadiusButton>
        </div>

        <EmpayPaddingBar padding-height="150" />
      </div>
    </template>

    <template #button>
      <CommonRadiusButton width="100%" height="110px" color="#2b2d2f" class="fixed-bottom">
        <CommonText font-size="32" font-color="#ffffff" spacing="-1.6">적용</CommonText>
      </CommonRadiusButton>
    </template>
  </SlidingContentWrapper>
</template>

<script>
import SlidingContentWrapper from '@/components/common/popup/slidingPopupContent/SlidingContentWrapper';
import CommonRadiusButton from '@/components/common/button/CommonRadiusButton';
import CommonText from '@/components/common/text/CommonText';
import EmpayPaddingBar from '@/components/common/EmptyPaddingBar';
export default {
  name: 'ShoppingPointExchangeIndex',
  components: {
    EmpayPaddingBar,
    CommonText,
    CommonRadiusButton,
    SlidingContentWrapper,
  },
  data() {
    return {
      feeData: [
        { feeInfo: '삼성카드 보너스포인트 : 수수료10%<BR>예) 보너스포인트 100P -> TV포인트 90P' },
        { feeInfo: 'GS칼텍스 GS＆POINT : 수수료11%(VAT포함)<BR>예) GS＆POINT 100P -> TV포인트 89P' },
        { feeInfo: '현대카드 M포인트 : 1.5:1<BR>예) M포인트 150 -> TV포인트 100P' },
        { feeInfo: 'SK플래닛 OK캐쉬백 : 수수료11%(VAT포함)<BR>예) OK캐쉬백 100P -> TV포인트 89P' },
        { feeInfo: '롯데엘포인트 L.POINT : 수수료10%<BR>예) L.POINT 100P -> TV포인트 90P' },
        { feeInfo: '북앤라이프 북앤라이프캐쉬 : 수수료11%(VAT포함)<BR>예) 북앤라이프캐쉬 100P -> TV포인트 89P' },
        { feeInfo: '해피머니 해피캐쉬 : 수수료11%(VAT포함)<BR>예) 해피캐쉬 100P -> TV포인트 89P' },
        { feeInfo: '북앤라이프 도서문화상품권 : 수수료11%(VAT포함)<BR>예) 도서문화상품권 100P -> TV포인트 89P' },
      ],
    };
  },
  methods: {
    search() {
      this.$commons.printLog('정렬 :' + this.$refs.searchOrder.$data.order);
      this.$commons.printLog(
        '카테고리 :' +
          this.$refs.searchCategory.$data.checkboxData
            .filter(data => {
              return data.checked;
            })
            .map(data => data.name),
      );
      this.$commons.printLog(
        '혜택유형 :' +
          this.$refs.searchBenefit.$data.checkboxData
            .filter(data => {
              return data.checked;
            })
            .map(data => data.name),
      );
    },
  },
};
</script>

<style scoped lang="scss">
.fee-wrapper {
  text-align: left;
  justify-content: flex-start;
  padding: 20px 26px;
  box-sizing: border-box;
}

.sliding-wrap {
  margin-top: 40px;
  box-sizing: border-box;
}

.button-wrapper {
  position: fixed;
  bottom: 0;
}
.refresh-wrapper {
  position: absolute;
  width: 48px;
  height: 48px;
  background: red;

  margin-top: -1px;
  left: calc(100% - 80px);
}
</style>
