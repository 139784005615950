<template>
  <div class="amount-wrap common-padding">
    <div class="amount-title">금액 선택</div>
    <div class="amount-container">
      <div
        v-for="(amount, idx) in amounts"
        :key="amount.text"
        class="amount"
        :class="isScroll && idx === amounts.length - 1 ? 'scroll-padding' : ''"
      >
        <input
          :id="'radio' + amount.price"
          type="radio"
          class="amount-radio"
          name="amount"
          :text="amount.text"
          :price="amount.price"
          @click="amountSelected"
        />
        <label :for="'radio' + amount.price" class="label"><i class="radio-img"></i>{{ amount.text }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amounts: [
        {
          text: '5천원',
          price: '5,000',
        },
        {
          text: '1만원',
          price: '10,000',
        },
        {
          text: '2만원',
          price: '20,000',
        },
        {
          text: '3만원',
          price: '30,000',
        },
        {
          text: '5만원',
          price: '50,000',
        },
        {
          text: '10만원',
          price: '100,000',
        },
        {
          text: '직접입력',
          price: '',
        },
      ],
    };
  },
  methods: {
    async amountSelected($event) {
      const text = $event.target.getAttribute('text');
      const price = $event.target.getAttribute('price');
      // await this.$store.commit("setAmountPopupData", { text: text, price: price });
      await this.$store.commit('setPopupData', { text, price });
      await this.$store.commit('slideShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.amount-wrap {
  height: inherit;
}
.amount-title {
  height: 128px;
  line-height: 128px;
  font-size: 34px;
  letter-spacing: -1.7px;
  font-weight: 500;
  color: #222222;
}
.amount-container {
  height: 100%;
  overflow: scroll;
}
.amount {
  // margin-bottom: 71px;
  padding-bottom: 40px;
  padding-top: 40px;
}
.amount-radio {
  display: none;
}
label {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #222222;
  height: 40px;
  display: flex;
  line-height: 40px;
}
.amount-radio:checked + label > i {
  background: url('../../../assets/img/tvpoint/rdo_on.svg') no-repeat;
}
.radio-img {
  width: 40px;
  padding-right: 20px;
  background: url('../../../assets/img/tvpoint/rdo_off.svg') no-repeat;
}
</style>
