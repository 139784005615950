<template>
  <div
    v-if="Object.keys(getBannerInfoList).length > 0"
    :class="paddingYN ? 'banner-container' : ''"
    :style="textYN || getBannerInfoList.templateCode === 'VT01' ? '' : 'width: 100%; text-align: -webkit-center'"
  >
    <!--텍스트로만 표기 해야 하는 경우-->
    <div
      v-if="textYN"
      :data-linkinfo="getBannerInfoList.resultData[0].linkInfo"
      :data-logony="getBannerInfoList.resultData[0].logonY"
      :data-linktype="getBannerInfoList.resultData[0].linkType"
      :data-operationid="getBannerInfoList.resultData[0].operationId"
      :data-menuid="getBannerInfoList.menuId"
      style="width: fit-content; box-sizing: border-box"
      @click="goPromotion($event.currentTarget)"
    >
      {{ getBannerInfoList.viewTitle }}
    </div>
    <!--배너가 1개인 경우-->
    <div
      v-else-if="Object.keys(getBannerInfoList).length > 0 && getBannerInfoList.resultData.length === 1"
      :data-linkinfo="getBannerInfoList.resultData[0].linkInfo"
      :data-logony="getBannerInfoList.resultData[0].logonY"
      :data-linktype="getBannerInfoList.resultData[0].linkType"
      :data-operationid="getBannerInfoList.resultData[0].operationId"
      :data-menuid="getBannerInfoList.menuId"
      style="width: fit-content; box-sizing: border-box; text-align: center; align-content: center"
      @click="goPromotion($event.currentTarget)"
    >
      <!--배너가 한개이고 VT01일 경우 view title텍스트만 표기-->
      {{ getBannerInfoList.templateCode === 'VT01' ? getBannerInfoList.viewTitle : '' }}
      <img
        v-if="getBannerInfoList.templateCode !== 'VT01'"
        :src="getBannerInfoList.resultData[0].imgUrl"
        class="partner-img"
        style="align-content: center; align-items: center"
      />
    </div>
    <!--배너가 2개이상 VT02 스크롤 배너-->
    <div v-else-if="Object.keys(getBannerInfoList).length > 0 && getBannerInfoList.templateCode === 'VT02'">
      <swiper class="swiper" v-bind="swiperOptions" @swiper="onSwiper" @slide-change="onSlide">
        <swiper-slide
          v-for="(banner, idx) in getBannerInfoList.resultData"
          :key="idx"
          :data-linkinfo="banner.linkInfo"
          :data-logony="banner.logonY"
          :data-linktype="banner.linkType"
          :data-operationid="banner.operationId"
          :data-menuid="getBannerInfoList.menuId"
          style="width: fit-content; box-sizing: border-box"
          @click="goPromotion($event.currentTarget)"
        >
          <img :src="banner.imgUrl" class="partner-img" />
        </swiper-slide>
        <!-- jhkim: vue3: ISSUE: pagination을 직접 구현한 것인가? 옵션 추가 여부는 점검해보고 결정... -->
        <div
          class="txt_bg swiper-pagination"
          :style="{
            display: getBannerInfoList.resultData.length > 1 ? 'block' : 'none',
          }"
        >
          <div v-if="pagingYN" class="paging-wrap">
            <div
              v-for="(item, idx) in getBannerInfoList.resultData"
              :key="idx"
              class="page-wrap"
              :data-idx="'page' + idx"
              :class="idx == itemIndex ? 'on' : 'off'"
            >
              <div v-if="idx == itemIndex" class="page-idx" style="margin-right: 2px; margin-top: 1px">{{ idx + 1 }}</div>
            </div>
          </div>
        </div>
      </swiper>
    </div>
    <!--배너가 2개이상 VT03 쌓는 배너-->
    <div v-else-if="Object.keys(getBannerInfoList).length > 0 && getBannerInfoList.templateCode === 'VT03'">
      <div
        v-for="(banner, idx) in getBannerInfoList.resultData"
        :key="idx"
        :data-linkinfo="banner.linkInfo"
        :data-logony="banner.logonY"
        :data-linktype="banner.linkType"
        :data-operationid="banner.operationId"
        :data-menuid="$store.getters.getBannerId"
        style="width: fit-content; box-sizing: border-box"
        @click="goPromotion($event.currentTarget)"
      >
        <img :src="banner.imgUrl" class="partner-img" />
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

export default {
  name: 'BannerInfo',
  props: {
    getBannerInfoList: {
      type: Object,
      default: () => {},
    },
    paddingYN: {
      type: Boolean,
      default: true,
    },
    textYN: {
      type: Boolean,
      default: false,
    },
    pagingYN: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const _vue = this;
    return {
      swiper: null,
      itemIndex: 0,
      swiperOptions: {
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 30,
        memberYN: '',
        on: {
          click() {
            _vue.goPromotion(this.clickedSlide);
          },
        },
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        modules: [Autoplay],
        nested: true,
      },
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.carousel.$swiper;
    // },
  },

  activated() {
    if (this.swiper && this.swiper.autoplay) {
      this.swiper.autoplay.resume();
    }
  },

  deactivated() {
    if (this.swiper && this.swiper.autoplay) {
      this.swiper.autoplay.pause();
    }
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlide() {
      if (!this.swiper) {
        this.itemIndex = 0;
        return;
      }
      const index = this.swiper.realIndex;
      this.itemIndex = index;
    },
    goPromotion($event) {
      const operationId = $event.getAttribute('data-operationid');
      const logonY = $event.getAttribute('data-logony');
      const linkType = $event.getAttribute('data-linktype');
      const menuId = $event.getAttribute('data-menuid');
      const linkInfo = $event.getAttribute('data-linkinfo');
      this.$commons.printLog('operationId', operationId);
      this.$commons.printLog('logonY', logonY);
      this.$commons.printLog('linkType', linkType);
      this.$commons.printLog('menuId', menuId);
      this.$commons.printLog('linkInfo', linkInfo);
      if (linkType !== 'NOTHING') {
        this.$utils.checkLinkType(this, linkType, logonY, menuId, operationId, linkInfo);
      }
    },
    goPromotionText() {
      const operationId = this.getBannerInfoList.resultData[0].operationId;
      const logonY = this.getBannerInfoList.resultData[0].logonY;
      const linkType = this.getBannerInfoList.resultData[0].linkType;
      const menuId = this.getBannerInfoList.menuId;
      const linkInfo = this.getBannerInfoList.resultData[0].linkInfo;
      if (linkType !== 'NOTHING') {
        this.$utils.checkLinkType(this, linkType, logonY, menuId, operationId, linkInfo);
      }
    },
  },
};
</script>

<style scoped>
.banner-container {
  /*padding-top: 32px;*/
  border-radius: 4px;
  padding-bottom: 40px;
}

.swiper {
  width: 100%;
}

.partner-img {
  width: 100%;
  object-fit: contain;
}

.txt_bg {
  z-index: 2;
  width: 100%;
  /*position: relative;*/
  /*bottom: 62px;*/
}

.paging-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-idx {
  font-size: 28px;
  line-height: 33.6px;
  letter-spacing: -1.68px;
  color: #ffffff;
  text-align: center;
}

.on {
  width: 40px;
  height: 40px;
  background-color: #343534;
  border-radius: 80px;
  align-items: center;
}
.off {
  margin-left: 12px;
  margin-right: 12px;
  width: 18px;
  height: 18px;
  background-color: #dddddd;
  border-radius: 80px;
}
</style>
