<template>
  <div class="popup-wrap">
    <div class="popup-content">이용권 해지가 완료되었습니다.</div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-yes" @click="agree">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CancelPassAgreePopup',
  methods: {
    agree() {
      this.$store.commit('alertPopup', { transAgree: 'N' });
      this.$router.go(-2); // 정기결제 내역으로
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}

.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px 40px 42px 40px;
}
</style>
