<template>
  <div class="telecom-wrap">
    <div class="telecom-title">통신사 선택</div>
    <div class="com-wrap">
      <div class="telecom-container popup-scroll">
        <div
          v-for="(telecom, idx) in telecoms"
          :key="idx"
          class="telecom"
          :class="isScroll && idx == telecoms.length - 1 ? 'scroll-padding' : ''"
        >
          <input
            :id="telecom.id"
            type="radio"
            class="telecom-radio"
            :checked="!$utils.isNull(getPopupData) && getPopupData.id == telecom.id ? true : false"
            :value="telecom.code"
            :code="telecom.code"
            :telecomname="telecom.name"
            name="telecom"
            @click="telecomSelected"
          />
          <label :for="telecom.id" class="label"><i class="radio-img"></i>{{ telecom.name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      telecoms: [
        {
          name: 'KT',
          code: '2',
          id: 'KT',
        },
        {
          name: 'SKT',
          code: '1',
          id: 'SKT',
        },
        {
          name: 'LGU+',
          code: '3',
          id: 'LGT',
        },
        {
          name: 'KT 알뜰폰',
          code: '6',
          id: 'KTM',
        },
        {
          name: 'SKT 알뜰폰',
          code: '5',
          id: 'SKM',
        },
        {
          name: 'LGU+ 알뜰폰',
          code: '7',
          id: 'LGM',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getPopupData']),
  },
  methods: {
    async telecomSelected($event) {
      const code = $event.target.getAttribute('code');
      const name = $event.target.getAttribute('telecomname');
      const id = $event.target.getAttribute('id');
      await this.$store.commit('setPopupData', { name, code, id });
      await this.$store.commit('slideShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.com-wrap {
  padding-bottom: 40px;
  height: 100%;
}
.telecom-wrap {
  padding-left: 40px;
  padding-right: 40px;
  height: inherit;
}
.telecom-title {
  height: 128px;
  font-size: 34px;
  line-height: 128px;
  letter-spacing: -1.36px;
  color: #222222;
  font-weight: 500;
}
.telecom-container {
  // height: calc(100% - 128px);
  height: 100%;
  overflow: scroll;
}
.telecom {
  // margin-bottom: 71px;
  padding-top: 35px;
  padding-bottom: 35px;
}
.telecom-radio {
  display: none;
}
label {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #222222;
  height: 40px;
  display: flex;
  line-height: 40px;
}
.telecom-radio:checked + label > i {
  background: url('../../../../assets/img/common/btn_radio_on.svg') no-repeat center / contain;
}
.radio-img {
  width: 40px;
  margin-right: 20px;
  background: url('../../../../assets/img/common/btn_radio_off.svg') no-repeat center / contain;
}
</style>
