<template>
  <div
    :style="`font-size:${fontSize}px; color:${fontColor}; font-weight:${fontWeight}; letter-spacing:${spacing}px; line-height:${lineHeight}px; text-decoration:${textDecoration}`"
    :class="sort === 'usage' ? 'text' : ''"
  >
    <slot></slot>
  </div>
</template>

<script>
/**
 * 컴포넌트 설명
 * @param 기본 : 기본 text(28, #2b2d2f, '', '');
 */
export default {
  name: 'CommonText',
  props: {
    fontSize: {
      type: String,
      default: '28',
    },
    fontColor: {
      type: String,
      default: '#2b2d2f',
    },
    fontWeight: {
      type: String,
      default: 'normal',
    },
    spacing: {
      type: String,
      default: '',
    },
    lineHeight: {
      type: String,
      default: '',
    },
    textDecoration: {
      type: String,
      default: '',
    },
    sort: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped></style>
