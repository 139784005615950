const getDefaultState = () => {
  return {
    paiChannelList: [],
    channelDetailInfo: {},
    channelMenus: [],
    storeRoomInfo: {},
    subChannelList: [],
    subKeyList: [],
    channelUseList: [],
    fetchHiddenAsset: [],
    nonMemberHiddenAsset: [],
    benefitList: [],
    benefitOpenTargetCondition: '',
    filterList: {},
    hiddenChannelList: [],
    storedList: [],
    storedBenefitList: [],
    contentsCountList: [],
    subChannelState: [],
    notificationList: [],
    channelPostSetting: [],
    alarmData: [],
    pushAlarmList: [],
    channelTop: {},
    saveAssociatePoint: {},
    channelAccount: '',
    alarmCount: 0,
    newCount: 0,
    hiddenAssetTopLength: 0,
    filter: {},
    paiAlarm: '',
    paiAlarmChannel: '',
    setSubsAlarm: '',
    setAlarm: '',
    codeCategoryMain: [],
    codeContentInfo: [],
  };
};

export default getDefaultState();
