import qs from 'qs';
import $commons from '@/utils/commons';
import axiosInterceptor from '@/apis/common/axiosInterceptors';

const axiosWrapper = {
  get(resource, params) {
    return axiosInterceptor.get(resource, params).catch(error => {
      // error.response.status
      throw new Error(`[ApiService] Error : ${error}`);
    });
  },
  post(resource, params) {
    return axiosInterceptor.post(resource, params).catch(error => {
      throw new Error(`[ApiService] Error ${error}`);
    });
  },
  postJson(resource, params) {
    return axiosInterceptor({
      method: 'POST',
      url: `${resource}`,
      headers: {
        'content-type': 'application/json',
      },
      data: params,
    });
  },
  postQs(resource, params) {
    return axiosInterceptor({
      method: 'POST',
      url: `${resource}`,
      data: qs.stringify(params),
    }).catch(error => {
      $commons.printLog(error);
    });
  },
  postLoginQs(resource, params) {
    return axiosInterceptor({
      method: 'POST',
      url: `${resource}`,
      data: qs.stringify({ ...params, reqDate: sessionStorage.getItem('reqDate') }),
    }).catch(error => {
      $commons.printLog(error);
    });
  },

  update(resource, params) {
    return axiosInterceptor.put(resource, params).catch(error => {
      throw new Error(`[ApiService] Error ${error}`);
    });
  },
  delete(resource, params) {
    return axiosInterceptor.delete(resource, params).catch(error => {
      throw new Error(`[ApiService] Error ${error}`);
    });
  },
};

export default axiosWrapper;
