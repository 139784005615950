<template>
  <div class="popup-wrap">
    <div class="popup-title">개인정보 제3자 제공동의(최초 1회)</div>
    <div class="popup-content">
      <div class="content-title">제공항목</div>
      <div class="content-content">PAYCO회원번호, 마스킹 된 PAYCO ID,<br />PAYCO포인트 잔액 및 전환신청 포인트 금액</div>
      <div class="content-title" style="margin-bottom: 26px">제공받는 자: (주)핏콜라보</div>
      <div class="content-title">제공받는 자의 이용 목적</div>
      <div class="content-content">PAYCO회원정보 확인,<br />PAYCO포인트 잔액 조회 및 전환</div>
      <div class="content-title">제공받는 자의 이용기간</div>
      <div class="content-content">이용목적 달성 시 즉시 파기</div>
      <div class="content-title">동의를 거부하실 수 있으나, 동의 거부 시<br />서비스 이용이 제한될 수 있습니다.</div>
    </div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="confirm">동의거부</button>
      <button class="popup-button-yes" @click="setTransAgree">동의하기</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters(['getPartnerShip']),
  },
  methods: {
    confirm() {
      this.$store.commit('alertPopup', { transAgree: 'N' });
    },
    setTransAgree() {
      const data = this.$store.dispatch('setTransAgree', { transAgreeYN: 'Y', partnershipId: this.getPartnerShip.partnerShipId });
      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          if (this.type == 'single') {
            this.$store.commit('alertPopup', {});
            this.$router.push('/tvpoint/exchange/SingleTransView');
          } else {
            bus.$emit('end:loading');
            this.$store.commit('alertPopup', { transAgree: 'Y' });
            // this.$store.commit("slideShow", { slideShow: true, slideType: "PointPassword" });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-title {
  font-size: 26px;
  line-height: 42px;
  letter-spacing: -1.3px;
  color: #343534;
}
.content-content {
  font-size: 26px;
  line-height: 37px;
  letter-spacing: -1.3px;
  color: #999999;
  margin-bottom: 26px;
}
.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 63px;
}

.popup-content {
  margin-top: 63px;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 31px 39px 38px 34px;
}
</style>
