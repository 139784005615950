import axios from './common/apiConfig';
import urlConfig from '@/config/urlConfig';
import $store from '@/store';

const commonApis = (function () {
  return {
    //이벤트(페이지 이동 등) 발생 시 호출 공통
    async informEvent(url) {
      const pageInform = {
        prevPageInfo: $store.state.fit.pageInform.pageInfo,
        pageInfo: { url: url },
      };
      // $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
      // 	? $store.state.fit.pageInform.pageInfo
      // 	: null;
      // $store.state.fit.pageInform.pageInfo = {};
      // $store.state.fit.pageInform.pageInfo.url = url;

      return await axios.post(`${urlConfig.BS_BASE_URL}statistics/informEvent`, pageInform);
    },
  };
})();

export default commonApis;
