<template>
  <div>
    <div class="d-flex" style="margin: 40px 0 56px 40px">
      <img src="@/assets/img/fit/btn_back.svg" alt="" @click="clickEvent('closePopup')" />
      <CommonText font-size="34" font-clor="#2b2d2f" spacing="-1.7" style="font-weight: bold">쇼골프 유료서비스약관</CommonText>
    </div>
    <div v-if="termsShow" class="terms" v-html="termsContent.replace(/\n/g, '<br>')"></div>
    <div v-else class="" style="height: 100%; margin: 40px 0 56px 40px">
      <div class="term-text">
        <CommonText font-size="27" font-clor="#343534" spacing="-1.3" style="font-weight: normal" @click="clickEvent('term')"
          >XGOLF 이용약관</CommonText
        >
      </div>
      <div class="term-text">
        <CommonText font-size="27" font-clor="#343534" spacing="-1.3" style="font-weight: normal" @click="clickEvent('personalInfo')"
          >XGOLF 개인정보처리방침</CommonText
        >
      </div>
      <div class="term-text">
        <CommonText font-size="27" font-clor="#343534" spacing="-1.3" style="font-weight: normal" @click="clickEvent('playTerm')"
          >쇼골프 플레이 서비스 이용약관</CommonText
        >
      </div>
      <div class="term-text">
        <CommonText font-size="27" font-clor="#343534" spacing="-1.3" style="font-weight: normal" @click="clickEvent('playPersonalInfo')"
          >쇼골프 플레이 개인정보 수집 및 동의</CommonText
        >
      </div>
      <div class="term-text">
        <CommonText font-size="27" font-clor="#343534" spacing="-1.3" style="font-weight: normal" @click="clickEvent('insurance')"
          >홀인원 보험 필수 동의</CommonText
        >
      </div>
      <!--      <span class="term-text" @click="clickEvent('term')">XGOLF 이용약관</span>-->
      <!--      <span class="term-text" @click="clickEvent('term')">XGOLF 개인정보처리방침</span>-->
      <!--      <span class="term-text" @click="clickEvent('term')">쇼골프 플레이 서비스 이용약관</span>-->
      <!--      <span class="term-text" @click="clickEvent('term')">쇼골프 플레이 개인정보 수집 및 동의</span>-->
      <!--      <span class="term-text" @click="clickEvent('term')">홀인원 보험 필수 동의</span>-->
    </div>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';

export default {
  name: 'XgolfTerms',
  components: {
    CommonText,
  },
  emits: ['closePopup'],

  data() {
    return {
      termsContent: '',
      termsShow: false,
    };
  },

  methods: {
    async clickEvent(sort) {
      switch (sort) {
        case 'closePopup':
          if (this.termsShow) {
            this.termsShow = false;
          } else {
            this.$emit('closePopup', 'onlyTerms');
          }
          break;
        case 'term':
          this.termsShow = true;
          this.termsContent = `XGOLF 이용약관

제 1 조 (목적)

이 약관은 주식회사 쇼골프 (이하 "회사" 또는 "쇼골프, SHOWGOLF", "엑스골프”, “XGOLF")가 제공하는 XGOLF 예약 및 기타 서비스 관련 제반 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제 2 조 (용어의 정의) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
(1) 서비스 : 주식회사 쇼골프의 자체 웹사이트 쇼골프(www.showgolf.co.kr), 엑스골프(www.xgolf.com)와 모바일 앱 “XGOLF”에서 제공하는 서비스와 제휴 계약을 맺고 기술적(프로그램) 연동을 통해 제휴업체의 웹사이트나 모바일에서 엑스골프의 회원에게 제공되는 서비스를 총칭하는 말입니다. 단, 광고 제휴를 통해 DB를 연동하는 기술적(프로그램) 작업 없이 단순 링크를 통해 제공되는 광고나 이벤트성 서비스는 서비스에서 제외합니다.
(2) 회원 : 서비스에 접속하여 이 약관에 동의하고 회원가입 절차를 완료한 이용자를 말합니다.
(3) 이용자 : 서비스에 접속하여, 이 약관에 따라 회사가 제공하는 서비스를 받는 회원과 비회원을 말합니다.
(4) 게시물 : 회사 및 제휴사가 자체 제작/수급하거나, 회원이 직접 웹사이트에 등록하는 모든 게시물(콘텐츠)을 총칭합니다.
(5) 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다.
(6) 비밀번호(PASSWORD) : 회원이 부여받은 아이디와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
(7) 해지 : 회원이 회원가입 후 탈퇴를 통하여 이용 계약을 해약하는 것을 말합니다.
(8) 이용 중지 : 회사가 약관에 따라 회원의 서비스 이용을 제한하는 것을 말합니다.
(9) 결제 : 회원이 특정한 유료 서비스의 이용을 위하여 온라인 입금, 휴대전화 결제, 각종 신용카드, 계좌이체 등 결제 모듈을 이용하여 일정 금액을 지불하는 행위를 말합니다.
(10) 제휴사 : 회사와 계약을 체결하여 회원에게 상품 등을 거래하거나 각종 정보 및 혜택 등을 제공하거나 회원이 적립금을 사용, 교환할 수 있는 온라인 및 오프라인 업체를 의미합니다. 제휴사는 회사가 제공하는 개별 서비스 홈페이지에 고지되어 있으며, 회사 및 제휴사의 사정에 따라 해지 또는 추가될 수 있습니다.
(11) 캐시(Xcash) : 회사가 회원의 구매 활동, 이벤트 참여 등에 따라 회원에게 지급할 수 있는 것으로 회원이 선결제 서비스를 이용할 때 현금처럼 사용할 수 있는 지급 수단을 말합니다.

제 3 조 (이용약관의 효력 및 변경)
(1) 본 약관은 회사에서 제공하는 서비스 사이트 및 앱에서 온라인으로 이용자에게 공지함으로써 효력이 발생합니다. 또한 합리적인 사유가 발생할 경우 관련 법령에 위배되지 않는 범위 안에서 개정될 수 있습니다.
(2) 회사는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
(3) 개정된 약관은 온라인(서비스 초기화면)에서 공지함으로써 그 효력발생일에 효력을 발생하며, 이용자의 권리 또는 의무 등 중요한 규정의 개정은 사전에 효력발생일 7일 이전부터 공지합니다.
(4) 이 약관에 동의하는 것은 정기적으로 웹/앱을 방문하여 약관의 변경 사항을 확인하여야 합니다.
(5) 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부 의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주합니다.
(6) 회사는 합리적인 사유가 발생할 경우에는 이 약관을 변경할 수 있으며, 약관을 변경할 경우에는 지체 없이 이를 사전에 공지합니다.
(7) 회사가 고지의 의무를 이행했음에도 불구하고 회원이 변경된 약관에 대한 정보를 알지 못해 발생하는 피해는 회사에서 책임지지 않습니다.

제 4 조 (이용 계약의 체결 및 성립)
(1) 이용 계약은 ‘회원’이 되고자 하는 자(이하 가입 신청자)가 이용약관 내용에 대한 동의를 한 다음, 회원가입 신청을 하고, 신청에 대한 회사의 이용 승낙으로 성립됩니다.
(2) 이 약관에 동의하고 회원가입을 한 회원은 약관 동의 시점부터 서비스 이용계약이 적용되며, 약관의 변경이 있을 때는 변경의 효력이 발생한 시점부터 변경된 약관의 적용을 받습니다.
(3) 제1항에 따른 신청에 있어 회사는 사전 또는 사후에 전문 기관을 통한 실명 확인 및 본인 인증을 요청할 수 있습니다.
(4) 이용계약의 성립 시기는 회사가 가입 완료를 신청 절차상에서 표시한 시점으로 합니다.
(5) 회사는 회원에 대해 회사 정책에 따라 등급별로 구분하여 이용 횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
(6) 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보하고, 이용자는 해지할 수 있습니다.

제 5 조 (서비스 이용 신청)
(1) 회원으로 가입하여 본 서비스를 이용하고자 하는 이용자는 회사에서 요청하는 소정의 정보(ID, 이메일, 본인인증(CI) 등)를 제공하여야 합니다.
(2) 모든 회원이 가입 신청 양식에 기재하는 회원 정보는 실제 데이터인 것으로 간주합니다. 만약, 허위 사실을 입력한 회원은 법적으로 보호받을 수 없으며, 서비스 이용 승낙 및 이용 시 제한을 받거나, 관계 법령에 따라 처벌받을 수 있습니다.

제 6 조 (이용 신청의 승낙과 제한)
(1) 회사는 제4조(이용계약의 체결 및 성립)의 규정에 의한 이용 신청 고객에 대하여 업무 수행상 또는 기술상 지장이 없는 경우에 원칙적으로 접수 순서에 따라 서비스 이용을 승낙합니다.
(2) 회사는 가입 신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다
(3) 다만, 회사는 아래 사항에 해당하는 경우에 대해서는 이용을 승낙하지 않거나 보류 혹은 유보, 사후에 이용 계약을 해지할 수 있습니다. ·이용 계약 신청서의 내용을 허위로 기재 또는 회사가 제시한 내용을 기재하지 않은 경우 ·사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한 경우 ·부정한 용도로 본 서비스를 이용하고자 하는 경우 ·개인 영리 추구를 목적으로 본 서비스를 이용하고자 하는 경우 ·기타 규정한 제반 사항을 위반하여 신청하는 경우 ·약관 또는 법령 위반 등으로 이전에 이용 제한 조치(회원자격 상실)를 받은 사실이 있는 경우( 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.) ·회원가입 시 타인의 정보를 도용하거나, 실명이 아니거나, 기재할 내용에 기재 누락, 허위, 오기가 있는 경우 ·회사의 설비 및 기술상의 지장이나 회사의 귀책 사유로 이용 승낙이 곤란한 경우 ·기타, 회원으로 등록하는 것이 회사의 원만한 서비스 제공에 지장을 초래한다고 판단되는 경우 ·이용자의 귀책 사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반, 신청하는 경우
(4) 관련 법령에 의거 만 14세 미만의 회원 가입은 제한됩니다.

제 7 조 (계약 해지)
(1) 회원은 언제든지 내 정보관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, 이용계약을 해지하고자 하는 때에는 회원 본인이 사이트의 회원 탈퇴 메뉴를 이용해 가입 해지를 해야 합니다.
(2) 회원이 계약을 해지할 경우, 관련법 및 개인정보 취급 방침에 따라 회사가 해당 회원의 정보를 보유할 수 있는 경우를 제외하고 회원 정보가 전체 삭제되며 XGOLF 에서 제공하는 전체 서비스 이용 계약이 해지됩니다.
(3) 회원 탈퇴 시 다음과 같이 적용됩니다. ·탈퇴하신 아이디는 복구와 재가입이 불가능합니다. ·탈퇴 시 보유하신 Xcash는 모두 소멸되며, 소멸된 데이터는 복구되지 않습니다. ·탈퇴 시 예약 내역 및 등록 게시물은 자동 취소 및 삭제되지 않으므로 탈퇴 전 취소, 삭제하시기를 바랍니다. ·개인 정보는 탈퇴 즉시 삭제됩니다. ·단, 부정 이용 방지를 위해 위약 기간이 남아 있으면 고유 식별 정보(CI)는 위약 기간 종료 시까지 보관되며, 위약 만료 기간 내 재가입 시 기존 위약 내용은 유지되어 서비스 이용에 제한받으실 수 있습니다.

제 8 조 (서비스 이용 제한 및 중지)
(1) 회사는 다음 각호의 내용에 해당하는 경우 또는 회원이 이 약관의 의무를 위반하거나, 서비스의 정상적인 운영을 방해하는 경우 서비스 제공의 일부 또는 전부를 제한하거나 중단할 수 있습니다. ·정보통신설비의 보수 점검, 교체 및 고장 등 공사로 인한 부득이한 경우 ·기간통신사업자가 전기통신 서비스를 중단했을 경우 ·서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우 ·국가 비상 상태 등 기타 불가항력적인 사유가 있는 경우 ·회사의 관리범위 외의 서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나 삭제된 경우 ·기타 약관에서 규정한 제반 사항을 위반하거나 원만한 서비스 제공에 지장을 초래한 경우 ·타 이용자에게 심한 모욕을 주거나, 서비스 이용을 방해한 경우 ·기타 정상적인 서비스 운영에 방해가 될 경우 ·정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우 ·불법 홈페이지나 상용소프트웨어나 크랙 파일을 올린 경우 ·정보통신 윤리 위원회의 심의 세칙 제7조에 어긋나는 음란물을 게재한 경우 ·반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우 ·저작권이 있는 글을 무단 복제하거나 올린 경우
(2) 상기 이용 제한 규정에 따라 회원이 이 약관의 의무를 위반하거나, 서비스의 정상적인 운영을 방해하는 경우에는 서비스를 이용하는 회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의 일시 정지, 초기화, 이용계약 해지 등을 불량이용자 처리 규정에 따라 취할 수 있습니다.
(3) 회사가 정상적인 서비스 제공의 어려움으로 인하여 일시적으로 서비스를 중지하여야 할 경우에는 서비스 중지 전 사전 고지 후 서비스를 중지할 수 있으며, 이 기간에 이용자가 고지 내용을 인지하지 못한 것에 대해 회사는 책임을 부담하지 아니합니다. 부득이한 사정이 있으면 위 사전 고지 기간은 감축되거나 생략될 수 있습니다. 또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있어도 회사는 책임을 부담하지 아니합니다.
(4) 회사의 사정으로 서비스를 일시적 또는 영구적으로 중단하여야 할 경우 사전 고지 기간은 2주로 합니다.
(5) 회사는 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할 수 있으며, 이에 대하여 회사의 고의 또는 중대한 과실이 없는 경우에는 이용자 또는 제3자에게 어떠한 책임도 부담하지 않습니다.

제 9 조 (서비스의 제공)
(1) 회사는 회원에게 아래와 같은 서비스를 제공합니다. ·골프장 제휴를 통한 예약 서비스(골프장, 국내 투어, 신멤버스 등) ·입점 제휴를 통한 예약 서비스(연습장) ·기타 회원 친화 서비스(이용 후기, 이벤트 등) ·게시판형 서비스(조인/양도 게시판, 해외회원권) ·기타 회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해 회원에게 제공하는 모든 서비스(스윙 분석, 일본골프장, 쇼핑몰 등)
(2) 회사는 서비스를 일정 범위로 분할하여 범위별로 이용 가능 시간을 별도로 지정할 수 있습니다. 다만, 그 내용을 사전에 공지합니다.
(3) 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절, 서비스 이용의 폭주 등으로 운영상 타당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
(4) 회사는 서비스의 제공에 필요한 경우 정기 점검을 실시할 수 있으며, 정기 점검 시간은 서비스 제공화면에 공지한 바에 따릅니다.
(5) 회사는 서비스 제공을 위하여 고객의 위치정보를 이용할 수 있으며 고객은 본 약관에 동의함으로써 이에 동의한 것으로 간주하며 회사는 회원이 제공한 개인위치정보를 고객의 동의 없이 서비스 제공 이외의 목적으로 이용하지 않습니다.

제 10 조 (서비스 이용 시간)
(1) 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 단, 회사는 업무상이나 기술상의 이유로 서비스를 일시 중단할 수 있으며, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 예정된 작업으로 인한 서비스 일시 중단은 웹사이트&앱을 통해 사전에 공지합니다.
(2) 회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유 발생 시 예고 없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.
(3) 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.
(4) 회사는 회사가 통제할 수 없는 사유로 인한 서비스 중단의 경우(시스템관리자의 고의성이 없는 디스크 장애, 시스템다운 등)에 사전통지가 불가능하며 타인(PC통신 회사, 기간통신사업자 등)의 고의, 과실로 인한 시스템중단 등의 경우에는 통지하지 않습니다. 다만 사후에 통지할 수 있습니다.

제 11 조 (서비스의 변경)
(1) 회사는 운영상, 기술상의 필요에 따라 제공하고 있는 서비스 전부 또는 일부를 변경할 수 있습니다.
(2) 서비스의 내용, 이용 방법, 이용 시간에 대하여 변경이 있는 경우에는 변경 사유, 변경될 서비스의 내용 및 제공 일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여 고지합니다.
(3) 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요로 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.

제 12 조 (정보의 제공)
(1) 회사는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에 대해서 전화, 전자우편, 서신 우편, SMS 등의 방법으로 회원에게 제공할 수 있습니다.
(2) 회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의를 받고 추가적인 개인 정보를 요구할 수 있습니다.

제 13 조 (회원에 대한 통지)
(1) 회사가 회원에 대해 통지하는 경우 본 약관에 별도 규정이 없는 한 회원이 지정한 이메일 주소, 서비스 내 전자메모 등으로 할 수 있습니다.
(2) 회사는 회원 전체에 대한 통지의 경우 7일 이상 ‘회사’에서 제공하는 서비스 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.

제 14 조 (개인정보 보호)
(1) 회사는 관계 법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위해 노력합니다. 이용자 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보 취급 방침이 적용됩니다.
(2) 회사의 공식사이트(www.xgolf.com, www.showgolf.co.kr), XGOLF APP 이외의 사이트에서는 회사의 개인정보처리방침이 적용되지 않으며, 회사는 이용자의 귀책 사유로 인해 노출된 정보에 대해서 모든 책임을 지지 않습니다.
(3) 회사는 서비스 제공과 관련해서 수집된 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 아래 경우에는 그러하지 않습니다. ·전기통신 기본법 등 법률 규정에 의해 국가기관의 요구가 있는 경우 ·범죄에 대한 수사상의 목적이 있거나 정보통신 윤리 위원회의 요청이 있는 경우 또는 기타 관계 법령에서 정한 절차에 따른 요청이 있는 경우 ·서비스에 가입한 미성년자의 법정대리인이 정보를 요청하여 서류상으로 가족관계가 확인된 경우 ·회원이 회사에 제공한 개인정보를 스스로 공개하거나 공개 승낙을 한 경우(동의 서명 경우) ·회사가 제공하는 정보, 자료 송부를 위하여 관련 업체에 최소한의 회원의 정보를 알려주는 경우 ·회사가 회원의 서비스 이용 편의나 원활한 이용을 위하여 여러 분야의 전문 콘텐츠 사업자 혹은 비즈니스 사업자에게 최소한의 회원 정보를 알려주는 경우 ·통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우

제 15 조 (개인정보 이용)
(1) 회사는 회원의 정보수집 시 서비스 제공에 필요한 최소한의 정보만을 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다. ·필수 사항 : 아이디(ID), 비밀번호(PW), 이름, 연락처, 주소, 이메일, 연계정보(CI) ·선택 사항 : 마케팅 활용 동의 여부(문자, 이메일), 마케팅 제3자 제공 여부
(2) 회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대한 열람 및 오류정정을 요구할 수 있으며, 회사는 이에 대해 지체 없이 필요한 조치할 의무가 있습니다. 제 16 조 (회사의 의무) (1) 회사는 관계 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
(2) 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보 취급 방침을 공시하고 준수합니다.
(3) 회사는 이용 고객으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 처리하여야 합니다.
(4) 회원이 제기한 의견이나 불만 사항에 대해서는 게시판을 활용하거나 이메일 등을 통하여 회원에게 처리 과정 및 결과를 전달합니다.

제 17 조 (이용자&회원의 의무)
(1) 이용자는 회원가입 신청 또는 회원 정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 모든 권리를 주장할 수 없습니다.
(2) 회원은 본 약관에서 규정하는 사항과 기타 회사가 정한 제반 규정, 공지사항 등 회사가 공지하는 사항 및 관계 법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위, 회사의 명예를 훼손하는 행위를 해서는 안 됩니다.
(3) 회원은 휴대전화 번호 등의 이용계약사항이 변경된 경우, 해당 절차를 거쳐 이를 회사에 즉시 알려야 합니다.
(4) 회사가 관계 법령 및 개인정보 보호정책 및 취급방침에 따라 그 책임을 지는 경우를 제외하고 회원에게 부여된 ID의 비밀번호 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
(5) 회원은 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 회사는 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우, 회원은 회사에 대해 손해배상 의무를 지며, 회사는 해당 회원에 대해 서비스 이용 제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
(6) 회원은 회사의 명시적 동의가 없는 한 서비스의 이용 권한, 기타 이용 계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.
(7) 회원은 회사 및 제3자의 지식재산권을 침해해서는 안 됩니다.
(8) 회원은 다음 각호에 해당하는 행위를 하여서는 안 되며, 해당 행위를 하는 경우에 회사는 회원의 서비스 이용 제한 및 적법 조치를 포함한 제재를 가할 수 있습니다. ·회원가입 신청 또는 회원 정보 변경 시 허위 내용을 등록하는 행위 ·다른 회원의 ID, 비밀번호를 도용하는 행위 ·회원 ID를 타인과 거래하는 행위 ·회사의 운영진, 직원 또는 관계자를 사칭하는 행위 ·회사로부터 특별한 권리를 부여받지 않고 회사의 클라이언트 프로그램을 변경하거나, 회사의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위 ·회사 및 서비스의 영위에 손해를 입히거나 고의로 방해하는 행위 ·서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3자에게 제공하는 행위 ·공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위 ·모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위 ·다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속해 고통 또는 불편을 주는 행위 ·회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는 행위 ·범죄와 결부된다고 객관적으로 판단되는 행위 ·약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위
(9) 회원은 관계법, 본 약관의 규정, 이용 안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.

제 18 조 (회원의 ‘아이디’ 및 ‘비밀번호’의 관리에 대한 의무)
(1) 회원의 "아이디"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
(2) 회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인할 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.
(3) 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
(4) 제3항의 경우 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제 19 조 (회원ID 부여 및 변경 등)
(1) 회사는 이용 고객에 대하여 약관에 정하는 바에 따라 회원 ID를 부여합니다.
(2) 회원 ID는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당 회원 ID를 해지하고 재가입해야 합니다.
(3) 회원 ID는 다음 각호에 해당하는 경우에 이용 고객 또는 회사의 요청으로 변경할 수 있습니다. ·회원 ID가 이용자의 주민등록번호, 전화번호 등과 관련되어 사생활 침해가 우려되는 경우 ·타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우 ·기타 합리적인 사유가 있는 경우
(4) 서비스 관리를 위해 필요한 ‘아이디(ID)’는 수정이 불가능합니다.
(5) 회원은 회원 가입 신청 시 기재한 사항이 변경되었을 경우 직접 온라인으로 수정하거나 전자우편 기타 방법으로 회사에 대하여 그 변경 사항을 알려야 합니다.
(6) 변경 사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

제 20 조 (게시물의 관리)
(1) ‘게시물’이라 함은 회사 및 제휴사가 자체 제작/수급하거나, 회원이 직접 웹사이트에 등록하는 모든 게시물(콘텐츠-영상,글,사진,그림, 각종 파일과 링크, 각종 답글 등)을 총칭합니다.
(2) 서비스에 게재한 게시물과 관련하여 저작권법 위반을 포함한 모든 민사 및 형사상의 책임은 전적으로 해당 게시물의 게재자인 회원 본인에게 있습니다.
(3) 회사는 권리자의 요청이 없는 경우라도 권리 침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 삭제, 숨김 등 임시 조처를 할 수 있습니다.
(4) 회사는 다음 각호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록 거부를 할 수 있으며, 그 게시물의 양에 따라 회원의 서비스 중지 또는 탈퇴하는 조치를 취할 수 있습니다. ·다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 훼손하는 내용인 경우 ·공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는 경우 ·불법복제 또는 해킹을 조장하는 내용인 경우 ·영리를 목적으로 하는 광고일 경우 ·범죄와 결부된다고 객관적으로 인정되는 내용일 경우 ·다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우 ·회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우 ·저작권 침해에 해당하는 게시물인 경우이거나 기타 관계 법령에 위배된다고 판단되는 경우
(5) 회원이 서비스 내에 게시하는 게시물은 검색 결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다.

제 21 조 (게시물에 대한 저작권)
(1) 이용자가 서비스 내에 게시한 게시물의 저작권은 저작권법에 의해 보호받습니다. 회사가 작성한 저작물에 대한 저작권, 기타 지식재산권은 회사에 귀속됩니다.
(2) 이용자는 자신이 게시한 게시물을 회사가 국내외에서 아래의 목적으로 사용하는 것을 허락합니다. ·회사의 서비스 혹은 관련 서비스 내에서 이용자 게시물의 복제, 수정, 개조, 전시, 전송, 배포, 출판 및 2차 저작물과 편집 저작물 작성 ·모바일(이동통신사 - SKT, KTF, LGT 등)서비스, PMP, Wibro, 웹서비스 등 회사의 제휴사에 상업적 또는 비상업적으로 이용자의 게시물 내용을 제공, 사용하게 하는 것. 단, 이 경우 회사는 회원의 동의 없이 개인정보를 제공하지 않습니다.
(3) 이용자는 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.
(4) 회사는 이용자가 게시하거나 등록하는 서비스 내의 내용물, 게시 내용에 대해 제21조(게시물의 관리) 4항에 해당한다고 판단되는 경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있습니다.

제 22 조 (유료서비스 정책)
(1) 회사에서 제공하는 서비스는 국내외 골프장 이용을 위한 직·간접 예약, 각종 연계 패키지 상품 예약 및 판매, 쇼핑몰 상품 판매 및 구매 등을 위한 무료, 유료 회원제도를 운영합니다.
(2) 회원가입 후 회사에서 제공하는 무료 회원 서비스는 회원가입 완료와 함께 무료로 제공되며, 수시로 회사정책에 따라 사전 통보 후 변동될 수 있습니다.
(3) 무료 회원이 유료 회원에게 제공되는 추가적 서비스의 혜택을 이용하기 위해서는 각 해당 등급별 유료 회원의 유료 액수 결제 완료 후 서비스를 이용하실 수 있습니다.
(4) 회사는 서비스 활성화를 위한 각종 이벤트, 프로모션, 광고 홍보 활동을 통하여 각 해당 등급별 유료 회원 권한을 무료 혹은 할인 지급 및 통제를 할 수 있습니다.

제 23 조 (캐시(Xcash))
(1) 캐시(Xcash)는 결제 및 캐시백 상품 이용 시 가감되는 일종의 현금처럼 사용할 수 있는 서비스 캐시 정책입니다.
(2) 캐시는 현금으로 환급될 수 없으며, 캐시의 사용 기간이 만료되거나 이용계약이 종료되면 소멸합니다.
(3) 회원의 캐시는 회사가 정하는 다양한 서비스 이용 시 획득, 소비할 수 있으며, 캐시를 부당하게 취득한 증거가 있을 때 회사는 사전 통지 없이 회원이 부당 취득한 캐시를 삭제할 수 있으며 회원 자격을 제한할 수 있습니다.
(4) 회원 탈퇴 또는 회원 자격 상실 시, 이용계약이 종료되면 잔여 여부와 상관없이 회원의 캐시는 전체 소멸합니다. ·회원 탈퇴 시 전환/적립된 캐시 중 미사용한 캐시는 즉시 소멸하며, 탈퇴 후 재가입하더라도 소멸한 캐시는 복구되지 아니합니다.
(5) 제3자 또는 다른 아이디로 양도할 수 없으며, 유상으로 거래하거나 현금으로 전환할 수 없습니다.
(6) 타인에게 양도, 양수, 대여, 담보의 목적으로 이용할 수 없습니다.
(7) 회사는 서비스의 효율적 이용 및 운영을 위해 사전 공지 후 캐시의 일부 또는 전부를 조정할 수 있으며, 회사가 정한 기간에 따라 주기적으로 소멸할 수 있습니다.
(8) 회사는 회원이 회사가 승인하지 않은 방법 또는 허위 정보 제공, 본 약관에 위배되는 등의 부정한 방법으로 캐시를 획득하거나 부정한 목적이나 용도로 캐시를 사용하는 경우 캐시의 사용을 제한하거나 회사가 정한 방법으로 회수할 수 있으며, 캐시를 사용한 구매신청을 취소할 수 있고, 회원 자격을 정지할 수 있습니다.

제 24 조 (손해배상)
(1) 회사는 서비스에서 제공하는 컨텐츠의 이용과 관련하여 개인정보보호정책에서 정하는 내용에 해당하지 않는 사항에 대해서는 어떠한 손해도 책임을 지지 않습니다.
(2) 회사는 유료 서비스의 중대한 하자에 의하여 디지털 컨텐츠가 손상, 훼손, 삭제되어 서비스 이용에 있어 회원에게 손해를 끼친 경우 캐시의 재충전 혹은 해당 컨텐츠의 복원을 통하여 보상합니다. 다만, 회사는 유료 서비스의 이용과 관련하여 회사의 고의 또는 중대한 과실이 없이 회원에게 발생한 모든 손해에 대해서는 책임을 지지 않습니다.
(3) 회원이 본 약관의 규정을 위반함으로써 회사에 손해가 발생하게 되는 경우, 위반한 회원은 발생한 모든 손해를 회사에 배상해야 합니다.
(4) 기타 손해배상의 방법, 절차 등은 관계 법령에 따릅니다.

제 25 조 (면책조항)
(1) 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
(2) 회사는 기간통신사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
(3) 회사는 서비스용 설비의 보수, 교체, 정기 점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.
(4) 회사는 회원의 귀책 사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임지지 않습니다.
(5) 회사는 이용자의 PC 또는 각종 모바일 기기 등 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 이메일주소 또는 연락할 수 있는 수단을 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
(6) 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임지지 않습니다.
(7) 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임지지 않습니다.
(8) 회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.
(9) 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임지지 않습니다.
(10) 회사는 이용자 상호 간 및 이용자와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
(11) 회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.

제 26 조 (서비스 제공을 위한 프로그램 설치 공지)
(1) 회사는 이용자에게 안정적인 서비스를 제공하기 위해, 이용자의 PC나 각종 모바일 기기의 일부 저장공간이나 자원을 활용하여, 서비스 이용 응용프로그램을 이용자의 동의를 통해 설치 적용할 수 있습니다.
(2) 회사는 이용자에게 최적의 서비스를 제공하기 위해, 더 나은 기술이 있다고 판단되는 경우, 이용자의 동의를 통해 이용자의 PC나 각종 모바일 기기에 적용된 프로그램을 수정/보완 및 교체할 수 있습니다.

제 27조 (이용약관에 대한 문의 사항)
"이용약관"에 대한 의문 사항은 아래 연락처를 참조하시기를 바랍니다.
고객센터 대표번호: 1544-9017
<부칙> 1. (시행일) 본 약관은 2023년 12월 14일부터 적용됩니다.
2. 기존에 시행되던 종전의 약관은 본 약관으로 변경 대체합니다.`;
          // this.termsContent = await require('!!raw-loader!@/assets/text/xgolf_term.txt');
          break;
        case 'personalInfo':
          this.termsShow = true;
          this.termsContent = `개인정보처리방침

(1) 개인정보 처리 방침이란 이용자가 XGOLF 서비스를 안심하고 이용할 수 있도록 준수되는 회사의 개인정보 보호 기준을 의미합니다.
(2) 주식회사 쇼골프(이하 "회사","쇼골프, SHOWGOLF" 또는 "엑스골프, XGOLF")는 XGOLF.COM 회원의 개인정보 보호에 만전을 다하며, [개인정보 보호법] 및 [정보통신망 이용촉진 및 정보보호 등에 관한 법률]을 비롯한 관련 법률을 준수하고 있습니다.
(3) 회사는 회원 개인정보 처리 방침을 통해 회원의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

제1조 (개인정보의 수집 · 이용 목적)
(1) 회사는 수집한 개인정보를 아래의 목적으로 이용합니다. ·서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산 : 컨텐츠 제공, 구매 및 요금 결제, 금융거래 본인 인증 및 금융 서비스, 물품 배송, 서비스 제공, 계약서´청구서 발송, 맞춤 서비스 제공, 정산, 채권추심 등 ·회원 관리 : 회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령 확인, 고지사항 전달 등 ·마케팅 및 광고에 활용 : 프로모션 이벤트 응모, 배송, 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계, 새로운 상품 안내 판매 권유 (보험 외) 등 ·고충 처리 : 민원인의 신원 확인, 민원 사항 확인, 사실조사를 위한 연락´통지, 처리결과 통보 등
(2) 본인 인증은 본인인증 기관을 통해 처리하고 있으며, 동의받은 수집 항목 외 개인정보는 저장하지 않습니다.
(3) 진행하는 이벤트에 따라 수집 항목이 상이할 수 있으므로 응모 시 별도 동의를 받으며, 목적 달성 즉시 파기합니다.
(4) 회사는 고객의 개인정보를 개인정보 처리 방침에서 고지한 범위 내에서 사용하며, 고객의 동의 없이 동 범위를 초과하여 이용하거나 타인 또는 타기업에 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다. ·회원의 사전 동의를 얻은 경우 ·관계 법령상 의무 준수사항을 이행하거나, 수사 등의 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 등이 적법하게 개인정보 제공을 요구해온 경우 ·보다 나은 서비스 제공을 위하여 고객의 동의를 받고 고객의 개인정보를 관계사에 제공하거나 또는 제휴사와 공유할 수 있습니다.
(5) 회원은 해당 제공에 대한 동의를 사후에 언제든지 철회할 수 있습니다.

제2조 개인정보의 수집 · 이용 항목
(1) 회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 또는 서비스를 이용하는 과정에서 최소한의 개인정보를 필수항목으로 수집하고 있습니다.
(2) 회원 가입 시 수집 정보 ·본인확인 정보(CI) / 아이디 / 패스워드 / 이메일주소 / 주소
(3) XGOLF 내의 개별 서비스 이용, 이벤트 응모 및 경품 신청 과정에서 해당 서비스의 이용자만 추가 개인정보 수집이 발생할 수 있습니다.
(4) 추가로 개인정보를 수집할 경우 해당 개인정보 수집 시점에서 이용자에게 '수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의 보관기간'에 대해 해당 사실을 알리고 동의를 구합니다.
(5) SNS 간편로그인 연동 시 개인정보 수집 항목
구분\t개인정보 항목\t이용목적\t제공항목\t보유 및 이용기간
SNS 연동\t네이버\tSNS 계정연동 및 이용자 식별\t이메일 (아이디), 본인확인 정보(CI)\t회원탈퇴 전까지 또는 개인정보 이용 목적달성 시 까지
\t카카오\t\t\t
\t애플\t\t\t
(6) 신멤버스(기업전용 상품) 회원 가입 시 수집 정보 ·신청기업정보 : 사업자번호 | 사업자명 | 대표자명 | 주소 ·신청담당자정보 : 이름 | 연락처 | 이메일

제3조 개인정보의 처리 및 보유 기간
(1) 회사는 정보 주체로부터 개인정보를 수집할 때 동의받은 개인정보 보유 및 이용 기간 또는 법률에 따른 개인정보 보유 및 이용 기간 내에서 개인정보를 처리하고 보유합니다.
(2) 개인정보를 수집할 때 동의받은 개인정보 보유 및 이용 기간은 다음과 같습니다. ·보유 및 이용 기간 : 회원 탈퇴 시 즉시 삭제(관계 법령 및 예외사항에 해당되는 경우 별도 보관 후 파기)
(3) 관계 법령의 규정에 따른 개인정보 보유 및 이용 기간은 다음과 같습니다. ·계약 또는 청약 철회 등에 관한 기록 : 5년 (전자상거래법 시행령 제6조) ·대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래법 시행령 제6조) ·소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년 (전자상거래법 시행령 제6조) ·표시ㆍ광고에 관한 기록 : 6개월 (전자상거래법 시행령 제6조)
(4) 회원의 경우 개인정보의 보유 및 이용 기간은 서비스 이용계약 체결 시(회원가입 시)부터 서비스 이용계약 해지(탈퇴 신청, 직권 탈퇴)까지 입니다.
(5) 관련 법령에 그 근거가 없더라도, 회사의 중대한 손실을 예방하거나, 범죄 및 소송 등을 위해 보관해야 하는 경우 회사방침에 따라 보관할 수 있습니다. 단 그 목적을 달성하기 위한 최소한의 기간 및 항목만 보관합니다.
보관 정보\t보관기간\t보관 사유
부정이용 회원 탈퇴 후 이용자 정보에 관한 기록\t위약 적용 기간\t부정이용 회원의 재발 방지

제4조 개인정보의 제3자 제공
(1) 회사는 회원의 동의가 있거나 개인정보보호법 제17조 및 제18조 등 관련 법령의 규정에 의한 경우를 제외하고는 본 개인정보 처리 방침에서 고지한 범위를 넘어 회원의 개인정보를 이용하거나 제3자에게 제공하지 않습니다.
(2) 다만, 다음의 경우에는 예외로 합니다. 이경우에도 관련법령에 의거하여 필요한 최소한의 개인정보만을 제공하며, 목적과 다르게 제공하지 않습니다. ·법령에 의하거나, 수사, 조사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 및 감독 당국의 요구가 있는 경우 ·재화 등의 거래에 따른 대금 정산을 위하여 필요한 경우 ·재난안전법, 감염병예방법, 가축전염병예방법, 실종아동법, 자살예방법, 위치정보법, 긴급복지지원법, 개인정보보호법, 전기통신사업법 등 법률의 규정 또는 관계 법률에 의하여 필요한 불가피한 사유가 있는 경우 ·영업의 이동 및 합병 등
(3) 회사는 회원에게 높은 품질의 서비스 제공 등을 위하여 아래와 같이 회원의 동의를 받고 고객의 개인정보를 아래와 같은 관계사에 제공하거나 또는 제휴사와 공유할 수 있습니다. ※ 개인정보 제3자 제공 세부 내용
구분\t제공 받는 자\t이용목적\t제공항목\t보유 및 이용기간
제3자제공\t제휴골프장\t골프장 예약확인 및 안내\t이름, 연락처(휴대폰번호)\t개인정보 이용 목적 달성 시까지
\t회사 제휴 숙박시설\t숙박시설 예약확인 및 안내\t이름, 연락처(휴대폰번호)\t개인정보 이용 목적 달성 시까지
\t라쿠텐 고라(JP)제휴골프장\t일본골프장 예약확인 및 안내\t이름, 연락처(휴대폰번호),ID, 이메일\t개인정보 이용 목적 달성 시까지
\t제휴 골프연습장\t골프연습장 예약확인 및 안내, 이벤트 및 당첨안내, 골프연습장 회원권 상품 안내 및 가입권유\t이름, 연락처(휴대폰번호)\t개인정보 이용 목적 달성 시까지
\t주식회사 쇼골프피플 및 제휴 골프아카데미\t고객 상담 및 레슨 관련 안내\t이름, 연락처(휴대폰번호), 문의 내용\t문의 접수 후 1년
(4) 회원 개인정보의 제3자 제공을 철회하고 싶은 회원은 이미 제3자에게 제공된 개인정보라 하더라도 언제든지 정정, 삭제, 정보 제공 동의 철회를 요구할 수 있습니다. 정정, 삭제, 정보 제공 동의 철회는 전화, 팩스 등을 통하여 본인 확인 후 요청할 수 있으며, 향후 정보제공 동의 여부를 결정할 수 있습니다. ·제3자 제공 정정, 삭제, 동의 철회 연락처 : 1544-9017
(5) 이미 제공된 회원 정보의 정정, 삭제, 정보 제공 동의 철회는 일정 시간이 소요될 수 있으며, 이와 같은 사유로 정정, 삭제, 정보 제공 동의 철회를 요청하였더라도 일정 기간 마케팅에 활용될 수 있음을 알려 드립니다.
(6) 회사는 회원에게 더욱 다양한 정보를 제공하고, 서비스의 질을 향상하기 위하여 회사의 비즈니스 파트너에게 본 개인정보 처리방침에 동의한 회원의 개인정보를 제공합니다. 개인정보가 제공되는 비즈니스 파트너 사와 제공 정보, 제공된 정보의 이용목적은 아래와 같습니다.
구분\t제공 받는 자\t이용목적\t제공항목\t보유 및 이용기간
제3자제공\t회사 제휴 카드사\t제휴카드&금융상품 안내, 가입권유 및 관련 민원 처리\t이름, 연락처(휴대폰번호)\t수집일로부터 1년
\t회사 제휴 업체
(홈페이지 상시 게시)
휴대폰 문자서비스 등을 활용한 타사 상품/서비스에 대한 제반 마케팅 활동(업무대행 포함)\t이름, 연락처(휴대폰번호)\t수집일로부터 1년

제5조 개인정보처리의 위탁
(1) 회사는 회원에게 높은 품질의 서비스 제공 등을 위하여 아래와 같이 회원 개인정보를 위탁하고 있습니다. ※ 개인정보 위탁 세부내용
구분\t수탁자\t이용목적\t제공항목\t보유 및 이용기간
제3자제공\t(주)CJ올리브네트웍스\t카카오비즈메시지(알림톡) 발송(재화, 용역, 예약진행과정 등에 대한 정보), SMS / LMS / MMS 발송 서비스\t이름(한글, 영문), 연락처(휴대폰번호)\t개인정보 이용 목적 달성 시까지
\t㈜세일코리아넷\t고객센터 운영 및 고객상담, TM업무\t이름(한글, 영문), 연락처(휴대폰번호), 예약 내역, 결제정보, 환불정보\t개인정보 이용 목적 달성 시까지
\tCJ대한통운\t사은품 배송\t수취인명, 배송 연락처(휴대폰번호), 배송 주소\t개인정보 이용 목적 달성 시까지
\t㈜쿠프마케팅\t모바일 쿠폰 발송 및 환불 등 고객지원서비스 업무 대행\t이름(한글, 영문), 연락처(휴대폰번호)\t개인정보 이용 목적 달성 시까지
\tNHN KCP(주)\t전자결제(결제 및 정산) 대행\t이름(한글, 영문), 연락처(휴대폰번호), 결제 정보\t개인정보 이용 목적 달성 시까지
\tNHN KCP(주)\tXpartners 이용 및 관리\t이름(한글, 영문), 연락처(휴대폰번호)\t개인정보 이용 목적 달성 시까지
\t나이스신용평가정보㈜\t회원가입 및 회원정보 서비스를 위한 본인확인(휴대폰 본인인증)\t이름(한글, 영문), 연락처(휴대폰번호)\t개인정보 이용 목적 달성 시까지
\t(주)쇼골프피플\t고객 상담 및 레슨 관련 안내\t이름, 연락처(휴대폰번호), 문의내용\t개인정보 이용 목적 달성 시까지
(2) 회사는 위탁계약 체결 시 [개인정보 보호법] 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리´감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
(3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 바로 본 개인정보 처리 방침을 통하여 공개하도록 하겠습니다.

제6조 개인정보 파기절차 및 방법
(1) 회사는 이용자의 개인정보를 원칙적으로 보유·이용 기간의 경과, 처리목적 달성, 서비스 이용약관에 따른 계약 해지 등 개인 정보가 불필요하게 되었을 때는 바로 해당 개인정보를 파기합니다.
(2) 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 개인정보를 안전하게 보관합니다.
(3) 동의를 얻은 경우는 아래와 같습니다. ·부정 이용으로 징계를 받기 전에 회원 가입 및 탈퇴를 반복하며 서비스를 부정 이용하는 사례를 막기 위해 탈퇴한 이용자의 휴대 전화번호를 복호화가 불가능한, 한 방향 암호화(해시 처리)하여 1년간 보관합니다.
(4) 개인정보 파기에 대한 절차 및 방법은 [개인정보 파기 지침]을 준수하며, 파기방법은 아래와 같습니다. - 종이에 의한 출력물 : 분쇄기로 분쇄 또는 소각 - 전자적 파일 형태 저장 개인정보 : 기록을 재생할 수 없는 기술적 방법(Low format 등)을 사용하여 파기

제7조 개인정보 안전성 확보조치
(1) 회사는 회원의 개인정보가 분실 · 도난 · 유출 · 변조 또는 훼손되지 아니하도록 내부 관리계획 수립, 접속기록 보관 등 안전성 확보에 필요한 기술적 · 관리적 및 물리적 조치를 다음과 같이 하고 있습니다.
(2) 비밀번호의 암호화 이용자의 비밀번호는 한 방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인, 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
(3) 관리적 조치 ·개인정보의 안전한 처리를 위한 내부 관리계획의 수립 ·개인정보를 직접 취급하는 회사 임직원 및 수탁자 등에 대한 교육계획 수립 및 실시 ·내부 관리계획에 따른 정기적인 자체 점검의 실시
(4) 기술적 조치 ·시스템에 대한 접근 권한을 업무 수행에 필요한 최소 범위로 차등 부여하고 있습니다. ·정보통신망을 통한 불법적인 접근 및 침해사고 방지를 위해 침입 차단 및 침입탐지시스템을 운영하고 있으며 24시간 365일 감시하고 있습니다. ·시스템 접속 기록의 보관 및 위/변조 방지를 위해 로그 데이터의 정기 백업을 실시하고 있으며 개인정보 문서의 도난/분실에 대응하기 위해 파일 패스워드 설정 및 잠금장치 서랍장에 문서를 보관하고 있습니다. ·개인 정보처리시스템 또는 업무용 컴퓨터에 악성 프로그램을 방지/치료할 수 있는 백신 소프트웨어 등의 보안 프로그램을 설치/운영하고 있습니다.
(5) 물리적 조치 ·회사는 정보 유출 등을 방지하기 위해 제한 및 통제구역에 대한 외부인의 출입을 통제하는 출입 통제 시스템 및 장비를 상시 운영하고 있음. 또한 24시간 CCTV 및 보안업체의 경비 시스템이 운영되고 있고 주요 통제구역은 별도의 시건 된 장소에 설치되어 인가된 자만이 출입할 수 있도록 하고 있습니다.

제8조 개인정보의 기술적/관리적 보호대책
(1) 해킹 등에 대비한 대책 ·회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. ·최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다. ·만일의 사태에 대비하여 침입 차단 시스템을 이용하여 보안에 최선을 다하고 있습니다. ·민감한 개인정보는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
(2) 개인정보 관련 처리 담당자를 최소한으로 제한하며, 개인정보 처리자에 대한 교육 등 관리적 조치를 통해 법령 및 내부 방침 등의 준수를 강조하고 있습니다.
(3) 개인정보보호 전담 부서를 운영하고 있으며, 개인정보 처리 방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가 발견될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.

제9조 정보주체의 권리·의무 행사방법
(1) 이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 열람(조회)하거나 정정(수정), 계약해지(회원탈퇴)를 요청할 수 있습니다.
(2) 회원 및 법정대리인의 개인정보 열람, 정정, 계약 해지는 개인정보보호 책임자 또는 담당자에게 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 바로 조치하겠습니다. ·T) 1544-9017, E) showgolf_webmail@xgolf.com
(3) 회원이 개인정보의 오류에 대한 정정을 요청하는 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
(4) 회원은 자신의 개인정보를 최신의 상태로 유지해야 하며, 회원은 부정확한 정보 입력으로 발생하는 문제의 책임은 회원 자신에게 있습니다.
(5) 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.
(6) 회원은 전자우편, 비밀번호 등에 대한 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.

제10조 아동의 개인정보보호
(1) 회사는 만 14세 미만 아동의 개인정보보호를 위하여 만 14세 이상의 이용자만 회원가입을 허용합니다.

제11조 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항
(1) 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
(2) 쿠키는 웹사이트를 운영하는 데 이용되는 서버가(http) 이용자의 컴퓨터 브라우저에 보내는 소량의 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
(3) 쿠키의 사용 목적 ·이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안 접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용합니다. ·쿠키의 설치´운영 및 거부 : 웹브라우저 상단의 도구> 인터넷 옵션> 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다. ·쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
(4) 회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
(5) 쿠키 설치· 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 아래와 같습니다. ·Internet Explorer 웹 브라우저[도구] > [인터넷 옵션] > [개인정보] 탭 > [고급] 변경 ·Chrome 웹 브라우저 우측 상단 메뉴 [설정] > [개인정보 및 보안] > [사이트 설정] > [쿠키 및 사이트 데이터] 설정

제12조 회원 개인정보 보호 책임자 및 개인정보 관리 담당 부서
(1) 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 고객님의 불만 처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호 책임자 및 개인정보보호 담당자를 지정하고 있습니다.
(2) 이용자는 회사의 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보와 관련된 문의, 불만 처리, 피해구제 등에 관한 사항을 개인정보 보호 책임자 및 담당자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
구분\t정보\t전화번호
개인정보 관리담당 부서\t재무본부\t1544-9017
개인정보보호
최고책임자\t임승현\t
(3) 이용자는 아래 이 기관에 대해 개인정보 침해에 대한 피해구제 상담 등을 문의하실 수 있습니다. 아래의 기관은 정보기관 소속으로서, 회사의 자체적인 개인정보 불만 처리 또는 개인정보 피해구제 결과에 만족하지 못하실 경우, 자세한 도움이 필요하실 경우에는 문의하여 주시기를 바랍니다. 개인정보 침해신고센터 (국번 없이) 118 / http://privacy.kisa.or.kr/ 대검찰청 사이버범죄 수사단 (국번 없이) 1301 / http://www.spo.go.kr/ 경찰청 사이버안전국 (국번 없이) 182 / http://www.cyber.go.kr/ 전자거래 분쟁조정위원회 1661-5714 / https://www.ecmc.or.k/

제13조 개인정보 처리 방침의 범위
(1) 본 개인정보 처리 방침은 회사의 대표 브랜드 ' 엑스골프 & XGOLF(www.xgolf.com) ' 및 관련 제반 서비스(WEB/APP/기타 세부 서비스 포함)에 적용됩니다.
제14조 고지의 의무
(1) 현 개인정보 처리 방침은 법령, 정부의 정책 또는 회사 내부 정책 등 필요에 의하여 변경될 수 있으며, 내용추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다. 다만, 이용자 권리의 중요한 변경이 있으면 최소 30일 전에 고지합니다.
(2) 현 개인정보 처리 방침은 2023년 12월 14일부터 적용되며, 변경 전의 개인정보 처리 방침은  XGOLF(www.xgolf.com) 공지사항을 통해서 확인하실 수 있습니다
·공고 일자 : 2023년 12월 7일 ·시행 일자 : 2023년 12월 14일
`;
          break;
        case 'playTerm':
          this.termsShow = true;
          this.termsContent = `쇼골프 플레이 서비스 이용약관
제1조 목적

본 약관은 주식회사 쇼골프(이하 "회사"라 칭함)가 운영하는 XGOLF 플랫폼에서 제공하는 유료 구독 서비스인 쇼골프 플레이 서비스를 이용하는 회원과 회사 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조 용어의 정의

1) 본 약관에서 사용되는 주요 용어의 의미는 다음과 같습니다.

  1. “쇼골프 플레이 서비스(이하 "본 서비스"라 칭함)”란, 회사가 회원에게 제공하고자 본 약관에서 규정하고 있는 각종 혜택 및 관련 부가서비스 일체를 의미하며, 본 서비스는 월 단위 또는 연단위 이용료를 지불하고 이용할 수 있습니다.

  2. “회원”이란 회원 중 본 약관 및 이용료 결제에 동의하고 이용신청 절차를 거쳐 본 서비스에 가입한 회원을 의미합니다.

  3. "이용료"란 본 서비스에 가입하여 본 서비스를 이용하고자 하는 회원이 회사에 지불하는 금액을 의미합니다.

  4. “제휴사”란 본 서비스를 구성하는 제휴 서비스 등을 회사에 공급하는 사업자를 의미합니다.

2) 약관에서 사용하는 용어 중 본조에서 정의되지 않은 용어의 의미는 일반적인 거래관행에 의합니다.

제3조 약관의 효력과 변경

1) 본 약관은 회원이 본 약관에 동의한 날로부터 본 서비스 해지 시까지 적용하는 것을 원칙으로 하며, 합리적인 사유가 발생할 경우 관련 법령에 위배되지 않는 범위 안에서 개정될 수 있습니다.

2) 개정된 약관은 온라인에서 공지함으로써 효력을 발휘하며, 이용자의 권리 또는 의무 등 중요한 규정의 개정은 사전에 공지합니다.

3) 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 인터넷 사이트 및 모바일 어플리케이션에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.

4) 회원은 변경된 약관에 동의하지 않을 권리가 있고, 변경된 약관에 동의하지 않을 경우 본 서비스 해지를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 봅니다.

5) 회원은 개정, 변경된 약관의 내용에 동의하지 않을 경우 본 약관 제11조에 따라 본 서비스 이용 해지를 요청할 수 있습니다.

제 4조 약관 및 준칙

1) 본 약관은 회사가 제공하는 "본 서비스"에 관한 이용안내에 적용합니다.

2) 본 약관에서 정하지 아니한 사항과 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 공정거래위원회가 정하는 「전자상거래 등에서의 소비자 보호지침」 등 관련 법령 및 상관례에 따릅니다.

제5조 이용계약의 체결

1) “이용계약”은 본 서비스를 이용하고자 하는 회원이 본 약관에 동의한 뒤 결제 수단 및 이용권을 선택하고 결제 정보를 입력하는 등 절차를 거쳐 이용신청을 완료하고, 회사가 회원의 이용신청을 승낙함으로써 성립합니다.

2) 회사는 회원의 이용신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.

  1. 이용신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 단, 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.

  2. 실명이 아니거나 타인의 명의를 이용한 경우

  3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우

  4. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우

  5. 회원 본인 명의로 이미 서비스에 가입 중인 경우

  6. 기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우

3) 제2항에 따라 회원가입 신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록 합니다.

4) 제1항 및 제2항과 관련하여 회사는 사전 또는 사후에 회원에게 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.

5) 이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.

6) 본 서비스는 회원 본인 명의로 회원이 보유할 수 있는 1개의 ID에서만 이용 가능하며, 타인의 명의를 도용하여 사용 할 수 없습니다.

제6조 결제 수단 및 결제 일자 등

1) 회원은 본 서비스에 결제 수단 및 결제 정보를 입력하고 이용료 결제 또는 이용권을 등록해야  하며, 월 또는 연단위로 지정된 날짜에 정기 결제됩니다.

2) 회원이 가장 마지막으로 등록한 결제 수단으로 본 서비스의 정기 결제가 이루어집니다. 단, 회원이 결제수단을 변경하거나 별도로 정하는 경우에는 그에 따릅니다.

3) 각 이용기간의 시작일은 매월 가입일과 같은 일자이며, 종료일은 다음 이용기간 시작일의 전날입니다.
<예시1> 7월 8일에 월단위 결제로 가입한 경우, 종료일은 8월 7일, 시작일은 8월 8일,

4) 이용계약 체결 이후 정기 결제일은 이용기간의 시작일로 설정됩니다.

5) 회원이 이용 계약 체결 시 매월 정기적으로 이루어지는 이용료 자동결제에 동의하고 결제가 성공적으로 완료된 경우 이용기간이 연장됩니다. 다만, 회원이 이용 계약 체결시 이용료 자동결제에 동의하였더라도 결제가 정상적으로 이루어지지 않은 경우 이용기간은 연장되지 않고 이용계약이 해지될 수 있습니다.

6) 회사는 결제 주기에 따른 정기 결제를 위해 회원이 등록한 결제 수단을 통한 결제가 정상적으로 이루어지지 않을 경우, 정책적으로 정한 기간 및 횟수에 따라 자동적으로 재결제를 시도할 수 있습니다.
제7조 쇼골프 구독서비스의 주요 내용

1) 회원은 이용료 지불의 대가로 회사 및 제휴사로부터 다음 각호와 같은 혜택 및 서비스를 제공받을 수 있습니다. 다만, 구체적인 서비스의 내용 및 범위는 회사 및 제휴사에서 정한 정책에 따라 내용 및 범위가 추가, 변경, 또는 제외될 수 있습니다.

  1. 골프 연습장 할인 서비스 : 본 서비스 구독 기준 매월 지정된 수량의 이용권/할인권 제공받아회사 직영 연습장 및 파트너 연습장 예약 시 사용 가능한 할인 서비스를 의미합니다.

2. 골프장 예약 할인 서비스 : 본 서비스 구독 기준 매월 지정된 수량의 할인권을을 제공받아 XGOLF앱에서 선결제 골프 예약 및 국내투어 상품 예약 시 사용 가능한 할인 서비스를 의미합니다.

  3. 일본 골프장 예약 서비스 : 본 서비스 구독 기준 매월 지정된 횟수만큼 회사에서 운영하는 일본 내 골프장 대상 예약 시 회사에서 정한 정회원 요금으로 이용할 수 있는 서비스를 의미합니다.

4. 골프장 발렛파킹 서비스 : 본 서비스 구독 기준 지정한 횟수만큼 회원은 회사가 지정한 골프장 대상 무료 발렛파킹 서비스 또는 세차할인을 제공하는 서비스를 의미합니다.

5. 홀인원 보험 서비스 : 본 서비스 구독 기준 회사가 제휴사와 체결한 보험계약으로, 제휴사는 회사를 보험계약자로 하고 회사가 정한자를 피보험자로하여, 매월 지정된 횟수만큼 XGOLF앱을 통해 골프예약을한 회원에 한해 홀인원보험의 무료 가입을 제공하는 서비스를 의미합니다.

6. 영화 할인 서비스 : 본 서비스 구독 기준 매월 지정된 수량의 할인권을 제공받아 회원은 회사가 지정한 제휴사의 영화 예매 시 할인을 제공하는 서비스를 의미합니다.

7. 클럽 피팅 서비스 : 본 서비스 구독 기준 지정된 횟수에 만큼 회사가 지정한 제휴사 대상 프리미엄 클럽 피팅 서비스를 제공하는 서비스를 의미합니다.

8. 더 라운지 할인 서비스 : 본 서비스 구독 기준 매월 지정된 수량의 할인권을 제공받아 회사가 지정한 국내 공항라운지 및 K공항리무진 할인을 받을 수 있는 서비스를 의미합니다.

2) 회사는 매 이용기간 정기적으로 제공되는 혜택 외에도 이용자를 대상으로 비정기적이고 일시적인 추가 혜택을 제공할 수 있습니다.

제8조 회사의 권리와 의무

1) 회사는 관계법령 및 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.

2) 회사는 운영상, 기술상의 필요 또는 제휴사의 사정 내지 정책 변경 등 상당한 이유가 있는 경우에 이용료, 혜택 구성 등을 포함하여 본 서비스의 전부 또는 일부를 변경할 수 있고, 변경 시 제3조에 따라 관련 사항을 통지 및 게시합니다.

3) 회사는 제2항에 따라 본 서비스의 이용료를 변경한 경우, 변경된 이용료가 결제되기 전에 회원에게 동의를 받아야 합니다. 회원이 이용료의 변경에 동의하지 아니할 경우, 해당 회원은 이용료가 자동결제되지 아니하며, 이 경우 기존에 결제한 본 서비스 이용기간 종료 시에 이용계약이 해지됩니다.

4) 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호법등 관련법이 정한 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.

5) 회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다.

6) 회원이 제기한 의견이나 불만사항에 대해서는 회사의 게시판을 활용하거나 회원이 제공한 이메일 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.

제9조 회원의 의무

1) 회원은 정상적으로 본 서비스를 이용하기 위하여 회원정보 및 본 서비스 앱의 버전을 최신으로 유지하여야 합니다.

2) 회원은 다음 각 호의 행위를 하여서는 안 됩니다.
  1. 이용 신청 또는 변경 시 허위내용의 등록
  2. 타인의 정보 도용
  3. 회사에 게시된 정보의 변경
  4. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 행위
  5. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해 행위
  6. 회사 및 제휴사, 기타 제3자의 명예를 손상시키거나 업무 방해, 재산상의 손해를 끼치는 행위
  7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 회사에 공개 또는 게시하는 행위
  8. 회사의 동의 없이 영리를 목적으로 본 서비스를 사용하는 행위
  9. 구매 등을 통해 획득한 이용권, 할인권 등을 유상양도, 판매 등의 방법으로 처분하는 행위
  10. 기타 불법적이거나 부당한 행위

3) 회원은 관계법, 본 약관의 규정, 이용안내 및 본 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.

4) 회원은 어떠한 경우에도 방법을 불문하고 직접 또는 제3자를 활용하여 회사의 서비스 환경 제공에 악영향을 끼치는 행위를 하여서는 안 되고, 각 서비스 운영정책에 의거하여 본 서비스의 이용이 제한될 수 있습니다.

제10조 회원에 대한 통지

1) 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 회원이 지정한 이메일 전화번호, 서비스 내 알림 등으로 할 수 있습니다.

2) 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사에서 제공하는 서비스 게시판에 게시 함으로서 제1항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 원칙으로 합니다.

제11조 이용계약의 해지

1) 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 본 서비스의 해지 메뉴를 이용해 해지를 해야 합니다. 본조에 따른 해지란 회원이 본 약관에 따라 가입하여 이용하고 있는 본 서비스의 정기 결제를 해지하는 절차를 말하며, 본 서비스 해지 이후에도 이용약관에 따라 이와 무관하게 계속해서 이용하실 수 있습니다.

2) 회원이 본 서비스를 해지할 경우, 다음 결제 예정일에 본 서비스에 대한 자동 결제가 이루어 지지 않으며 해지 기간 및 서비스 이용에 따라 이미 결제가 이루어진 남은 이용 기간 동안은 본 서비스를 정상 이용할 수 있습니다.

3) 본 서비스 해지가 확정되는 시점은 원칙적으로 서비스 이용기간 종료일의 익일 0시이며, 해당 시점에 본 서비스는 혜택은 종료되고 발급 받은 할인권에 대한 이용이 제한 될 수 있습니다.

4) 회원의 의도적인 해지와 재가입이 확인되는 경우, 해당 회원은 향후 본 서비스 가입 및 혜택 제공에 제한이 따를 수 있습니다.

5) 회원이 제9조 제2항을 위반한 경우, 회사는 사전통지 없이 이용계약을 해지하거나 본 서비스 이용을 즉시 제한하고 이용기간의 연장을 거절할 수 있습니다.

6) 전항의 조치가 이루어진 경우, 회사는 이용계약이 해지된 회원의 본 서비스 재가입을 제한할 수 있습니다.

제12조 서비스 이용 제한

1) 회사는 회원이 서비스 이용내용에 있어서 본 약관 제 9조 내용을 위반하거나, 다음 각 호에 해당하는 경우 서비스 이용을 제한할 수 있습니다.

  1. 본 서비스 및 제휴사 이용 중 타 이용자, 제휴사, 기타 제 3자에게 욕설, 폭언, 성희롱 등 모욕을 주는 행위나 서비스 이용 방해, 재산상의 손해를 끼치는 경우

  2. 기타 정상적인 본 서비스 운영에 방해가 될 경우

  3. 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우

  4. 불법 홈페이지나 상용소프트웨어나 크랙 파일을 올린 경우

  5. 정보통신에 관한 심의규정 제8조에 어긋나는 음란물을 게재한 경우

  6. 반국가적 행위의 수행을 목적으로 하는 내용을 포함한 경우

  7. 저작권이 있는 글을 무단 복제하거나 올린 경우

  8. 당일취소, 미내장, 지속적으로 예약을 파기하는 경우

  11. 기타 불법적이거나 부당한 행위

2) 상기 이용제한 규정에 따라 회원에게 본 서비스 이용에 대하여 별도 공지 없이 본 서비스 이용의 일시 정지, 초기화, 이용계약 해지 등을 할 수 있습니다.

3) 본조의 규정 이외에 별도로 정한 회사의 운영정책 위반 시 본 서비스 이용이 제한될 수 있습니다

제13조 청약철회

1) 회원은 가입일로부터 7일 이내에 제7조 제1항 제4호에 정한 제공 서비스의 어떠한 혜택도 사용하지 않은 경우 청약철회가 가능합니다.

2) 회원이 가입 후 본 서비스의 주요 내용을 이용한 경우에는 청약철회 및 환불이 불가능합니다. 단, 연간 결제의 경우 제13조 1항의 경우를 제외하고 서비스의 이용이 없더라도 갱신된 월을 포함한 기간을 산정한 이용료를 제외하고 청약철회가 가능합니다.

3) 회사가 서비스 중지 또는 장애발생을 사전에 알리지 않은 경우 다음 각 호에 따릅니다. 단, 회원의 책임있는 사유가 있는 경우 그 시간은 중지 또는 장애시간에 포함되지 않습니다.

  1. 1개월 동안의 중지 또는 장애발생 누적시간이 72시간을 초과한 경우 : 회원은 계약을 해제 또는 해지할 수 있으며, 회사는 미 이용기간을 포함한 잔여기간에 대한 이용료의 환급 및 손해배상을 합니다. 다만, 사업자가 고의 또는 과실 없음을 증명한 경우에는 손해배상책임을 부담하지 않습니다.

  2. 회사의 책임있는 사유로 인해 중지 또는 장애가 발생하고, 그 중지 또는 장애시간이 1개월 동안 72시간 이하인 경우 : 회사는 중지 또는 장애시간의 3배를 무료로 연장합니다.

  3. 불가항력 또는 제3자의 불법행위 등으로 인해 중지 또는 장애가 발생하고, 그 중지 또는 장애시간이 1개월 동안 72시간 이하인 경우 : 회사는 중지 또는 장애시간만큼 무료로 이용기간을 연장합니다.

4) 회사가 본 서비스 중지 또는 장애에 대하여 사전에 알린 경우 다음 각 호에서 정한 바에 따릅니다. 다만, 본 서비스 개선을 목적으로 한 설비 점검 및 보수의 경우에 1개월을 기준으로 최대 24시간은 중지 또는 장애시간에 포함되지 않습니다.

  1. 1개월을 기준으로 중지 또는 장애시간이 10시간을 초과하는 경우 : 회사는 10시간과 이를 초과한 시간의 2배의 시간만큼 이용기간을 무료로 연장합니다.

  2. 1개월을 기준으로 중지 또는 장애시간이 10시간 이하인 경우 : 회사는 중지 또는 장애시간만큼 무료로 연장합니다.

5) 사전고지는 중지 또는 장애시점을 기준으로 24시간 이전에 알린 것으로 한정합니다. 다만, 불가항력적인 사유로 인해 사전에 고지할 수 없는 경우에는 그러하지 않습니다.

6) 회사가 제11조 제6항에 따라 회원의 본 서비스 이용을 즉시 제한하는 경우 회사는 미 이용기간을 포함한 잔여기간에 대한 이용료를 환불합니다.

7) 전항의 환불은 「전자상거래 등에서 소비자보호에 관한 법률」 제18조 제3항(신용카드 결제 등의 환급)에 따라 처리됩니다.

8) 회사는 환불 시점에 본 서비스에 따른 혜택의 주요 내용을 즉시 회수할 수 있습니다.

제14조 서비스의 종료

1) 회사는 앱 내 사전 공지 후 본 서비스의 운영을 종료할 수 있습니다.

2) 전항의 공지가 이루어진 날로부터 30일이 지나고 최초로 도래하는 이용기간 종료일에 이용계약이 해지되며, 그 이후 본 서비스에 재가입할 수 없습니다.

제15조 약관의 해석, 관할 및 준거법

1) 본 약관에 명시되지 않은 사항은 전기통신사업법 등 관계법령과 상관습에 따릅니다.

2) 회사는 본 약관 외에 본 서비스에 관한 별도의 운영정책을 둘 수 있습니다.

3) 본 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 관할 법원은 민사소송법에 정한 바에 따릅니다.





【 부 칙 】

제 1조 [시행일]

본 약관은 2024년 07월 08일부터 적용됩니다.

`;
          break;
        case 'playPersonalInfo':
          this.termsShow = true;
          this.termsContent = `쇼골프(이하 ‘회사’)는 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을 다하고 있습니다.

처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우 개인정보 보호법에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

[쇼골프 플레이 서비스 가입 시]
수집항목
-(필수) 결제수단, 쇼골프 플레이 정기 결제 이력
이용목적
-쇼골프 플레이 서비스 가입 및 이용
보유 및 이용기간
-쇼골프 플레이 서비스 해지 시 즉시 삭제합니다.
-관계법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 보관한 후 파기합니다.
--------------------------------------------------------------------------------------------------------------
수집항목
-(필수) 쇼골프 플레이 서비스 이용 내역
이용목적
-쇼골프 플레이 서비스 이용에 따른 마케팅, 이벤트 참여 안내 등
보유 및 이용기간
-회원 탈퇴 시 즉시 삭제합니다.
-관계법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 보관한 후 파기합니다.

회원님은 개인정보 수집 및 이용 동의에 관해 동의하지 않을 권리가 있습니다. 단, 서비스 제공을 위해 필요한 최소한의 개인정보이므로 동의 하셔야 가입 및 서비스 이용이 가능합니다. 더 자세한 내용에 대해서는 개인정보처리방침을 참고하시기 바랍니다.
`;
          break;
        case 'insurance':
          this.termsShow = true;
          this.termsContent = `1. 쇼골프 멤버십 서비스 회원 단체규약

제1조(목적)
본 규약의 목적은 ㈜쇼골프(이하 ”회사”)를 단체보험 계약자로 하여 회사가 운영하는 쇼골프 멤버십 서비스의 회원을 대상으로 롯데손해보험주식회사(이하”제휴보험사”)와 함께 원라운딩골프보험 서비스(이하 ”서비스”)를 제공함에 있어, 서비스 이용자(이하 ”회원”)와 회사간의 권리와 의무를 명확히 하는 데 있습니다.

제2조(회원자격의 득실)
① 회원 중 본 규약에 동의한 자는 본 규약의 당사자가 됩니다.
② 회원의 피보험자 지위 득실 등 보험계약에 따른 지위는 제휴보험사가 제공하는 보험의 이용약관에서 정하는 바에 따릅니다.

제3조(서비스의 내용) 회사가 제공하는 서비스는 다음과 같습니다.
① 원라운딩골프보험 상품에 관한 정보 제공
② 원라운딩골프보험 가입, 유지에 필요한 제반 사무
③ 기타 보험가입 관련 정보제공 및 단체보험 계약자로서 제반 업무

제4조(단체보험의 가입 및 탈회)
① 회사는 보험계약자로서 다수의 회원을 피보험자로 하여 제휴보험사와 단체보험계약(이하”단체보험”)을 체결할 수 있고, 회원의 동의에 의거하여 회원정보를 제휴보험사에게 전달할 수 있습니다. 회사와 제휴보험사가 체결하는 단체보험의 종류와 보장내용 등 조건에 관한 사항은 회사가 회원에게 안내합니다.
② 단체보험의 피보험자로 가입대상이 되는 회원은 소정의 절차에 따라 보험가입신청을 할 수 있습니다. 보험가입을 신청하는 회원은 제휴보험사가 회사를 통하여 안내하는 보험약관, 각종동의서, 본 규약 등을 충분히 읽고 이해한 후 단체보험가입을 신청하여야 합니다.
③ 회원은 보험가입 신청 시 회사, 제휴보험사가 고지를 요구하는 사항에 대하여 성실히 답하여야 합니다. 회원이 입력한 사항이 사실과 다름으로써 발생하는 제반 문제에 대한 책임은 회원이 부담합니다.
④ 단체보험가입을 신청한 회원에 관한 정보는 회사가 회원의 동의에 의거하여 수집한 후 일괄하여 제휴보험사에 제공할 수 있으며, 회원은 이에 동의합니다.
⑤ 보험가입이 완료된 회원(이하”보험가입자”)이 신청한 단체보험의 수익자는 보험가입자(피보험자) 본인과 그 법정상속인이 됩니다.
⑥ 단체보험과 관련하여 본 규약에서 정하지 않은 사항은 제휴보험사의 개별보험약관에 따릅니다.회사는 보험가입자가 단체보험약관을 확인할 수 있도록 멤버십 서비스 페이지에 이를 게시합니다.

제5조(회사 등의 서비스 제공)
① 회사는 다음 각호의 1에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
1.\t내부 전산설비 보수
2.\t서비스의 근간인 기간통신사업자의 망서비스 장애 또는 제공 중지
② 회사가 서비스의 제공을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 회원에게 알립니다.
③ 회원은 개인정보의 부정사용이 발생하지 않도록 부정사용을 알게 된 경우 즉시 회사에 알려   피해방지에 협력합니다.
④ 회원은 서비스에 관한 권리의무의 일부 또는 전부를 제3자에게 양도하거나 담보로 제공할 수 없습니다.

제6조(본규약의 변경)
① 관계법령의 변경, 회사의 서비스 변경 등으로 본 규약을 변경할 필요가 발생할 경우, 회사는 본 규약을 즉시 변경할 수 있습니다. 변경된 규약은 회사가 별도로 정하지 않는 한 즉시 효력이 발생합니다.
② 회사는 규약 변경을 위해 필요한 경우 회원에게 의견제시를 요청할 수 있습니다. 회사의 요청이 있는 경우 회원은 의견제시를 거부할 수 있는 정당한 사유가 없는 한 요청에 성실히 응하여야 합니다.
③ 본 규약의 변경을 희망하는 회원은 구체적인 변경안과 사유를 제시하여 회사에 대하여 본 규약의 변경을 요청할 수 있습니다. 회사는 회원의 요청을 성실하게 검토하여야 하며, 회원의 요청이 합리적인 경우 본 조에 따라 규약을 변경할 수 있습니다.

2. 원라운딩 골프보험 상품내용 동의
1.\t보험 계약 기본사항
보험상품명\tlet:safe 원라운딩골프보험
보험기간\t1일
가입연령\t19~80세(보험나이 기준)
가입제한\t골프 선수, 지도자 및 골프장 운영관련자(경영인, 직원)인 경우 가입 제한
보험제공사\t롯데손해보험


2.\t보장내용
담보명\t보장내용\t보험가입금액
골프 중 홀인원비용
(최초1회)\t골프경기 중에 홀인원을 행한 경우(깔때기 홀 제외) 축하비용을 부담함으로써 입은 손해를 보험기간 중 최초 1회에 한하여 보험가입금액을 한도로 지급\t200만원
골프 중 배상책임\t골프시설 구내에서 골프의 연습, 경기, 지도(이에 따른 탈의, 휴식 포함) 중 타인의 신체 및 재물에 대한 법률상의 손해배상책임을 부담함으로써 입은 손해를 보험가입금액 한도로 지급(자기부담금 2만원)\t2000만원
•\t본 상품은 소멸성 보험으로 만기 시 환급금이 없는 상품입니다.
•\t보험나이란, 보험계약일 기준 만 나이에서 6개월 미만 끝수는 버리고 6개월 이상의 끝수는 1년으로 하여 계산하는 나이입니다.
•\t만 19세 이상만 보험가입이 가능합니다.
•\t보험기간은 보험개시 시점으로부터 24시간으로 합니다. 단, 골프 중 홀인원비용(최초1회) 담보는 보험기간 내 시작된 최초의 1회 골프경기가 끝날 때까지로 합니다.
•\t위 청약내용은 보험계약의 기본사항만 요약한 것이므로 기타 자세한 내용은 보험약관을 반드시 확인해주세요
※ 용어의 정의
①\t골프경기 : 18홀 이상을 보유하고 국내에 소재한 골프장에서 골프장에 속한 캐디를 보조자로 사용하고 동반경기자 2명 이상(골프장이 주최 또는 공동 주최한 공식경기의 경우에는 그러하지 않습니다)과 기준타수(PAR) 35 이상의 9홀을 정규로 라운드하는 것을 말함. (단, 파크골프, 그라운드골프 및 이와 유사한 경기를 위한 골프장은 제외)
②\t홀인원 : 각 홀에서 제1타에 의해 홀에 직접 들어가는 것을 말함
③\t축하비용 : 증정용 기념품 구입비용(상품권 등 물품 전표, 선불카드 제외), 축하회 비용, 골프장에 대한 기념식수 비용, 동반 캐디에 대한 축의금을 말함. 단, 축하회 비용은 홀인원을 행한 날로부터 1개월 이내 개최된 축하회 비용을 말함
④\t골프시설 : 골프의 연습 또는 경기를 행하는 시설을 말하며, 골프연습장, 탈의실 등 그 외 부속시설을 포함

3.\t보험계약 관련 유의 사항
\t보험 계약 체결
•\t보험계약시에는 보험상품명, 보험기간, 보험료, 보험료납입기간, 피보험자 등을 반드시 확인하시고, 자세한 상품내용은 반드시 보험약관을 확인하시기 바랍니다.
•\t보험계약자가 기존에 체결했던 보험계약을 해지하고 다른 보험계약을 체결하면 보험인수가 거절되거나 보험료가 인상되거나 보장내용이 달라질수 있습니다.
\t단체보험 계약
•\t본 보험계약은 ㈜쇼골프를 계약자, ㈜쇼골프의 멤버십 회원 중 가입고객을 피보험자, 가입 고객 및 법정 상속인을 보험수익자로 하는 단체계약입니다.
•\t본 보험계약은 단체형으로 운영하여 청약철회 및 품질보증해지 대상이 아닙니다.
\t해지환급금
•\t본 상품은 보험기간이 1일이므로 해지 시 해지환급금이 없습니다.
\t만기환급금
•\t본 상품은 순수보장성 상품으로 만기시 만기환급금이 없으며 또한 보험계약대출제도가 없습니다.
\t예금자 보호 안내
•\t이 보험계약은 예금자보호법에 따라 해약환급금(또는 만기 시 보험금)에 기타지급금을 합한 금액이 1인당 “5천만원까지”(본 보험회사의 여타 보호상품과 합산) 보호됩니다.
이와 별도로 본 보험회사 보호상품의 사고보험금을 합산한 금액이 1인당 “5천만원까지” 보호됩니다. (단, 보험계약자 및 보험료 납부자가 법인이면 보호되지 않습니다.)

\t보험계약 전 알릴 의무
•\t계약자 또는 피보험자는 보험계약 청약 시 청약서(질문서를 포함합니다)의 질문사항에 대하여 알고 있는 내용을 반드시 사실대로 알려야 합니다. 그렇지 않은 경우 보험금의 지급이 거절되거나 보험계약이 해지될 수 있습니다.
\t보험계약 후 알릴 의무
•\t계약자 또는 피보험자는 보험계약을 맺은 후 보험약관에 정한 계약 후 알릴 의무사항이 발생하였을 경우 지체없이 회사에 알려야 합니다. 그렇지 않을 경우 보험금 지급이 거절될 수 있습니다.

4.\t보험금 지급 제한사항 안내
①\t회사는 다음 중 어느 한 가지의 경우에 의하여 보험금 지급사유가 발생한 때에는 보험금을 드리지 않습니다.
1. 피보험자의 고의
2. 보험수익자의 고의. 다만, 그 보험수익자가 보험금의 일부 보험수익자인 경우에는 다른 보험수익자에 대한 보험금은 지급합니다.
3. 계약자의 고의
4. 전쟁, 외국의 무력행사, 혁명, 내란, 사변, 폭동, 소요, 기타 이들과 유사한 사태
5. 지진, 분화, 해일 또는 이와 비슷한 천재지변
6. 핵연료물질 또는 핵연료물질에 의하여 오염된 물질의 방사성, 폭발성 그 밖의 유해한 특성 또는 이들의 특성에 의한 사고
7. 위 제6호 이외의 방사선을 쬐는 것 또는 방사능 오염에 의한 사고
* [핵연료물질] 사용된 연료를 포함합니다.
* [핵연료물질에 의하여 오염된 물질] 원자핵 분열 생성물을 포함합니다.
②\t회사는 제1항 이외에 아래의 사유로 생긴 배상책임손해도 보상하여 드리지 않습니다.
1. 피보험자와 타인 간에 손해배상에 관한 약정이 있는 경우, 그 약정에 의하여 가중된 배상책임
2. 피보험자가 소유, 사용 또는 관리하는 재물의 없어짐, 훼손 또는 망가뜨릴 경우에 그 재물에 정당한 권리를 가진 사람에게 부담하는 손해에 대한 배상책임
3. 피보험자와 세대를 같이하는 친족(민법 제777조(친족의 범위))에 대한 손해배상책임
③\t회사는 피보험자가 골프장의 경영자 또는 사용인(임시고용인을 포함합니다)인 경우에는 그 피보험자가 경영하는 또는 고용되어 있는 골프장에서 행한 홀인원에 대해서는 보상하여 드리지 않습니다.
④\t피보험자가 깔때기 홀 또는 이벤트 홀(홀컵의 직경이 108mm를 초과하는 홀을 말한다) 등 정규 홀과 달리 공이 홀컵에 쉽게 들어가도록 변형한 홀에서 행한 홀인원에 대해서는 보상하여 드리지 않습니다.
⑤\t이 계약에서 보장하는 위험과 같은 위험을 보장하는 다른 계약이 있다면 해당 보험약관에 따라 보험금 지급은 가입된 각 보험사와 나눠서 보상됩니다.
⑥\t보상하는 손해, 보상하지 않는 손해 등 기타 자세한 내용은 보통약관 및 특별약관 내용에 따라 보험금 지급이 제한될 수 있으니 반드시 약관을 읽어주세요.

3. 개인(신용)정보 제3자 제공 동의(롯데손해보험)
㈜쇼골프의 멤버십 회원 계정으로 홀인원 서비스를 이용하는 경우, 아래의 개인(신용)정보 제자 제공 동의가 필요합니다. 자세히 읽은 후 동의하여 주시기 바랍니다.

제공받는자\t롯데손해보험(주)
제공항목\t이름, 회원ID, 생년월일, 성별, 연락처
제공목적\t보험계약의 인수심사, 체결, 이행, 유지, 관리
가입한 보험계약 상담, 법률, 국제협약 등의 의무 이행
모집질서의 유지(공정경쟁질서 유지에 관한 협정업무 포함)
보험금지급・심사(손해사정 및 구상업무, 법률자문 관련) 및 보험사고 조사 (보험사기 조사 포함)
민원처리 및 분쟁대응, 금융거래(보험료 및 보험금 등 출・수납을 위한 금융거래 신청, 자동이체 등 접수) 관련 업무
보험계약 및 보험금 청구에 이해관계가 있는 자에 대한 법규 및 계약상 의무 이행
계약체결, 보험금청구 등으로 고객이 제공한 개인(신용)정보의 최신성 유지
보유기간\t동의일로부터 거래 종료 후 5년까지(단, 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름)
“거래 종료일이란” ①보험계약 만기, 해지, 취소, 철회일 또는 소멸일, ②보험금 청구권 소멸시효 완성일(상법 제662조), ③채권・채무 관계 소멸일 중 가장 나중에 도래한 사유를 기준으로 판단한 날을 말한다.

정보주체는 위 개인(신용)정보 제공 동의를 거부할 권리가 있으며, 동의 거부 시 서비스 제공에 제한을 받을 수 있습니다.

그 밖의 사항은 ㈜쇼골프의 개인정보 처리방침에 따릅니다.

`;
          break;
      }
    },
  },
};
</script>

<style scoped>
.terms {
  height: 800px;
  overflow-y: scroll;
}
.terms {
  font-family: NotoSansCJKkr;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #2b2d2f;
  margin: 0 40px;
  overflow: auto;
}
.term-text {
  margin-bottom: 20px;
  /*height: 27px;*/
  /*line-height: 27px;*/
  /*font-size: 26px;*/
  /*letter-spacing: -1.3px;*/
  /*color: #343534;*/
  /*vertical-align: middle;*/
  /*border-bottom: 2px solid #343534;*/
}
</style>
