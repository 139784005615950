<template>
  <div class="date-wrap common-padding">
    <div class="date-title">날짜 선택</div>
    <div class="date-container">
      <div
        v-for="(date, idx) in dates"
        :key="date.text_day"
        class="date"
        :class="isScroll && idx === dates.length - 1 ? 'scroll-padding' : ''"
      >
        <input
          :id="'radio' + date.day"
          type="radio"
          class="date-radio"
          name="date"
          :text_day="date.text_day"
          :day="date.day"
          @click="dateSelected"
        />
        <label :for="'radio' + date.day" class="label"><i class="radio-img"></i>{{ date.text_day }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dates: [
        {
          text_day: '1일',
          day: '1',
        },
        {
          text_day: '5일',
          day: '5',
        },
        {
          text_day: '10일',
          day: '10',
        },
        {
          text_day: '15일',
          day: '15',
        },
        {
          text_day: '20일',
          day: '20',
        },
        {
          text_day: '25일',
          day: '25',
        },
      ],
    };
  },
  methods: {
    async dateSelected($event) {
      const text_day = $event.target.getAttribute('text_day');
      const day = $event.target.getAttribute('day');
      await this.$store.commit('setPopupData', { text_day, day });
      await this.$store.commit('slideShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.date-wrap {
  height: inherit;
}
.date-title {
  height: 128px;
  line-height: 128px;
  font-size: 34px;
  letter-spacing: -1.7px;
  font-weight: 500;
  color: #222222;
  margin-bottom: 40px;
}
.date-container {
  height: 100%;
  overflow: scroll;
}
.date {
  // margin-bottom: 71px;
  padding-bottom: 40px;
  padding-top: 40px;
}
.date-radio {
  display: none;
}
label {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #222222;
  height: 40px;
  display: flex;
  line-height: 40px;
}
.date-radio:checked + label > i {
  background: url('../../../assets/img/tvpoint/rdo_on.svg') no-repeat;
}
.radio-img {
  width: 40px;
  padding-right: 20px;
  background: url('../../../assets/img/tvpoint/rdo_off.svg') no-repeat;
}
</style>
