<template>
  <div class="d-flex c-center r-center">
    <transition name="fade">
      <div v-if="!$commons.isNullObject($store.state.common.toastPopup)" class="toast-wrapper" @click.prevent.stop="">
        <div class="save-popup-wrap">
          <div class="save-popup d-grid">
            <div class="save-popup-text" v-html="toastPopup.content"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import bus from '@/utils/bus';

export default {
  name: 'ToastPopup',

  props: {},

  data() {
    return {
      timeout: null,
    };
  },

  computed: {
    toastPopup() {
      return this.$store.state.common.toastPopup;
    },
  },

  watch: {
    toastPopup: {
      deep: true,
      handler(newVal) {
        if (!this.$commons.isNullObject(newVal)) {
          bus.$emit('end:loading');
          setTimeout(() => {
            this.$store.commit('setCommonToastPopup', {});
          }, 3000);

          window.addEventListener('touchmove', this.handleTouchmove, {
            passive: false,
          });
        } else {
          window.removeEventListener('touchmove', this.handleTouchmove);
        }
      },
    },
  },

  beforeCreate() {
    this.$store.commit('setCommonToastPopup', {});
  },

  methods: {
    handleTouchmove(e) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
};
</script>

<style scoped lang="scss">
.div-wrap {
  align-items: center;
  text-align: center;
}
.save-popup-wrap {
  width: 580px;
  vertical-align: middle;
  height: auto;
  background-color: #343534;
  border-radius: 16px;
  padding: 35px 40px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  position: fixed;
  z-index: 999999;
  box-sizing: border-box;
}
.save-popup-text {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #ffffff;
}
.fade-enter-active,
.fade-leave-active {
  z-index: 999999;
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  z-index: 999999;
  opacity: 0;
}
</style>
