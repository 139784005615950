import getDefaultState from './state';
import mutations from './mutations';
import actions from './actions';

const state = getDefaultState;

export default {
  defaultState: getDefaultState,
  state,
  mutations,
  actions,
};
