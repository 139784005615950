<template>
  <div class="search-category-wrapper">
    <PaiSearchTitle>
      <template #left>
        <span>카테고리</span>
      </template>
      <template #right>
        <PaiSearchArrow v-model="isShow" @click-event="clickEvent" />
      </template>
    </PaiSearchTitle>

    <BorderBasic height="1" background="#dadcde" class="padding-top-20" />

    <PaiSearchCheckboxWrapper v-if="isShow" class="padding-top-32" :checkbox-data="checkboxData" @click-event="clickEvent" />
  </div>
</template>

<script>
import PaiSearchTitle from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchTitle';
import PaiSearchArrow from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchArrow';
import BorderBasic from '@/components/common/border/BorderBasic';
import PaiSearchCheckboxWrapper from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchCheckboxWrapper';
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiSearchCategory',
  components: { PaiSearchCheckboxWrapper, BorderBasic, PaiSearchArrow, PaiSearchTitle },
  data() {
    return {
      isShow: true,
      // checkboxData: [
      // 	{ name: '쇼핑', id: '"SHOPPING"', id2: 'SHOPPING', filter: true },
      // 	{ name: '뷰티', id: '"BEAUTY"', id2: 'BEAUTY', filter: true },
      // 	{ name: '생활', id: '"LIFE"', id2: 'LIFE', filter: true },
      // 	{ name: 'IT', id: '"IT"', id2: 'IT', filter: true },
      // 	{ name: '교육', id: '"EDU"', id2: 'EDU', filter: true },
      // 	{ name: '식품', id: '"FOOD"', id2: 'FOOD', filter: true },
      // 	{ name: '여행', id: '"TRIP"', id2: 'TRIP', filter: true },
      // 	{ name: '문화', id: '"CULTURE"', id2: 'CULTURE', filter: true },
      // 	{ name: '교통', id: '"TRAN"', id2: 'TRAN', filter: true },
      // 	// { name: '외식/식음료', filter: false },
      // 	// { name: '여행/래저/스포츠', filter: false },
      // 	// { name: '자동차/교통', filter: false },
      // 	// { name: '문화/엔터테인먼트', filter: false },
      // 	{ name: '기타', id: '"ETC"', id2: 'ETC', filter: false },
      // ],
      checkboxData: [],
    };
  },
  created() {
    if (!this.$store.state.pai.filter.category) {
      this.checkboxData = this.$store.state.pai.codeCategoryMain ?? [];
      this.checkboxData.unshift({ name: '전체', id: 'all' });
      this.$commons.printLog('카테고리 체크박스> ', this.checkboxData);
      for (let i = 0; i < this.checkboxData.length; i++) {
        this.checkboxData[i].checked = true;
        this.checkboxData[i].id2 = '"' + this.checkboxData[i].id + '"';
      }
    } else {
      this.checkboxData = this.$store.state.pai.filter.category;
    }
    // if (!this.$store.state.pai.filter.category) {
    // 	this.checkboxData.unshift({ name: '전체', id: 'all', filter: true });
    // 	this.checkboxData = this.checkboxData.map(data => {
    // 		return { ...data, ...{ checked: false } };
    // 	});
    //
    // 	this.$commons.printLog(this.$store.state.pai.filter.category);
    // 	const codeDetail1 = this.$store.state.pai.filterList.filter_conditions.code_detail1_list ?? [];
    // 	for (let i = 0; i < codeDetail1.length; i++) {
    // 		for (let j = 0; j < this.checkboxData.length; j++) {
    // 			if (codeDetail1[i] === this.checkboxData[j].id2) {
    // 				this.checkboxData[j].filter = true;
    // 				this.checkboxData[j].checked = true;
    // 			}
    // 		}
    // 	}
    // } else {
    // 	this.checkboxData = this.$store.state.pai.filter.category;
    // }
  },
  beforeUnmount() {
    this.$store.state.pai.filter.category = this.checkboxData;
    this.$commons.printLog('카테고리: ', this.$store.state.pai.filter.category);
  },
  methods: {
    clickEvent(sort, data) {
      switch (sort) {
        case 'arrow':
          this.isShow = data;
          break;
        case 'checkBox':
          this.checkboxData[data.index].checked = data.value;
          if (data.index === 0 && this.checkboxData[0].checked === true) {
            this.checkboxData = this.checkboxData.map(data => {
              return { ...data, ...{ checked: true } };
            });
          }
          if (
            data.index === 0 &&
            this.checkboxData[0].checked === false &&
            this.checkboxData.filter(e => e.checked === false).length === 1
          ) {
            this.checkboxData = this.checkboxData.map(data => {
              return { ...data, ...{ checked: false } };
            });
          }
          if (this.checkboxData[0].checked === true && data.index !== 0 && this.checkboxData[data.index].checked === false) {
            //전체가 체크되어있고 방금 체크클릭한 체크가 전체가 아니고 해당 체크가 false로 바꼈다면
            this.checkboxData[0].checked = false;
          }
          if (
            this.checkboxData[0].checked === false &&
            this.checkboxData[data.index].checked === true &&
            data.index !== 0 &&
            this.checkboxData.filter(e => e.checked === true).length === this.checkboxData.length - 1
          ) {
            //체크된 것이 0번째(전체)가 아니고 변경된 체크가 true이고 모든항목이 체크되어있는 상태라면 전체를 true로 변경
            this.checkboxData[0].checked = true;
          }
          break;
      }
    },
    refresh() {
      for (let i = 0; i < this.checkboxData.length; i++) {
        this.checkboxData[i].checked = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-category-wrapper {
  padding: 64px 40px;
}
</style>
