<template>
  <div class="newTerms-wrap common-padding">
    <div class="newTerms-title">
      <div class="newTerms-title-content" @click="close">{{ getSlideContent.termsTitle }}</div>
    </div>
    <div class="newterms-container">
      <div class="term-text" v-html="termsContent"></div>
    </div>
  </div>
</template>

<script>
// 텍스트 파일 불러오기
// npm install raw-loader --save
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      termsContent: '',
    };
  },
  computed: {
    ...mapGetters(['getSlideContent']),
  },
  created() {
    this.termsContent = this.getSlideContent.termsContent.replace(/\n/g, '<br>');
  },
  methods: {
    close() {
      this.$parent.closeSlide();
    },
  },
};
</script>

<style lang="scss" scoped>
.newTerms-wrap {
  height: inherit;
  margin-bottom: 20px;
  //min-width: -webkit-fill-available;
}
.newTerms-title {
  height: 128px;
  margin-bottom: 38px;
  display: table-cell;
  vertical-align: middle;
}
.newTerms-title-content {
  height: 48px;
  line-height: 48px;
  font-size: 34px;
  letter-spacing: -1.7px;
  color: #343534;
  font-weight: bold;
}
.newTerms-content {
  height: 48px;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -1.4px;
  color: #666666;
}
.newterms-container {
  width: 100%;
  //height: 100%;
  height: calc(100% - 178px);
  overflow-y: scroll;
  overflow-x: hidden;
  //padding-bottom: 128px;
}
.term {
  margin-bottom: 45px;
}
.term_img {
  display: inline-block;
  vertical-align: middle;
}
.term-text {
  height: 100%;
  width: 100%;
  overflow: scroll;
  font-size: 26px;
  letter-spacing: -1.3px;
  color: #343534;
  vertical-align: middle;
  box-sizing: border-box;
  padding-bottom: 40px;
}
.terms-agree-btn {
  background-color: #e42028;
  height: 100px;
  width: 100%;
  font-size: 32px;
  line-height: 100px;
  color: #ffffff;
  text-align: center;
  letter-spacing: -1.6px;
  border-radius: 10px;
  margin-bottom: 40px;
}
</style>
