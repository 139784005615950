<template>
  <SlidingContentWrapper class="margin-top-40">
    <template #content>
      <div class="">
        <div class="refresh-wrapper" @click="clickEvent('filterRefresh')">↻</div>
        <PaiSearchOrder ref="searchOrder" class="common-padding" />
        <PaiSearchCategory ref="searchCategory" />
        <PaiSearchBenefit ref="searchBenefit" class="common-padding padding-top-64" />
        <EmpayPaddingBar :padding-height="161" />
        <EmpayPaddingBar :padding-height="40" />
      </div>
    </template>
    <template #button>
      <CommonRadiusButton width="100%" height="110px" color="#2b2d2f" class="fixed-bottom" @click-event="search">
        <CommonText font-size="32" font-color="#ffffff" spacing="-1.28" line-height="1.71">적용</CommonText>
      </CommonRadiusButton>
    </template>
  </SlidingContentWrapper>
</template>

<script>
import PaiSearchOrder from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchOrder';
import PaiSearchCategory from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchCategory';
import CommonRadiusButton from '@/components/common/button/CommonRadiusButton';
import EmpayPaddingBar from '@/components/common/EmptyPaddingBar';
import PaiSearchBenefit from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchBenefit';
import SlidingContentWrapper from '@/components/common/popup/slidingPopupContent/SlidingContentWrapper';
import CommonText from '@/components/common/text/CommonText';
import $store from '@/store';
import $commons from '@/utils/commons';
import bus from '@/utils/bus';
export default {
  name: 'PaiSearchIndex',
  components: {
    CommonText,
    SlidingContentWrapper,
    PaiSearchBenefit,
    EmpayPaddingBar,
    CommonRadiusButton,
    PaiSearchCategory,
    PaiSearchOrder,
  },
  data() {
    return {};
  },

  beforeCreate() {
    // this.$store.dispatch('getFilter');
  },
  methods: {
    clickEvent(sort) {
      switch (sort) {
        case 'filterRefresh':
          this.$refs.searchOrder.refresh();
          this.$refs.searchCategory.refresh();
          this.$refs.searchBenefit.refresh();
          break;
      }
    },
    async search() {
      // this.$commons.printLog('정렬 :' + this.$refs.searchOrder.$data.order + 1);
      this.$commons.printLog('정렬 :', this.$refs.searchOrder.$data.order + 1);
      this.$commons.printLog(
        '카테고리 :' +
          this.$refs.searchCategory.$data.checkboxData
            .filter(data => {
              return data.checked;
            })
            .map(data => data.name),
      );
      this.$commons.printLog(
        '혜택유형 :' +
          this.$refs.searchBenefit.$data.checkboxData
            .filter(data => {
              return data.checked;
            })
            .map(data => data.name),
      );

      // list_contents?order_type=1&info_type=all
      let params = {
        order_type: 1,
        info_type: 'all',
        category: 'all',
      };

      if (this.$refs.searchOrder.$data.order !== null && this.$refs.searchOrder.$data.order !== undefined) {
        params.order_type = this.$refs.searchOrder.$data.order + 1;
        this.$commons.printLog('order_type', this.$refs.searchOrder.$data.order + 1);
        if (this.$store.state.tvpoint.isLogin) {
          if (params.order_type === 2) {
            // 안읽은 순 - count 조회해서 count 모두 0이면
            const benefit = await $store.dispatch('contentsCount');
            const benefitCount = benefit.counts ?? [];
            const keyword = await $store.dispatch('subscriptionCount');
            const keyWordCount = keyword.counts ?? [];

            if (
              [...benefitCount, ...keyWordCount].filter(e => e.count !== 0).length === 0 ||
              this.$store.state.pai.benefitList.length === 0
            ) {
              this.$store.commit('alertPopup', {
                popupType: 'ErrorPopup',
                popupContent: '읽지 않은 메시지가 없습니다.',
                popupTitle: '',
              });
            }
          } else if (params.order_type === 3) {
            //구독순
            const { data } = await $store.dispatch('subChannelList');
            // await $store.commit('subChannelList', data.subs_channels);
            this.$commons.printLog(data);
            if (data && data.length === 0) {
              this.$store.commit('alertPopup', {
                popupType: 'ErrorPopup',
                popupContent: '구독한 채널이 없습니다.',
                popupTitle: '',
              });
            }
          }
        } else {
          // 비로그인 시 안읽은 순
          // if (this.$refs.searchOrder.$data.order === 2) {
          if (params.order_type === 2) {
            if (
              this.$store.state.pai.benefitList.filter(e => e.read !== false).length === 0 ||
              this.$store.state.pai.benefitList.length === 0
            ) {
              this.$store.commit('alertPopup', {
                popupType: 'ErrorPopup',
                popupContent: '읽지 않은 메시지가 없습니다.',
                popupTitle: '',
              });
            }
          }
        }

        // params.order_type = this.$refs.searchOrder.$data.order;
        this.$commons.printLog('order_type', params.order_type);
      }

      if (this.$refs.searchBenefit.$data.checkboxData && this.$refs.searchBenefit.$data.checkboxData.length !== 0) {
        params.info_type = this.$refs.searchBenefit.$data.checkboxData
          .filter(data => {
            return data.checked;
          })
          .filter(data => data.id !== 'all')
          .map(data => data.id2);

        this.$commons.printLog('params.info_type>', params.info_type);
      }

      if (this.$refs.searchCategory.$data.checkboxData && this.$refs.searchCategory.$data.checkboxData.length !== 0) {
        params.category = this.$refs.searchCategory.$data.checkboxData
          .filter(data => {
            return data.checked;
          })
          .filter(data => data.id !== 'all')
          .map(data => data.id2);

        this.$commons.printLog('@ params : ', params.category);

        // let split = params.category.split(',');
        // params.category = split;

        this.$commons.printLog('@ params : ', params.category);
        this.$commons.printLog('params.order_type>', params.order_type);
      }

      //둘 다 전체 all 선택했을 시
      if (
        this.$refs.searchBenefit.$data.checkboxData
          .filter(data => {
            return data.checked;
          })
          .filter(data => data.id === 'all').length !== 0 &&
        this.$refs.searchCategory.$data.checkboxData
          .filter(data => {
            return data.checked;
          })
          .filter(data => data.id === 'all').length !== 0
      ) {
        params.info_type = 'all';
        params.category = 'all';
      }
      this.$commons.printLog(params);
      let benefitList;
      if (
        params.info_type !== 'all' &&
        params.category !== 'all' &&
        ((params.info_type && params.info_type.length !== 0) || (params.category && params.category.length !== 0))
      ) {
        //필터링조건을 선택했을 시
        const data = await $store.dispatch('paiBenefitListFilter', params);
        this.$commons.printLog('benefitList> ', data);
        benefitList = data.contents ?? [];
        if (benefitList && benefitList.length === 0) {
          //  alertPopup 선택하신 조건에 맞는 혜택이 없습니다. 다시 선택해 주세요 확인 : 선택한 조건 초기화 및 정렬/필터 재호출
          this.$store.commit('alertPopup', {
            popupType: 'ErrorPopup',
            popupContent: '선택하신 조건에 맞는 혜택이 없습니다. 다시 선택해 주세요.',
            popupTitle: '',
          });
          // await $store.dispatch('getFilter');
          await $store.dispatch('getCodeCategoryMain');
          await $store.dispatch('getCodeContentInfo');
          this.resetFilter();
        } else {
          //  alertPopup 추가
          await this.sortBenefitList(benefitList);
        }
      } else {
        //필터링조건 선택 없이 조회했을 시
        params.info_type = 'all';
        params.category = 'all';
        const data = await $store.dispatch('paiBenefitList', params);
        benefitList = data.contents;
        await this.sortBenefitList(benefitList);
      }
      bus.$emit('end:loading');
    },

    async sortBenefitList(benefitList) {
      if (this.$store.state.tvpoint.isLogin) {
        const benefit = await $store.dispatch('contentsCount');
        const benefitCount = benefit.counts ?? [];
        const keyword = await $store.dispatch('subscriptionCount');
        const keyWordCount = keyword.counts ?? [];

        const countList = [...benefitCount, ...keyWordCount];
        this.$commons.printLog('fitMixin benefitCountList', countList);

        if (!$commons.isNull(benefitList)) {
          for (let i = 0; i < benefitList.length; i++) {
            for (let j = 0; j < countList.length; j++) {
              // benefitList[i].count = 11;
              if (countList[j].channel_id && benefitList[i].channel_id === countList[j].channel_id) {
                benefitList[i].count = countList[j].count;
              }
              if (countList[j].keyword && benefitList[i].keyword === countList[j].keyword) {
                benefitList[i].keyword = countList[j].keyword;
              }
            }
          }

          //1. ch_type SUB, null 인 것 저장
          const nullSubList = benefitList.filter(item => item.ch_type !== 'TOP');
          //2. ch_type TOP 인 것 저장
          let topList = benefitList.filter(item => item.ch_type === 'TOP');

          if (!$commons.isNull(topList)) {
            for (let i = 0; i < topList.length; i++) {
              for (let j = nullSubList.length - 1; j >= 0; j--) {
                if (nullSubList[j].channel_id === topList[i].channel_id) {
                  // this.$set(nullSubList[j], 'channel_id', 'top');
                  nullSubList[j].ch_type = 'sub';
                }
              }
            }
            this.$commons.printLog(nullSubList);
            //3. TOP 리스트 날짜에 따라 정렬
            topList = topList.sort((a, b) => new Date(a.subs_date) - new Date(b.subs_date));
            //4. TOP 리스트 상단 배치 위해 합침
            const benefitList = [...topList, ...nullSubList];
            this.$commons.printLog('benefitList', benefitList);
          }
          $store.commit('benefitList', benefitList);
        }
        this.$commons.printLog('benefitList', benefitList);

        bus.$emit('end:loading');
      } else {
        // 비로그인 시
        if (!localStorage.getItem('benefitList')) {
          for (let i = 0; i < benefitList.length; i++) {
            benefitList[i].read = true;
          }
        } else {
          //local에 저장된 목록이 있을 경우, 로컬에 저장해놓은 데이터와 비교해서 메시지 내용 다르면 새 혜택으로 간주 - (전체읽음 시와 채널소식방 진입 시 benefitList 로컬에 저장)
          const localBenefit = JSON.parse(localStorage.getItem('benefitList'));
          for (let i = 0; i < benefitList.length; i++) {
            if (!benefitList[i].read) {
              benefitList[i].read = true;
            }
            for (let j = localBenefit.length - 1; j >= 0; j--) {
              if (localBenefit[j].channel_id === benefitList[i].channel_id) {
                benefitList[i].read = localBenefit[j].read;
                if (localBenefit[j].id !== benefitList[i].id) {
                  benefitList[i].read = true;
                }
              }
            }
          }
          // local에 저장
          localStorage.setItem('benefitList', JSON.stringify(benefitList));
        }
        this.$commons.printLog(this.$refs.searchOrder.$data.order);
        if (this.$refs.searchOrder.$data.order === 1) {
          //  안읽음순
          //1. 안읽은메시지
          let notReadList = benefitList.filter(item => item.read === true);
          //2. 읽은메시지
          const readList = benefitList.filter(item => item.read !== true);
          benefitList = [...notReadList, ...readList];
        }
        $store.commit('benefitList', benefitList);
      }
      //alert팝업창 떴을 경우엔 true로 열려있고,  그외의 경우엔 false로 닫히도록 수정
      if (this.$store.state.tvpoint.alertPopup.popupType === 'ErrorPopup') {
        this.$store.commit('setSlideUpPopup', { showSlide: true, pages: 'PaiSearch', height: '1050px' });
      } else {
        this.$store.commit('setSlideUpPopup', { showSlide: false, pages: 'PaiSearch', height: '1050px' });
      }
      bus.$emit('end:loading');
    },

    resetFilter() {
      //조건 초기화
      this.$refs.searchOrder.$data.order = 0;
      for (let i = 0; i < this.$refs.searchCategory.$data.checkboxData.length; i++) {
        // if (this.$refs.searchCategory.$data.checkboxData[i].filter === true) {
        // 	this.$refs.searchCategory.$data.checkboxData[i].checked = true;
        // }
        this.$refs.searchCategory.$data.checkboxData[i].checked = true;
      }
      for (let i = 0; i < this.$refs.searchBenefit.$data.checkboxData.length; i++) {
        // if (this.$refs.searchBenefit.$data.checkboxData[i].filter === true) {
        // 	this.$refs.searchBenefit.$data.checkboxData[i].checked = true;
        // }
        this.$refs.searchBenefit.$data.checkboxData[i].checked = true;
      }
      // this.componentKeyOrder += 1;
      // this.componentKeyCategory += 1;
      // this.componentKeyBenefit += 1;
    },
  },
};
</script>

<style scoped lang="scss">
.sliding-wrap {
  margin-top: 40px;
  box-sizing: border-box;
}

.button-wrapper {
  position: fixed;
  bottom: 0;
}
.refresh-wrapper {
  font-size: 36px;
  letter-spacing: -1.44px;
  text-align: center;
  color: #76787a;
  position: absolute;
  width: 58px;
  height: 58px;
  line-height: 1.35;
  background: #f2f3f5;
  border-radius: 8px;
  transform: rotate(90deg);
  margin-top: -1px;
  left: calc(100% - 100px);
}
</style>
