<template>
  <div class="common-padding">
    <CommonText spacing="-1.12" line-height="42" class="dormant__content">
      고객님의 계정은 장기간 미 사용으로 휴면 상태입니다. <br />
      <b>[휴면 해제 동의하기]</b> 선택 시 휴면 해제가 완료됩니다.
    </CommonText>
    <CommonRadiusButton radius="8" color="#2b2d2f" width="640px" height="100px" border="0px #2b2d2f" class="dormant__agree">
      <CommonText font-size="32" font-color="#ffffff" spacing="-1.28" line-height="42">휴면 해제 동의하기</CommonText>
    </CommonRadiusButton>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
import CommonRadiusButton from '@/components/common/button/CommonRadiusButton';
export default {
  name: 'ChannelDormantPopup',
  components: { CommonRadiusButton, CommonText },
};
</script>

<style lang="scss" scoped>
.dormant__content {
  width: 640px;
  height: 130px;
  margin-top: 24px;
  margin-bottom: 56px;
}

.dormant__agree {
  color: #ffffff;
  margin-bottom: 40px;
}
</style>
