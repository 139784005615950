import common from './index';
import user from '@/store/tvpoint/index';
import $commons from '@/utils/commons';
import $utils from '@/utils/tvPointcommon';

const mutations = {
  setMainPage(state, payload) {
    state.mainPage = payload;
  },
  setHeader: (commit, data) => {
    commit.headerMode = data.mode;
    commit.headerText = data.text;
    commit.subText = data.subText;
    commit.path = data.path;
  },
  setHeaderText: (commit, data) => {
    commit.headerText = data;
  },
  setBannerInfo: (commit, data) => {
    commit.bannerInfo = data;
  },
  setSubBannerInfo(commit, data) {
    commit.subBannerInfo = data;
  },
  setConfirmPopup: (commit, data) => {
    commit.confirmPopup = data;
  },
  alertPopup: (commit, data) => {
    commit.alertPopup = data;
  },
  alertPopup2: (commit, alertPopup2) => {
    commit.alertPopup2 = alertPopup2;
  },
  loading: (commit, data) => {
    commit.loading = data;
  },
  showSummary: commit => {
    commit.showSummary = !commit.showSummary;
  },
  slideShow: (commit, payload) => {
    if (payload.slideShow) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'auto';
    }
    commit.slideShow = payload.slideShow;
    commit.slideType = payload.slideType;
    commit.slideContent = payload.slideContent;
  },
  slideShowBtn: (commit, payload) => {
    commit.slideShowBtn = payload.slideShowBtn;
    commit.slideType = payload.slideType;
  },
  telecomCode: (commit, telecomCode) => {
    commit.telecomCode = telecomCode;
  },
  lastScrollTop: (commit, payload) => {
    commit.lastScrollTop = payload.lastScrollTop;
    commit.isUp = payload.isUp;
  },
  terms: (commit, terms) => {
    commit.terms = terms;
  },
  newTerms: (commit, newTerms) => {
    commit.newTerms = newTerms;
  },
  setEventDetail: (commit, eventDetail) => {
    commit.eventDetail = eventDetail;
  },
  setPopupData: (commit, data) => {
    commit.slidePopupData = data;
  },
  setInit: (commit, data) => {
    commit.init = data;
  },
  existingMemberPopup: (commit, existingMemberPopup) => {
    commit.existingMemberPopup = existingMemberPopup;
  },
  nonMemberPopup: (commit, nonMemberPopup) => {
    commit.nonMemberPopup = nonMemberPopup;
  },
  // setIsFirst: (commit, data) => {
  // 	commit.isFirst = data;
  // },
  setIsMarketing: (commit, data) => {
    commit.isMarketing = data;
  },
  // setToken: (commit, data) => {
  // 	commit.token = data;
  // },
  setDeviceId: (commit, data) => {
    commit.deviceId = data;
  },
  setDeviceName: (commit, data) => {
    commit.deviceName = data;
  },
  // savePermission: (commit, data) => {
  // 	commit.permission = data;
  // },
  pushPopupStep(state, step) {
    state.pushPopupStep = step;
  },
  setAppVersion(state, step) {
    state.appVersion = step;
  },
  setAlertPopup(state, data) {
    state.popupType = data;
  },
  setToastPopup(state, data) {
    state.toastPopup = data;
  },
  isHeader(state, data) {
    state.isHeader = data;
  },
  endPopupFlag(state, data) {
    state.endPopupFlag = data;
  },
  startPopupFlag(state, data) {
    state.startPopupFlag = data;
  },
  setSetPwdState(state, data) {
    $commons.printLog('setSetPwdState state!!!!!!!!!!!!!', state);
    $commons.printLog('setSetPwdState data!!!!!!!!!!!!!', data);
    state.setPwdState = data;
  },
  setNoticeList(state, data) {
    state.noticeList = data;
  },
  setMW0100(state, data) {
    state.MW0100 = data;
  },
  setMW0601(state, data) {
    state.MW0601 = data;
  },
  setMW0102(state, data) {
    state.MW0102 = data;
  },
  setMW0205(state, data) {
    state.MW0205 = data;
  },
  setMW0307(state, data) {
    state.MW0307 = data;
  },
  setRedirect(state, data) {
    state.redirect = data;
  },
  setTermsText(state, data) {
    state.termsText = data;
  },
  setSetAuthType(state, data) {
    state.setAuthType = data;
  },
  setNoticeDetail(state, data) {
    state.noticeDetail = data;
  },
  setAutoLoginYn(state, data) {
    state.autoLoginYn = data;
  },
  setQnaTypeList(state, data) {
    state.qnaTypeList = data;
  },
  setIsInit(state, data) {
    state.isInit = data;
  },
  setUserInfo(state, data) {
    state.userInfo = data;
  },
  setTvInfo(state, data) {
    state.tvInfo = data;
  },
  setHistoryTab(state, data) {
    state.historyTab = data;
  },
  setIsBack(state, data) {
    state.isBack = data;
  },
  setNoticeId(state, data) {
    state.noticeId = data;
  },
  setTabStatus(state, data) {
    state.tabStatus = data;
  },
  setPrevPage(state, data) {
    state.prevPage = data;
  },
  setHeaderColor(state, data) {
    state.headerColor = data;
  },
  setInitialTerms(state, data) {
    state.initialTerms = data;
  },
  setTvLogin(state, data) {
    state.tvLogin = data;
  },
  ssKey(state, data) {
    state.ssKey = data;
  },
  userDetailInfo(state, data) {
    state.userDetailInfo = data;
  },
  setAppCardType(state, data) {
    state.appCardType = data;
  },
  setCardRegType(state, data) {
    state.cardRegType = data;
  },
  setCardInfo(state, data) {
    state.cardInfo = data;
  },
  resetCommonState(state) {
    Object.assign(state, common.defaultState);
  },
  setEventBannerInfo: (commit, data) => {
    commit.eventBannerInfo = data;
  },
  isSaved: (commit, isSaved) => {
    commit.isSaved = isSaved;
  },
  setOrderDetail: (commit, data) => {
    commit.orderDetail = data;
  },
  setPartnerShip: (commit, data) => {
    commit.partnerShip = data;
  },
  setCertDetail: (commit, data) => {
    commit.certDetail = data;
  },
  setFamilyDetail: (commit, data) => {
    commit.familyDetail = data;
  },
  setStoreList: (commit, data) => {
    commit.storeList = data;
  },
  setCheckStore: (commit, data) => {
    commit.checkStore = data;
  },
  setExchangeDetail: (commit, data) => {
    commit.exchangeDetail = data;
  },
  setSaleDetail: (commit, data) => {
    commit.saleDetail = data;
  },
  setSaleContent: (commit, data) => {
    commit.saleContent = data;
  },
  setFamilyPointDetail: (commit, data) => {
    commit.familyPointDetail = data;
  },
  setChargeDetail: (commit, data) => {
    commit.chargeDetail = data;
  },
  setAutoCharge: (commit, data) => {
    commit.autoChargeDetail = data;
  },
  setMemberYN: (commit, data) => {
    commit.memberYN = data;
  },
  setTmoDetail: (commit, data) => {
    commit.tmoDetail = data;
  },
  setCouponPrice: (commit, data) => {
    commit.couponPrice = data;
  },
  setUseBannerDetail: (commit, data) => {
    commit.useBannerDetail = data;
  },
  setPartnerShipList: (commit, data) => {
    commit.partnerShipList = data;
  },
  setTransferInfo: (commit, data) => {
    commit.transferInfo = data;
  },
  setPointChange: (commit, data) => {
    commit.pointChange = data;
  },
  setPointCouponList: (commit, data) => {
    commit.pointCouponList = data;
  },
  setSkpmallDetail: (commit, data) => {
    commit.skpmallDetail = data;
  },
  setAutoChargeInfo: (commit, data) => {
    commit.autoChargeInfo = data;
  },
  setBuyPoint: (commit, data) => {
    commit.buyPoint = data;
  },
  setBuzzPoint: (commit, data) => {
    commit.buzzPoint = data;
  },
  setVodPoint: (commit, data) => {
    commit.vodPoint = data;
  },
  setPurchaseInfo: (commit, data) => {
    commit.purchaseInfo = data;
  },
  setGiftMessage: (commit, data) => {
    commit.giftMessage = data;
  },
  setTransablePoint: (commit, data) => {
    commit.transablePoint = data;
  },
  setMenuId: (commit, data) => {
    commit.menuId = data;
  },
  setTermsTitleList: (commit, data) => {
    commit.termsTitleList = data;
  },
  setTermId(state, data) {
    state.termId = data;
  },

  setRpSession(state, data) {
    state.rpSession = data;
  },
  setMobileUserYN(state, data) {
    state.mobileUserYN = data;
  },
  setCardList(state, data) {
    state.cardList = data;
  },

  setViewAccountDetail(state, data) {
    state.viewAccountDetail = data;
  },

  setAuthDetail(state, data) {
    state.authDetail = data;
  },
  setChangePhoneDetail(state, data) {
    state.changePhoneDetail = data;
  },

  isLogin: (commit, isLogin) => {
    commit.isLogin = isLogin;
  },

  isAutoLogin: (commit, data) => {
    commit.isAutoLogin = data;
  },
  isAutoCharge: (commit, isAutoCharge) => {
    commit.isAutoCharge = isAutoCharge;
  },
  isAutoTrans: (commit, isAutoTrans) => {
    commit.isAutoTrans = isAutoTrans;
  },
  saveGetUser: (commit, data) => {
    commit.userCel = $utils.makeNumber(data.celNo);
    commit.expirationWillPoint = data.expiraionWillPoint;
    commit.foreignerYn = data.foreignerYn;
    commit.jsessionid = data.jsessionid;
    commit.pointBalance = data.usablePoint;
    commit.userBirthDay = data.userBirthDay;
    commit.userGender = data.userGender;
    commit.genderCode = data.userGender;
    commit.userName = data.userName;
    commit.userNo = data.userNo;
    commit.isAutoCharge = data.autoChargeYN;
    commit.isAutoTrans = data.autoTransferYN;
    // commit.noticeCount = data.nrNoticeCount;
    commit.totalPoint = data.totalPoint;
    commit.platFormList = data.platFormList;

    // 로그인 완료 처리
    commit.isLogin = true;
  },
  saveLoginData: (commit, data) => {
    commit.agreementYN = data.agreementYN;
    // commit.expirationWillPoint = data.expiraionWillPoint;
    commit.genderCode = data.genderCode;
    // commit.pointBalance = data.pointBalance;
    commit.userBirthDate = data.userBirthDate;
    commit.userCel = $utils.makeNumber(data.userCel);
    commit.userName = data.userName;
    commit.userNo = data.userNo;
    commit.jsessionId = data.jsessionid;
    commit.userShaPwd = data.userShaPwd;
    // 로그인 완료 처리
    commit.isLogin = true;

    // 포인트 전환설정 여부
    commit.transferYn = data.transferYn;
  },
  changeUserId: (commit, data) => {
    commit.userCel = data;
  },
  saveLogOutData: (commit, data) => {
    commit.agreementYN = '';
    commit.expiraionWillPoint = '';
    commit.genderCode = '';
    commit.pointBalance = '';
    commit.userBirthDate = '';
    commit.userCel = '';
    commit.userName = '';
    commit.userNo = '';
    commit.jsessionId = '';
    commit.userShaPwd = '';

    // 로그인 완료 처리
    commit.isLogin = false;
  },
  setSessionId(state, data) {
    state.jsessionId = data;
  },
  setSelfAuthData(state, data) {
    state.selfAuthData = data;
  },
  setUserCel(state, data) {
    state.userCel = data;
  },
  setUserBirthDate(state, data) {
    state.userBirthDate = data;
  },
  setUserEmail(state, data) {
    state.userEmail = data;
  },
  setUserPoint(state, data) {
    state.pointBalance = data;
  },
  setPresentUser(state, data) {
    state.presentUser = data;
  },
  setAutoTransDetail(state, data) {
    state.autoTransDetail = data;
  },
  setAutoChargeDetail(state, data) {
    state.autoChargeDetail = data;
  },
  setDirects(state, data) {
    state.directs = data;
  },
  setUserNoticeList(state, data) {
    state.userNoticeList = data;
  },
  setAgrmntList(state, data) {
    state.agrmntList = data;
  },
  setFamilyInfo(state, data) {
    state.familyInfo = data;
  },
  setMemberType(state, data) {
    state.memberType = data;
  },
  resetUserState(state) {
    Object.assign(state, user.defaultState);
  },
  resetPointState(state) {
    Object.assign(state, user.defaultState);
  },
  resetRpState(state) {
    Object.assign(state, user.defaultState);
  },
  setPwdType(state, data) {
    state.pwdType = data;
  },
  setPwdYN(state, data) {
    state.pwdYN = data;
  },
  setUserShaPwd(state, data) {
    state.userShaPwd = data;
  },
  lastShareUrl(state, data) {
    state.lastShareUrl = data;
  },
  setBuzvillToolTip(state, data) {
    state.buzvillToolTip = data;
  },
  setSkipPwd(state, data) {
    state.skipPwd = data;
  },
  setUserRegType(state, data) {
    state.userRegType = data;
  },
  slideContent(state, data) {
    state.slideContent = data;
  },
  isPrivacyTerms(state, data) {
    state.isPrivacyTerms = data;
  },
  setLoginToken(state, data) {
    state.loginToken = data;
  },
};

export default mutations;
