<template>
  <div>
    <img :src="require('@/assets/img/common/img_subscribe.png')" alt="" />
    <div class="popup-wrap">
      <div class="popup-content">
        채널의 구독자만 볼 수 있는 혜택입니다.<br />
        채널을 구독해주세요.
      </div>
      <div class="popup-button-wrap flex-right-align-wrap">
        <button class="popup-button-yes" @click="confirm">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NeedToSubscribePopup',

  methods: {
    confirm() {
      this.$store.commit('alertPopup', {});
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  padding: 16px 40px 43px;
}

.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 63px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
</style>
