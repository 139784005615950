<template>
  <div>
    <PaiBenefitCheckbox ref="terms0" v-model="isChecked" checkbox-name="terms2">
      <template #text>
        <TermsTopTitle :is-arrow="false" @click.prevent="clickAll">{{ groupName }}</TermsTopTitle>
      </template>
    </PaiBenefitCheckbox>

    <!--하위 약관-->
    <div class="padding-top-24">
      <div v-for="(data, idx) in propsData" :key="idx" class="d-flex c-center r-flex-start" :class="idx !== 0 ? 'padding-top-8' : ''">
        <div class="d-flex c-center r-center common-wrapper">
          <div
            :key="`checkbox${checkboxName}${idx}Wrap`"
            class="d-flex base-line c-center r-center"
            :style="`padding:${padding}; height: ${height}; width: ${width}`"
            style="text-align: center"
          >
            <input :id="`checkbox${checkboxName}${idx}Wrap`" v-model="checkedData" type="checkbox" :value="idx" :hidden="true" />
            <!--				<label :for="`checkbox${checkboxName}Wrap`" :style="`height: ${height}; width: ${width}`" @click="clickCheckbox">-->
            <label :for="`checkbox${checkboxName}${idx}Wrap`" :style="`height: ${height}; width: ${width}`">
              <img
                v-if="checkedData.includes(idx)"
                :src="require('@/assets/img/tvpoint/check_on.svg')"
                :style="`height: ${height}; width: ${width}`"
              />
              <img v-else :src="require('@/assets/img/tvpoint/check_off.svg')" :style="`height: ${height}; width: ${width}`" />
              {{ labelName }}</label
            >
          </div>
        </div>
        <TermsSubTitle>{{ data.name }}</TermsSubTitle>
      </div>
    </div>
  </div>
</template>

<script>
import PaiBenefitCheckbox from '@/components/common/popup/slidingPopupContent/pai/join/PaiBenefitCheckbox';
import TermsTopTitle from '@/components/common/popup/slidingPopupContent/pai/join/TermsTopTitle';
import TermsSubTitle from '@/components/common/popup/slidingPopupContent/pai/join/TermsSubTitle';
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiBenefitGroupCheckbox',
  components: { TermsSubTitle, TermsTopTitle, PaiBenefitCheckbox },
  props: {
    // 높이
    height: {
      type: String,
      default: '42px',
    },
    // 너비
    width: {
      type: String,
      default: '42px',
    },
    // 라디오 버튼 이름
    // 곂치지 않은 이름이여야 함
    checkboxName: {
      type: String,
      default: 'common',
    },
    groupName: {
      type: String,
      default: 'common',
    },
    // 체크박스 그룹을 지어 전체 선택이 필요한경우 사용
    checkboxGroup: {
      type: String,
      default: '',
    },
    // 라벨에 텍스트가 들어갈 경우
    // 라벨 이름
    labelName: {
      type: String,
      default: '',
    },
    // 체크박스 선택 값
    value: {
      type: Boolean,
      default: false,
    },
    // 패딩
    padding: {
      type: String,
      default: '0 0 0 0',
    },
    // 체크 된 이미지
    backgroundImageOn: {
      type: String,
      default: 'img/btn_filter_chk_on_f.svg',
    },
    // 미 체크 이미지
    backgroundImageOff: {
      type: String,
      default: 'img/btn_filter_chk_off_f.svg',
    },
  },
  emits: ['clickEvent'],
  data() {
    return {
      propsData: [{ name: '[필수] 이용약관' }, { name: '[필수] 이용약관' }, { name: '[필수] 이용약관' }],
      checkedData: [],
    };
  },
  computed: {
    isChecked() {
      this.$commons.printLog('isChecked');
      const result = this.checkedData.length === this.propsData.length;
      this.$emit('clickEvent', 'checkCheckbox', { name: this.checkboxName, checked: result });
      return result;
    },
  },
  created() {
    this.$commons.printLog('created', this.value);

    this.propsData = this.propsData.map((data, idx) => {
      return { ...data, ...{ idx: idx } };
    });
  },
  methods: {
    clickAll() {
      if (this.checkedData.length === this.propsData.length) {
        this.checkedData = this.checkedData.splice(0);
        this.checkedData = [];
      } else {
        const idx = this.propsData.length;

        this.checkedData = [];

        for (let i = 0; i < idx; i++) {
          this.checkedData.push(i);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: none;
}

label {
  border-radius: 8px;
  box-sizing: border-box;
  line-height: 2.46;
}

input[type='checkbox'] + label {
  display: block;
  //background-image: url('~@/assets/img/rdo_off.svg');
  background-repeat: no-repeat;
  background-position: center;
}

input[type='checkbox']:checked + label {
  //background-image: url('~@/assets/img/btn_filter_chk_off_f.svg');
  background-repeat: no-repeat;
  background-position: center;
}
</style>
