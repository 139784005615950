<template>
  <div
    class="d-flex c-center r-center divbox"
    :style="`border-radius: ${radius}px; background-color: ${color}; width: ${width}; height:${height}; box-shadow: 0 0 0 ${border} inset; margin-top: ${marginTop}px;`"
    @click="clickEvent"
  >
    <slot></slot>
  </div>
</template>

<script>
/**
 * 컴포넌트 설명
 * @param color : color 백그라운드 색상 String ex)color="#2222"
 * @param radius : radius 보더 테두리 Number ex) :radius="16"
 * @param width : width width 설정 String ex)width="126px"
 * @param height : height String ex) height="125px"
 * @param border : border ex) border="0px #fff"
 */
export default {
  name: 'CommonRadiusButton',

  props: {
    color: {
      type: String,
      default: '#fff',
    },
    radius: {
      type: String,
      default: '0',
    },
    width: {
      type: String,
      default: 'auto',
    },
    border: {
      type: String,
      default: '0px #fff',
    },
    height: {
      type: String,
      default: 'auto',
    },
    marginTop: {
      type: Number,
      default: 0,
    },
  },
  emits: ['click-event'],
  methods: {
    clickEvent(e) {
      this.$emit('click-event', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.common-radius-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.divbox {
  overflow: hidden;
}
</style>
