<template>
  <div v-if="isLoading" class="bg">
    <div id="loading"></div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scrollY: 0,
    };
  },

  updated() {
    //홈만 배경 색상이 다른데. body에 컬러를 주기 때문에 홈 아닐때만 스크롤 락 건다. 이유는 홈은 스크롤 될 필요도 없기 때문에
    if (this.$store.state.common.mainIdx !== 0) {
      this.isLoading ? this.lockParentScroll() : this.unLockParentScroll();
    } else if (this.$store.state.common.mainIdx === 0) {
      // initPage 홈에서 빠숍 페이지를 다녀오면 스크롤이 되어 상단 헤더가 안보이는 이슈가 있어 탑으로 보내주도록 함(23.01.25 espark)
      this.unLockParentScroll();
    }
  },

  methods: {
    lockParentScroll() {
      this.scrollY = window.scrollY;
      document.body.style = 'width:100%; position:fixed; top: -' + this.scrollY + 'px';
    },
    //팝업 종료 시 부모창 스크롤 잠금해제
    unLockParentScroll() {
      document.body.style = '';
      if (this.$route.name === 'storeRoom') {
        //채널 소식 방 잴 아래로 진입 시켜줘야 하는데 로딩바가 돌때 body에 position fix 후 값을 찾지 못해서 로딩바 에서 하단 이동 처리
        // window.scrollTo(0, document.body.scrollHeight); //로딩바 없애서 이제 필요 없을듯(22.10.20 espark)
      } else if (
        //서비스이용안내에서 버튼클릭 후 다른 페이지 진입시 중간부터 화면이 보여지는 것을 막음
        this.$route.name === 'tvpointUseDetail' ||
        this.$route.name === 'tvpayUseDetail' ||
        this.$route.name === 'hiddenAsset' ||
        this.$route.name === 'tvpayManagement' ||
        this.$route.name === 'EventDetailView' ||
        this.$route.name === 'VodEventDetailView' ||
        this.$route.name === 'initPage' ||
        this.$route.name === 'storeInfo'
      ) {
        window.scrollTo(0, window.scrollY);
      } else {
        window.scrollTo(0, this.scrollY);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.2);
  display: table;
}

#loading {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 96px;
  height: 96px;
  margin-left: -50px;
  border: 10px solid #f2f3f5;
  border-radius: 50%;
  border-top-color: #2b2d2f;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  z-index: 99999;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>
