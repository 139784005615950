<template>
  <div id="passWrap" class="password-wrap common-padding">
    <div v-if="!deleteYn" class="lost-wrap common-padding" @click="resetPwd">
      <div class="lost">비밀번호 재설정</div>
    </div>
    <div v-else class="lost-wrap common-padding" @click="deleteAccount">
      <div class="lost">다른방식으로 인증하기</div>
    </div>
    <div class="text-wrap">
      <div class="title">(TV포인트) 모바일 비밀번호 인증</div>
      <!--      <div v-if="isWrong" class="wrong">비밀번호가 일치하지 않습니다.다시입력해주세요.</div>-->
    </div>
    <div class="input-wrap" :style="$store.state.tvpoint.skipPwd === 'Y' ? 'margin-bottom: 23px;' : 'margin-bottom: 94px;'">
      <div class="d-flex com">
        <input id="inputPwd" ref="inputPwd" v-model="inputPwd" type="password" placeholder="비밀번호 입력" @click="test" @focus="openBtn" />
        <div v-show="inputPwd.length > 0" class="erase-btn-wrapper d-flex c-center r-center">
          <img id="cancel" bind="inputPwd" style="margin-right: 25px" src="@/assets/img/tvpoint/ic_cancel.svg" @click="eraseAmount" />
        </div>
      </div>
    </div>
    <div v-if="$store.state.tvpoint.skipPwd === 'Y'" class="d-flex" style="margin-bottom: 28px">
      <div class="check-img">
        <input id="transferSetting" v-model="transferYn" class="checkbox" @click="checkTransferYn" />
        <label for="transferSetting">
          <div class="check-bg" :class="{ 'bg-active': transferYn }">
            <div class="check-btn" :class="{ 'btn-active': transferYn }"></div>
          </div>
        </label>
      </div>
      <div class="check-text">다음 전환 시 비밀번호 생략</div>
    </div>
    <div class="btn-wrap">
      <button id="btn" class="btn" :class="validate ? 'focus' : ''" :disabled="!validate" @click="check">확인</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import sha256 from 'sha256';
import bus from '@/utils/bus';
import _ from 'lodash';
export default {
  data() {
    return {
      inputPwd: '',
      validate: false,
      // isWrong: false,
      isLoaded: false,
      transferYn: false,
      deleteYn: false,
    };
  },

  computed: {
    ...mapGetters(['getTermType', 'getUserCel', 'getUserNo']),
  },

  watch: {
    isLoaded(val) {
      this.$forceUpdate();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputPwd.focus();
      this.giveFocus();
    });
    const el = document.getElementById('inputPwd');
    el.focus();
    el.click();
    this.isLoaded = true;
  },
  updated() {
    this.giveFocus();
  },
  created() {
    this.deleteYn =
      this.$store.state.tvpoint.setPwdState === 'deleteUser' && this.$store.state.tvpay.previousPage === 'deleteAccount' ? true : false;
    this.transferYn = this.$store.state.tvpoint.transferYn === 'Y' ? true : false;
    this.el = document.activeElement;
    this.watchCollection(['inputPwd'], this.checkValid);
    document.documentElement.addEventListener(
      'touchmove',
      // e => {
      //   e.preventDefault();
      //   e.stopPropagation();
      // },
      this.stopScroll,
      { passive: false },
    );
  },
  unmounted() {
    document.documentElement.removeEventListener('touchmove', this.stopScroll, { passive: false });
  },
  methods: {
    deleteAccount() {
      this.$store.commit('setPreviousPage', 'deleteAccount');
      this.$store.commit('setSetPwdState', 'deleteUser');
      this.$router.push('/user/SelectAuthPage');
    },
    resetPwd() {
      this.$store.commit('slideShow', { slideShow: false });
      this.$store.commit('setSetPwdState', 'resetPassword');
      this.$router.push('/user/SelectAuthPage');
    },
    test() {
      this.openBtn();
    },
    stopScroll($event) {
      $event.preventDefault();
      $event.stopPropagation();
    },
    openBtn($event) {
      // this.$utils.openBtn($event);
      document.getElementById('passWrap').style.paddingBottom = `95px`;
      setTimeout(() => {
        const top = document.getElementById('passWrap').offsetHeight + 120;
        // document.documentElement.scrollTop = top;
      }, 30);
    },
    closeBtn($event) {
      this.$utils.closeBtn($event);
      document.getElementById('passWrap').style.paddingBottom = `60px`;
    },
    checkValid() {
      if (this.inputPwd.length > 7) {
        this.validate = true;
      } else {
        this.validate = false;
      }
    },
    check() {
      if (this.transferYn) {
        this.$utils.sequenceEx([this.checkPwd, this.setTransferYn]);
      } else {
        const _vue = this;
        this.$utils.sequenceEx([
          this.checkPwd,
          function () {
            if (_vue.$store.state.tvpoint.setPwdState === 'deleteUser' && _vue.$store.state.tvpay.previousPage === 'deleteAccount') {
              _vue.deleteFitUser();
            }
          },
        ]);
      }
    },
    checkPwd() {
      const _vue = this;
      const deferred = this.$utils.defer();

      const pwd = sha256(this.inputPwd);
      const data = this.$store.dispatch('checkUserPwd', { termType: this.getTermType, deviceId: this.getUserCel, userPwd: pwd });
      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          _vue.$store.commit('setPopupData', { checkPwd: true });
          _vue.$store.commit('setUserShaPwd', pwd);
          _vue.$store.commit('slideShow', { slideShow: false });
          deferred.resolve();
        } else {
          // _vue.$utils.alertPopup(_vue, data.resultMessage);
          this.inputPwd = '';
          deferred.reject([_vue, data]);
          // this.isWrong = true;
        }
      });
      return deferred.promise;
    },
    eraseAmount($event) {
      const bind = $event.target.getAttribute('bind');
      this[bind] = '';
      this.$refs[bind].focus();
    },
    giveFocus() {
      document.getElementById('inputPwd').focus();
      document.getElementById('inputPwd').click();
    },
    checkTransferYn() {
      this.$utils.printLog('checkTransferYn');
      this.transferYn = !this.transferYn;
      // this.setTransferYn();
    },
    setTransferYn() {
      const _vue = this;
      const deferred = this.$utils.defer();

      const params = {
        transferYn: this.transferYn === true ? 'Y' : 'N',
        userNo: _vue.$store.getters.getUserNo,
      };

      const data = _vue.$store.dispatch('setTransferYn', params);

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('setTransferYn success');

          this.transferYn = data.transferYn === 'Y' ? true : false;
          this.$store.state.tvpoint.transferYn = data.transferYn;

          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      bus.$emit('end:loading');
      return deferred.promise;
    },
    deleteFitUser: _.debounce(
      async function () {
        this.$utils.printLog('deleteFitUser');
        const deferred = this.$utils.defer();
        const data = this.$store.dispatch('deleteFitUser', {
          deviceType: this.$store.state.common.deviceType,
        });
        data.then(data => {
          this.$utils.printLog('deleteFitUser resultCode ', data.resultCode);
          this.$utils.printLog('deleteFitUser resultMessage ', data.resultMessage);

          this.$store.commit('setSetPwdState', '');
          this.$store.commit('setPreviousPage', '');

          if (parseInt(data.resultCode) === 0) {
            this.$utils.printLog('회원탈퇴 성공');
            this.$bridge.setStringValue('userPhone', '');
            this.$bridge.setStringValue('userId', '');
            this.$bridge.setStringValue('session', '');
            this.$bridge.setStringValue('reqDate', '');
            this.$bridge.setStringValue('access_token', '');
            this.$bridge.setStringValue('renewal_token', '');
            this.$bridge.setStringValue('loginToken', '');
            this.$bridge.setStringValue('autoLoginYn', 'N');
            // 스토어 정보 날리기
            this.$store.commit('resetPointState');
            this.$store.commit('resetRpState');
            this.$store.commit('resetUserState');
            this.$store.commit('resetTvpayState');
            this.$store.commit('resetPaiState');

            this.$store.commit('alertPopup', { popupType: 'DeleteUserPopup' });
            deferred.resolve();
          } else {
            this.$utils.printLog('회원탈퇴 실패');
            this.$utils.errorMessage(this, data);
            deferred.reject([this, data]);
          }
        });
      },
      300,
      false,
    ),
  },
};
</script>

<style lang="scss" scoped>
.password-wrap {
  padding-bottom: 75px !important;
  overflow: hidden;
  height: 500px;
  box-sizing: border-box;
}

.lost-wrap {
  position: absolute;
  top: -55px;
  left: 0;
}

.lost {
  font-size: 28px;
  line-height: 33px;
  letter-spacing: -1.4px;
  color: #ffffff;
  text-decoration: underline;
  z-index: 2000;
}

.text-wrap {
  height: 84px;
  text-align: center;
  margin-bottom: 44px;
}

.title {
  font-size: 34px;
  height: 40px;
  line-height: 40px;
  letter-spacing: -1.7px;
  font-weight: 500;
  color: #2b2d2f;
  padding-top: 44px;
}

.wrong {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: -1.56px;
  color: #e42048;
}

.input-wrap {
  //margin-bottom: 23px;
}

input {
  height: 110px;
  background-color: #f3f5f7;
  border-radius: 8px;
  font-size: 40px;
  letter-spacing: -1.6px;
  color: #2b2d2f;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.com {
  background-color: #f3f5f7;
  border-radius: 8px;
}

input::placeholder {
  color: #a8aaac;
  font-size: 32px;
}

.btn-wrap {
  width: 100%;
  text-align: center;
}

.btn {
  width: 200px;
  height: 94px;
  background-color: #f3f5f7;
  border-radius: 80px;
  color: #a8aaac;
  text-align: center;
  font-size: 34px;
  letter-spacing: -1.36px;
  font-weight: 500;
}

.focus {
  background-color: #2b2d2f;
  color: #ffffff;
}

.checkbox {
  display: none;
}

.check-bg {
  width: 78px;
  height: 43px;
  background-color: #dedfe1;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.check-btn {
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border-radius: 80px;
  margin-left: 4px;
  margin-right: 4px;
}
.bg-active {
  background-color: #2b2d2f;
}
.btn-active {
  margin-left: 2px;
  transform: translateX(100%);
}
.check-text {
  font-size: 26px;
  letter-spacing: -0.52px;
  color: #8f9193;
  align-items: center;
  display: flex;
}
.check-img {
  margin-right: 20px;
}
</style>
