const tvmobile = [
  {
    path: '/tvmobile/inquireInfo',
    name: 'tvMobileInquireInfo',
    component: () => import('@/views/tvmobile/inquireInfo'),
  },
  {
    path: '/point/family',
    name: 'tvMobileFamily',
    component: () => import('@/views/tvpoint/family'),
  },
  {
    path: '/point/exchange',
    name: 'tvMobileExchange',
    component: () => import('@/views/tvpoint/exchange'),
  },
  {
    path: '/buzzvil',
    natm: 'tvMobileBuzzvil',
    component: () => import('@/views/tvmobile/buzzvil'),
  },
  {
    path: '/point/purchase',
    name: 'tvMobilePurchase',
    component: () => import('@/views/tvpoint/purchase'),
  },
  {
    path: '/point/pointGift',
    name: 'tvMobilePointGift',
    component: () => import('@/views/tvpoint/pointGift'),
  },
  {
    path: '/point/gift/chargecoupon',
    name: 'tvMobileChargeCoupon',
    component: () => import('@/views/tvpoint/gift/ChargeCoupon.vue'),
  },
  {
    path: '/point/history',
    name: 'tvMobileHistory',
    component: () => import('@/views/tvpoint/history'),
  },
];

export default tvmobile;
