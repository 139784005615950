<template>
  <div class="terms-wrap common-padding">
    <div class="terms-title">서비스 이용을 위해<br />아래 약관을 확인해 주세요!</div>
    <div class="terms-container">
      <div v-for="(list, idx) in getTermsTitleList" :id="'termsCheck' + idx" :key="list.title" class="term">
        <span class="term-text" :for="'termsCheck' + idx" @click="showCheck(list)">[필수] {{ list.title }}</span>
      </div>
    </div>
    <button class="terms-agree-btn" @click="appCardLogin">전체동의 후 계속 진행하기</button>
    <object
      v-if="isShow"
      id="appCard"
      :data="appUrl"
      type="text/html"
      style="z-index: 9998; position: fixed; bottom: 0px; left: 0px; width: 100%; height: 100%"
    ></object>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
export default {
  name: 'AppCardTermsSlideComponent',

  data() {
    return {
      isShow: false,
      nativeFocus: true,
      transNo: '',
      appUrl: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCardInfo',
      'getDeviceName',
      'getTermType',
      'getTermId',
      'getDeviceType',
      'isLogin',
      'getCardList',
      'getMobileUserYN',
      'getTermsTitleList',
      'getRpSession',
    ]),
    cardName() {
      return this.getCardInfo.cardName;
    },
    terms() {
      const term = [
        {
          id: 'card_service',
          name: `[필수] ${this.cardName} TV페이 서비스 이용약관`,
          img_on: require('@/assets/img/tvpoint/check_on.svg'),
          img_off: require('@/assets/img/tvpoint/check_off.svg'),
        },
        {
          id: 'term_3_tvpay_personal_agreement',
          name: '[필수] 개인정보 제3자 제공에 대한 동의 (카드사)',
          img_on: require('@/assets/img/tvpoint/check_on.svg'),
          img_off: require('@/assets/img/tvpoint/check_off.svg'),
        },
        {
          id: 'telecom_term',
          name: '[필수] 개인정보 수집, 이용에 대한 동의 (카드사)',
          img_on: require('@/assets/img/tvpoint/check_on.svg'),
          img_off: require('@/assets/img/tvpoint/check_off.svg'),
        },
      ];
      return term;
    },
  },
  watch: {
    appUrl(newVal) {
      if (newVal != null) {
        this.appLogin();
      }
    },
  },
  created() {},
  methods: {
    showCheck(type) {
      window.addEventListener('message', this.handleMessage);
      // 이전버튼을 컨트롤 하기위해 히스토리를 추가
      window.history.pushState('forward', null, '');
      // history back 이벤트 핸들러 등록
      window.addEventListener('popstate', this.backBtnHandler);

      let termsTitle = '';
      let termsContent = '';

      const _vue = this;
      _vue.$utils.printLog('getOrderTerms');
      const params = {
        tvpaySubUrl: `/api/getCardTerms.mv;jsessionid=${_vue.getRpSession}`,
        methodType: type.methodId,
        userType: type.userType,
        termType: type.termType,
      };
      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('getCardTerms resultCode', data.resultCode);
        _vue.$utils.printLog('getCardTerms data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('getCardTerms resultData', data.resultData);
          termsTitle = data.resultData.termsTitle;
          termsContent = data.resultData.termsContents;
          this.$store.commit('slideShow', {
            slideShow: true,
            slideType: 'AppCardTermsComponent',
            slideContent: { termsTitle, termsContent },
          });
          bus.$emit('end:loading');
        } else {
          _vue.$utils.errorMessage(_vue, data);
        }
      });
      // this.$store.commit("slideShow", { slideShow: true, slideType: "BasicTermsComponent", slideContent: { termsTitle, termsContent } });
    },
    // 팝업 호출 시 넣어놓은 history가 삭제 되면서 이벤트 발생 및 팝업종료 처리
    backBtnHandler() {
      window.removeEventListener('message', this.handleMessage);
      // history back 이벤트 핸들러 삭제
      window.removeEventListener('popstate', this.backBtnHandler);
    },
    // 앱카드 인증 토큰 발급
    appCardLogin() {
      this.$store.commit('setPopupData', { appCardAuth: true });
      this.$store.commit('slideShow', { slideShow: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-wrap {
  height: 100%;
}
.terms-title {
  height: 84px;
  font-size: 34px;
  line-height: 50px;
  letter-spacing: -1.7px;
  color: #343534;
  font-weight: bold;
  margin-top: 44px;
  margin-bottom: 66px;
}
.terms.container {
  height: 100%;
  overflow: scroll;
}
.term {
  margin-bottom: 28px;
}
.term-text {
  height: 27px;
  line-height: 27px;
  font-size: 26px;
  letter-spacing: -1.3px;
  color: #343534;
  vertical-align: middle;
  border-bottom: 1px solid #343534;
}
.terms-agree-btn {
  background-color: #2b2d2f;
  border-radius: 8px;
  height: 100px;
  width: 100%;
  font-size: 32px;
  line-height: 100px;
  color: #ffffff;
  text-align: center;
  letter-spacing: -1.6px;
  margin-top: 18px;
  margin-bottom: 40px;
}
</style>
