<template>
  <div class="d-flex c-center r-space-between" @click="clickEvent">
    <div class="d-flex c-center r-space-between">
      <img :src="url" :style="imgSize" alt="" />
      <div style="padding-left: 24px">
        <slot name="title"></slot>
        <slot name="content"></slot>
      </div>
    </div>

    <img v-if="toggleArrow" :src="require('@/assets/img/tvpoint/img_arr_right2.svg')" alt="" />
  </div>
</template>

<script>
/**
 * 컴포넌트 설명
 * @see @/assets/explain/ImageText.png
 * @param url : 이미지 왼쪽에 보여질 이미지 url props
 * @param imgSize : 이미지 왼쪽에 이미지 사이즈 props
 * @param toggleArrow 오른쪽 아이콘을 가려야 할때 props로 false로 내려준다 기본은 " > "
 */
export default {
  name: 'ImageText',

  props: {
    toggleArrow: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: '',
    },
    imgSize: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['click-event'],

  methods: {
    clickEvent() {
      this.$emit('click-event');
    },
  },
};
</script>

<style lang="scss" scoped>
.right-arrow {
  width: 24px;
  height: 48px;
}
</style>
