<template>
  <div class="d-flex c-center r-space-between">
    <slot name="title"></slot>
    <div class="column__header__content d-flex">
      <slot name="left"></slot>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
/**
 * 기본 보더 설정
 * @see @/assets/explain/ColumnHeader.png
 * @param title : 로고 왼쪽에 보여질 타이틀 혹은 로고
 * @param left : 왼쪽 오른쪽 영역 두개 중에서 왼쪽
 * @param right : 오른쪽 오른쪽 영역 두개 중에서 오른쪽
 */
export default {};
</script>

<style lang="scss" scoped></style>
