<template>
  <div ref="arrangeList1Wrapper">
    <ArrangeList1 v-for="(item, index) in dataList" v-show="index < seq" :key="index" class="event-list">
      <template #first>
        <img :src="item.component_upload" alt="" :style="imgSize" @click="clickEvent(index)" />
      </template>
    </ArrangeList1>
  </div>
</template>

<script>
import ArrangeList1 from '@/components/common/listType/ArrangeList1';

/**
 * 카테고리 리스트 형 컴포넌트
 * @see @/assets/explain/ArrangeList1Wrapper.png
 * @param imgUrl : 이미지 한줄로 노출 시키는 이미지
 * @param title : 제목 이미지 외 내용물 추가 필요할때 사용하기 위해
 * @param imgSize : imgSize 이미지 사이즈 부모에서 내려주기
 */
export default {
  components: { ArrangeList1 },

  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    seq: {
      type: Number,
      default: 0,
    },
    imgSize: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['clickEvent'],
  methods: {
    clickEvent(index) {
      this.$emit('clickEvent', 'showDetail', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.event-list {
  margin-bottom: 16px;
}
</style>
