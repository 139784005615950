<template>
  <div class="popup-wrap">
    <div class="popup-content" v-html="content"></div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="confirm">확인</button>
      <button class="popup-button-yes" @click="zzim">{{ btnText }}</button>
    </div>
  </div>
</template>

<script>
import { fitMixin } from '@/utils/fitMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'ZzimConfirmPopup',
  mixins: [fitMixin],
  props: {
    title: {
      type: String,
      default: '알림',
      require: false,
    },
    content: {
      type: String,
      require: true,
      default: '',
    },
  },

  computed: {
    ...mapGetters(['getAlertPopup']),

    btnText() {
      return this.getAlertPopup.linkType ? '찜한 상품 목록 보기' : this.getAlertPopup.btnText ? this.getAlertPopup.btnText : '';
    },
  },

  methods: {
    confirm() {
      this.$store.commit('alertPopup', {});
    },
    zzim() {
      this.$commons.printLog(this.getAlertPopup);
      if (this.getAlertPopup.linkType) {
        // this.sendBbarShopLink(this.getAlertPopup.linkType);
        this.$router.push('/bbarshop/zzim');
      }
      if (this.getAlertPopup.path) {
        this.$router.push(this.getAlertPopup.path);
      }
      // this.$store.commit('alertPopup', {});
      // if (this.title === '찜한 상품 목록 보기 ') {
      // 	//11-1.찜(찜한 상품) 으로 이동
      // } else if (this.title === '찜한 스토어 목록 보기') {
      // 	//11-1.찜(찜한 스토어) 으로 이동
      // }
      this.$store.commit('alertPopup', {});
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px 40px 42px 40px;
}
</style>
