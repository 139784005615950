<template>
  <!--  TODO - 홈 리스트 타입별로 데이터 어떻게 내려오는지 확인 하고 수정 해야 할듯.-->
  <div class="homeList d-flex grid">
    <ImageText
      v-for="(item, idx) in dataList"
      :key="idx"
      :url="item.component_upload"
      :img-size="imgSize"
      :toggle-arrow="false"
      class="homeList__container"
      @click-event="clickEvent(idx)"
    >
      <template #title>
        <CommonText v-if="item.component_name" font-color="#e6e8ea" spacing="-1.28">{{ item.component_name }}</CommonText>
      </template>

      <template #content>
        <CommonText
          v-if="item.benefit && item.type === 'pay'"
          font-size="32"
          font-color="#f2f3f5"
          font-weight="500"
          spacing="-1.28"
          :class="'title'"
        >
          {{ $filters.formatComma(item.benefit) }}원
        </CommonText>
        <CommonText v-else-if="item.benefit" font-size="32" font-color="#f2f3f5" font-weight="500" spacing="-1.28" :class="'title'">
          {{ $filters.formatComma(item.benefit) }}P
        </CommonText>
        <CommonText
          v-else
          font-size="30"
          font-color="#fff"
          spacing="-1.2"
          :class="'title'"
          class="ellipse-row-2"
          style="word-break: keep-all; width: 400px"
        >
          {{ item.contents }}
        </CommonText>
      </template>
    </ImageText>
  </div>
</template>

<script>
import ImageText from '@/components/common/text/ImageText';
import CommonText from '@/components/common/text/CommonText';
import { fitMixin } from '@/utils/fitMixin';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'HomeShowList',
  components: { CommonText, ImageText },

  mixins: [fitMixin],

  data() {
    return {
      dataList: this.$store.state.fit.componentBenefitList.benefit_components,
      imgSize: {
        width: '104px',
        height: '104px',
        borderRadius: '100%',
        objectFit: 'cover',
        background: '',
      },
    };
  },

  computed: {
    ...mapGetters(['isLogin']),
  },

  created() {
    if (this.isLogin) {
      let tvpoint;
      let tvpay;

      const tvpointNull = this.$commons.isNull(this.$store.state.tvpoint.pointBalance);
      // const tvpayNull = this.$commons.isNull(this.$store.state.tvpay.payHistoryResult.paymentList);

      if (!tvpointNull) {
        const id = this.$store.state.fit.pageDepth1.find(data => data.title === 'TV 포인트').id;

        tvpoint = {
          component_name: 'TV포인트',
          benefit: this.$store.state.tvpoint.pointBalance,
          component_upload: require('@/assets/img/fit/img_tvpoint_profile.svg'),
          link: {
            id: 1,
            name: '내부 페이지',
            reserved: null,
            type: 2,
            value: id,
          },
          ['use_icon?']: false,
        };
      }

      // payment 쪽 해결 되면 아래 주석 풀면 됨.
      // if (!tvpayNull) {
      // 	const store = this.$store.state.tvpay.payHistoryResult.paymentList[0];
      // 	const id = this.$store.state.fit.pageDepth2.find(data => data.uri === '/tvpay/paymentHistory').id;
      //
      // 	tvpay = {
      // 		component_name: `${store.cardName}로 결제하셨습니다.`,
      // 		benefit: store.payAmount,
      // 		component_upload: {
      // 			url: require('@/assets/img/fit/img_' + store.cardCode + '_2_f.svg'),
      // 		},
      // 		link: {
      // 			id: 1,
      // 			name: '내부 페이지',
      // 			reserved: null,
      // 			type: 2,
      // 			value: id,
      // 		},
      // 		['use_icon?']: false,
      // 		type: 'pay',
      // 	};
      // }

      if (!tvpointNull) {
        const random = _.sampleSize(this.$store.state.fit.componentBenefitList.benefit_components, 5);
        this.dataList = _.shuffle([...random, { ...tvpoint }]);
      }

      // payment 쪽 해결 되면 위 코드 삭제하고 아래 주석 풀면 됨.
      // if (!tvpointNull && tvpayNull) {
      // 	const random = _.sampleSize(this.$store.state.fit.componentBenefitList.benefit_components, 5);
      // 	this.dataList = _.shuffle([...random, { ...tvpoint }]);
      // } else if (tvpointNull && !tvpayNull) {
      // 	const random = _.sampleSize(this.$store.state.fit.componentBenefitList.benefit_components, 5);
      // 	this.dataList = _.shuffle([...random, { ...tvpay }]);
      // } else {
      // 	const random = _.sampleSize(this.$store.state.fit.componentBenefitList.benefit_components, 5);
      // 	this.dataList = _.shuffle([...random, { ...tvpoint }, { ...tvpay }]);
      // }
    } else {
      this.dataList = this.$store.state.fit.componentBenefitList.benefit_components;
    }
  },

  methods: {
    clickEvent(idx) {
      if (this.dataList[idx].link.value === 'https://opoc.gifticon.com') {
        if (this.isLogin) {
          this.$utils.callSkpAuth(this);
        } else {
          this.$store.commit('alertPopup', { popupType: 'LoginPopup' });
        }
      } else {
        this.sendEventLink(this.dataList[idx].link.value, this.dataList[idx].link.type);
      }
      //클릭시 팝업 닫아주기
      this.$store.commit('setComponentPopup', {});
    },
  },
};
</script>

<style lang="scss" scoped>
.homeList {
  row-gap: 16px;

  #{&}__container {
    padding: 24px 40px 24px 24px;
    //opacity: 0.08;
    border-radius: 24px;
    background-color: #37393f;
  }
}

.title {
  margin-top: 6px;
  font-family: Pretendard;
}
</style>
