<template>
  <div>
    <div style="margin: 0 40px">
      <div class="title">잠깐, 구매 전 확인해 주세요!</div>
      <div class="info info-bold">
        주문 이후 TV포인트를 적립 받으시려면
        <br />
        로그인이 필요합니다.
      </div>
    </div>

    <div class="d-flex c-center common-padding" :style="userType === 1 ? 'margin: 32px 0 40px 0' : 'margin: 56px 0 40px 0'">
      <input id="check" v-model="ongoingChecked" type="checkbox" />
      <label for="check"></label>
      <CommonText font-size="28" font-color="#76787a" spacing="-1.12">일주일간 보지 않기</CommonText>
      <CommonText font-size="20" font-color="#dadcde" spacing="-1.12" style="margin: 0 16px">|</CommonText>
      <CommonText font-size="28" font-color="#76787a" spacing="-1.12" @click="clickEvent('close')">닫기</CommonText>
    </div>
    <div>
      <div class="btn-two-box d-flex grid c-center">
        <CommonText class="btn-black" font-size="30" font-color="#ffffff" spacing="-1.6" line-height="88" @click="clickEvent('login')"
          >로그인하기</CommonText
        >
        <CommonText class="btn-white" font-size="30" font-color="#2b2d2f" spacing="-1.6" line-height="88" @click="clickEvent('continue')"
          >쇼핑 계속하기</CommonText
        >
      </div>
    </div>
  </div>
</template>

<script>
import { fitMixin } from '@/utils/fitMixin';
import { mapGetters } from 'vuex';
import CommonText from '@/components/common/text/CommonText';
export default {
  components: { CommonText },

  mixins: [fitMixin],

  emits: ['closePopup'],

  data() {
    return {
      ongoingChecked: false,
      dataList2: '',
    };
  },

  computed: {
    ...mapGetters(['isLogin', 'slideShow', 'getSlideContent']),
    userType() {
      return this.isLogin ? 1 : 2;
    },
    dataList() {
      return !this.$commons.isNull(this.$store.state.bbarshop.productData) ? this.$store.state.bbarshop.productData : [];
    },
  },

  created() {
    if (this.getSlideContent && this.getSlideContent.value) {
      this.dataList2 = this.getSlideContent.value;
    }
  },

  methods: {
    closePopup() {
      if (this.ongoingChecked === true) {
        this.$utils.setCookie('shopPointPopup', 'N', 7);
      }
      this.$emit('closePopup');
      this.$store.commit('slideShow', { slideShow: false });
    },
    clickEvent(sort, idx) {
      switch (sort) {
        case 'close':
          this.$commons.printLog('click', sort);
          this.closePopup();
          break;
        case 'continue':
          this.$commons.printLog('click', sort);
          //value, linkType
          // this.sendBbarShopLink(this.dataList);
          if (this.$store.state.common.toPath === '/bbarshop/productDetail') {
            this.$store.commit('setShopContinue', true);
            this.sendBbarShopLink(this.dataList);
            this.$store.commit('setShopContinue', false);
          } else {
            this.$store.commit('setShopContinue', true);
            this.sendBbarShopLink(this.dataList2);
            this.$store.commit('setShopContinue', false);
          }
          this.closePopup();
          break;
        case 'login':
          this.$commons.printLog('click', sort);
          this.$router.push('/user/login');
          this.closePopup();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 43px 0 67px;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: -2.1px;
  text-align: left;
  color: #2b2d2f;
}
.info {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
.info-bold {
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: -1.2px;
  text-align: left;
  color: #2b2d2f;
}
.info-bold-in {
  display: inline;
  font-size: 34px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: -1.36px;
  text-align: left;
  color: #2b2d2f;
}

.info-light {
  font-size: 24px;
  line-height: 1.67;
  letter-spacing: -0.72px;
  text-align: left;
  color: #8f9193;
}
input[type='checkbox'] {
  display: none;
  box-sizing: border-box;
}

input[type='checkbox'] + label {
  margin-top: 3px;
  margin-right: 10px;
  width: 42px;
  height: 42px;
  background-image: url('~@/assets/img/common/check_circle_off.svg');
  background-repeat: no-repeat;
}

input[type='checkbox']:checked + label {
  margin-top: 3px;
  margin-right: 10px;
  width: 42px;
  height: 42px;
  background-image: url('~@/assets/img/common/check_circle_on.svg');
  background-repeat: no-repeat;
}
.btn-one-box {
  width: 100%;
  height: 110px;
  background-color: #2b2d2f;
  text-align: center;
}
.btn-two-box {
  text-align: center;
}

.btn-black {
  width: 640px;
  // margin: 40px 40px 24px;
  // padding: 29px 99px;
  border-radius: 8px;
  background-color: #2b2d2f;
}
.btn-white {
  width: 640px;
  height: 88px;
  padding: 0 0 40px;
  background-color: #ffffff;
}
</style>
