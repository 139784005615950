<template>
  <div>
    <CommonRadiusButton v-for="(radio, idx) in radioData" :key="idx" height="110px" class="common-radius-button r-flex-start">
      <div class="d-flex c-center r-center">
        <TvPaySettingRadio v-model="radioIdx" :index="idx" radio-name="paiBenefit" @click-event="clickEvent" />
        <CommonText font-size="30" font-color="#22" spacing="-1.2" class="padding-left-20">{{ radio.name }}</CommonText>
      </div>
    </CommonRadiusButton>
  </div>
</template>

<script>
import CommonRadiusButton from '@/components/common/button/CommonRadiusButton';
import TvPaySettingRadio from '@/components/common/popup/slidingPopupContent/tvpay/setting/importData/TvPaySettingRadio';
import CommonText from '@/components/common/text/CommonText';
import { mapGetters } from 'vuex';
import $store from '@/store';
import bus from '@/utils/bus';

import { fitMixin } from '@/utils/fitMixin';
import $commons from '@/utils/commons';

/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'TvPaySettingRadioWrapper',
  components: { CommonText, TvPaySettingRadio, CommonRadiusButton },
  mixins: [fitMixin],
  data() {
    return {
      radioIdx: -1,
      radioData: [],
      // radioData: [{ name: 'TV페이 비밀번호 변경' }, { name: 'TV페이 서비스란?' }],
    };
  },
  computed: {
    ...mapGetters(['getRpSession', 'isLogin']),
  },
  created() {
    /* UI분기처리 - TV페이 비밀번호 설정 상태인 경우|  TV페이 비밀번호 설정 상태인 경우 | TV페이 카드 없는 경우 */
    if (this.$store.state.tvpay.cardListCount !== 0) {
      /* 등록카드  有일 경우 -> tv페이 비번 설정 유,무 분기 */
      if (this.$store.state.tvpay.cardResult.pinYn === 'Y') {
        this.radioData = [{ name: 'TV페이 비밀번호 변경' }, { name: 'TV페이 서비스란?' }]; //비번 설정O && TV페이 카드 있는 경우
      } else if (this.$store.state.tvpay.cardResult.pinYn === 'N') {
        this.radioData = [{ name: 'TV페이 비밀번호 설정' }, { name: 'TV페이 서비스란?' }]; //비번 설정X && TV페이 카드 있는 경우
      }
    } else if (this.$store.state.tvpay.cardListCount === 0) {
      /* 등록카드 無 */
      this.radioData = [{ name: 'TV페이 서비스란?' }];
    }
  },
  methods: {
    clickEvent(sort, data) {
      switch (sort) {
        case 'radio':
          this.radioIdx = data;
          if (data === 0) {
            if (this.$store.state.tvpay.cardListCount !== 0) {
              if (this.$store.state.tvpay.cardResult.listCount === 0) this.$store.dispatch('setTVCardList');
              /* 등록카드  有일 경우 -> tv페이 비번 설정 유무로 분기 */
              if (this.$store.state.tvpay.cardResult.pinYn === 'Y') {
                /* TV페이 비밀번호 설정 상태인 경우: TV페이 비밀번호 변경 페이지로 이동(-> 비밀번호 잊어버린 경우 인증방식 선택 페이지
                -> 성공 시 TV페이 비밀번호 설정 페이지로 이동) */
                this.$router.push('/tvpoint/purchase/tvpayChangePwd');
              } else if (this.$store.state.tvpay.cardResult.pinYn === 'N') {
                //TV페이 비밀번호 미설정 상태인 경우: 인증방식 선택(인증성공시)->TV페이 비밀번호 설정 페이지로 이동
                const _vue = this;
                this.$utils.sequenceEx([
                  this.viewAccount,
                  function () {
                    _vue.$store.commit('setSetPwdState', 'tvpaySetPwd');
                    _vue.$router.push('/user/SelectAuthPage');
                  },
                ]);
              }
            } else {
              //비로그인시 'TV페이 서비스란?' 버튼밖에 없는 경우
              this.$store.commit('setServiceInfoType', 'tvpay');
              this.$router.push('/serviceInfo');
            }
          } else {
            this.$store.commit('setServiceInfoType', 'tvpay');
            this.$router.push('/serviceInfo');
          }
          break;
      }
    },
    viewAccount() {
      const _vue = this;
      const deferred = this.$utils.defer();

      _vue.$utils.printLog('viewAccount');

      const params = {
        tvpaySubUrl: `/cert2/viewAccount.mv;jsessionid=${this.getRpSession}`,
        acntNo: _vue.$store.state.tvpay.cardResult.list[0].acntNo,
        userNo: _vue.$store.state.tvpay.cardResult.list[0].userNo,
        cardCode: _vue.$store.state.tvpay.cardResult.list[0].cardCode,
        termId: _vue.$store.state.tvpay.cardResult.list[0].termId,
        paramTermIdYN: 'Y',
        // isMobileAccount: 'Y', // 카드 등록 시 모바일 계정 등록으로 값 고정.
        // storeId: 'MOBILETP01', // 카드 등록 시 모바일 계정 등록으로 값 고정.
      };

      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('viewAccount resultCode', data.resultCode);
        _vue.$utils.printLog('viewAccount data', data);
        if (parseInt(data.resultCode) === 0) {
          const authDetail = {
            phoneNo: data.resultData.phoneNum,
          };

          _vue.$utils.printLog('viewAccount data.resultData', data.resultData);
          _vue.$store.commit('setAuthDetail', authDetail);
          _vue.$store.commit('setViewAccountDetail', data.resultData);
          deferred.resolve();
        } else {
          _vue.$utils.printLog('viewAccount fail');
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.common-radius-button {
  justify-content: flex-start !important;
}
</style>
