<template>
  <div class="pai-showmore">
    <input :id="`checkbox${name}Wrap`" v-model="isChecked" type="checkbox" :checked="isChecked" />
    <label :for="`checkbox${name}Wrap`"></label>
  </div>
</template>

<script>
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiSearchArrow',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: 'common',
    },
  },
  emits: ['clickEvent'],
  data() {
    return {
      isChecked: true,
    };
  },
  watch: {
    value(newVal) {
      this.isChecked = newVal;
    },
    isChecked(newVal) {
      this.$emit('clickEvent', 'arrow', newVal);
    },
  },
  created() {
    this.isChecked = this.value;
  },
};
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  display: none;
  box-sizing: border-box;
}

input[type='checkbox'] + label {
  display: block;
  width: 32px;
  height: 54px;
  background-image: url('~@/assets/img/common/img_arr_low2.svg');
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 300ms ease-in-out;
}

input[type='checkbox']:checked + label {
  transition: transform 300ms ease-in-out;
  transform: rotate(180deg);
}

.pai-showmore {
  width: 32px;
  height: 48px;
}
</style>
