<template>
  <div class="d-flex c-center base-line r-space-between width-100 padding-left-16">
    <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.4"> <slot></slot></CommonText
    ><img v-if="isArrow" :src="require('@/assets/img/fit/img_arr_right_2.svg')" class="image-wrapper" />
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'TermsTopTitle',
  components: { CommonText },
  props: {
    isArrow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
