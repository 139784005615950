import store from '@/store';
import $commons from '@/utils/commons';
import $store from '@/store';

const common = [
  {
    path: '/event',
    name: 'EventDetailView',
    component: () => import('@/views/event/eventDetailView'),
    async beforeEnter(to, from, next) {
      const data = await store.dispatch('eventDetail', to.query.id);
      store.commit('setEventDetail', data);

      //공유하기 숏링크 만들기 - 현재 stg에서 안되는중 espark
      // const shareUrl = `?redirectPage=/event&id=${to.query.id}`;
      // await $commons.createShortLinkForWeb(shareUrl, data.event_page.event_uploads[0].url, data.event_page.component_name);
      next();

      if (to.query.id) {
        delete $store.state.fit.pageInform.pageInfo.buttonInfo;
        $store.state.fit.pageInform.prevPageInfo = !$commons.isNull($store.state.fit.pageInform.pageInfo)
          ? $store.state.fit.pageInform.pageInfo
          : null;

        $store.state.fit.pageInform.pageInfo = {};
        $store.state.fit.pageInform.pageInfo.page_id = to.query.id;
        $store.state.fit.pageInform.pageInfo.title = data.event_page.component_name;
        $store.state.fit.pageInform.pageInfo.url = null;
        $store.state.fit.pageInform.pageInfo.depth = 0;
        $store.state.fit.pageInform.pageInfo.pageAttribute = { type: 2, event_id: to.query.id };

        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }

        $commons.printLog('이벤트 from.fullPath : ', from.fullPath);
        $commons.printLog('이벤트 pageInfo : ', JSON.stringify($store.state.fit.pageInform.pageInfo));
        $commons.printLog('이벤트 pageInfo.prevPageInfo : ', JSON.stringify($store.state.fit.pageInform.prevPageInfo));

        let alarmId = '';
        if (from.fullPath.indexOf('pushId') > -1) {
          alarmId = from.fullPath.split('pushId=')[1].split('&')[0];
          $store.commit('setSalesPlatform', 'BBARS00001');
          $store.commit('setMallCode', 'PUSH');
          $store.commit('setBannerId', alarmId);
        } else if (to.fullPath.indexOf('pushId') > -1) {
          $store.commit('setSalesPlatform', 'BBARS00001');
          $store.commit('setMallCode', 'PUSH');
          $store.commit('setBannerId', alarmId);
        }

        if (!$commons.isNull($store.state.fit.pageInform.prevPageInfo)) {
          await $store.dispatch('informEvent', $store.state.fit.pageInform);
        }
        $store.state.fit.pageInform.pageInfo.buttonInfo = {
          button_id: data.event_page.link.id,
          button_name: data.event_page.button_title,
        };
      }
    },
  },
  {
    path: '/event/vod',
    name: 'VodEventDetailView',
    component: () => import('@/views/event/eventDetailView'),
    async beforeEnter(to, from, next) {
      const params = { ...to.params.vodDetail, detailId: to.params.id };
      const data = await store.dispatch('fitBasicApiDetail', params);
      store.commit('setVodDetail', data);

      //공유하기 숏링크 만들기
      const shareUrl = `?redirectPage=/event/vod&componentId=${to.params.vodDetail.componentId}&groupId=${to.params.vodDetail.groupId}&groupType=${to.params.vodDetail.groupType}&pageId=${to.params.vodDetail.pageId}&id=${to.params.id}`;
      await $commons.createShortLinkForWeb(shareUrl, data.component_upload, data.title);
      next();
    },
  },
];

export default common;
