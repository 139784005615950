<template>
  <transition name="fade">
    <div v-if="existingMemberPopup" id="layerWrap" class="layer-wrap">
      <div class="pop-layer">
        <div class="pop-container">
          <div class="pop-conts">
            <div class="contents-container">
              <div class="txt_contents">고객님은 기존 회원입니다.<br />로그인 하시겠습니까?</div>
            </div>
            <div class="pwd-text" @click="setPwd">비밀번호가 기억나지 않아요</div>
            <div class="btn_confirm">
              <span style="margin-right: 44px; color: #343534" @click="cancel">취소</span>
              <span style="color: #648cff; font-weight: 500" @click="confirm">확인</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['existingMemberPopup', 'getTermType', 'getSelfAuthData', 'getSetAuthType']),
  },

  watch: {
    existingMemberPopup(val) {
      if (val) {
        window.removeEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      } else {
        window.addEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      }
    },
  },

  methods: {
    cancel() {
      this.$store.commit('existingMemberPopup', false);
    },
    confirm() {
      this.$store.commit('existingMemberPopup', false);
      if (Object.keys(this.$store.getters.getTvInfo).length !== 0) {
        this.$store.commit('setTvLogin', true);

        if (this.$store.getters.getTvInfo.redirectPage !== '/tvmobile/inquireinfo') {
          this.$router.go(-1);
        } else {
          this.$router.push('/user/login');
        }
      } else {
        this.$router.go(-1);
      }
    },
    setPwd() {
      this.$store.commit('existingMemberPopup', false);
      if (this.getSetAuthType === 'changephone') {
        this.$utils.sequenceEx([this.checkUser, this.sendCode]);
      } else if (this.getSetAuthType === 'signup') {
        this.$utils.sequenceEx([this.checkUserExist]);
      }
    },
    checkUserExist() {
      const _vue = this;
      const deferred = this.$utils.defer();
      const data = _vue.$store.dispatch('checkUserExist', {
        termType: _vue.getTermType,
        celNo: _vue.getSelfAuthData.phoneNo,
        userName: _vue.getSelfAuthData.userName,
        birthDate: _vue.getSelfAuthData.birthDate,
        // genderCode: this.$utils.getStoreGenderCode(parseInt(this.getSelfAuthData.socialNum.substr(this.getSelfAuthData.socialNum.length - 1))),
      });

      data.then(data => {
        if (parseInt(data.resultCode) === -2002) {
          deferred.reject([_vue, data]);
        } else if (parseInt(data.resultCode) === 0) {
          this.$utils.sequenceEx([this.checkUser, this.sendCode]);
        } else if (parseInt(data.resultCode) === -2000) {
          // 모바일 회원 x, TV 회원 x
          deferred.reject([_vue, data]);
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    checkUser() {
      const _vue = this;
      const deferred = this.$utils.defer();
      _vue.$utils.printLog('checkUser', _vue.phoneNo);
      _vue.$utils.printLog('checkUser', _vue.$utils.makeInteger(_vue.phoneNo));
      const data = _vue.$store.dispatch('checkUser', {
        termType: _vue.$store.getters.getTermType,
        celNo: _vue.$utils.makeNumber(_vue.getSelfAuthData.phoneNo),
      });

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('checkUser success');
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    sendCode() {
      const _vue = this;
      const deferred = this.$utils.defer();
      const sendData = {
        celNo: _vue.getSelfAuthData.phoneNo,
        termId: _vue.getSelfAuthData.phoneNo,
        birthDate: _vue.getSelfAuthData.birthDate,
        userName: _vue.getSelfAuthData.userName,
        termType: _vue.getTermType,
        isResetPwd: 'Y',
        // userName: this.$refs.userInfo.name,
      };

      const data = _vue.$store.dispatch('sendSelfAuth', sendData);
      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          _vue.$store.commit('setSelfAuthData', {
            checkData: data.checkData,
            telecom: sendData.telecom,
            phoneNo: sendData.celNo,
            userName: _vue.getSelfAuthData.userName,
            birthDate: _vue.getSelfAuthData.birthDate,
          });
          _vue.$store.commit('setSetPwdState', 'resetPassword');
          _vue.$store.commit('setUserCel', _vue.getSelfAuthData.phoneNo);
          _vue.$store.commit('setSetAuthType', 'sendCode');
          _vue.$store.commit('setCommonToastPopup', { content: '인증번호가 발송되었습니다.' });
          _vue.authSent = true;
          _vue.$router.push('/user/UserAuthPage');
          deferred.resolve();
        } else if (parseInt(data.resultCode) === -6000) {
          deferred.reject([_vue, data]);
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.layer-wrap {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  // margin-right: -.25em;
}
.pop-layer {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 140px);
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 12.5px;
}
.contents-container {
  padding: 40px 40px 30px 40px;
}
.txt_contents {
  width: 100%;
  height: 70px;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
.pwd-text {
  height: 26px;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.04px;
  text-align: left;
  color: #666666;
  text-decoration: underline;
  padding: 0px 40px 40px 40px;
}
.btn_confirm {
  float: right;
  width: 150px;
  height: 50px;
  font-size: 30px;
  line-height: 1.7;
  letter-spacing: -1.5px;
  border-radius: 16px;
  background-color: #ffffff;
  margin-bottom: 40px;
  padding-right: 40px;
}
.btn_confirm span {
  display: inline-block;
}
</style>
