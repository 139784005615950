import $store from '@/store';
import $commons from '@/utils/commons';
import { fitMixin } from '@/utils/fitMixin';
import bus from '@/utils/bus';
import $bridge from '@/utils/FTBridgeShared';

const pai = [
  {
    path: '/pai/storedSubscription',
    name: 'storedSubscription',
    component: () => import('@/views/pai/storedSubscription'),
    async beforeEnter(to, from, next) {
      if ($store.state.fit.fitSelectType === 'hide') {
        await $store.dispatch('hiddenChannelList');
      } else {
        const { data } = await $store.dispatch('subChannelList');
        await $store.commit('subChannelList', data.subs_channels);
        next();
      }
    },
  },
  {
    path: '/pai/channelList',
    name: 'paiChannelList',
    component: () => import('@/views/pai/channelList'),
    async beforeEnter(to, from, next) {
      await $store.dispatch('paiChannelList');
      next();
    },
  },
  {
    path: '/pai/SubscribedChannelsNoticeSettings',
    name: 'SubscribedChannelsNoticeSettings',
    component: () => import('@/views/pai/subscribedChannelsNoticeSettings/index.vue'),
    async beforeEnter(to, from, next) {
      if ($store.state.pai.setSubsAlarm !== 'true') {
        const { data } = await $store.dispatch('subChannelList');
        await $store.commit('subChannelList', data.subs_channels);
        $store.state.pai.setSubsAlarm = '';
        next();
      } else {
        bus.$emit('start:loading');
      }
    },
  },
  {
    path: '/pai/SubscribedChannelsKeywordSettings',
    name: 'keyword',
    component: () => import('@/views/pai/keyword'),
  },
  {
    path: '/pai/storeInfo',
    name: 'storeInfo',
    component: () => import('@/views/pai/storeInfo'),
    async beforeEnter(to, from, next) {
      // bus.$emit('start:loading');
      const id = to.query.id;
      await fitMixin.methods.channelSetup(id);
      await $store.dispatch('channelDetailInfo', id);
      await $store.dispatch('channelUseList', id);
      next();
    },
  },
  {
    path: '/pai/storeRoom',
    name: 'storeRoom',
    component: () => import('@/views/pai/storeRoom'),
    async beforeEnter(to, from, next) {
      // bus.$emit('start:loading');
      const id = to.query.id;
      await $store.dispatch('channelDetailInfo', id);
      next();
      // bus.$emit('start:loading');
      fitMixin.methods.channelSetup(id);
      const res = $store.dispatch('storeRoomInfo', id);

      //공유하기로 들어 왔을 경우 체크
      let autoLoginCookie = await $bridge.shared().stringValue('autoLoginYn');

      if (to.query.benefitId) {
        const data = res.contents.filter(data => data.id === to.query.benefitId);
        if (!$commons.isNull(data)) {
          $store.commit('benefitOpenTargetCondition', data.open_target);

          if (data.open_target === 'SUB' && (autoLoginCookie === 'N' || $commons.isNull(autoLoginCookie))) {
            $store.commit('alertPopup', { popupType: 'LoginPopup' });
          }
        } else {
          if (autoLoginCookie === 'N' || $commons.isNull(autoLoginCookie)) {
            $store.commit('alertPopup', { popupType: 'LoginPopup' });
          }
          $store.commit('benefitOpenTargetCondition', 'EMPTY');
        }
      }
      // bus.$emit('end:loading');
      // next();
    },
  },
  {
    path: '/pai/hiddenAsset',
    name: 'hiddenAsset',
    component: () => import('@/views/pai/hiddenAsset'),
    async beforeEnter(to, from, next) {
      // bus.$emit('start:loading');
      next();
      await fitMixin.methods.fetchPoint();
    },
  },
  {
    path: '/pai/channelList',
    name: 'channelList',
    component: () => import('@/views/pai/channelList'),
  },
  {
    path: '/pai/hiddenChannelManagement',
    name: 'hiddenChannelManagement',
    component: () => import('@/views/pai/hiddenChannelManagement'),
    async beforeEnter(to, from, next) {
      await $store.dispatch('hiddenChannelList');
      next();
    },
  },
  {
    path: '/pai/storedBenefits',
    name: 'storedBenefits',
    component: () => import('@/views/pai/storedBenefits'),
    async beforeEnter(to, from, next) {
      await $store.dispatch('storedBenefitList', false);
      next();
    },
  },
  {
    path: '/pai/channelPostSetting',
    name: 'channelPostSetting',
    component: () => import('@/views/pai/channelPostSettings'),
    async beforeEnter(to, from, next) {
      const id = to.query.id;
      await $store.dispatch('channelPostSetting', id);
      next();
    },
  },
];

export default pai;
