<template>
  <div style="width: 42px; height: 42px">
    <input :id="`checkboxWrap${name}${index}`" v-model="isChecked" type="checkbox" :checked="isChecked" />
    <label :for="`checkboxWrap${name}${index}`"></label>
  </div>
</template>

<script>
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiSearchCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: 'common',
    },
  },
  emits: ['clickEvent'],
  data() {
    return {
      isChecked: false,
    };
  },
  watch: {
    value(newVal) {
      this.isChecked = newVal;
    },
    isChecked(newVal) {
      this.$emit('clickEvent', 'checkBox', { index: this.index, value: newVal });
    },
  },
  created() {
    this.isChecked = this.value;
  },
  methods: {
    test($event) {},
  },
};
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
  display: none;
  box-sizing: border-box;
}

input[type='checkbox'] + label {
  display: block;
  width: 42px;
  height: 42px;
  background-image: url('~@/assets/img/common/check_circle_off.svg');
  background-repeat: no-repeat;
  background-position: center;
}

input[type='checkbox']:checked + label {
  background-image: url('~@/assets/img/common/check_circle_on.svg');
  background-repeat: no-repeat;
  background-position: center;
}
</style>
