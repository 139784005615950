<template>
  <div class="main-wrap">
    <transition name="fade">
      <div v-if="showSlide" key="fade" class="dim" @click="clickEvent"></div>
    </transition>

    <transition name="slide-up" mode="out-in">
      <div v-if="showSlide" ref="contentWrap" key="slide" class="content-wrap" :style="`height:${height}`">
        <div v-if="title" ref="title" class="content-title d-flex c-center r-flex-start padding-left-40">
          <CommonText :font-size="fontSize" spacing="-1.28" font-weight="bold">
            {{ title }}
          </CommonText>
        </div>
        <component
          :is="pages"
          :amount="amount"
          :data="data"
          class="component-wrapper"
          @close-popup="clickEvent"
          @remove-card="removeCard"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import PaiSearch from '@/components/common/popup/slidingPopupContent/pai/search';
import PaiSetting from '@/components/common/popup/slidingPopupContent/pai/setting';
import TvPaySetting from '@/components/common/popup/slidingPopupContent/tvpay/setting/index.vue';
import PaiJoin from '@/components/common/popup/slidingPopupContent/pai/join';
import ShoppingPointExchange from '@/components/common/popup/slidingPopupContent/shoppingPoint/exchange';
import PaiTerms from '@/components/common/popup/slidingPopupContent/pai/terms';
import ChannelDormantPopup from '@/components/pai/channelLoginStatus/ChannelDormantPopup';
import CommonText from '@/components/common/text/CommonText';
import Share from '@/components/common/Share';
import ExecutionBanner from '@/components/common/ExecutionBanner';
import FilterPopUp from '@/components/bbarshop/category/FilterPopUp';
import TvpInfoPopup from '@/components/common/popup/TvpInfoPopup';
import ShopPointInfo from '@/components/orderPayment/shopPointInfo';
import OrderOptionSelect from '@/components/orderPayment/orderOptionSelect';
import callSlidePopup from '@/components/orderPayment/callSlidePopup';
import BenefitDetail from '@/components/orderPayment/BenefitDetail';
import TvPayPopup from '@/components/common/popup/slidingPopupContent/tvpay/TvPayPopup';
import OneClickPopup from '@/components/common/popup/slidingPopupContent/tvpay/OneClickPopup';
import FastPayContent from '@/components/orderPayment/FastPayContent.vue';
import ContentPopup from '@/components/user/ContentPopup';
import PaymentPopupOnCheck from '@/components/common/popup/slidingPopupContent/fitcol/PaymentPopupOnCheck';
import MelonTerms from '@/components/common/popup/slidingPopupContent/fitcol/MelonTerms';
import XgolfPopup from '@/components/common/popup/slidingPopupContent/fitcol/XgolfPopup';
import XgolfTerms from '@/components/common/popup/slidingPopupContent/fitcol/XgolfTerms';
import WooriCardPopup from '@/components/common/popup/slidingPopupContent/fitcol/WooriCardPopup';

import { fitMixin } from '@/utils/fitMixin';

/**
 * 컴포넌트 사용법
 * @param show  props로 show boolean 내려주기
 * @param title props로 제목 내려주기
 * @param pages props로 component :is 내려줄 페이지 내려주기
 * @param height  props로 화면 높이 내려주기
 */
export default {
  name: 'SlidingPopUp',

  components: {
    Share,
    CommonText,
    ChannelDormantPopup,
    PaiSearch,
    PaiSetting,
    TvPaySetting,
    PaiJoin,
    PaiTerms,
    ShoppingPointExchange,
    ExecutionBanner,
    FilterPopUp,
    TvpInfoPopup,
    ShopPointInfo,
    OrderOptionSelect,
    callSlidePopup,
    BenefitDetail,
    TvPayPopup,
    OneClickPopup,
    FastPayContent,
    ContentPopup,
    PaymentPopupOnCheck,
    MelonTerms,
    XgolfPopup,
    XgolfTerms,
    WooriCardPopup,
  },

  mixins: [fitMixin],

  props: {
    title: {
      type: String,
      default: '',
    },
    pages: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '1050px',
    },
    show: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '32',
    },
    amount: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
    },
  },
  emits: ['clickEvent'],

  data() {
    return {
      scrollY: 0,
    };
  },

  computed: {
    showSlide() {
      return this.show;
    },
  },

  watch: {
    showSlide(flag) {
      if (this.$store.state.common.mainIdx !== 0) {
        flag ? this.lockParentScroll() : this.unLockParentScroll();
      }
    },
  },

  unmounted() {
    this.unLockParentScroll();
  },

  methods: {
    clickEvent(data, item) {
      this.$emit('clickEvent', 'closeSlidingPopup', data, item);
    },
    removeCard() {
      this.$emit('clickEvent', 'remove');
    },
    lockParentScroll() {
      this.scrollY = window.scrollY;
      document.getElementsByTagName('body')[0].style = 'width:100%; position:fixed; top: -' + this.scrollY + 'px';
    },
    //팝업 종료 시 부모창 스크롤 잠금해제
    unLockParentScroll() {
      document.getElementsByTagName('body')[0].style = '';
      window.scrollTo(0, this.scrollY);
    },
  },
};
</script>

<style scoped lang="scss">
.component-wrapper {
  //margin-top: 40px;
}

.main-wrap {
  padding: 0 30px;
  width: 100%;
  height: 100%;
}

.content-wrap {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 103;
  background-color: #fff;
  border-radius: 38px 38px 0 0;
  box-sizing: border-box;
}

.content-title {
  height: 130px;
  //padding-top: 60px;
  //font-size: 32px;
  //font-weight: bold;
  //line-height: 1.69;
  //letter-spacing: -1.6px;
  //text-align: left;
  //color: #333333;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.fade-splash-enter-active,
.fade-splash-leave-active {
  transition: opacity 0.25s;
}

.fade-splash-enter,
.fade-splash-leave-to {
  opacity: 0;
}

.slide-up {
  transition: all 0.25s;
}

.slide-up-enter-active {
  transition: all 0.25s ease;
}

.slide-up-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-up-enter,
.slide-up-leave-active {
  opacity: 0;
  transform: translateY(100%);
}

.fades-enter-active {
  animation: fadeEnter 0.25s;
}

@keyframes fadeEnter {
  from {
    background: #fff;
  }
  to {
    background: #000000;
  }
}

.slides-up-enter-active {
  animation: slideYEnter 0.25s;
}

@keyframes slideYEnter {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.slide-down-enter-active {
  animation: slideYLeave 0.25s;
}

@keyframes slideYLeave {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
</style>
