<template>
  <div>
    <div class="popup-wrap">
      <div class="popup-content">
        앱에서만 사용 가능한 기능입니다.<br />
        아이폰 앱 서비스는 준비중 입니다.<br />
        조금만 더 기다려 주세요.<br />
        곧 좋은 컨텐츠로 찾아뵙겠습니다 :)
      </div>
      <div class="popup-button-wrap flex-right-align-wrap">
        <button class="popup-button-yes" @click="confirm">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZzimDeviceCheckPopup',

  methods: {
    confirm() {
      // App.vue의 451번째 줄 참고
      // if (this.getDeviceType === 1) {
      if (this.$deviceType === 1 || this.$deviceType === 2) {
        // 안드로이드
        window.open('https://play.google.com/store/apps/details?id=com.tvpoint');
      } else {
        // 웹 & ios : 팝업 닫기
        this.$store.commit('alertPopup', {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  padding: 40px;
}

.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 57px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
</style>
