<template>
  <div>
    <div class="d-flex c-center c-flex-start" style="flex-wrap: wrap; justify-content: flex-start; row-gap: 24px">
      <div v-for="(checkbox, idx) in checkboxData" :key="idx" :style="idx === 0 ? 'width:100%' : 'width:50%'">
        <div class="d-flex c-center base-line">
          <PaiSearchCheckbox :value="checkbox.checked" :name="name" :index="idx" @click-event="clickEvent" />
          <CommonText font-size="26" spacing="-1.04" class="padding-left-14">{{ checkbox.name }}</CommonText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaiSearchCheckbox from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchCheckbox';
import CommonText from '@/components/common/text/CommonText';
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiSearchCheckboxWrapper',
  components: { CommonText, PaiSearchCheckbox },
  props: {
    checkboxData: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: 'common',
    },
  },
  emits: ['clickEvent'],
  methods: {
    clickEvent(sort, data) {
      switch (sort) {
        case 'checkBox':
          this.$emit('clickEvent', sort, data);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
