const filters = {
  withComma(val) {
    if (val == null || val == 0) {
      return val;
    }
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  phoneFormatter(num) {
    let formatNum = '';

    num.length === 8
      ? (formatNum = num.toString().replace(/(\d{4})(\d{4})/, '$1-$2'))
      : (formatNum = num.toString().replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3,4})([0-9]{4})/, '$1-$2-$3'));

    return formatNum;
  },
  toDigit(str) {
    return str.replace(/[^0-9]/g, '');
  },
  formatComma(data) {
    if (data === null || data === '') {
      return 0;
    }
    return String(data)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  isNull(object) {
    return !!(object === null || object === '');
  },
  switchLine(data) {
    return String(data).toString().replace(';', '<br>');
  },
  textLengthCheck32(data) {
    return String(data).match(/^[\S+\n\r\s]{0,32}/g)[0];
  },
  textLengthCheck12(data) {
    let text;
    if (data.length > 12) {
      text = String(data).match(/^[\S+\n\r\s]{0,12}/g)[0];
      text = `${text}...`;
    } else {
      text = data;
    }
    return text;
  },
};

export default filters;
