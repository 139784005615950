<template>
  <div class="ask-type-wrap common-padding">
    <div class="type-title">문의 유형 선택</div>
    <div class="type-container">
      <div
        v-for="(type, idx) in getQnaTypeList"
        :key="idx"
        class="type-component"
        :class="isScroll && idx === getQnaTypeList.length - 1 ? 'scroll-padding' : ''"
      >
        <div class="component-wrap">
          <input :id="type.code" type="radio" name="types" :typename="type.name" :code="type.code" @click="typeSelected" />
          <label :for="type.code"><i class="rdo-img"></i> {{ type.name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(['getQnaTypeList']),
  },

  mounted() {
    if (this.$store.state.tvpoint.slidePopupData.code) {
      document.getElementById(this.$store.state.tvpoint.slidePopupData.code).checked = true;
    }
  },
  methods: {
    async typeSelected($event) {
      const code = $event.target.getAttribute('code');
      const name = $event.target.getAttribute('typename');
      await this.$store.commit('setPopupData', { name, code });
      await this.$store.commit('slideShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.ask-type-wrap {
  height: 100%;
}
.type-title {
  font-size: 34px;
  line-height: 128px;
  letter-spacing: -1.36px;
  color: #222222;
  height: 128px;
  font-weight: bold;
}
.rdo-img {
  width: 40px;
  padding-right: 20px;
  background: url('../../../../assets/img/common/btn_radio_off.svg') no-repeat;
}
input[type='radio']:checked + label > i {
  background: url('../../../../assets/img/common/btn_radio_on.svg') no-repeat;
}
input[type='radio'] {
  display: none;
}
label {
  font-size: 30px;
  display: flex;
  padding-top: 35px;
  padding-bottom: 35px;
}
.component-wrap {
  height: 110px;
}
.type-container {
  padding-bottom: 40px;
  height: 100%;
  overflow: scroll;
}
</style>
