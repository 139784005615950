<template>
  <div class="popup-wrap">
    <div class="popup-title">결제내역 삭제</div>
    <div class="popup-content" v-html="getAlertPopup.popupContent"></div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="cancel">아니오</button>
      <button class="popup-button-yes" @click="confirm">네</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getAlertPopup']),
  },
  methods: {
    cancel() {
      this.$store.commit('alertPopup', {});
    },
    confirm() {
      this.$store.commit('alertPopup', { returnValue: true });
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 46px;
  font-size: 30px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: 500;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 30px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 40px;
  margin-left: 300px;
}

.popup-content {
  margin-top: 40px;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 31px 39px 38px 34px;
}
</style>
