<template>
  <SlidingContentWrapper class="common-padding">
    <template #content>
      <div class="d-flex c-center r-flex-start">
        <TvPaySettingRadioWrapper />
      </div>
    </template>
  </SlidingContentWrapper>
</template>

<script>
import SlidingContentWrapper from '@/components/common/popup/slidingPopupContent/SlidingContentWrapper';
import TvPaySettingRadioWrapper from '@/components/common/popup/slidingPopupContent/tvpay/setting/importData/TvPaySettingRadioWrapper';
export default {
  name: 'TvPaySettingIndex',
  components: {
    SlidingContentWrapper,
    TvPaySettingRadioWrapper,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.sliding-wrap {
  margin-top: 40px;
  box-sizing: border-box;
}

.button-wrapper {
  position: fixed;
  bottom: 0;
}
.refresh-wrapper {
  position: absolute;
  width: 48px;
  height: 48px;
  background: red;

  margin-top: -1px;
  left: calc(100% - 80px);
}
</style>
