<template>
  <transition name="fade">
    <div v-if="alertPopup2" id="layerWrap" class="layer-wrap">
      <div class="pop-layer">
        <div v-if="$utils.isNull(alertPopup2.title)">
          <div class="pop-container">
            <div class="pop-conts">
              <div class="contents-container">
                <div class="txt_contents" style="padding-top: 40px" v-html="content"></div>
                <div class="txt_sub_contents" @click="phoneChange">{{ alertPopup2.sub_content }}</div>
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="btn_cancel">
              <span @click="cancel">취소</span>
            </div>
            <div class="btn_confirm" style="font-size: 28px">
              <span id="nomMemberGift" @click="goGift">충전권 선물 진행</span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="pop-title">{{ alertPopup2.title }}</div>
          <div class="pop-container">
            <div class="pop-conts">
              <div class="contents-container">
                <div class="txt_contents" v-html="content"></div>
                <div class="txt_sub_contents" @click="phoneChange">{{ alertPopup2.sub_content }}</div>
              </div>
              <div class="division"></div>
            </div>
          </div>
          <div class="footer padding">
            <div class="btn_cancel">
              <span @click="cancel">아니요</span>
            </div>
            <div class="btn_confirm">
              <span id="resetPwd" @click="confirm">네,전송할래요!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['alertPopup2', 'getRpSession', 'getAuthDetail', 'getViewAccountDetail']),
    content() {
      let data;
      if (!this.$utils.isNull(this.alertPopup2)) {
        data = this.alertPopup2.content.replace(/(\n|\r\n)/g, '<br/>');
      }
      return data;
    },
  },

  watch: {
    alertPopup2(val) {
      if (this.$utils.isNull(val)) {
        window.removeEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      } else {
        window.addEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      }
    },
  },

  methods: {
    phoneChange() {
      this.$store.commit('alertPopup2', null);
      this.$router.push('/tvpoint/pay/register/cardphonechange');
    },
    confirm() {
      const _vue = this;
      _vue.$store.commit('loading', true);
      _vue.$store.commit('setAuthDetail', { acntNo: _vue.getViewAccountDetail.acntNo, userNo: _vue.getViewAccountDetail.userNo });
      this.$utils.sequenceEx([
        this.tvpaySendData,
        function () {
          _vue.$store.commit('alertPopup2', null);
          _vue.$router.push('/tvpoint/pay/register/payselfauth');
        },
      ]);
    },
    goGift() {
      this.$store.commit('alertPopup2', null);
      this.$store.commit('setMemberYN', 'N');
      // this.$emit("nonMemberGift");
    },
    cancel() {
      this.$store.commit('alertPopup2', null);
    },
    handleTouchmove(e) {
      this.$utils.printLog('[AlertPopup2] test ! ');
      e.stopPropagation();
      e.preventDefault();
    },
    tvpaySendData() {
      const _vue = this;
      _vue.$utils.printLog('tvpaySendData');
      const deferred = this.$utils.defer();
      const params = {
        tvpaySubUrl: `/cert2/tvpaySendData.mv;jsessionid=${this.getRpSession}`,
        acntNo: _vue.getViewAccountDetail.acntNo,
        userNo: _vue.getViewAccountDetail.userNo,
      };
      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('tvpaySendData resultCode', data.resultCode);
        _vue.$utils.printLog('tvpaySendData data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('tvpaySendData resultCode');
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.layer-wrap {
  // display: none;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.pop-layer {
  display: inline-block;
  vertical-align: middle;
  // width: calc(100% - 140px);
  width: 540px;
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 16px;
}
.pop-title {
  display: flex;
  margin: 40px 40px 10px 40px;
  height: 82px;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.6px;
  text-align: left;
  // width: 100%;
  color: #343534;
}
.pop-container {
  height: 228px;
}
.txt_contents {
  padding-left: 40px;
  padding-right: 44px;
  font-size: 28px;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
.txt_sub_contents {
  margin-top: 30px;
  padding-left: 40px;
  font-size: 26px;
  text-align: left;
  letter-spacing: -1.3px;
  color: #929292;
  text-decoration: underline;
}
.footer {
  margin-top: 46px;
  display: flex;
  height: 90px;
  padding-left: 40px;
  padding-right: 40px;
}
.btn_cancel {
  font-size: 28px;
  letter-spacing: -1.4px;
  color: #2b2d2f;
  text-align: right;
  width: 100%;
}
.btn_confirm {
  padding-left: 44px;
  text-align: right;
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #648cff;
  font-weight: 500;
  width: 100%;
}
</style>
