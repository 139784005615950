<template>
  <SlidingContentWrapper class="">
    <template #content>
      <div class="common-padding">
        <select v-model="selectedTerm" class="select">
          <option value="0" disabled selected>이전 개인정보 처리방침 보기</option>
          <option v-for="(termsTitle, idx) in termsData" :key="idx + 1" :value="idx + 1">{{ termsTitle.title }}</option>
        </select>

        <div
          class="terms-content-wrapper margin-top-40"
          v-html="selectedTerm === 0 ? termsData[0].content : termsData[selectedTerm - 1].content"
        ></div>
      </div>
    </template>
  </SlidingContentWrapper>
</template>

<script>
import SlidingContentWrapper from '@/components/common/popup/slidingPopupContent/SlidingContentWrapper';

export default {
  name: 'PaiTermsIndex',
  components: {
    SlidingContentWrapper,
  },
  data() {
    return {
      selectedTerm: 0,
      termsData: [
        {
          title: '2022년02월 03일',
          content:
            '[개인 정보 처리 방침]1' +
            '<br>\n' +
            '<br>주식회사 핏콜라보(이하 “회사”라 함) 는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 준수하여야 할 관련 법규상의 개인정보보호규정을 준수하며, 개인정보보호법에 의거한 개인정보처리방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다. ' +
            "<br>개인정보처리방침의 '정보주체' 는 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을 말합니다. “회원”은 '정보주체' 중 “회사”가 제공하는 TV포인트, TV페이(TV결제), 홈빠 서비스 등(이하 총칭하여 “서비스” 라 함)를 이용하는 자를 의미합니다. " +
            '<br>' +
            '<br>“회사”의 개인정보처리방침은 다음과 같은 내용으로 구성되어 있습니다. ' +
            '<br>' +
            '<br>1.개인정보의 처리 목적 ' +
            '<br>2.처리하는 개인정보의 항목 ' +
            '<br>3.정보 처리 및 보유기간 ' +
            '<br>4.개인정보의 제3자 제공에 관한 사항 ' +
            '<br>5.개인정보처리의 위탁에 관한 사항 ' +
            '<br>6.개인정보의 파기에 관한 사항 ' +
            '<br>7.정보주체의 권리의무 및 그 행사방법에 관한 사항 ' +
            '<br>8.개인정보의 안전성 확보 조치에 관한 사항 ' +
            '<br>9.개인정보 보호책임자에 관한 사항 ' +
            '<br>10.개인정보의 열람청구를 접수/처리하는 부서 ' +
            '<br>11.정보주체의 권익침해에 대한 구제방법' +
            '<br>12.개인정보처리방침의 변경에 관한 사항' +
            '<br>' +
            '<br>1.개인정보의 처리 목적 ' +
            '<br>“회사”는 “서비스”의 제공을 위하여 다음과 같은 목적으로 “회원” 또는 “회원”이 동의한 금융사, 가맹점, 제휴사 등으로부터 정보를 수집 및 이용하고 있습니다. 단, 일부 “서비스”와 관련하여 금융사, 가맹점, 제휴사 등 정보를 제공하는 회사의 정책으로 인하여 정보 제공이 원칙적으로 이루어지지 않는 경우에는 “서비스” 제공이 불가능하거나 이용에 제한을 둘 수 있습니다.' +
            '<br>1)회원제 서비스 이용에 따른 본인여부 식별(확인), 회원가입, 회원관리 ' +
            '<br>2)“회원”이 신청하신 서비스에 대한 당사와 금융사 또는 가맹점, 제휴사 등과의 회원 인증 및 결제, 이에 따른 대금 정산 ' +
            '<br>3)“회원”이 구매하는 재화와 용역에 대한 결제, 주문, 배송 등에 대한 처리' +
            '<br>4)“회원”이 신청하신 포인트 선물 또는 포인트 충전권 등의 휴대폰 문자 발송' +
            '<br>5)“회원”의 서비스 이용내역, 잔액조회 등의 각종 조회서비스 제공' +
            '<br>6)고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 분쟁조정을 위한 기록보존 등 ' +
            '<br>7)새로운 서비스나 이벤트 정보 등 최신 정보의 안내 ' +
            '<br>8)통계학적 분석(이용자의 연령별, 성별, 지역별 통계분석 등) 등' +
            '<br>9)기타 개인별 맞춤서비스 제공 ' +
            '<br>' +
            '<br>2. 처리하는 개인정보의 항목 ' +
            '<br>1)필수항목' +
            '<br>가.TV결제의 TV간편결제, 간편주문, ARS간편주문' +
            '<br>- “회원”의 가입 및 카드본인인증, 고객식별, 고객응대 등을 위한 정보' +
            '<br>- 성명, CI연계정보, 휴대폰번호, 남여구분, 생년월일, 내국인여부, 카드 회원 여부를 인증하기 위한 신용카드 정보(카드번호, 유효기간, CVC, 카드비밀번호 일부 등 카드사별로 정한 정보), 주문 및 결제내역, 서비스별 계정정보 등에 관한 정보 등' +
            '<br>- 주문상품 배송을 위한 배송지 주소 정보' +
            '<br>나. 홈빠' +
            '<br>       - “회원”의 가입 및 고객식별, 고객응대, 주문 및 결제정보 관리를 위한 정보' +
            '<br>- 성명, CI연계정보, 휴대폰번호, 남여구분, 생년월일, 내국인여부, 주소지정보(배송), 주문 및 결제내역' +
            '<br>다.  TV포인트' +
            '<br>- “회원”의 가입 및 본인인증, 포인트 조회/전환, 고객식별, 고객응대 등을 위한 정보' +
            '<br>- 성명, 생년월일, 남여구분, 내외국인구분, CI연계정보, 휴대폰번호, 거래내역 등 포인트에 관한 정보 등' +
            '<br>2)선택항목 : “회원”이 가입된 카드사 또는 제휴사의 회원계정 존재 여부 및 해당 계정잔액 확인, 정산 등을 위해 “회원”에게 일시적으로 제공받는 정보로써 사용 목적 달성시 까지만 이용되며 저장되지 않는 정보' +
            '<br>가.TV간편결제' +
            '<br>결제처리를 위해 등록된 간편결제 계정에 따라 카드사로부터 제공받는 신용카드 정보 ' +
            '<br>나.  TV간편결제2.0' +
            '<br>TV간편결제를 등록하지 않고 1회용 결제처리를 위한 카드번호, 유효기간, 생년월일, 비밀번호(일부), 또는 카드사가 인증을 위해 요구하는 추가 정보' +
            '<br>다.  TV포인트 : 포인트전환/적립/구매 및 사용' +
            '<br>신용카드 포인트 제휴사 : CI정보, 카드번호, 유효기간, 카드고유번호, 카드 비밀번호 일부(해당 카드사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>멤버쉽 제휴사 : 멤버쉽번호(카드번호, 회원ID 등), 멤버쉽비밀번호(해당 제휴사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>상품권, 온라인캐쉬 등 제휴사 : 상품권번호, 회원ID,(온라인캐쉬), 회원비밀번호(온라인캐쉬). (해당 제휴사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>포인트 선물을 위한 포인트충전권 발송 : 통화목록 또는 연락처목록에서 “회원”이 선택 또는 직접 입력한 수신자의 휴대폰번호 등' +
            '<br>      마.  기타 정보로써 사용 목적 달성 시 까지만 이용되는 정보' +
            '<br>3)“서비스” 이용과정이나 사업처리 과정에서 자동으로 수집될 수 있는 항목 ' +
            '<br>“회원”의 방송/통신 “제휴사” 및 셋톱박스 또는 휴대폰 관련 정보 (단말기ID,브라우저 종류 및 OS), 서비스 이용 기록, IP Address, 방문 일시, 서비스 이용기록, 결제기록, 쿠키, 로그정보, 세션정보, 로컬저장소 등' +
            '<br>' +
            '<br>3. 개인정보의 처리 및 보유기간 ' +
            '<br>“회사”는 「개인정보의 처리 목적」이 달성될 때까지 개인정보를 보유합니다. 단, 정보주체로 부터 사전에 동의를 받은 경우 또는 상법 등 관련법규에 의하여 보존할 필요성이 있는 다음의 경우에는 개인정보를 일정기간 동안 보유합니다. ' +
            '<br>1)계약 또는 청약철회 등에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 5년' +
            '<br>2)거래 내역 및 대금결제 등에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 5년' +
            '<br>3)소비자의 불만 또는 분쟁처리에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 3년' +
            '<br>4)본인확인에 관한 기록' +
            '<br>가.보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률' +
            '<br>나.보존 기간 : 6개월' +
            '<br>5)방문에 관한 기록' +
            '<br>가.보존 이유 : 통신비밀보호법' +
            '<br>나.보존 기간 : 3개월' +
            '<br>6)기타 고객의 동의를 받은 경우 : 동의를 받은 기간까지' +
            '<br>' +
            '<br>4.개인정보의 제3자 제공에 관한 사항 ' +
            '<br>1)"핏콜라보"가 본 약관에 의하여 “서비스”를 위하여 정보제공에 대한 계약을 체결한 회사는 다음과 같습니다. 다만, “회원”이 해당 “서비스”를 이용하지 않을 경우에는 정보가 제공되지 않습니다.' +
            '<br>가.TV결제 서비스 (간편주문 포함)' +
            '<br>- 카드사 : 신한카드㈜, 비씨카드㈜,  ㈜KB국민카드, 농협은행㈜, 삼성카드㈜, 하나카드㈜, 롯데카드㈜, 현대카드㈜' +
            '<br>- 홈쇼핑 가맹점 : 케이티하이텔㈜, ㈜에스케이스토아, ㈜신세계티비쇼핑, ㈜쇼핑엔티, ㈜더블유쇼핑, ㈜엔에스쇼핑, ㈜현대홈쇼핑, ㈜우리홈쇼핑, ㈜씨제이이엔엠, ㈜지에스홈쇼핑, ㈜홈앤쇼핑, ㈜공영홈쇼핑' +
            '<br>- 콘텐츠 가맹점 : ㈜케이티, ㈜엘지유플러스, SK브로드밴드㈜, ㈜케이티스카이라이프, ㈜KCTV제주방송,' +
            '<br>나. 홈빠 서비스 제휴 홈쇼핑사' +
            '<br>- 케이티하이텔㈜, ㈜에스케이스토아, ㈜신세계티비쇼핑, ㈜쇼핑엔티, ㈜더블유쇼핑, ㈜엔에스쇼핑, ㈜현대홈쇼핑, ㈜우리홈쇼핑, ㈜씨제이이엔엠, ㈜지에스홈쇼핑, ㈜홈앤쇼핑, ㈜공영홈쇼핑' +
            '<br>다.포인트 전환/적립 제휴사 ' +
            '<br>신한카드㈜, 비씨카드㈜, ㈜KB국민카드, 농협은행㈜, 우리카드㈜, 현대카드㈜, 하나카드㈜, 삼성카드㈜, 우정사업본부, 롯데멤버스㈜, ㈜타미마케팅, ㈜이노션, ㈜GS엠비즈, ㈜S-오일, 현대오일뱅크㈜, 한국문화진흥㈜, ㈜해피머니, 한국도서보급㈜, SK플래닛㈜, 이제너두㈜, 한국선불카드㈜, ㈜NHN페이코' +
            '<br>라.포인트 사용 제휴사 : “회원”이 이용하는 사업자에 한정함.' +
            '<br>㈜케이티, ㈜엘지유플러스, ㈜LG헬로비전, ㈜딜라이브, ㈜HCN, SK브로드밴드㈜, ㈜케이티스카이라이프, ㈜KCTV제주방송, ㈜JCN울산방송, ㈜지니뮤직(M-net 포함), ㈜카카오페이지, TJ커뮤니케이션㈜, ㈜수커뮤니케이션, ㈜카카오, ㈜십일번가, ㈜해피머니아이엔씨, ㈜티몬 ' +
            '<br>마.휴대폰 본인인증 서비스 : 코리아크레딧뷰로㈜, 한국모바일인증㈜, 나이스신용정보㈜' +
            '<br>(휴대폰 본인인증 서비스 이용약관은 해당 약관 하단에 별도 표기)' +
            '<br>' +
            '<br>2)“회사”는 「개인정보의 처리 목적」에서 고지한 범위 내에서 개인정보를 제3자에게 제공하며, 동 범위를 초과하여 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다. ' +
            '<br>가.“회원”으로 부터 사전에 개인정보를 목적 외의 용도로 제공하는 것에 대한 별도 동의를 받은 경우 ' +
            '<br>나.주문처리 및 배송 업무상 배송업체에게 배송에 필요한 최소한의 정보(성명, 휴대폰번호, 주소)를 “회원”이 알려주는 경우 ' +
            '<br>다.통계처리, 학술연구, 시장조사 등을 위하여 필요한 경우로서 특정한 개인을 식별할 수 없는 형태로 개인정보를 제공하는 경우 ' +
            '<br>라.다른 법률에 특별한 규정이 있는 경우 ' +
            '<br>마.정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우 ' +
            '<br>바.“회사”가 법령상 의무를 준수하기 위하여 불가피하게 수집한 개인정보를 그 수집 목적 범위 내에서 제공하는 경우 ' +
            '<br>' +
            '<br>5.개인정보처리의 위탁에 관한 사항 ' +
            '<br>“회사”는 더 나은 서비스를 제공하기 위하여 정보주체의 개인정보 처리를 외부전문기관에 위탁할 수 있습니다. ' +
            '<br>1)“회사”는 개인정보 처리를 위탁하는 경우 위탁하는 업무의 내용과 수탁자를 정보주체가 언제든지 쉽게 확인할 수 있도록 “회사”의 인터넷 홈페이지, TV채널, 모바일앱 등에 지속적으로 게재하여 공개합니다. 특히, “회사”가 재화 또는 서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 서면, 전자우편, TV/모바일 어플리케이션, 인터넷 홈페이지 상의 동의화면, 팩스, 전화, 문자전송 또는 이에 상당하는 방법 (이하 "서면 등의 방법"이라 합니다)으로 위탁하는 업무의 내용과 수탁자를 정보주체에게 알려드립니다. 위탁하는 업무의 내용이나 수탁자가 변경된 경우에도 동일합니다. 만약, “회사”가 과실 없이 “서면 등의 방법”으로 정보주체에게 알릴 수 없는 경우에는 해당 사항을 “회사”의 인터넷 홈페이지, TV채널, 모바일앱 등에 30일 이상 게재합니다. ' +
            '<br>2)“회사”는 수탁자에게 다음의 내용이 포함된 문서에 의하여 개인정보의 처리 업무를 위탁하고 있으며, 당해 문서를 서면 또는 전자적으로 보관하여 정보주체의 권익을 보호합니다. ' +
            '<br>가.위탁업무의 목적 및 범위 ' +
            '<br>나.위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항 ' +
            '<br>다.재위탁 제한에 관한 사항 ' +
            '<br>라.개인정보의 기술적, 관리적 보호조치에 관한 사항' +
            '<br>마.개인정보에 대한 접근 권한 등 안전성 확보 조치에 관한 사항 ' +
            '<br>바.위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항 ' +
            '<br>사.수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 등에 관한 사항 ' +
            '<br>' +
            '<br>※취급 위탁 현황 ' +
            '<br>- 취급 위탁 없음 ' +
            '<br>' +
            '<br>6.개인정보의 파기에 관한 사항 ' +
            '<br>“회사”는 「개인정보의 처리 목적」이 달성되면 다음과 같은 절차 및 방법에 따라 개인정보를 파기합니다. ' +
            '<br>1)개인정보는 「개인정보의 처리 목적」이 달성된 후 별도의 데이터베이스(DB)로 옮겨져 (종이의 경우 별도의 서류함) 개인정보의 처리 및 보유기간」에 따라 일정 기간 보관된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보존 이외의 다른 목적으로 이용되거나 제공되지 않습니다. ' +
            '<br>2)전자적 파일 형태인 경우 복원이 불가능한 방법으로 영구 삭제하며, 그 외의 기록물, 인쇄물, 서면, 기타 기록매체인 경우에는 파쇄, 소각, 용해 등의 방법으로 파기합니다. ' +
            '<br>' +
            '<br>7.정보주체의 권리의무 및 그 행사방법에 관한 사항 ' +
            '<br>1)개인정보의 열람 ' +
            '<br>가.정보주체는 “회사”가 처리하는 자신의 개인정보에 대하여 행정안전부령으로 정하는 바에 따라 개인정보 열람요구서를 회사에 제출하는 등의 방법으로 열람을 요구할 수 있습니다. ' +
            '<br>나.“회사”는 열람을 요구 받으면 그로부터 10일 이내에 정보주체가 해당 개인정보를 열람할 수 있게 합니다. 만약 해당 기간 내에 열람할 수 없는 사유가 있을 때에는 요구를 받은 날부터 10일 이내에 정보주체에게 그 사유 및 이의제기 방법을 알리고 열람을 연기할 수 있으며, 그 사유가 소멸한 경우에는 정당한 사유가 없는 한 사유가 소멸한 날로부터 10일 이내에 열람하도록 할 수 있습니다. ' +
            '<br>다.“회사”는 다음의 어느 하나에 해당하는 경우 정보주체에게 그 사유를 알리고 열람을 제한하거나 거절할 수 있습니다. 이 경우 열람이 제한되는 사항을 제외한 부분은 열람할 수 있으며, 열람 요구를 받은 날부터 10일 이내에 그 사유 및 이의제기방법을 알려드립니다. ' +
            '<br>① 법률에 따라 열람이 금지되거나 제한되는 경우 ' +
            '<br>② 다른 사람의 생명신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>2)개인정보의 정정 및 삭제 ' +
            '<br>가.「개인정보의 열람」에 따라 자신의 개인정보를 열람한 정보주체는 그 개인정보의 정정 또는 삭제를 행정안전부령으로 정하는 바에 따라 개인정보 정정 및 삭제 요구서를 회사에 제출하는 등의 방법으로 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. ' +
            '<br>나.“회사”는 정보주체의 요구를 받은 경우 개인정보의 정정 또는 삭제에 관하여 다른 법령에 특별한 절차가 규정되어 있는 경우를 제외하고는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 그 개인정보를 조사하여 정보주체의 요구에 따라 정정 및 삭제 등 필요한 조치를 한 후 그 결과를 알려드립니다. 제휴사 등 다른 개인정보처리자로부터 적법하게 제공받은 개인정보의 정정 또는 삭제 요구를 받으면 제공한 기관에도 정정ㆍ삭제 요구를 알려드리고 그 처리 결과에 따라 필요한 조치를 취합니다. “회사”는 조사를 할 때 필요하면 개인에게 정정 및 삭제 요구사항의 확인에 필요한 증거를 요청할 수 있으며, 개인정보를 삭제할 때에는 복구 또는 재생되지 아니하도록 조치하고 있습니다. ' +
            '<br>다.“회사”는 다음의 어느 하나에 해당하는 경우 정당한 사유가 없는 한 정보주체의 요구를 받은 날로부터 10일 이내에 정정ㆍ삭제를 할 수 없는 사유와 이의제기 방법을 알려드립니다. ' +
            '<br>① 법률에 따라 열람이 금지되거나 제한되는 경우 ' +
            '<br>② 다른 사람의 생명이나 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>3)개인정보의 처리정지 ' +
            '<br>가.정보주체는 “회사”에 대하여 행정안전부령으로 정하는 바에 따라 개인정보 처리정지 요구서를 “회사”에 제출하는 등의 방법으로 자신의 개인정보 처리의 정지를 요구할 수 있습니다. ' +
            '<br>나.“회사”는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 정보주체의 요구에 따라 개인정보 처리의 전부를 정지하거나 일부를 정지하고, 처리가 정지된 개인정보에 대하여 처리정지의 요구를 받은 날로부터 10일 이내에 필요한 조치를 하여야 합니다. 다만, 다음의 어느 하나에 해당하는 경우에는 정보주체의 처리정지 요구를 거절할 수 있으며, 이 경우 정보주체에게 요구를 받은 날로부터 10일 이내에 그 사유를 알려주어야 합니다. ' +
            '<br>① 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우 ' +
            '<br>② 다른 사람의 생명이나 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>③ 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행 이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우 ' +
            '<br>4)권리행사의 방법 및 절차 ' +
            '<br>“회사”는 개인정보의 수집 시에 요구되지 않았던 증빙서류 등을 요구하거나 추가적인 절차를 요구하지 않습니다. ' +
            '<br>가.정보주체는 개인정보의 열람, 정정 및 삭제, 처리정지 요구를 대리인(정보주체의 법정대리인, 정보주체로부터 위임을 받은 자)에게 하게 할 수 있습니다. 이 경우, 대리인은 “회사”에 정보주체의 위임장을 제출하여야 합니다. ' +
            '<br>나.만 14세 미만 아동의 법정대리인은 “회사”에게 그 아동의 개인정보 열람, 정정 및 삭제, 처리정지 요구를 할 수 있습니다. ' +
            '<br>' +
            '<br>8.개인정보의 안전성 확보 조치에 관한 사항 ' +
            '<br>“회사”는 정보주체의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 안전성 확보 조치를 강구하고 있습니다. ' +
            '<br>가.개인정보 내부관리계획의 수립 및 시행' +
            '<br>“회사”는 개인정보의 안전한 처리를 위하여 다음의 사항을 포함하는 내부관리계획을 수립 및 시행하고 있습니다. ' +
            '<br>① 개인정보 보호책임자의 지정에 관한 사항 ' +
            '<br>② 개인정보 보호책임자 및 개인정보처리자의 역할 및 책임에 관한 사항 ' +
            '<br>③ 개인정보의 안전성 확보에 필요한 조치에 관한 사항 ' +
            '<br>④ 개인정보처리자에 대한 교육에 관한 사항 ' +
            '<br>⑤ 그 밖에 개인정보 보호를 위하여 필요한 사항 ' +
            '<br>나.개인정보에 대한 접근 통제 및 접근 권한의 제한 조치 ' +
            '<br>① “회사”는 개인정보처리시스템에 대한 접근권한을 업무 수행에 필요한 최소한의 범위로 하여 업무 담당자 별로 한 개의 사용자 계정을 차등 부여하고 있으며, 개인정보처리자가 변경되었을 경우 지체 없이 개인정보처리시스템의 접근권한을 변경 또는 말소하고 그 기록을 최소 3년간 보관하고 있습니다. ' +
            '<br>② “회사”는 개인정보처리자의 비밀번호 작성규칙을 수립하여 적용하고 있습니다. ' +
            '<br>③ “회사”는 개인정보처리시스템에 대한 불법적인 접근 및 침해사고 방지를 위해 접근 제한 시스템과 유출 시도 탐지 시스템을 운영하고 있습니다. 개인정보처리자가 외부에서 “회사”의 개인정보처리시스템에 접속하려는 경우에는 가상사설망(VPN) 또는 전용선 등 안전한 접속수단에 의한 접속만 가능합니다. 또한, 개인정보가 권한이 없는 자에게 공개되거나 외부에 유출되지 않도록 개인정보처리시스템 및 업무용 컴퓨터에 조치를 취하고 있습니다.' +
            '<br>다.개인정보 암호화 기술의 적용 또는 이에 상응하는 조치 ' +
            '<br>① “회사”는 개인의 고유식별정보, 비밀번호 및 핸드폰번호를 정보통신망을 통하여 송/수신하거나 보조저장매체 등을 통하여 전달하는 경우에는 이를 암호화하고 있습니다. 비밀번호 및 바이오정보는 안전한 암호알고리즘으로 암호화하여 저장합니다. ' +
            '<br>② “회사”는 인터넷 구간 및 인터넷 구간과 내부망의 중간 지점 (DMZ Demilitarized Zone)에 고유식별정보를 저장하는 경우에는 이를 암호화하여야 합니다. ' +
            '<br>③ “회사”는 부득이한 사유로 업무용 컴퓨터에 고유식별정보를 저장하여 관리하는 경우 상용 암호화 소프트웨어 또는 안전한 암호화 알고리즘을 사용하여 암호화하여 저장하고 있습니다. ' +
            '<br>라.접속기록의 보관 및 위조ㆍ변조 방지를 위한 조치 ' +
            '<br>“회사”는 개인정보처리자가 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관 및 관리하며, 개인정보처리자의 접속기록이 위변조 및 도난, 분실되지 않도록 해당 접속기록을 안전하게 보관하고 있습니다. ' +
            '<br>마.개인정보에 대한 보안프로그램의 설치 및 갱신' +
            '<br>“회사”는 개인정보처리시스템 또는 업무용 컴퓨터에 악성 프로그램 등을 방지 및 치료할 수 있는 백신 소프트웨어 등의 보안 프로그램을 설치 및 운영하고 있으며, 보안 프로그램의 자동 업데이트 기능을 사용하고 월 1회 PC 검사를 실시하고 있습니다. 또한 악성 프로그램관련 경보가 발령된 경우 또는 사용 중인 응용 프로그램이나 운영체제 소프트웨어의 제작업체에서 보안 업데이트 공지가 있는 경우, 즉시 이에 따른 업데이트를 실시하고 있습니다. ' +
            '<br>바.개인정보의 안전한 보관을 위한 물리적 조치' +
            '<br>“회사”는 전산실, 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소에 대한 출입통제 절차를 수립 및 운영하고 있으며, 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다. ' +
            '<br>' +
            '<br>9.개인정보 보호책임자에 관한 사항 ' +
            '<br>“회사”는 고객의 개인정보를 보호하고 개인정보와 관련한 고객의 불만사항을 처리하기 위하여 개인정보보호책임자를 두고 있습니다. 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호책임자 또는 개인정보관리담당자에게 연락하여 주시기 바랍니다. ' +
            '<br>' +
            '<br>가.개인정보보호책임자' +
            '<br>성명 : 안평해 ' +
            '<br>소속 : (주)핏콜라보 운영실 ' +
            '<br>직책 : 이사 ' +
            '<br>' +
            '<br>나.개인정보관리담당자 ' +
            '<br>성명 : 서영관 ' +
            '<br>소속 : (주)핏콜라보 IT인프라팀 ' +
            '<br>직책 : 팀장 ' +
            '<br>전화번호 : 070-7079-1100 ' +
            '<br>주소 : 서울시 서초구 남부순환로 2457 보성빌딩 6층' +
            '<br>E-mail : ygseo@fitcollabo.co.kr ' +
            '<br>' +
            '<br>10.개인정보의 열람청구를 접수ㆍ처리 부서 ' +
            '<br>1)“회사”는 개인정보의 열람청구와 관련하여 고객과의 원활한 의사소통을 위해 고객상담센터를 운영하고 있으며 연락처는 다음과 같습니다. ' +
            '<br>가.고객만족센터 ' +
            '<br>전화번호 : 1588-7758 ' +
            '<br>주 소 : 서울시 서초구 남부순환로 2457 보성빌딩 6층 ' +
            '<br>' +
            '<br>11.정보주체의 권익침해에 대한 구제방법' +
            '<br>개인정보에 관한 권익을 침해 당한 경우 개인정보침해신고센터, 대검찰청 컴퓨터 등 첨단범죄 신고, 사이버경찰청 등으로 문의하실 수 있습니다. ' +
            '<br>개인정보침해신고센터 : 전화번호 118 , http //privacy.kisa.or.kr' +
            '<br>대검찰청 컴퓨터 등 첨단범죄 신고 : http//www.spo.go.kr/minwon/center/report/minwon20.jsp ' +
            '<br>사이버경찰청 : 전화번호 1566-0112 ,  http //www.police.go.kr' +
            '<br>12.개인정보 처리방침 변경에 관한 사항 ' +
            "<br>본 『개인정보 처리방침』은 2017년 08월 01일에 최초 게시되며, 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 및 각 채널의 '공지사항'을 통해 고지합니다." +
            '<br>',
        },
        {
          title: '2021년02월 03일',
          content:
            '[개인 정보 처리 방침]2' +
            '<br>\n' +
            '<br>주식회사 핏콜라보(이하 “회사”라 함) 는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 준수하여야 할 관련 법규상의 개인정보보호규정을 준수하며, 개인정보보호법에 의거한 개인정보처리방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다. ' +
            "<br>개인정보처리방침의 '정보주체' 는 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을 말합니다. “회원”은 '정보주체' 중 “회사”가 제공하는 TV포인트, TV페이(TV결제), 홈빠 서비스 등(이하 총칭하여 “서비스” 라 함)를 이용하는 자를 의미합니다. " +
            '<br>' +
            '<br>“회사”의 개인정보처리방침은 다음과 같은 내용으로 구성되어 있습니다. ' +
            '<br>' +
            '<br>1.개인정보의 처리 목적 ' +
            '<br>2.처리하는 개인정보의 항목 ' +
            '<br>3.정보 처리 및 보유기간 ' +
            '<br>4.개인정보의 제3자 제공에 관한 사항 ' +
            '<br>5.개인정보처리의 위탁에 관한 사항 ' +
            '<br>6.개인정보의 파기에 관한 사항 ' +
            '<br>7.정보주체의 권리의무 및 그 행사방법에 관한 사항 ' +
            '<br>8.개인정보의 안전성 확보 조치에 관한 사항 ' +
            '<br>9.개인정보 보호책임자에 관한 사항 ' +
            '<br>10.개인정보의 열람청구를 접수/처리하는 부서 ' +
            '<br>11.정보주체의 권익침해에 대한 구제방법' +
            '<br>12.개인정보처리방침의 변경에 관한 사항' +
            '<br>' +
            '<br>1.개인정보의 처리 목적 ' +
            '<br>“회사”는 “서비스”의 제공을 위하여 다음과 같은 목적으로 “회원” 또는 “회원”이 동의한 금융사, 가맹점, 제휴사 등으로부터 정보를 수집 및 이용하고 있습니다. 단, 일부 “서비스”와 관련하여 금융사, 가맹점, 제휴사 등 정보를 제공하는 회사의 정책으로 인하여 정보 제공이 원칙적으로 이루어지지 않는 경우에는 “서비스” 제공이 불가능하거나 이용에 제한을 둘 수 있습니다.' +
            '<br>1)회원제 서비스 이용에 따른 본인여부 식별(확인), 회원가입, 회원관리 ' +
            '<br>2)“회원”이 신청하신 서비스에 대한 당사와 금융사 또는 가맹점, 제휴사 등과의 회원 인증 및 결제, 이에 따른 대금 정산 ' +
            '<br>3)“회원”이 구매하는 재화와 용역에 대한 결제, 주문, 배송 등에 대한 처리' +
            '<br>4)“회원”이 신청하신 포인트 선물 또는 포인트 충전권 등의 휴대폰 문자 발송' +
            '<br>5)“회원”의 서비스 이용내역, 잔액조회 등의 각종 조회서비스 제공' +
            '<br>6)고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 분쟁조정을 위한 기록보존 등 ' +
            '<br>7)새로운 서비스나 이벤트 정보 등 최신 정보의 안내 ' +
            '<br>8)통계학적 분석(이용자의 연령별, 성별, 지역별 통계분석 등) 등' +
            '<br>9)기타 개인별 맞춤서비스 제공 ' +
            '<br>' +
            '<br>2. 처리하는 개인정보의 항목 ' +
            '<br>1)필수항목' +
            '<br>가.TV결제의 TV간편결제, 간편주문, ARS간편주문' +
            '<br>- “회원”의 가입 및 카드본인인증, 고객식별, 고객응대 등을 위한 정보' +
            '<br>- 성명, CI연계정보, 휴대폰번호, 남여구분, 생년월일, 내국인여부, 카드 회원 여부를 인증하기 위한 신용카드 정보(카드번호, 유효기간, CVC, 카드비밀번호 일부 등 카드사별로 정한 정보), 주문 및 결제내역, 서비스별 계정정보 등에 관한 정보 등' +
            '<br>- 주문상품 배송을 위한 배송지 주소 정보' +
            '<br>나. 홈빠' +
            '<br>       - “회원”의 가입 및 고객식별, 고객응대, 주문 및 결제정보 관리를 위한 정보' +
            '<br>- 성명, CI연계정보, 휴대폰번호, 남여구분, 생년월일, 내국인여부, 주소지정보(배송), 주문 및 결제내역' +
            '<br>다.  TV포인트' +
            '<br>- “회원”의 가입 및 본인인증, 포인트 조회/전환, 고객식별, 고객응대 등을 위한 정보' +
            '<br>- 성명, 생년월일, 남여구분, 내외국인구분, CI연계정보, 휴대폰번호, 거래내역 등 포인트에 관한 정보 등' +
            '<br>2)선택항목 : “회원”이 가입된 카드사 또는 제휴사의 회원계정 존재 여부 및 해당 계정잔액 확인, 정산 등을 위해 “회원”에게 일시적으로 제공받는 정보로써 사용 목적 달성시 까지만 이용되며 저장되지 않는 정보' +
            '<br>가.TV간편결제' +
            '<br>결제처리를 위해 등록된 간편결제 계정에 따라 카드사로부터 제공받는 신용카드 정보 ' +
            '<br>나.  TV간편결제2.0' +
            '<br>TV간편결제를 등록하지 않고 1회용 결제처리를 위한 카드번호, 유효기간, 생년월일, 비밀번호(일부), 또는 카드사가 인증을 위해 요구하는 추가 정보' +
            '<br>다.  TV포인트 : 포인트전환/적립/구매 및 사용' +
            '<br>신용카드 포인트 제휴사 : CI정보, 카드번호, 유효기간, 카드고유번호, 카드 비밀번호 일부(해당 카드사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>멤버쉽 제휴사 : 멤버쉽번호(카드번호, 회원ID 등), 멤버쉽비밀번호(해당 제휴사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>상품권, 온라인캐쉬 등 제휴사 : 상품권번호, 회원ID,(온라인캐쉬), 회원비밀번호(온라인캐쉬). (해당 제휴사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>포인트 선물을 위한 포인트충전권 발송 : 통화목록 또는 연락처목록에서 “회원”이 선택 또는 직접 입력한 수신자의 휴대폰번호 등' +
            '<br>      마.  기타 정보로써 사용 목적 달성 시 까지만 이용되는 정보' +
            '<br>3)“서비스” 이용과정이나 사업처리 과정에서 자동으로 수집될 수 있는 항목 ' +
            '<br>“회원”의 방송/통신 “제휴사” 및 셋톱박스 또는 휴대폰 관련 정보 (단말기ID,브라우저 종류 및 OS), 서비스 이용 기록, IP Address, 방문 일시, 서비스 이용기록, 결제기록, 쿠키, 로그정보, 세션정보, 로컬저장소 등' +
            '<br>' +
            '<br>3. 개인정보의 처리 및 보유기간 ' +
            '<br>“회사”는 「개인정보의 처리 목적」이 달성될 때까지 개인정보를 보유합니다. 단, 정보주체로 부터 사전에 동의를 받은 경우 또는 상법 등 관련법규에 의하여 보존할 필요성이 있는 다음의 경우에는 개인정보를 일정기간 동안 보유합니다. ' +
            '<br>1)계약 또는 청약철회 등에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 5년' +
            '<br>2)거래 내역 및 대금결제 등에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 5년' +
            '<br>3)소비자의 불만 또는 분쟁처리에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 3년' +
            '<br>4)본인확인에 관한 기록' +
            '<br>가.보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률' +
            '<br>나.보존 기간 : 6개월' +
            '<br>5)방문에 관한 기록' +
            '<br>가.보존 이유 : 통신비밀보호법' +
            '<br>나.보존 기간 : 3개월' +
            '<br>6)기타 고객의 동의를 받은 경우 : 동의를 받은 기간까지' +
            '<br>' +
            '<br>4.개인정보의 제3자 제공에 관한 사항 ' +
            '<br>1)"핏콜라보"가 본 약관에 의하여 “서비스”를 위하여 정보제공에 대한 계약을 체결한 회사는 다음과 같습니다. 다만, “회원”이 해당 “서비스”를 이용하지 않을 경우에는 정보가 제공되지 않습니다.' +
            '<br>가.TV결제 서비스 (간편주문 포함)' +
            '<br>- 카드사 : 신한카드㈜, 비씨카드㈜,  ㈜KB국민카드, 농협은행㈜, 삼성카드㈜, 하나카드㈜, 롯데카드㈜, 현대카드㈜' +
            '<br>- 홈쇼핑 가맹점 : 케이티하이텔㈜, ㈜에스케이스토아, ㈜신세계티비쇼핑, ㈜쇼핑엔티, ㈜더블유쇼핑, ㈜엔에스쇼핑, ㈜현대홈쇼핑, ㈜우리홈쇼핑, ㈜씨제이이엔엠, ㈜지에스홈쇼핑, ㈜홈앤쇼핑, ㈜공영홈쇼핑' +
            '<br>- 콘텐츠 가맹점 : ㈜케이티, ㈜엘지유플러스, SK브로드밴드㈜, ㈜케이티스카이라이프, ㈜KCTV제주방송,' +
            '<br>나. 홈빠 서비스 제휴 홈쇼핑사' +
            '<br>- 케이티하이텔㈜, ㈜에스케이스토아, ㈜신세계티비쇼핑, ㈜쇼핑엔티, ㈜더블유쇼핑, ㈜엔에스쇼핑, ㈜현대홈쇼핑, ㈜우리홈쇼핑, ㈜씨제이이엔엠, ㈜지에스홈쇼핑, ㈜홈앤쇼핑, ㈜공영홈쇼핑' +
            '<br>다.포인트 전환/적립 제휴사 ' +
            '<br>신한카드㈜, 비씨카드㈜, ㈜KB국민카드, 농협은행㈜, 우리카드㈜, 현대카드㈜, 하나카드㈜, 삼성카드㈜, 우정사업본부, 롯데멤버스㈜, ㈜타미마케팅, ㈜이노션, ㈜GS엠비즈, ㈜S-오일, 현대오일뱅크㈜, 한국문화진흥㈜, ㈜해피머니, 한국도서보급㈜, SK플래닛㈜, 이제너두㈜, 한국선불카드㈜, ㈜NHN페이코' +
            '<br>라.포인트 사용 제휴사 : “회원”이 이용하는 사업자에 한정함.' +
            '<br>㈜케이티, ㈜엘지유플러스, ㈜LG헬로비전, ㈜딜라이브, ㈜HCN, SK브로드밴드㈜, ㈜케이티스카이라이프, ㈜KCTV제주방송, ㈜JCN울산방송, ㈜지니뮤직(M-net 포함), ㈜카카오페이지, TJ커뮤니케이션㈜, ㈜수커뮤니케이션, ㈜카카오, ㈜십일번가, ㈜해피머니아이엔씨, ㈜티몬 ' +
            '<br>마.휴대폰 본인인증 서비스 : 코리아크레딧뷰로㈜, 한국모바일인증㈜, 나이스신용정보㈜' +
            '<br>(휴대폰 본인인증 서비스 이용약관은 해당 약관 하단에 별도 표기)' +
            '<br>' +
            '<br>2)“회사”는 「개인정보의 처리 목적」에서 고지한 범위 내에서 개인정보를 제3자에게 제공하며, 동 범위를 초과하여 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다. ' +
            '<br>가.“회원”으로 부터 사전에 개인정보를 목적 외의 용도로 제공하는 것에 대한 별도 동의를 받은 경우 ' +
            '<br>나.주문처리 및 배송 업무상 배송업체에게 배송에 필요한 최소한의 정보(성명, 휴대폰번호, 주소)를 “회원”이 알려주는 경우 ' +
            '<br>다.통계처리, 학술연구, 시장조사 등을 위하여 필요한 경우로서 특정한 개인을 식별할 수 없는 형태로 개인정보를 제공하는 경우 ' +
            '<br>라.다른 법률에 특별한 규정이 있는 경우 ' +
            '<br>마.정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우 ' +
            '<br>바.“회사”가 법령상 의무를 준수하기 위하여 불가피하게 수집한 개인정보를 그 수집 목적 범위 내에서 제공하는 경우 ' +
            '<br>' +
            '<br>5.개인정보처리의 위탁에 관한 사항 ' +
            '<br>“회사”는 더 나은 서비스를 제공하기 위하여 정보주체의 개인정보 처리를 외부전문기관에 위탁할 수 있습니다. ' +
            '<br>1)“회사”는 개인정보 처리를 위탁하는 경우 위탁하는 업무의 내용과 수탁자를 정보주체가 언제든지 쉽게 확인할 수 있도록 “회사”의 인터넷 홈페이지, TV채널, 모바일앱 등에 지속적으로 게재하여 공개합니다. 특히, “회사”가 재화 또는 서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 서면, 전자우편, TV/모바일 어플리케이션, 인터넷 홈페이지 상의 동의화면, 팩스, 전화, 문자전송 또는 이에 상당하는 방법 (이하 "서면 등의 방법"이라 합니다)으로 위탁하는 업무의 내용과 수탁자를 정보주체에게 알려드립니다. 위탁하는 업무의 내용이나 수탁자가 변경된 경우에도 동일합니다. 만약, “회사”가 과실 없이 “서면 등의 방법”으로 정보주체에게 알릴 수 없는 경우에는 해당 사항을 “회사”의 인터넷 홈페이지, TV채널, 모바일앱 등에 30일 이상 게재합니다. ' +
            '<br>2)“회사”는 수탁자에게 다음의 내용이 포함된 문서에 의하여 개인정보의 처리 업무를 위탁하고 있으며, 당해 문서를 서면 또는 전자적으로 보관하여 정보주체의 권익을 보호합니다. ' +
            '<br>가.위탁업무의 목적 및 범위 ' +
            '<br>나.위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항 ' +
            '<br>다.재위탁 제한에 관한 사항 ' +
            '<br>라.개인정보의 기술적, 관리적 보호조치에 관한 사항' +
            '<br>마.개인정보에 대한 접근 권한 등 안전성 확보 조치에 관한 사항 ' +
            '<br>바.위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항 ' +
            '<br>사.수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 등에 관한 사항 ' +
            '<br>' +
            '<br>※취급 위탁 현황 ' +
            '<br>- 취급 위탁 없음 ' +
            '<br>' +
            '<br>6.개인정보의 파기에 관한 사항 ' +
            '<br>“회사”는 「개인정보의 처리 목적」이 달성되면 다음과 같은 절차 및 방법에 따라 개인정보를 파기합니다. ' +
            '<br>1)개인정보는 「개인정보의 처리 목적」이 달성된 후 별도의 데이터베이스(DB)로 옮겨져 (종이의 경우 별도의 서류함) 개인정보의 처리 및 보유기간」에 따라 일정 기간 보관된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보존 이외의 다른 목적으로 이용되거나 제공되지 않습니다. ' +
            '<br>2)전자적 파일 형태인 경우 복원이 불가능한 방법으로 영구 삭제하며, 그 외의 기록물, 인쇄물, 서면, 기타 기록매체인 경우에는 파쇄, 소각, 용해 등의 방법으로 파기합니다. ' +
            '<br>' +
            '<br>7.정보주체의 권리의무 및 그 행사방법에 관한 사항 ' +
            '<br>1)개인정보의 열람 ' +
            '<br>가.정보주체는 “회사”가 처리하는 자신의 개인정보에 대하여 행정안전부령으로 정하는 바에 따라 개인정보 열람요구서를 회사에 제출하는 등의 방법으로 열람을 요구할 수 있습니다. ' +
            '<br>나.“회사”는 열람을 요구 받으면 그로부터 10일 이내에 정보주체가 해당 개인정보를 열람할 수 있게 합니다. 만약 해당 기간 내에 열람할 수 없는 사유가 있을 때에는 요구를 받은 날부터 10일 이내에 정보주체에게 그 사유 및 이의제기 방법을 알리고 열람을 연기할 수 있으며, 그 사유가 소멸한 경우에는 정당한 사유가 없는 한 사유가 소멸한 날로부터 10일 이내에 열람하도록 할 수 있습니다. ' +
            '<br>다.“회사”는 다음의 어느 하나에 해당하는 경우 정보주체에게 그 사유를 알리고 열람을 제한하거나 거절할 수 있습니다. 이 경우 열람이 제한되는 사항을 제외한 부분은 열람할 수 있으며, 열람 요구를 받은 날부터 10일 이내에 그 사유 및 이의제기방법을 알려드립니다. ' +
            '<br>① 법률에 따라 열람이 금지되거나 제한되는 경우 ' +
            '<br>② 다른 사람의 생명신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>2)개인정보의 정정 및 삭제 ' +
            '<br>가.「개인정보의 열람」에 따라 자신의 개인정보를 열람한 정보주체는 그 개인정보의 정정 또는 삭제를 행정안전부령으로 정하는 바에 따라 개인정보 정정 및 삭제 요구서를 회사에 제출하는 등의 방법으로 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. ' +
            '<br>나.“회사”는 정보주체의 요구를 받은 경우 개인정보의 정정 또는 삭제에 관하여 다른 법령에 특별한 절차가 규정되어 있는 경우를 제외하고는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 그 개인정보를 조사하여 정보주체의 요구에 따라 정정 및 삭제 등 필요한 조치를 한 후 그 결과를 알려드립니다. 제휴사 등 다른 개인정보처리자로부터 적법하게 제공받은 개인정보의 정정 또는 삭제 요구를 받으면 제공한 기관에도 정정ㆍ삭제 요구를 알려드리고 그 처리 결과에 따라 필요한 조치를 취합니다. “회사”는 조사를 할 때 필요하면 개인에게 정정 및 삭제 요구사항의 확인에 필요한 증거를 요청할 수 있으며, 개인정보를 삭제할 때에는 복구 또는 재생되지 아니하도록 조치하고 있습니다. ' +
            '<br>다.“회사”는 다음의 어느 하나에 해당하는 경우 정당한 사유가 없는 한 정보주체의 요구를 받은 날로부터 10일 이내에 정정ㆍ삭제를 할 수 없는 사유와 이의제기 방법을 알려드립니다. ' +
            '<br>① 법률에 따라 열람이 금지되거나 제한되는 경우 ' +
            '<br>② 다른 사람의 생명이나 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>3)개인정보의 처리정지 ' +
            '<br>가.정보주체는 “회사”에 대하여 행정안전부령으로 정하는 바에 따라 개인정보 처리정지 요구서를 “회사”에 제출하는 등의 방법으로 자신의 개인정보 처리의 정지를 요구할 수 있습니다. ' +
            '<br>나.“회사”는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 정보주체의 요구에 따라 개인정보 처리의 전부를 정지하거나 일부를 정지하고, 처리가 정지된 개인정보에 대하여 처리정지의 요구를 받은 날로부터 10일 이내에 필요한 조치를 하여야 합니다. 다만, 다음의 어느 하나에 해당하는 경우에는 정보주체의 처리정지 요구를 거절할 수 있으며, 이 경우 정보주체에게 요구를 받은 날로부터 10일 이내에 그 사유를 알려주어야 합니다. ' +
            '<br>① 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우 ' +
            '<br>② 다른 사람의 생명이나 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>③ 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행 이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우 ' +
            '<br>4)권리행사의 방법 및 절차 ' +
            '<br>“회사”는 개인정보의 수집 시에 요구되지 않았던 증빙서류 등을 요구하거나 추가적인 절차를 요구하지 않습니다. ' +
            '<br>가.정보주체는 개인정보의 열람, 정정 및 삭제, 처리정지 요구를 대리인(정보주체의 법정대리인, 정보주체로부터 위임을 받은 자)에게 하게 할 수 있습니다. 이 경우, 대리인은 “회사”에 정보주체의 위임장을 제출하여야 합니다. ' +
            '<br>나.만 14세 미만 아동의 법정대리인은 “회사”에게 그 아동의 개인정보 열람, 정정 및 삭제, 처리정지 요구를 할 수 있습니다. ' +
            '<br>' +
            '<br>8.개인정보의 안전성 확보 조치에 관한 사항 ' +
            '<br>“회사”는 정보주체의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 안전성 확보 조치를 강구하고 있습니다. ' +
            '<br>가.개인정보 내부관리계획의 수립 및 시행' +
            '<br>“회사”는 개인정보의 안전한 처리를 위하여 다음의 사항을 포함하는 내부관리계획을 수립 및 시행하고 있습니다. ' +
            '<br>① 개인정보 보호책임자의 지정에 관한 사항 ' +
            '<br>② 개인정보 보호책임자 및 개인정보처리자의 역할 및 책임에 관한 사항 ' +
            '<br>③ 개인정보의 안전성 확보에 필요한 조치에 관한 사항 ' +
            '<br>④ 개인정보처리자에 대한 교육에 관한 사항 ' +
            '<br>⑤ 그 밖에 개인정보 보호를 위하여 필요한 사항 ' +
            '<br>나.개인정보에 대한 접근 통제 및 접근 권한의 제한 조치 ' +
            '<br>① “회사”는 개인정보처리시스템에 대한 접근권한을 업무 수행에 필요한 최소한의 범위로 하여 업무 담당자 별로 한 개의 사용자 계정을 차등 부여하고 있으며, 개인정보처리자가 변경되었을 경우 지체 없이 개인정보처리시스템의 접근권한을 변경 또는 말소하고 그 기록을 최소 3년간 보관하고 있습니다. ' +
            '<br>② “회사”는 개인정보처리자의 비밀번호 작성규칙을 수립하여 적용하고 있습니다. ' +
            '<br>③ “회사”는 개인정보처리시스템에 대한 불법적인 접근 및 침해사고 방지를 위해 접근 제한 시스템과 유출 시도 탐지 시스템을 운영하고 있습니다. 개인정보처리자가 외부에서 “회사”의 개인정보처리시스템에 접속하려는 경우에는 가상사설망(VPN) 또는 전용선 등 안전한 접속수단에 의한 접속만 가능합니다. 또한, 개인정보가 권한이 없는 자에게 공개되거나 외부에 유출되지 않도록 개인정보처리시스템 및 업무용 컴퓨터에 조치를 취하고 있습니다.' +
            '<br>다.개인정보 암호화 기술의 적용 또는 이에 상응하는 조치 ' +
            '<br>① “회사”는 개인의 고유식별정보, 비밀번호 및 핸드폰번호를 정보통신망을 통하여 송/수신하거나 보조저장매체 등을 통하여 전달하는 경우에는 이를 암호화하고 있습니다. 비밀번호 및 바이오정보는 안전한 암호알고리즘으로 암호화하여 저장합니다. ' +
            '<br>② “회사”는 인터넷 구간 및 인터넷 구간과 내부망의 중간 지점 (DMZ Demilitarized Zone)에 고유식별정보를 저장하는 경우에는 이를 암호화하여야 합니다. ' +
            '<br>③ “회사”는 부득이한 사유로 업무용 컴퓨터에 고유식별정보를 저장하여 관리하는 경우 상용 암호화 소프트웨어 또는 안전한 암호화 알고리즘을 사용하여 암호화하여 저장하고 있습니다. ' +
            '<br>라.접속기록의 보관 및 위조ㆍ변조 방지를 위한 조치 ' +
            '<br>“회사”는 개인정보처리자가 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관 및 관리하며, 개인정보처리자의 접속기록이 위변조 및 도난, 분실되지 않도록 해당 접속기록을 안전하게 보관하고 있습니다. ' +
            '<br>마.개인정보에 대한 보안프로그램의 설치 및 갱신' +
            '<br>“회사”는 개인정보처리시스템 또는 업무용 컴퓨터에 악성 프로그램 등을 방지 및 치료할 수 있는 백신 소프트웨어 등의 보안 프로그램을 설치 및 운영하고 있으며, 보안 프로그램의 자동 업데이트 기능을 사용하고 월 1회 PC 검사를 실시하고 있습니다. 또한 악성 프로그램관련 경보가 발령된 경우 또는 사용 중인 응용 프로그램이나 운영체제 소프트웨어의 제작업체에서 보안 업데이트 공지가 있는 경우, 즉시 이에 따른 업데이트를 실시하고 있습니다. ' +
            '<br>바.개인정보의 안전한 보관을 위한 물리적 조치' +
            '<br>“회사”는 전산실, 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소에 대한 출입통제 절차를 수립 및 운영하고 있으며, 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다. ' +
            '<br>' +
            '<br>9.개인정보 보호책임자에 관한 사항 ' +
            '<br>“회사”는 고객의 개인정보를 보호하고 개인정보와 관련한 고객의 불만사항을 처리하기 위하여 개인정보보호책임자를 두고 있습니다. 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호책임자 또는 개인정보관리담당자에게 연락하여 주시기 바랍니다. ' +
            '<br>' +
            '<br>가.개인정보보호책임자' +
            '<br>성명 : 안평해 ' +
            '<br>소속 : (주)핏콜라보 운영실 ' +
            '<br>직책 : 이사 ' +
            '<br>' +
            '<br>나.개인정보관리담당자 ' +
            '<br>성명 : 서영관 ' +
            '<br>소속 : (주)핏콜라보 IT인프라팀 ' +
            '<br>직책 : 팀장 ' +
            '<br>전화번호 : 070-7079-1100 ' +
            '<br>주소 : 서울시 서초구 남부순환로 2457 보성빌딩 6층' +
            '<br>E-mail : ygseo@fitcollabo.co.kr ' +
            '<br>' +
            '<br>10.개인정보의 열람청구를 접수ㆍ처리 부서 ' +
            '<br>1)“회사”는 개인정보의 열람청구와 관련하여 고객과의 원활한 의사소통을 위해 고객상담센터를 운영하고 있으며 연락처는 다음과 같습니다. ' +
            '<br>가.고객만족센터 ' +
            '<br>전화번호 : 1588-7758 ' +
            '<br>주 소 : 서울시 서초구 남부순환로 2457 보성빌딩 6층 ' +
            '<br>' +
            '<br>11.정보주체의 권익침해에 대한 구제방법' +
            '<br>개인정보에 관한 권익을 침해 당한 경우 개인정보침해신고센터, 대검찰청 컴퓨터 등 첨단범죄 신고, 사이버경찰청 등으로 문의하실 수 있습니다. ' +
            '<br>개인정보침해신고센터 : 전화번호 118 , http //privacy.kisa.or.kr' +
            '<br>대검찰청 컴퓨터 등 첨단범죄 신고 : http//www.spo.go.kr/minwon/center/report/minwon20.jsp ' +
            '<br>사이버경찰청 : 전화번호 1566-0112 ,  http //www.police.go.kr' +
            '<br>12.개인정보 처리방침 변경에 관한 사항 ' +
            "<br>본 『개인정보 처리방침』은 2017년 08월 01일에 최초 게시되며, 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 및 각 채널의 '공지사항'을 통해 고지합니다." +
            '<br>',
        },
        {
          title: '2020년02월 02일',
          content:
            '[개인 정보 처리 방침]3' +
            '<br>\n' +
            '<br>주식회사 핏콜라보(이하 “회사”라 함) 는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 준수하여야 할 관련 법규상의 개인정보보호규정을 준수하며, 개인정보보호법에 의거한 개인정보처리방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다. ' +
            "<br>개인정보처리방침의 '정보주체' 는 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을 말합니다. “회원”은 '정보주체' 중 “회사”가 제공하는 TV포인트, TV페이(TV결제), 홈빠 서비스 등(이하 총칭하여 “서비스” 라 함)를 이용하는 자를 의미합니다. " +
            '<br>' +
            '<br>“회사”의 개인정보처리방침은 다음과 같은 내용으로 구성되어 있습니다. ' +
            '<br>' +
            '<br>1.개인정보의 처리 목적 ' +
            '<br>2.처리하는 개인정보의 항목 ' +
            '<br>3.정보 처리 및 보유기간 ' +
            '<br>4.개인정보의 제3자 제공에 관한 사항 ' +
            '<br>5.개인정보처리의 위탁에 관한 사항 ' +
            '<br>6.개인정보의 파기에 관한 사항 ' +
            '<br>7.정보주체의 권리의무 및 그 행사방법에 관한 사항 ' +
            '<br>8.개인정보의 안전성 확보 조치에 관한 사항 ' +
            '<br>9.개인정보 보호책임자에 관한 사항 ' +
            '<br>10.개인정보의 열람청구를 접수/처리하는 부서 ' +
            '<br>11.정보주체의 권익침해에 대한 구제방법' +
            '<br>12.개인정보처리방침의 변경에 관한 사항' +
            '<br>' +
            '<br>1.개인정보의 처리 목적 ' +
            '<br>“회사”는 “서비스”의 제공을 위하여 다음과 같은 목적으로 “회원” 또는 “회원”이 동의한 금융사, 가맹점, 제휴사 등으로부터 정보를 수집 및 이용하고 있습니다. 단, 일부 “서비스”와 관련하여 금융사, 가맹점, 제휴사 등 정보를 제공하는 회사의 정책으로 인하여 정보 제공이 원칙적으로 이루어지지 않는 경우에는 “서비스” 제공이 불가능하거나 이용에 제한을 둘 수 있습니다.' +
            '<br>1)회원제 서비스 이용에 따른 본인여부 식별(확인), 회원가입, 회원관리 ' +
            '<br>2)“회원”이 신청하신 서비스에 대한 당사와 금융사 또는 가맹점, 제휴사 등과의 회원 인증 및 결제, 이에 따른 대금 정산 ' +
            '<br>3)“회원”이 구매하는 재화와 용역에 대한 결제, 주문, 배송 등에 대한 처리' +
            '<br>4)“회원”이 신청하신 포인트 선물 또는 포인트 충전권 등의 휴대폰 문자 발송' +
            '<br>5)“회원”의 서비스 이용내역, 잔액조회 등의 각종 조회서비스 제공' +
            '<br>6)고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 분쟁조정을 위한 기록보존 등 ' +
            '<br>7)새로운 서비스나 이벤트 정보 등 최신 정보의 안내 ' +
            '<br>8)통계학적 분석(이용자의 연령별, 성별, 지역별 통계분석 등) 등' +
            '<br>9)기타 개인별 맞춤서비스 제공 ' +
            '<br>' +
            '<br>2. 처리하는 개인정보의 항목 ' +
            '<br>1)필수항목' +
            '<br>가.TV결제의 TV간편결제, 간편주문, ARS간편주문' +
            '<br>- “회원”의 가입 및 카드본인인증, 고객식별, 고객응대 등을 위한 정보' +
            '<br>- 성명, CI연계정보, 휴대폰번호, 남여구분, 생년월일, 내국인여부, 카드 회원 여부를 인증하기 위한 신용카드 정보(카드번호, 유효기간, CVC, 카드비밀번호 일부 등 카드사별로 정한 정보), 주문 및 결제내역, 서비스별 계정정보 등에 관한 정보 등' +
            '<br>- 주문상품 배송을 위한 배송지 주소 정보' +
            '<br>나. 홈빠' +
            '<br>       - “회원”의 가입 및 고객식별, 고객응대, 주문 및 결제정보 관리를 위한 정보' +
            '<br>- 성명, CI연계정보, 휴대폰번호, 남여구분, 생년월일, 내국인여부, 주소지정보(배송), 주문 및 결제내역' +
            '<br>다.  TV포인트' +
            '<br>- “회원”의 가입 및 본인인증, 포인트 조회/전환, 고객식별, 고객응대 등을 위한 정보' +
            '<br>- 성명, 생년월일, 남여구분, 내외국인구분, CI연계정보, 휴대폰번호, 거래내역 등 포인트에 관한 정보 등' +
            '<br>2)선택항목 : “회원”이 가입된 카드사 또는 제휴사의 회원계정 존재 여부 및 해당 계정잔액 확인, 정산 등을 위해 “회원”에게 일시적으로 제공받는 정보로써 사용 목적 달성시 까지만 이용되며 저장되지 않는 정보' +
            '<br>가.TV간편결제' +
            '<br>결제처리를 위해 등록된 간편결제 계정에 따라 카드사로부터 제공받는 신용카드 정보 ' +
            '<br>나.  TV간편결제2.0' +
            '<br>TV간편결제를 등록하지 않고 1회용 결제처리를 위한 카드번호, 유효기간, 생년월일, 비밀번호(일부), 또는 카드사가 인증을 위해 요구하는 추가 정보' +
            '<br>다.  TV포인트 : 포인트전환/적립/구매 및 사용' +
            '<br>신용카드 포인트 제휴사 : CI정보, 카드번호, 유효기간, 카드고유번호, 카드 비밀번호 일부(해당 카드사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>멤버쉽 제휴사 : 멤버쉽번호(카드번호, 회원ID 등), 멤버쉽비밀번호(해당 제휴사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>상품권, 온라인캐쉬 등 제휴사 : 상품권번호, 회원ID,(온라인캐쉬), 회원비밀번호(온라인캐쉬). (해당 제휴사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>포인트 선물을 위한 포인트충전권 발송 : 통화목록 또는 연락처목록에서 “회원”이 선택 또는 직접 입력한 수신자의 휴대폰번호 등' +
            '<br>      마.  기타 정보로써 사용 목적 달성 시 까지만 이용되는 정보' +
            '<br>3)“서비스” 이용과정이나 사업처리 과정에서 자동으로 수집될 수 있는 항목 ' +
            '<br>“회원”의 방송/통신 “제휴사” 및 셋톱박스 또는 휴대폰 관련 정보 (단말기ID,브라우저 종류 및 OS), 서비스 이용 기록, IP Address, 방문 일시, 서비스 이용기록, 결제기록, 쿠키, 로그정보, 세션정보, 로컬저장소 등' +
            '<br>' +
            '<br>3. 개인정보의 처리 및 보유기간 ' +
            '<br>“회사”는 「개인정보의 처리 목적」이 달성될 때까지 개인정보를 보유합니다. 단, 정보주체로 부터 사전에 동의를 받은 경우 또는 상법 등 관련법규에 의하여 보존할 필요성이 있는 다음의 경우에는 개인정보를 일정기간 동안 보유합니다. ' +
            '<br>1)계약 또는 청약철회 등에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 5년' +
            '<br>2)거래 내역 및 대금결제 등에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 5년' +
            '<br>3)소비자의 불만 또는 분쟁처리에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 3년' +
            '<br>4)본인확인에 관한 기록' +
            '<br>가.보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률' +
            '<br>나.보존 기간 : 6개월' +
            '<br>5)방문에 관한 기록' +
            '<br>가.보존 이유 : 통신비밀보호법' +
            '<br>나.보존 기간 : 3개월' +
            '<br>6)기타 고객의 동의를 받은 경우 : 동의를 받은 기간까지' +
            '<br>' +
            '<br>4.개인정보의 제3자 제공에 관한 사항 ' +
            '<br>1)"핏콜라보"가 본 약관에 의하여 “서비스”를 위하여 정보제공에 대한 계약을 체결한 회사는 다음과 같습니다. 다만, “회원”이 해당 “서비스”를 이용하지 않을 경우에는 정보가 제공되지 않습니다.' +
            '<br>가.TV결제 서비스 (간편주문 포함)' +
            '<br>- 카드사 : 신한카드㈜, 비씨카드㈜,  ㈜KB국민카드, 농협은행㈜, 삼성카드㈜, 하나카드㈜, 롯데카드㈜, 현대카드㈜' +
            '<br>- 홈쇼핑 가맹점 : 케이티하이텔㈜, ㈜에스케이스토아, ㈜신세계티비쇼핑, ㈜쇼핑엔티, ㈜더블유쇼핑, ㈜엔에스쇼핑, ㈜현대홈쇼핑, ㈜우리홈쇼핑, ㈜씨제이이엔엠, ㈜지에스홈쇼핑, ㈜홈앤쇼핑, ㈜공영홈쇼핑' +
            '<br>- 콘텐츠 가맹점 : ㈜케이티, ㈜엘지유플러스, SK브로드밴드㈜, ㈜케이티스카이라이프, ㈜KCTV제주방송,' +
            '<br>나. 홈빠 서비스 제휴 홈쇼핑사' +
            '<br>- 케이티하이텔㈜, ㈜에스케이스토아, ㈜신세계티비쇼핑, ㈜쇼핑엔티, ㈜더블유쇼핑, ㈜엔에스쇼핑, ㈜현대홈쇼핑, ㈜우리홈쇼핑, ㈜씨제이이엔엠, ㈜지에스홈쇼핑, ㈜홈앤쇼핑, ㈜공영홈쇼핑' +
            '<br>다.포인트 전환/적립 제휴사 ' +
            '<br>신한카드㈜, 비씨카드㈜, ㈜KB국민카드, 농협은행㈜, 우리카드㈜, 현대카드㈜, 하나카드㈜, 삼성카드㈜, 우정사업본부, 롯데멤버스㈜, ㈜타미마케팅, ㈜이노션, ㈜GS엠비즈, ㈜S-오일, 현대오일뱅크㈜, 한국문화진흥㈜, ㈜해피머니, 한국도서보급㈜, SK플래닛㈜, 이제너두㈜, 한국선불카드㈜, ㈜NHN페이코' +
            '<br>라.포인트 사용 제휴사 : “회원”이 이용하는 사업자에 한정함.' +
            '<br>㈜케이티, ㈜엘지유플러스, ㈜LG헬로비전, ㈜딜라이브, ㈜HCN, SK브로드밴드㈜, ㈜케이티스카이라이프, ㈜KCTV제주방송, ㈜JCN울산방송, ㈜지니뮤직(M-net 포함), ㈜카카오페이지, TJ커뮤니케이션㈜, ㈜수커뮤니케이션, ㈜카카오, ㈜십일번가, ㈜해피머니아이엔씨, ㈜티몬 ' +
            '<br>마.휴대폰 본인인증 서비스 : 코리아크레딧뷰로㈜, 한국모바일인증㈜, 나이스신용정보㈜' +
            '<br>(휴대폰 본인인증 서비스 이용약관은 해당 약관 하단에 별도 표기)' +
            '<br>' +
            '<br>2)“회사”는 「개인정보의 처리 목적」에서 고지한 범위 내에서 개인정보를 제3자에게 제공하며, 동 범위를 초과하여 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다. ' +
            '<br>가.“회원”으로 부터 사전에 개인정보를 목적 외의 용도로 제공하는 것에 대한 별도 동의를 받은 경우 ' +
            '<br>나.주문처리 및 배송 업무상 배송업체에게 배송에 필요한 최소한의 정보(성명, 휴대폰번호, 주소)를 “회원”이 알려주는 경우 ' +
            '<br>다.통계처리, 학술연구, 시장조사 등을 위하여 필요한 경우로서 특정한 개인을 식별할 수 없는 형태로 개인정보를 제공하는 경우 ' +
            '<br>라.다른 법률에 특별한 규정이 있는 경우 ' +
            '<br>마.정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우 ' +
            '<br>바.“회사”가 법령상 의무를 준수하기 위하여 불가피하게 수집한 개인정보를 그 수집 목적 범위 내에서 제공하는 경우 ' +
            '<br>' +
            '<br>5.개인정보처리의 위탁에 관한 사항 ' +
            '<br>“회사”는 더 나은 서비스를 제공하기 위하여 정보주체의 개인정보 처리를 외부전문기관에 위탁할 수 있습니다. ' +
            '<br>1)“회사”는 개인정보 처리를 위탁하는 경우 위탁하는 업무의 내용과 수탁자를 정보주체가 언제든지 쉽게 확인할 수 있도록 “회사”의 인터넷 홈페이지, TV채널, 모바일앱 등에 지속적으로 게재하여 공개합니다. 특히, “회사”가 재화 또는 서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 서면, 전자우편, TV/모바일 어플리케이션, 인터넷 홈페이지 상의 동의화면, 팩스, 전화, 문자전송 또는 이에 상당하는 방법 (이하 "서면 등의 방법"이라 합니다)으로 위탁하는 업무의 내용과 수탁자를 정보주체에게 알려드립니다. 위탁하는 업무의 내용이나 수탁자가 변경된 경우에도 동일합니다. 만약, “회사”가 과실 없이 “서면 등의 방법”으로 정보주체에게 알릴 수 없는 경우에는 해당 사항을 “회사”의 인터넷 홈페이지, TV채널, 모바일앱 등에 30일 이상 게재합니다. ' +
            '<br>2)“회사”는 수탁자에게 다음의 내용이 포함된 문서에 의하여 개인정보의 처리 업무를 위탁하고 있으며, 당해 문서를 서면 또는 전자적으로 보관하여 정보주체의 권익을 보호합니다. ' +
            '<br>가.위탁업무의 목적 및 범위 ' +
            '<br>나.위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항 ' +
            '<br>다.재위탁 제한에 관한 사항 ' +
            '<br>라.개인정보의 기술적, 관리적 보호조치에 관한 사항' +
            '<br>마.개인정보에 대한 접근 권한 등 안전성 확보 조치에 관한 사항 ' +
            '<br>바.위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항 ' +
            '<br>사.수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 등에 관한 사항 ' +
            '<br>' +
            '<br>※취급 위탁 현황 ' +
            '<br>- 취급 위탁 없음 ' +
            '<br>' +
            '<br>6.개인정보의 파기에 관한 사항 ' +
            '<br>“회사”는 「개인정보의 처리 목적」이 달성되면 다음과 같은 절차 및 방법에 따라 개인정보를 파기합니다. ' +
            '<br>1)개인정보는 「개인정보의 처리 목적」이 달성된 후 별도의 데이터베이스(DB)로 옮겨져 (종이의 경우 별도의 서류함) 개인정보의 처리 및 보유기간」에 따라 일정 기간 보관된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보존 이외의 다른 목적으로 이용되거나 제공되지 않습니다. ' +
            '<br>2)전자적 파일 형태인 경우 복원이 불가능한 방법으로 영구 삭제하며, 그 외의 기록물, 인쇄물, 서면, 기타 기록매체인 경우에는 파쇄, 소각, 용해 등의 방법으로 파기합니다. ' +
            '<br>' +
            '<br>7.정보주체의 권리의무 및 그 행사방법에 관한 사항 ' +
            '<br>1)개인정보의 열람 ' +
            '<br>가.정보주체는 “회사”가 처리하는 자신의 개인정보에 대하여 행정안전부령으로 정하는 바에 따라 개인정보 열람요구서를 회사에 제출하는 등의 방법으로 열람을 요구할 수 있습니다. ' +
            '<br>나.“회사”는 열람을 요구 받으면 그로부터 10일 이내에 정보주체가 해당 개인정보를 열람할 수 있게 합니다. 만약 해당 기간 내에 열람할 수 없는 사유가 있을 때에는 요구를 받은 날부터 10일 이내에 정보주체에게 그 사유 및 이의제기 방법을 알리고 열람을 연기할 수 있으며, 그 사유가 소멸한 경우에는 정당한 사유가 없는 한 사유가 소멸한 날로부터 10일 이내에 열람하도록 할 수 있습니다. ' +
            '<br>다.“회사”는 다음의 어느 하나에 해당하는 경우 정보주체에게 그 사유를 알리고 열람을 제한하거나 거절할 수 있습니다. 이 경우 열람이 제한되는 사항을 제외한 부분은 열람할 수 있으며, 열람 요구를 받은 날부터 10일 이내에 그 사유 및 이의제기방법을 알려드립니다. ' +
            '<br>① 법률에 따라 열람이 금지되거나 제한되는 경우 ' +
            '<br>② 다른 사람의 생명신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>2)개인정보의 정정 및 삭제 ' +
            '<br>가.「개인정보의 열람」에 따라 자신의 개인정보를 열람한 정보주체는 그 개인정보의 정정 또는 삭제를 행정안전부령으로 정하는 바에 따라 개인정보 정정 및 삭제 요구서를 회사에 제출하는 등의 방법으로 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. ' +
            '<br>나.“회사”는 정보주체의 요구를 받은 경우 개인정보의 정정 또는 삭제에 관하여 다른 법령에 특별한 절차가 규정되어 있는 경우를 제외하고는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 그 개인정보를 조사하여 정보주체의 요구에 따라 정정 및 삭제 등 필요한 조치를 한 후 그 결과를 알려드립니다. 제휴사 등 다른 개인정보처리자로부터 적법하게 제공받은 개인정보의 정정 또는 삭제 요구를 받으면 제공한 기관에도 정정ㆍ삭제 요구를 알려드리고 그 처리 결과에 따라 필요한 조치를 취합니다. “회사”는 조사를 할 때 필요하면 개인에게 정정 및 삭제 요구사항의 확인에 필요한 증거를 요청할 수 있으며, 개인정보를 삭제할 때에는 복구 또는 재생되지 아니하도록 조치하고 있습니다. ' +
            '<br>다.“회사”는 다음의 어느 하나에 해당하는 경우 정당한 사유가 없는 한 정보주체의 요구를 받은 날로부터 10일 이내에 정정ㆍ삭제를 할 수 없는 사유와 이의제기 방법을 알려드립니다. ' +
            '<br>① 법률에 따라 열람이 금지되거나 제한되는 경우 ' +
            '<br>② 다른 사람의 생명이나 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>3)개인정보의 처리정지 ' +
            '<br>가.정보주체는 “회사”에 대하여 행정안전부령으로 정하는 바에 따라 개인정보 처리정지 요구서를 “회사”에 제출하는 등의 방법으로 자신의 개인정보 처리의 정지를 요구할 수 있습니다. ' +
            '<br>나.“회사”는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 정보주체의 요구에 따라 개인정보 처리의 전부를 정지하거나 일부를 정지하고, 처리가 정지된 개인정보에 대하여 처리정지의 요구를 받은 날로부터 10일 이내에 필요한 조치를 하여야 합니다. 다만, 다음의 어느 하나에 해당하는 경우에는 정보주체의 처리정지 요구를 거절할 수 있으며, 이 경우 정보주체에게 요구를 받은 날로부터 10일 이내에 그 사유를 알려주어야 합니다. ' +
            '<br>① 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우 ' +
            '<br>② 다른 사람의 생명이나 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>③ 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행 이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우 ' +
            '<br>4)권리행사의 방법 및 절차 ' +
            '<br>“회사”는 개인정보의 수집 시에 요구되지 않았던 증빙서류 등을 요구하거나 추가적인 절차를 요구하지 않습니다. ' +
            '<br>가.정보주체는 개인정보의 열람, 정정 및 삭제, 처리정지 요구를 대리인(정보주체의 법정대리인, 정보주체로부터 위임을 받은 자)에게 하게 할 수 있습니다. 이 경우, 대리인은 “회사”에 정보주체의 위임장을 제출하여야 합니다. ' +
            '<br>나.만 14세 미만 아동의 법정대리인은 “회사”에게 그 아동의 개인정보 열람, 정정 및 삭제, 처리정지 요구를 할 수 있습니다. ' +
            '<br>' +
            '<br>8.개인정보의 안전성 확보 조치에 관한 사항 ' +
            '<br>“회사”는 정보주체의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 안전성 확보 조치를 강구하고 있습니다. ' +
            '<br>가.개인정보 내부관리계획의 수립 및 시행' +
            '<br>“회사”는 개인정보의 안전한 처리를 위하여 다음의 사항을 포함하는 내부관리계획을 수립 및 시행하고 있습니다. ' +
            '<br>① 개인정보 보호책임자의 지정에 관한 사항 ' +
            '<br>② 개인정보 보호책임자 및 개인정보처리자의 역할 및 책임에 관한 사항 ' +
            '<br>③ 개인정보의 안전성 확보에 필요한 조치에 관한 사항 ' +
            '<br>④ 개인정보처리자에 대한 교육에 관한 사항 ' +
            '<br>⑤ 그 밖에 개인정보 보호를 위하여 필요한 사항 ' +
            '<br>나.개인정보에 대한 접근 통제 및 접근 권한의 제한 조치 ' +
            '<br>① “회사”는 개인정보처리시스템에 대한 접근권한을 업무 수행에 필요한 최소한의 범위로 하여 업무 담당자 별로 한 개의 사용자 계정을 차등 부여하고 있으며, 개인정보처리자가 변경되었을 경우 지체 없이 개인정보처리시스템의 접근권한을 변경 또는 말소하고 그 기록을 최소 3년간 보관하고 있습니다. ' +
            '<br>② “회사”는 개인정보처리자의 비밀번호 작성규칙을 수립하여 적용하고 있습니다. ' +
            '<br>③ “회사”는 개인정보처리시스템에 대한 불법적인 접근 및 침해사고 방지를 위해 접근 제한 시스템과 유출 시도 탐지 시스템을 운영하고 있습니다. 개인정보처리자가 외부에서 “회사”의 개인정보처리시스템에 접속하려는 경우에는 가상사설망(VPN) 또는 전용선 등 안전한 접속수단에 의한 접속만 가능합니다. 또한, 개인정보가 권한이 없는 자에게 공개되거나 외부에 유출되지 않도록 개인정보처리시스템 및 업무용 컴퓨터에 조치를 취하고 있습니다.' +
            '<br>다.개인정보 암호화 기술의 적용 또는 이에 상응하는 조치 ' +
            '<br>① “회사”는 개인의 고유식별정보, 비밀번호 및 핸드폰번호를 정보통신망을 통하여 송/수신하거나 보조저장매체 등을 통하여 전달하는 경우에는 이를 암호화하고 있습니다. 비밀번호 및 바이오정보는 안전한 암호알고리즘으로 암호화하여 저장합니다. ' +
            '<br>② “회사”는 인터넷 구간 및 인터넷 구간과 내부망의 중간 지점 (DMZ Demilitarized Zone)에 고유식별정보를 저장하는 경우에는 이를 암호화하여야 합니다. ' +
            '<br>③ “회사”는 부득이한 사유로 업무용 컴퓨터에 고유식별정보를 저장하여 관리하는 경우 상용 암호화 소프트웨어 또는 안전한 암호화 알고리즘을 사용하여 암호화하여 저장하고 있습니다. ' +
            '<br>라.접속기록의 보관 및 위조ㆍ변조 방지를 위한 조치 ' +
            '<br>“회사”는 개인정보처리자가 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관 및 관리하며, 개인정보처리자의 접속기록이 위변조 및 도난, 분실되지 않도록 해당 접속기록을 안전하게 보관하고 있습니다. ' +
            '<br>마.개인정보에 대한 보안프로그램의 설치 및 갱신' +
            '<br>“회사”는 개인정보처리시스템 또는 업무용 컴퓨터에 악성 프로그램 등을 방지 및 치료할 수 있는 백신 소프트웨어 등의 보안 프로그램을 설치 및 운영하고 있으며, 보안 프로그램의 자동 업데이트 기능을 사용하고 월 1회 PC 검사를 실시하고 있습니다. 또한 악성 프로그램관련 경보가 발령된 경우 또는 사용 중인 응용 프로그램이나 운영체제 소프트웨어의 제작업체에서 보안 업데이트 공지가 있는 경우, 즉시 이에 따른 업데이트를 실시하고 있습니다. ' +
            '<br>바.개인정보의 안전한 보관을 위한 물리적 조치' +
            '<br>“회사”는 전산실, 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소에 대한 출입통제 절차를 수립 및 운영하고 있으며, 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다. ' +
            '<br>' +
            '<br>9.개인정보 보호책임자에 관한 사항 ' +
            '<br>“회사”는 고객의 개인정보를 보호하고 개인정보와 관련한 고객의 불만사항을 처리하기 위하여 개인정보보호책임자를 두고 있습니다. 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호책임자 또는 개인정보관리담당자에게 연락하여 주시기 바랍니다. ' +
            '<br>' +
            '<br>가.개인정보보호책임자' +
            '<br>성명 : 안평해 ' +
            '<br>소속 : (주)핏콜라보 운영실 ' +
            '<br>직책 : 이사 ' +
            '<br>' +
            '<br>나.개인정보관리담당자 ' +
            '<br>성명 : 서영관 ' +
            '<br>소속 : (주)핏콜라보 IT인프라팀 ' +
            '<br>직책 : 팀장 ' +
            '<br>전화번호 : 070-7079-1100 ' +
            '<br>주소 : 서울시 서초구 남부순환로 2457 보성빌딩 6층' +
            '<br>E-mail : ygseo@fitcollabo.co.kr ' +
            '<br>' +
            '<br>10.개인정보의 열람청구를 접수ㆍ처리 부서 ' +
            '<br>1)“회사”는 개인정보의 열람청구와 관련하여 고객과의 원활한 의사소통을 위해 고객상담센터를 운영하고 있으며 연락처는 다음과 같습니다. ' +
            '<br>가.고객만족센터 ' +
            '<br>전화번호 : 1588-7758 ' +
            '<br>주 소 : 서울시 서초구 남부순환로 2457 보성빌딩 6층 ' +
            '<br>' +
            '<br>11.정보주체의 권익침해에 대한 구제방법' +
            '<br>개인정보에 관한 권익을 침해 당한 경우 개인정보침해신고센터, 대검찰청 컴퓨터 등 첨단범죄 신고, 사이버경찰청 등으로 문의하실 수 있습니다. ' +
            '<br>개인정보침해신고센터 : 전화번호 118 , http //privacy.kisa.or.kr' +
            '<br>대검찰청 컴퓨터 등 첨단범죄 신고 : http//www.spo.go.kr/minwon/center/report/minwon20.jsp ' +
            '<br>사이버경찰청 : 전화번호 1566-0112 ,  http //www.police.go.kr' +
            '<br>12.개인정보 처리방침 변경에 관한 사항 ' +
            "<br>본 『개인정보 처리방침』은 2017년 08월 01일에 최초 게시되며, 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 및 각 채널의 '공지사항'을 통해 고지합니다." +
            '<br>',
        },
        {
          title: '2019년02월 03일',
          content:
            '[개인 정보 처리 방침]4' +
            '<br>\n' +
            '<br>주식회사 핏콜라보(이하 “회사”라 함) 는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 준수하여야 할 관련 법규상의 개인정보보호규정을 준수하며, 개인정보보호법에 의거한 개인정보처리방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다. ' +
            "<br>개인정보처리방침의 '정보주체' 는 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을 말합니다. “회원”은 '정보주체' 중 “회사”가 제공하는 TV포인트, TV페이(TV결제), 홈빠 서비스 등(이하 총칭하여 “서비스” 라 함)를 이용하는 자를 의미합니다. " +
            '<br>' +
            '<br>“회사”의 개인정보처리방침은 다음과 같은 내용으로 구성되어 있습니다. ' +
            '<br>' +
            '<br>1.개인정보의 처리 목적 ' +
            '<br>2.처리하는 개인정보의 항목 ' +
            '<br>3.정보 처리 및 보유기간 ' +
            '<br>4.개인정보의 제3자 제공에 관한 사항 ' +
            '<br>5.개인정보처리의 위탁에 관한 사항 ' +
            '<br>6.개인정보의 파기에 관한 사항 ' +
            '<br>7.정보주체의 권리의무 및 그 행사방법에 관한 사항 ' +
            '<br>8.개인정보의 안전성 확보 조치에 관한 사항 ' +
            '<br>9.개인정보 보호책임자에 관한 사항 ' +
            '<br>10.개인정보의 열람청구를 접수/처리하는 부서 ' +
            '<br>11.정보주체의 권익침해에 대한 구제방법' +
            '<br>12.개인정보처리방침의 변경에 관한 사항' +
            '<br>' +
            '<br>1.개인정보의 처리 목적 ' +
            '<br>“회사”는 “서비스”의 제공을 위하여 다음과 같은 목적으로 “회원” 또는 “회원”이 동의한 금융사, 가맹점, 제휴사 등으로부터 정보를 수집 및 이용하고 있습니다. 단, 일부 “서비스”와 관련하여 금융사, 가맹점, 제휴사 등 정보를 제공하는 회사의 정책으로 인하여 정보 제공이 원칙적으로 이루어지지 않는 경우에는 “서비스” 제공이 불가능하거나 이용에 제한을 둘 수 있습니다.' +
            '<br>1)회원제 서비스 이용에 따른 본인여부 식별(확인), 회원가입, 회원관리 ' +
            '<br>2)“회원”이 신청하신 서비스에 대한 당사와 금융사 또는 가맹점, 제휴사 등과의 회원 인증 및 결제, 이에 따른 대금 정산 ' +
            '<br>3)“회원”이 구매하는 재화와 용역에 대한 결제, 주문, 배송 등에 대한 처리' +
            '<br>4)“회원”이 신청하신 포인트 선물 또는 포인트 충전권 등의 휴대폰 문자 발송' +
            '<br>5)“회원”의 서비스 이용내역, 잔액조회 등의 각종 조회서비스 제공' +
            '<br>6)고지사항 전달, 본인 의사 확인, 불만 처리 등 원활한 의사소통 경로의 확보, 분쟁조정을 위한 기록보존 등 ' +
            '<br>7)새로운 서비스나 이벤트 정보 등 최신 정보의 안내 ' +
            '<br>8)통계학적 분석(이용자의 연령별, 성별, 지역별 통계분석 등) 등' +
            '<br>9)기타 개인별 맞춤서비스 제공 ' +
            '<br>' +
            '<br>2. 처리하는 개인정보의 항목 ' +
            '<br>1)필수항목' +
            '<br>가.TV결제의 TV간편결제, 간편주문, ARS간편주문' +
            '<br>- “회원”의 가입 및 카드본인인증, 고객식별, 고객응대 등을 위한 정보' +
            '<br>- 성명, CI연계정보, 휴대폰번호, 남여구분, 생년월일, 내국인여부, 카드 회원 여부를 인증하기 위한 신용카드 정보(카드번호, 유효기간, CVC, 카드비밀번호 일부 등 카드사별로 정한 정보), 주문 및 결제내역, 서비스별 계정정보 등에 관한 정보 등' +
            '<br>- 주문상품 배송을 위한 배송지 주소 정보' +
            '<br>나. 홈빠' +
            '<br>       - “회원”의 가입 및 고객식별, 고객응대, 주문 및 결제정보 관리를 위한 정보' +
            '<br>- 성명, CI연계정보, 휴대폰번호, 남여구분, 생년월일, 내국인여부, 주소지정보(배송), 주문 및 결제내역' +
            '<br>다.  TV포인트' +
            '<br>- “회원”의 가입 및 본인인증, 포인트 조회/전환, 고객식별, 고객응대 등을 위한 정보' +
            '<br>- 성명, 생년월일, 남여구분, 내외국인구분, CI연계정보, 휴대폰번호, 거래내역 등 포인트에 관한 정보 등' +
            '<br>2)선택항목 : “회원”이 가입된 카드사 또는 제휴사의 회원계정 존재 여부 및 해당 계정잔액 확인, 정산 등을 위해 “회원”에게 일시적으로 제공받는 정보로써 사용 목적 달성시 까지만 이용되며 저장되지 않는 정보' +
            '<br>가.TV간편결제' +
            '<br>결제처리를 위해 등록된 간편결제 계정에 따라 카드사로부터 제공받는 신용카드 정보 ' +
            '<br>나.  TV간편결제2.0' +
            '<br>TV간편결제를 등록하지 않고 1회용 결제처리를 위한 카드번호, 유효기간, 생년월일, 비밀번호(일부), 또는 카드사가 인증을 위해 요구하는 추가 정보' +
            '<br>다.  TV포인트 : 포인트전환/적립/구매 및 사용' +
            '<br>신용카드 포인트 제휴사 : CI정보, 카드번호, 유효기간, 카드고유번호, 카드 비밀번호 일부(해당 카드사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>멤버쉽 제휴사 : 멤버쉽번호(카드번호, 회원ID 등), 멤버쉽비밀번호(해당 제휴사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>상품권, 온라인캐쉬 등 제휴사 : 상품권번호, 회원ID,(온라인캐쉬), 회원비밀번호(온라인캐쉬). (해당 제휴사별 요청 정보에 따라 상이함 - CI정보만 사용될 수 있음)' +
            '<br>포인트 선물을 위한 포인트충전권 발송 : 통화목록 또는 연락처목록에서 “회원”이 선택 또는 직접 입력한 수신자의 휴대폰번호 등' +
            '<br>      마.  기타 정보로써 사용 목적 달성 시 까지만 이용되는 정보' +
            '<br>3)“서비스” 이용과정이나 사업처리 과정에서 자동으로 수집될 수 있는 항목 ' +
            '<br>“회원”의 방송/통신 “제휴사” 및 셋톱박스 또는 휴대폰 관련 정보 (단말기ID,브라우저 종류 및 OS), 서비스 이용 기록, IP Address, 방문 일시, 서비스 이용기록, 결제기록, 쿠키, 로그정보, 세션정보, 로컬저장소 등' +
            '<br>' +
            '<br>3. 개인정보의 처리 및 보유기간 ' +
            '<br>“회사”는 「개인정보의 처리 목적」이 달성될 때까지 개인정보를 보유합니다. 단, 정보주체로 부터 사전에 동의를 받은 경우 또는 상법 등 관련법규에 의하여 보존할 필요성이 있는 다음의 경우에는 개인정보를 일정기간 동안 보유합니다. ' +
            '<br>1)계약 또는 청약철회 등에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 5년' +
            '<br>2)거래 내역 및 대금결제 등에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 5년' +
            '<br>3)소비자의 불만 또는 분쟁처리에 관한 기록 ' +
            '<br>가.보존이유 : 전자상거래 등에서의 소비자 보호에 관한 법률' +
            '<br>나.보존기간 : 3년' +
            '<br>4)본인확인에 관한 기록' +
            '<br>가.보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률' +
            '<br>나.보존 기간 : 6개월' +
            '<br>5)방문에 관한 기록' +
            '<br>가.보존 이유 : 통신비밀보호법' +
            '<br>나.보존 기간 : 3개월' +
            '<br>6)기타 고객의 동의를 받은 경우 : 동의를 받은 기간까지' +
            '<br>' +
            '<br>4.개인정보의 제3자 제공에 관한 사항 ' +
            '<br>1)"핏콜라보"가 본 약관에 의하여 “서비스”를 위하여 정보제공에 대한 계약을 체결한 회사는 다음과 같습니다. 다만, “회원”이 해당 “서비스”를 이용하지 않을 경우에는 정보가 제공되지 않습니다.' +
            '<br>가.TV결제 서비스 (간편주문 포함)' +
            '<br>- 카드사 : 신한카드㈜, 비씨카드㈜,  ㈜KB국민카드, 농협은행㈜, 삼성카드㈜, 하나카드㈜, 롯데카드㈜, 현대카드㈜' +
            '<br>- 홈쇼핑 가맹점 : 케이티하이텔㈜, ㈜에스케이스토아, ㈜신세계티비쇼핑, ㈜쇼핑엔티, ㈜더블유쇼핑, ㈜엔에스쇼핑, ㈜현대홈쇼핑, ㈜우리홈쇼핑, ㈜씨제이이엔엠, ㈜지에스홈쇼핑, ㈜홈앤쇼핑, ㈜공영홈쇼핑' +
            '<br>- 콘텐츠 가맹점 : ㈜케이티, ㈜엘지유플러스, SK브로드밴드㈜, ㈜케이티스카이라이프, ㈜KCTV제주방송,' +
            '<br>나. 홈빠 서비스 제휴 홈쇼핑사' +
            '<br>- 케이티하이텔㈜, ㈜에스케이스토아, ㈜신세계티비쇼핑, ㈜쇼핑엔티, ㈜더블유쇼핑, ㈜엔에스쇼핑, ㈜현대홈쇼핑, ㈜우리홈쇼핑, ㈜씨제이이엔엠, ㈜지에스홈쇼핑, ㈜홈앤쇼핑, ㈜공영홈쇼핑' +
            '<br>다.포인트 전환/적립 제휴사 ' +
            '<br>신한카드㈜, 비씨카드㈜, ㈜KB국민카드, 농협은행㈜, 우리카드㈜, 현대카드㈜, 하나카드㈜, 삼성카드㈜, 우정사업본부, 롯데멤버스㈜, ㈜타미마케팅, ㈜이노션, ㈜GS엠비즈, ㈜S-오일, 현대오일뱅크㈜, 한국문화진흥㈜, ㈜해피머니, 한국도서보급㈜, SK플래닛㈜, 이제너두㈜, 한국선불카드㈜, ㈜NHN페이코' +
            '<br>라.포인트 사용 제휴사 : “회원”이 이용하는 사업자에 한정함.' +
            '<br>㈜케이티, ㈜엘지유플러스, ㈜LG헬로비전, ㈜딜라이브, ㈜HCN, SK브로드밴드㈜, ㈜케이티스카이라이프, ㈜KCTV제주방송, ㈜JCN울산방송, ㈜지니뮤직(M-net 포함), ㈜카카오페이지, TJ커뮤니케이션㈜, ㈜수커뮤니케이션, ㈜카카오, ㈜십일번가, ㈜해피머니아이엔씨, ㈜티몬 ' +
            '<br>마.휴대폰 본인인증 서비스 : 코리아크레딧뷰로㈜, 한국모바일인증㈜, 나이스신용정보㈜' +
            '<br>(휴대폰 본인인증 서비스 이용약관은 해당 약관 하단에 별도 표기)' +
            '<br>' +
            '<br>2)“회사”는 「개인정보의 처리 목적」에서 고지한 범위 내에서 개인정보를 제3자에게 제공하며, 동 범위를 초과하여 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다. ' +
            '<br>가.“회원”으로 부터 사전에 개인정보를 목적 외의 용도로 제공하는 것에 대한 별도 동의를 받은 경우 ' +
            '<br>나.주문처리 및 배송 업무상 배송업체에게 배송에 필요한 최소한의 정보(성명, 휴대폰번호, 주소)를 “회원”이 알려주는 경우 ' +
            '<br>다.통계처리, 학술연구, 시장조사 등을 위하여 필요한 경우로서 특정한 개인을 식별할 수 없는 형태로 개인정보를 제공하는 경우 ' +
            '<br>라.다른 법률에 특별한 규정이 있는 경우 ' +
            '<br>마.정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우 ' +
            '<br>바.“회사”가 법령상 의무를 준수하기 위하여 불가피하게 수집한 개인정보를 그 수집 목적 범위 내에서 제공하는 경우 ' +
            '<br>' +
            '<br>5.개인정보처리의 위탁에 관한 사항 ' +
            '<br>“회사”는 더 나은 서비스를 제공하기 위하여 정보주체의 개인정보 처리를 외부전문기관에 위탁할 수 있습니다. ' +
            '<br>1)“회사”는 개인정보 처리를 위탁하는 경우 위탁하는 업무의 내용과 수탁자를 정보주체가 언제든지 쉽게 확인할 수 있도록 “회사”의 인터넷 홈페이지, TV채널, 모바일앱 등에 지속적으로 게재하여 공개합니다. 특히, “회사”가 재화 또는 서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 서면, 전자우편, TV/모바일 어플리케이션, 인터넷 홈페이지 상의 동의화면, 팩스, 전화, 문자전송 또는 이에 상당하는 방법 (이하 "서면 등의 방법"이라 합니다)으로 위탁하는 업무의 내용과 수탁자를 정보주체에게 알려드립니다. 위탁하는 업무의 내용이나 수탁자가 변경된 경우에도 동일합니다. 만약, “회사”가 과실 없이 “서면 등의 방법”으로 정보주체에게 알릴 수 없는 경우에는 해당 사항을 “회사”의 인터넷 홈페이지, TV채널, 모바일앱 등에 30일 이상 게재합니다. ' +
            '<br>2)“회사”는 수탁자에게 다음의 내용이 포함된 문서에 의하여 개인정보의 처리 업무를 위탁하고 있으며, 당해 문서를 서면 또는 전자적으로 보관하여 정보주체의 권익을 보호합니다. ' +
            '<br>가.위탁업무의 목적 및 범위 ' +
            '<br>나.위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항 ' +
            '<br>다.재위탁 제한에 관한 사항 ' +
            '<br>라.개인정보의 기술적, 관리적 보호조치에 관한 사항' +
            '<br>마.개인정보에 대한 접근 권한 등 안전성 확보 조치에 관한 사항 ' +
            '<br>바.위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항 ' +
            '<br>사.수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 등에 관한 사항 ' +
            '<br>' +
            '<br>※취급 위탁 현황 ' +
            '<br>- 취급 위탁 없음 ' +
            '<br>' +
            '<br>6.개인정보의 파기에 관한 사항 ' +
            '<br>“회사”는 「개인정보의 처리 목적」이 달성되면 다음과 같은 절차 및 방법에 따라 개인정보를 파기합니다. ' +
            '<br>1)개인정보는 「개인정보의 처리 목적」이 달성된 후 별도의 데이터베이스(DB)로 옮겨져 (종이의 경우 별도의 서류함) 개인정보의 처리 및 보유기간」에 따라 일정 기간 보관된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고서는 보존 이외의 다른 목적으로 이용되거나 제공되지 않습니다. ' +
            '<br>2)전자적 파일 형태인 경우 복원이 불가능한 방법으로 영구 삭제하며, 그 외의 기록물, 인쇄물, 서면, 기타 기록매체인 경우에는 파쇄, 소각, 용해 등의 방법으로 파기합니다. ' +
            '<br>' +
            '<br>7.정보주체의 권리의무 및 그 행사방법에 관한 사항 ' +
            '<br>1)개인정보의 열람 ' +
            '<br>가.정보주체는 “회사”가 처리하는 자신의 개인정보에 대하여 행정안전부령으로 정하는 바에 따라 개인정보 열람요구서를 회사에 제출하는 등의 방법으로 열람을 요구할 수 있습니다. ' +
            '<br>나.“회사”는 열람을 요구 받으면 그로부터 10일 이내에 정보주체가 해당 개인정보를 열람할 수 있게 합니다. 만약 해당 기간 내에 열람할 수 없는 사유가 있을 때에는 요구를 받은 날부터 10일 이내에 정보주체에게 그 사유 및 이의제기 방법을 알리고 열람을 연기할 수 있으며, 그 사유가 소멸한 경우에는 정당한 사유가 없는 한 사유가 소멸한 날로부터 10일 이내에 열람하도록 할 수 있습니다. ' +
            '<br>다.“회사”는 다음의 어느 하나에 해당하는 경우 정보주체에게 그 사유를 알리고 열람을 제한하거나 거절할 수 있습니다. 이 경우 열람이 제한되는 사항을 제외한 부분은 열람할 수 있으며, 열람 요구를 받은 날부터 10일 이내에 그 사유 및 이의제기방법을 알려드립니다. ' +
            '<br>① 법률에 따라 열람이 금지되거나 제한되는 경우 ' +
            '<br>② 다른 사람의 생명신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>2)개인정보의 정정 및 삭제 ' +
            '<br>가.「개인정보의 열람」에 따라 자신의 개인정보를 열람한 정보주체는 그 개인정보의 정정 또는 삭제를 행정안전부령으로 정하는 바에 따라 개인정보 정정 및 삭제 요구서를 회사에 제출하는 등의 방법으로 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. ' +
            '<br>나.“회사”는 정보주체의 요구를 받은 경우 개인정보의 정정 또는 삭제에 관하여 다른 법령에 특별한 절차가 규정되어 있는 경우를 제외하고는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 그 개인정보를 조사하여 정보주체의 요구에 따라 정정 및 삭제 등 필요한 조치를 한 후 그 결과를 알려드립니다. 제휴사 등 다른 개인정보처리자로부터 적법하게 제공받은 개인정보의 정정 또는 삭제 요구를 받으면 제공한 기관에도 정정ㆍ삭제 요구를 알려드리고 그 처리 결과에 따라 필요한 조치를 취합니다. “회사”는 조사를 할 때 필요하면 개인에게 정정 및 삭제 요구사항의 확인에 필요한 증거를 요청할 수 있으며, 개인정보를 삭제할 때에는 복구 또는 재생되지 아니하도록 조치하고 있습니다. ' +
            '<br>다.“회사”는 다음의 어느 하나에 해당하는 경우 정당한 사유가 없는 한 정보주체의 요구를 받은 날로부터 10일 이내에 정정ㆍ삭제를 할 수 없는 사유와 이의제기 방법을 알려드립니다. ' +
            '<br>① 법률에 따라 열람이 금지되거나 제한되는 경우 ' +
            '<br>② 다른 사람의 생명이나 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>3)개인정보의 처리정지 ' +
            '<br>가.정보주체는 “회사”에 대하여 행정안전부령으로 정하는 바에 따라 개인정보 처리정지 요구서를 “회사”에 제출하는 등의 방법으로 자신의 개인정보 처리의 정지를 요구할 수 있습니다. ' +
            '<br>나.“회사”는 정당한 사유가 없는 한 요구를 받은 날로부터 10일 이내에 정보주체의 요구에 따라 개인정보 처리의 전부를 정지하거나 일부를 정지하고, 처리가 정지된 개인정보에 대하여 처리정지의 요구를 받은 날로부터 10일 이내에 필요한 조치를 하여야 합니다. 다만, 다음의 어느 하나에 해당하는 경우에는 정보주체의 처리정지 요구를 거절할 수 있으며, 이 경우 정보주체에게 요구를 받은 날로부터 10일 이내에 그 사유를 알려주어야 합니다. ' +
            '<br>① 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우 ' +
            '<br>② 다른 사람의 생명이나 신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우 ' +
            '<br>③ 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행 이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우 ' +
            '<br>4)권리행사의 방법 및 절차 ' +
            '<br>“회사”는 개인정보의 수집 시에 요구되지 않았던 증빙서류 등을 요구하거나 추가적인 절차를 요구하지 않습니다. ' +
            '<br>가.정보주체는 개인정보의 열람, 정정 및 삭제, 처리정지 요구를 대리인(정보주체의 법정대리인, 정보주체로부터 위임을 받은 자)에게 하게 할 수 있습니다. 이 경우, 대리인은 “회사”에 정보주체의 위임장을 제출하여야 합니다. ' +
            '<br>나.만 14세 미만 아동의 법정대리인은 “회사”에게 그 아동의 개인정보 열람, 정정 및 삭제, 처리정지 요구를 할 수 있습니다. ' +
            '<br>' +
            '<br>8.개인정보의 안전성 확보 조치에 관한 사항 ' +
            '<br>“회사”는 정보주체의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 안전성 확보 조치를 강구하고 있습니다. ' +
            '<br>가.개인정보 내부관리계획의 수립 및 시행' +
            '<br>“회사”는 개인정보의 안전한 처리를 위하여 다음의 사항을 포함하는 내부관리계획을 수립 및 시행하고 있습니다. ' +
            '<br>① 개인정보 보호책임자의 지정에 관한 사항 ' +
            '<br>② 개인정보 보호책임자 및 개인정보처리자의 역할 및 책임에 관한 사항 ' +
            '<br>③ 개인정보의 안전성 확보에 필요한 조치에 관한 사항 ' +
            '<br>④ 개인정보처리자에 대한 교육에 관한 사항 ' +
            '<br>⑤ 그 밖에 개인정보 보호를 위하여 필요한 사항 ' +
            '<br>나.개인정보에 대한 접근 통제 및 접근 권한의 제한 조치 ' +
            '<br>① “회사”는 개인정보처리시스템에 대한 접근권한을 업무 수행에 필요한 최소한의 범위로 하여 업무 담당자 별로 한 개의 사용자 계정을 차등 부여하고 있으며, 개인정보처리자가 변경되었을 경우 지체 없이 개인정보처리시스템의 접근권한을 변경 또는 말소하고 그 기록을 최소 3년간 보관하고 있습니다. ' +
            '<br>② “회사”는 개인정보처리자의 비밀번호 작성규칙을 수립하여 적용하고 있습니다. ' +
            '<br>③ “회사”는 개인정보처리시스템에 대한 불법적인 접근 및 침해사고 방지를 위해 접근 제한 시스템과 유출 시도 탐지 시스템을 운영하고 있습니다. 개인정보처리자가 외부에서 “회사”의 개인정보처리시스템에 접속하려는 경우에는 가상사설망(VPN) 또는 전용선 등 안전한 접속수단에 의한 접속만 가능합니다. 또한, 개인정보가 권한이 없는 자에게 공개되거나 외부에 유출되지 않도록 개인정보처리시스템 및 업무용 컴퓨터에 조치를 취하고 있습니다.' +
            '<br>다.개인정보 암호화 기술의 적용 또는 이에 상응하는 조치 ' +
            '<br>① “회사”는 개인의 고유식별정보, 비밀번호 및 핸드폰번호를 정보통신망을 통하여 송/수신하거나 보조저장매체 등을 통하여 전달하는 경우에는 이를 암호화하고 있습니다. 비밀번호 및 바이오정보는 안전한 암호알고리즘으로 암호화하여 저장합니다. ' +
            '<br>② “회사”는 인터넷 구간 및 인터넷 구간과 내부망의 중간 지점 (DMZ Demilitarized Zone)에 고유식별정보를 저장하는 경우에는 이를 암호화하여야 합니다. ' +
            '<br>③ “회사”는 부득이한 사유로 업무용 컴퓨터에 고유식별정보를 저장하여 관리하는 경우 상용 암호화 소프트웨어 또는 안전한 암호화 알고리즘을 사용하여 암호화하여 저장하고 있습니다. ' +
            '<br>라.접속기록의 보관 및 위조ㆍ변조 방지를 위한 조치 ' +
            '<br>“회사”는 개인정보처리자가 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관 및 관리하며, 개인정보처리자의 접속기록이 위변조 및 도난, 분실되지 않도록 해당 접속기록을 안전하게 보관하고 있습니다. ' +
            '<br>마.개인정보에 대한 보안프로그램의 설치 및 갱신' +
            '<br>“회사”는 개인정보처리시스템 또는 업무용 컴퓨터에 악성 프로그램 등을 방지 및 치료할 수 있는 백신 소프트웨어 등의 보안 프로그램을 설치 및 운영하고 있으며, 보안 프로그램의 자동 업데이트 기능을 사용하고 월 1회 PC 검사를 실시하고 있습니다. 또한 악성 프로그램관련 경보가 발령된 경우 또는 사용 중인 응용 프로그램이나 운영체제 소프트웨어의 제작업체에서 보안 업데이트 공지가 있는 경우, 즉시 이에 따른 업데이트를 실시하고 있습니다. ' +
            '<br>바.개인정보의 안전한 보관을 위한 물리적 조치' +
            '<br>“회사”는 전산실, 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소에 대한 출입통제 절차를 수립 및 운영하고 있으며, 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다. ' +
            '<br>' +
            '<br>9.개인정보 보호책임자에 관한 사항 ' +
            '<br>“회사”는 고객의 개인정보를 보호하고 개인정보와 관련한 고객의 불만사항을 처리하기 위하여 개인정보보호책임자를 두고 있습니다. 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호책임자 또는 개인정보관리담당자에게 연락하여 주시기 바랍니다. ' +
            '<br>' +
            '<br>가.개인정보보호책임자' +
            '<br>성명 : 안평해 ' +
            '<br>소속 : (주)핏콜라보 운영실 ' +
            '<br>직책 : 이사 ' +
            '<br>' +
            '<br>나.개인정보관리담당자 ' +
            '<br>성명 : 서영관 ' +
            '<br>소속 : (주)핏콜라보 IT인프라팀 ' +
            '<br>직책 : 팀장 ' +
            '<br>전화번호 : 070-7079-1100 ' +
            '<br>주소 : 서울시 서초구 남부순환로 2457 보성빌딩 6층' +
            '<br>E-mail : ygseo@fitcollabo.co.kr ' +
            '<br>' +
            '<br>10.개인정보의 열람청구를 접수ㆍ처리 부서 ' +
            '<br>1)“회사”는 개인정보의 열람청구와 관련하여 고객과의 원활한 의사소통을 위해 고객상담센터를 운영하고 있으며 연락처는 다음과 같습니다. ' +
            '<br>가.고객만족센터 ' +
            '<br>전화번호 : 1588-7758 ' +
            '<br>주 소 : 서울시 서초구 남부순환로 2457 보성빌딩 6층 ' +
            '<br>' +
            '<br>11.정보주체의 권익침해에 대한 구제방법' +
            '<br>개인정보에 관한 권익을 침해 당한 경우 개인정보침해신고센터, 대검찰청 컴퓨터 등 첨단범죄 신고, 사이버경찰청 등으로 문의하실 수 있습니다. ' +
            '<br>개인정보침해신고센터 : 전화번호 118 , http //privacy.kisa.or.kr' +
            '<br>대검찰청 컴퓨터 등 첨단범죄 신고 : http//www.spo.go.kr/minwon/center/report/minwon20.jsp ' +
            '<br>사이버경찰청 : 전화번호 1566-0112 ,  http //www.police.go.kr' +
            '<br>12.개인정보 처리방침 변경에 관한 사항 ' +
            "<br>본 『개인정보 처리방침』은 2017년 08월 01일에 최초 게시되며, 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지 및 각 채널의 '공지사항'을 통해 고지합니다." +
            '<br>',
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
select::-ms-expand {
  display: none;
}
.select {
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 100%;
  height: 72px;
  border-radius: 8px;
  border: solid 1px #a8aaac;

  padding-left: 24px;

  // 제플린 기준
  //line-height: 1.14;
  line-height: 2.84;
  @include text(28, #76787a, '', -1.12);
}

select {
  background: url('../../../../../../assets/img/shoppingPoint/btn_my_shop_list.png') no-repeat 95% 50%;
}

.terms-content-wrapper {
  word-break: break-all;
  word-wrap: break-word;
  @include text(28, #343534, '', -1.4);
}

select option[value='0'][disabled] {
  display: none;
}
</style>
