import getDefaultState from './state';
import mutations from './mutations';
import actions from './actions';

const state = getDefaultState;

//defaultState 는 초기화 할때 한번에 선언하기 위해서.
export default {
  defaultState: getDefaultState,
  state,
  mutations,
  actions,
};
