import $commons from '@/utils/commons';
import $store from '@/store';
import $bridge from '@/utils/FTBridgeShared';

import { fitReqRelay, getPayCardList, fitGetAddr, appCardSmartConfirm, orderSave, cardCompanyList } from '@/apis/orderPayment';

const actions = {
  async fitReqRelay({ commit }, params) {
    const { data } = await fitReqRelay(params);
    return data;
  },
  async fitGetAddr({ commit }, params) {
    const { data } = await fitGetAddr(params);
    // mUserNo가 null일때 data의 mUserNo로 commit //-- dh 조건 추가
    if (
      $store.state.orderPayment.tvPayUserNo === 'null' ||
      $commons.isNull($store.state.orderPayment.tvPayUserNo) ||
      $store.state.orderPayment.tvPayUserNo === undefined
    ) {
      commit('setTvPayUserNo', data.mUserNo);

      // deviceType 1로 분기 되어있던 코드 통합 espark
      let mUserNo = await $bridge.shared().stringValue('mUserNo');
      if (mUserNo === 'null' || $commons.isNull(mUserNo) || mUserNo === undefined) {
        $bridge.shared().setStringValue('mUserNo', data.mUserNo);
      }
    }
    return data;
  },
  async getPayCardList({ commit }, params) {
    const { data } = await getPayCardList(params);
    return data;
  },
  async appCardSmartConfirm({ commit }, params) {
    const { data } = await appCardSmartConfirm(params);
    return data;
  },
  async orderSave({ commit }, params) {
    const { data } = await orderSave(params);
    return data;
  },
  async getCardCompanyList({ commit }, params) {
    const { data } = await cardCompanyList(params);
    return data;
  },
};

export default actions;
