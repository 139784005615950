<template>
  <div>
    <section class="column__title">
      <ColumnHeader :class="isMore ? 'optionsWrap' : 'optionsoptionsWrapMore'" @click="clickEvent('option', '', '', $event)">
        <template #title>
          <div class="options d-flex">
            {{ optionTitle }} 수령예정
            <img
              :src="require('@/assets/img/bbarshop/ic_warning.svg')"
              alt=""
              class="ic_warning"
              style="margin-left: 8px"
              @click="clickEvent('alertPopup')"
            />
          </div>
        </template>
        <template #right>
          <img ref="option" :src="require('@/assets/img/common/img_arr_low2.svg')" class="column__title__arrow" alt="" />
        </template>
      </ColumnHeader>
      <div ref="optionMore" class="column__title__more off" style="padding-top: 25px">
        <div
          v-for="(dateItem, itemIdx) in availableDates"
          :key="itemIdx"
          :class="itemIdx === availableDates.length - 1 ? 'd-flex section margin-less' : 'd-flex section margin'"
          style="line-height: 1.36"
          @click="clickEvent('check', dateItem, itemIdx)"
        >
          <div>
            <input
              :id="item.id"
              :ref="item.id"
              v-model="ongoingChecked"
              type="radio"
              name="store"
              class="check-all"
              :value="item.id"
              :checked="clickOption()"
            />
            <label :for="item.id"></label>
          </div>
          <CommonText font-size="28" font-color="#2b2d2f" spacing="-1.12">{{
            formattedDate(item.date) + '(' + item.dayOfWeek + ')'
          }}</CommonText>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ColumnHeader from '@/components/menu/ColumnHeader';
import CommonText from '@/components/common/text/CommonText';
import { fitMixin } from '@/utils/fitMixin';

export default {
  name: 'DateSelect',

  components: {
    ColumnHeader,
    CommonText,
  },
  mixins: [fitMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: Number,
      default: 0,
    },
    availableDates: {
      type: Array,
      default: () => [],
    },
    isClicked: {
      type: Boolean,
      default: false,
    },
    dateIdx: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      count: 1,
      btnMinusImg: require('@/assets/img/common/btn_minus.svg'),
      btnPlusImg: require('@/assets/img/common/btn_plus.svg'),
      // optionTitle: this.reserveList ? this.reserveList[0].name : null,
      optionTitle: '',
      clickId: [],
      ongoingChecked: 0,
      //체크박스 선택 상태
      selectedOptions: [],
      isMore: true,
    };
  },
  computed: {
    availableDatesList() {
      return !this.$commons.isNull(this.$store.state.bbarshop.availableDates) ? this.$store.state.bbarshop.availableDates : [];
    },
    formattedDate() {
      return dateString => {
        const result = dateString.replace(/(0[1-9]|10|11|12)월 (0[1-9]|[1-2][0-9]|3[0-1])일/g, (match, p1, p2) => {
          const month = p1.replace(/^0/, ''); // '월' 앞에 있는 '0' 제거
          const day = p2.replace(/^0/, ''); // '일' 앞에 있는 '0' 제거
          return `${month}월 ${day}일`;
        });

        return result;
      };
    },
  },
  watch: {
    isClicked: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.$nextTick(() => {
            this.$refs.optionMore.classList.toggle('off');
            this.$refs.option.classList.toggle('clicked');
          });
        }
      },
    },
    availableDatesList: {
      immediate: true,
      handler(newVal) {
        this.optionTitle = this.formattedDate(this.$store.state.bbarshop.availableDates[0].date);
        // this.$store.commit('setSelectedDate', {
        // 	idx: 0,
        // 	date: this.$store.state.bbarshop.availableDates[0],
        // 	shippingHopeDate: this.formatDate(this.$store.state.bbarshop.availableDates[0]),
        // });
      },
    },
    dateIdx: {
      immediate: true,
      handler(newVal) {
        if (this.dateIdx) {
          this.optionTitle = this.formattedDate(this.$store.state.bbarshop.availableDates[this.dateIdx].date);
          // this.$store.commit('setSelectedDate', {
          // 	idx: this.dateIdx,
          // 	date: this.$store.state.bbarshop.availableDates[this.dateIdx],
          // 	shippingHopeDate: this.formatDate(this.$store.state.bbarshop.availableDates[this.dateIdx]),
          // });
          this.ongoingChecked = this.dateIdx;
        }
      },
    },
  },
  created() {},
  methods: {
    clickEvent(sort, item, idx, e) {
      switch (sort) {
        case 'option': {
          // this.$refs.optionMore.classList.toggle('off');
          // this.$refs.option.classList.toggle('clicked');
          // this.isMore = this.$refs.optionMore.classList.contains('off');
          if (e.target.className && !e.target.className.includes('ic_warning')) {
            this.$refs.optionMore.classList.toggle('off');
            this.$refs.option.classList.toggle('clicked');
            this.isMore = this.$refs.optionMore.classList.contains('off');
          }

          break;
        }
        case 'check': {
          //라디오 박스 선택시 드롭박스 닫히기
          // this.$store.commit('setSelectedDate', {
          // 	idx: idx,
          // 	date: item,
          // 	shippingHopeDate: this.formatDate(item),
          // });
          this.$refs.optionMore.classList.add('off');
          this.$refs.option.classList.remove('clicked');
          this.isMore = this.$refs.optionMore.classList.contains('off');

          this.optionTitle = this.formattedDate(item.date);
          this.ongoingChecked = idx;

          // this.$emit('quantity', true);
          // this.$emit('price', this.dataList.price_discount);
          // if (this.dataList.price_discount === 0) {
          // 	this.$emit('price', this.dataList.price_original + item.add_price);
          // } else {
          // 	this.$emit('price', this.dataList.price_discount + item.add_price); //할인금액 + 추가금액
          // }

          // this.$emit('options', item);
          break;
        }
        case 'alertPopup':
          this.$store.commit('alertPopup', {
            popupType: 'ErrorPopup',
            popupContent: '택배사의 사정에 따라 배송 일정이 달라 질 수 있습니다.',
          });
          break;
      }
    },
    clickOption() {
      //버튼 활성화
      // this.$emit('ongoingChecked', this.ongoingChecked);
    },
    extractMonthAndDay(dateStr) {
      const [month, day] = dateStr.match(/\d+/g);
      return [month.padStart(2, '0'), day.padStart(2, '0')];
    },
    formatDate(dataObj) {
      const [month, day] = this.extractMonthAndDay(dataObj.date);
      const result = `${dataObj.year}${month}${day}`;
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.column__title {
  //font-family: NotoSansCJKkr;

  #{&}__arrow {
    transition: transform 300ms ease-in-out;
    margin-left: 24px;

    &.clicked {
      transition: transform 300ms ease-in-out;
      transform: rotate(180deg);
    }
  }

  #{&}__more {
    & {
      display: block;
    }

    &.off {
      display: none;
    }
  }
}

.optionsWrap {
  width: auto;
  height: 78px;
  padding: 0 31px;
  border: solid 1px #dadcde;
  border-radius: 8px;
}
.optionsoptionsWrapMore {
  width: auto;
  height: 80px;
  padding: 0 32px;
  background-color: #f2f3f5;
  border-radius: 8px 8px 0 0;
}
.options {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -1.12px;
  // text-align: left;
  color: #648cff;
}
.column__title__more {
  overflow: scroll;
  //width: 100%;
  height: 348px;
  border: solid 1px #dadcde;
  border-top: none;
  border-radius: 0 0 8px 8px;
}
.check-all:checked + label {
  margin-right: 16px;
  float: left;
  display: inline-block;
  width: 42px;
  height: 42px;
  background: url('~@/assets/img/common/btn_radio_on.svg') no-repeat;
  box-sizing: border-box;
}

.check-all {
  display: none;
  float: left;
  box-sizing: border-box;
}

.check-all + label {
  margin-right: 16px;
  float: left;
  display: inline-block;
  width: 42px;
  height: 42px;
  background: url('~@/assets/img/common/btn_radio_off.svg') no-repeat;
  box-sizing: border-box;
}
.margin-less {
  margin: auto auto 25px 32px;
}
.margin {
  margin: auto auto 41px 32px;
}
</style>
