<template>
  <SlidingContentWrapper class="margin-top-24">
    <template #content>
      <div class="padding-left-40 padding-right-40">
        <PaiBenefitCheckbox ref="terms0" v-model="terms0" checkbox-name="terms0" @click-event="clickEvent">
          <template #text>
            <TermsTopTitle>[필수] 개인정보 제 3자 제공에 대한 동의</TermsTopTitle>
          </template>
        </PaiBenefitCheckbox>
        <PaiBenefitCheckbox ref="terms0" v-model="terms1" checkbox-name="terms1" class="padding-top-24" @click-event="clickEvent">
          <template #text>
            <TermsTopTitle>[필수] 개인정보 수집, 이용에 대한 동의</TermsTopTitle>
          </template>
        </PaiBenefitCheckbox>

        <PaiBenefitGroupCheckbox class="margin-top-56" checkbox-name="terms2" group-name="채널 약관" @click-event="clickEvent" />
        <!--			<PaiBenefitCheckbox ref="terms0" v-model="terms2" @clickEvent="clickEvent" checkbox-name="terms2" class="padding-top-56"-->
        <!--				><TermsTopTitle slot="text" :is-arrow="false">채널 약관</TermsTopTitle>-->
        <!--			</PaiBenefitCheckbox>-->

        <!--			<div class="padding-top-24">-->
        <!--				<PaiBenefitCheckbox-->
        <!--					ref="subTerms0"-->
        <!--					@clickEvent="clickEvent"-->
        <!--					checkbox-name="subTerms1"-->
        <!--					checkbox-group="terms2"-->
        <!--					background-image-on="img/tvpoint/check_on.svg"-->
        <!--					background-image-off="img/tvpoint/check_off.svg"-->
        <!--					><TermsSubTitle slot="text">[필수] 이용약관</TermsSubTitle></PaiBenefitCheckbox-->
        <!--				>-->
        <!--				<PaiBenefitCheckbox-->
        <!--					@clickEvent="clickEvent"-->
        <!--					ref="subTerms1"-->
        <!--					class="padding-top-8"-->
        <!--					checkbox-name="subTerms2"-->
        <!--					checkbox-group="terms2"-->
        <!--					background-image-on="img/tvpoint/check_on.svg"-->
        <!--					background-image-off="img/tvpoint/check_off.svg"-->
        <!--					><TermsSubTitle slot="text">[필수] 이용약관</TermsSubTitle></PaiBenefitCheckbox-->
        <!--				>-->
        <!--				<PaiBenefitCheckbox-->
        <!--					@clickEvent="clickEvent"-->
        <!--					ref="subTerms2"-->
        <!--					class="padding-top-8"-->
        <!--					checkbox-name="subTerms3"-->
        <!--					checkbox-group="terms2"-->
        <!--					background-image-on="img/tvpoint/check_on.svg"-->
        <!--					background-image-off="img/tvpoint/check_off.svg"-->
        <!--					><TermsSubTitle slot="text">[필수] 이용약관</TermsSubTitle></PaiBenefitCheckbox-->
        <!--				>-->
        <!--			</div>-->

        <CommonRadiusButton class="margin-top-56" height="100px" radius="8" color="#2b2d2f" @click-event="clickJoin">
          <CommonText font-size="32" font-color="#fff" spacing="-1.28">가입하기</CommonText>
        </CommonRadiusButton>
      </div>
    </template>
  </SlidingContentWrapper>
</template>

<script>
import SlidingContentWrapper from '@/components/common/popup/slidingPopupContent/SlidingContentWrapper';
import PaiBenefitCheckbox from '@/components/common/popup/slidingPopupContent/pai/join/PaiBenefitCheckbox';
import TermsTopTitle from '@/components/common/popup/slidingPopupContent/pai/join/TermsTopTitle';
import CommonRadiusButton from '@/components/common/button/CommonRadiusButton';
import CommonText from '@/components/common/text/CommonText';
import PaiBenefitGroupCheckbox from '@/components/common/popup/slidingPopupContent/pai/join/PaiBenefitGroupCheckbox';
export default {
  name: 'JoinIndex',
  components: {
    PaiBenefitGroupCheckbox,
    CommonText,
    CommonRadiusButton,
    TermsTopTitle,
    PaiBenefitCheckbox,
    SlidingContentWrapper,
  },
  data() {
    return {
      terms0: false,
      terms1: false,
      terms2: false,
      terms: [],
      termsData: [
        { title: '개인정보 수집 및 이용동의', termType: 2, userType: 'mobile_pay', methodId: 'SMART_PAY' },
        { title: '개인정보 제3자 제공에 대한 동의', termType: 3, userType: 'mobile_pay', methodId: 'SMART_PAY' },
      ],
    };
  },
  watch: {},
  methods: {
    clickEvent(sort, data) {
      switch (sort) {
        case 'checkCheckbox':
          this[data.name] = data.checked;
          break;
      }
    },
    clickJoin() {
      this.$commons.printLog('회원가입');
    },
  },
};
</script>

<style scoped lang="scss">
.image-wrapper {
  width: 20px;
  height: 20px;
}
.sliding-wrap {
  margin-top: 40px;
  box-sizing: border-box;
}

.button-wrapper {
  position: fixed;
  bottom: 0;
}
.refresh-wrapper {
  position: absolute;
  width: 48px;
  height: 48px;
  background: red;

  margin-top: -1px;
  left: calc(100% - 80px);
}
</style>
