<template>
  <transition name="modal">
    <div v-if="showModal" ref="modal-mask" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <component :is="pages" name="componentBody" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import AppTutorial from '@/components/common/AppTutorial';

/**
 * 컴포넌트 설명
 this.$store.commit('setTutorialModal', {
						show: true,
						pages: 'HomeShowList',
					});
 */
export default {
  name: 'TutorialModal',

  components: {
    AppTutorial,
  },

  data() {
    return {
      scrollY: 0,
    };
  },

  computed: {
    showModal() {
      return this.$store.state.common.tutorialModal.show;
    },
    pages() {
      return this.$store.state.common.tutorialModal.pages;
    },
  },

  watch: {
    showModal(flag) {
      if (this.$store.state.common.mainIdx !== 0) {
        flag ? this.lockParentScroll() : this.unLockParentScroll();
      }
    },
  },

  methods: {
    lockParentScroll() {
      this.scrollY = window.scrollY;
      document.getElementsByTagName('body')[0].style = 'width:100%; position:fixed; top: -' + this.scrollY + 'px';
    },
    //팝업 종료 시 부모창 스크롤 잠금해제
    unLockParentScroll() {
      document.getElementsByTagName('body')[0].style = '';
      window.scrollTo(0, this.scrollY);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.close-modal {
  flex-direction: row-reverse;
  margin: 35px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
