<template>
  <div class="execution-popup">
    <div v-if="bannerListLength > 1">
      <swiper class="swiper" v-bind="swiperOptions" @swiper="onSwiper">
        <swiper-slide v-for="(item, index) in dataList" :key="index.priority" style="">
          <img :src="item.component_upload" alt="" @click="clickEvent('eventDetail', index)" />
        </swiper-slide>
        <div ref="pagination" class="swiper-pagination" @click="clickEvent('showEventList')"></div>
      </swiper>
    </div>
    <div v-else-if="bannerListLength === 1" class="execution-popup-content">
      <img :src="dataList[0].component_upload" alt="" @click="clickEvent('eventDetail', 0)" />
    </div>

    <div class="d-flex c-flex-start r-space-between" style="width: 640px">
      <CommonText v-if="getBannerType === 'end'" font-color="#8f9193" line-height="48" @click="clickEvent('exit')">종료</CommonText>
      <CommonText v-else font-color="#8f9193" line-height="48" @click="clickEvent('closeForToday')">오늘 하루 그만보기</CommonText>
      <CommonText line-height="48" @click="clickEvent('closeSlidingPopup')">닫기</CommonText>
    </div>
  </div>
</template>

<script>
import { fitMixin } from '@/utils/fitMixin';
import CommonText from '@/components/common/text/CommonText';

import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

export default {
  name: 'ExecutionBanner',

  components: { CommonText },

  mixins: [fitMixin],

  data() {
    return {
      swiper: null,
      swiperOptions: {
        loop: true,
        watchOverflow: true,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 40,
        autoplay: { delay: 10000, disableOnInteraction: false },
        on: {},
        modules: [Pagination, Autoplay],
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
        },
        nested: true,
      },
    };
  },

  computed: {
    dataList() {
      return !this.$commons.isNull(this.$store.state.fit.executionBannerList) ? this.$store.state.fit.executionBannerList.item_list : [];
    },
    bannerListLength() {
      return this.$commons.isNull(this.dataList) ? -1 : this.dataList.length;
    },
    getBannerType() {
      return this.$store.state.common.setSlidePopup.flag ?? '';
    },
  },

  activated() {
    if (this.swiper && this.swiper.autoplay) {
      this.swiper.autoplay.resume();
    }
  },

  deactivated() {
    if (this.swiper && this.swiper.autoplay) {
      this.swiper.autoplay.pause();
    }
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    async clickEvent(sort, idx) {
      switch (sort) {
        case 'eventDetail':
          this.$store.commit('setMallCode', 'POPUP');
          this.$store.commit('setSalesPlatform', 'BBARS00001');
          if (!this.$commons.isUndefined(this.dataList) && !this.$commons.isNull(this.dataList[idx].id)) {
            this.$store.commit('setBannerId', this.dataList[idx].id);
          }
          this.sendEventLink(this.dataList[idx].link.value, this.dataList[idx].link.type);
          this.$store.commit('setSlideUpPopup', { showSlide: false, pages: '', height: '' });

          // 튜토리얼 show 유무
          // if ((this.$deviceType === 1 || this.$deviceType === 3) && (await this.$bridge.stringValue('0')) !== 'home') {
          //   this.$store.commit('setTutorialModal', {
          //     show: true,
          //     pages: 'AppTutorial',
          //   });
          //   this.$bridge.setStringValue('0', 'home');
          // }
          break;
        case 'closeForToday':
          if (this.$deviceType === 1 || this.$deviceType === 3) {
            // 앱은 오늘 하루 안보기를 구현
            this.$bridge.setStringValue('stopSee', this.$utils.getDate('bar'));
            // 튜토리얼 show 유무
            // if ((await this.$bridge.stringValue('0')) !== 'home') {
            //   this.$store.commit('setTutorialModal', {
            //     show: true,
            //     pages: 'AppTutorial',
            //   });
            //   this.$bridge.setStringValue('0', 'home');
            // }
          } else {
            // 쿠키 설정
            this.$utils.setCookie('executionPopup', 'N', 1);
          }
          this.$store.commit('setSlideUpPopup', { showSlide: false, pages: '', height: '' });
          break;
        case 'closeSlidingPopup':
          this.$store.commit('setExitPopUp', false);
          this.$store.commit('setSlideUpPopup', { showSlide: false, pages: '', height: '' });

          // 튜토리얼 show 유무
          // if ((this.$deviceType === 1 || this.$deviceType === 3) && (await this.$bridge.stringValue('0')) !== 'home') {
          //   this.$store.commit('setTutorialModal', {
          //     show: true,
          //     pages: 'AppTutorial',
          //   });
          //   this.$bridge.setStringValue('0', 'home');
          // }
          break;
        case 'showEventList':
          this.$store.commit('setComponentPopup', {
            show: true,
            pages: 'EventPopupBannerList',
            showMore: false,
            icon: 'close',
            name: 'modal',
          });
          break;
        case 'exit':
          if (this.$deviceType === 1) {
            this.$bridge.call('appExit');
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.execution-popup {
  margin: 40px 40px 0;

  #{&}-content {
    margin-bottom: 37px;
  }
}

img {
  border-radius: 16px;
  width: 640px;
  height: 360px;
}

.swiper-pagination-fraction {
  position: relative;
  background-color: rgba(43, 45, 47, 0.5);
  font-size: 20px;
  color: #f3f5f7;
  width: 80px;
  height: 32px;
  border-radius: 22px;
  text-align: center;
  left: 530px;
  bottom: 60px;
  z-index: 1;
}
</style>
