<template>
  <div>
    <div class="popup-wrap">
      <div class="popup-content">
        {{ title }} 구독완료! <br />
        이제부터 {{ title }} 의 다양한 혜택 정보를 받아 보실 수 있습니다. <br />
        (채널 알림은 구독 중일 때만 가능합니다.)
      </div>
      <div class="popup-button-wrap flex-right-align-wrap">
        <button class="popup-button-yes" @click="confirm">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscribeSuccessPopup',

  props: {
    title: {
      type: String,
      default: '',
    },
  },

  methods: {
    confirm() {
      this.$store.commit('alertPopup', {});
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  padding: 31px 39px 38px 34px;
}

.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 63px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
</style>
