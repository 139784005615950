<template>
  <div class="d-flex c-center r-center common-wrapper">
    <div
      v-for="(radio, index) in data"
      :key="index"
      class="d-flex c-center r-center"
      :style="`padding:${padding}; height: ${height}; width: ${width}`"
      style="text-align: center"
    >
      <input :id="`radio${index}`" v-model="selectTab" type="radio" :name="radioName" :value="index" :hidden="true" />
      <label :for="`radio${index}`" :style="` height: ${height}; width: ${width}`">{{ radio }}</label>
    </div>
  </div>
</template>

<script>
/**
 * 카테고리 리스트 형 컴포넌트
 * @see @/assets/explain/.png
 * @param radioName :  라디오네임 input name=""
 * @param padding : 라디어사이즈 라디오 버튼 width,height - padding으로 잡아주기
 * @param data : 버튼노출데이터 노출 시켜줄 데이터
 */
export default {
  name: 'CommonRadioButton',
  props: {
    radioName: {
      type: String,
      default: '',
    },
    padding: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: 'auto',
    },
    width: {
      type: String,
      default: 'auto',
    },
  },
  emits: ['clickEvent'],
  data() {
    return {
      selectTab: 0,
    };
  },
  watch: {
    value(newVal) {
      this.selectTab = newVal;
    },
    selectTab(newVal) {
      this.$emit('clickEvent', 'order', newVal);
    },
  },
  created() {
    if (this.radioName === 'orderRadio' && !this.$utils.isNull(this.$store.state.pai.filter.order))
      this.selectTab = this.$store.state.pai.filter.order;
  },
  beforeUnmount() {
    this.$store.state.pai.filter.order = this.selectTab;
  },
  methods: {
    test() {},
  },
  // mounted() {
  // 	const el = document.querySelector('.radioBtn').firstChild;
  // 	el.firstChild.checked = true;
  // },
};
</script>

<style lang="scss" scoped>
.radio {
  display: none;
}

label {
  border-radius: 8px;
  box-sizing: border-box;
  line-height: 2.46;
}

input[type='radio'] + label {
  @include text(26, #2b2d2f, '', -1.04);
}

input[type='radio']:checked + label {
  @include text(26, #fff, '', -1.04);
  background-color: #2b2d2f;
}
</style>
