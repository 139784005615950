<template>
  <div class="popup-wrap">
    <img style="display: inline" src="@/assets/img/app_icon_120x120.png" alt="" />
    <div class="popup-content"><font class="title-emp">핏콜라보 앱</font>에서 쉽고 편리하게!<br />더 많은 혜택을 빠르게 확인하세요.</div>
    <button class="popup-button-no" @click="goStore">앱에서 보기</button>
    <button class="popup-button-yes" @click="goWeb">오늘은 그냥 볼게요.</button>
  </div>
</template>

<script>
export default {
  name: 'AppInstallPopup',
  methods: {
    goStore() {
      if (this.$store.state.tvpoint.deviceName === 'ANDROID') {
        location.href = 'https://play.google.com/store/apps/details?id=com.tvpoint';
      } else if (this.$store.state.tvpoint.deviceName === 'IOS') {
        location.href = 'https://apps.apple.com/us/app/%ED%95%8F%EC%BD%9C%EB%9D%BC%EB%B3%B4/id6456410168';
      } else {
        location.replace('https://www.fitcollabo.com');
      }
    },
    goWeb() {
      location.replace('https://www.fitcollabo.com');
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-button-yes {
  height: 38px;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -1.3px;
  text-align: center;
  background-color: #ffffff;
  color: #8f9193;
  text-decoration: underline;
}

.popup-button-no {
  font-size: 32px;
  letter-spacing: -1.28px;
  text-align: center;
  color: #ffffff;
  background-color: #2b2d2f;
  justify-content: center;
  gap: 10px;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  margin-top: 44px;
  width: 500px;
  margin-bottom: 22px;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: center;
  color: #343534;
  padding-top: 22px;
}

.popup-wrap {
  padding: 40px 40px 42px 40px;
}

.title-emp {
  font-weight: bold;
}
</style>
