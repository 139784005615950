<template>
  <div style="padding: 56px 40px; box-sizing: border-box">
    <div class="text"><b>콘텐츠의 명칭</b><br />: 상품콘텐츠(정보) 및 이벤트 정보</div>
    <div class="text"><b>콘텐츠의 제작 및 표시 연월일</b><br />: 개별 표기된 제작일 또는 갱신일</div>
    <div class="text">
      <b>콘텐츠의 제작자</b><br />: (주) 핏콜라보
      <div class="sub-text">전화 : 1588-7758 팩스 : 02-582-2129</div>
    </div>
    <div class="text">
      <b>콘텐츠의 이용조건</b><br />: 이용약권 및 서비스 안내 참고
      <div class="sub-text">(동의 없이 무단복제 및 가공을 금함)</div>
    </div>
    <div class="last-text">
      * (주) 핏콜라보 운영 페이지 내의 모든 콘텐츠는 [콘텐츠산업 진흥법]에 따라 제작일 또는 그 갱신일로부터 5년간 보호 됩니다.
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentPopup',

  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.text {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
  margin-bottom: 14px;
}
.sub-text {
  font-size: 26px;
  line-height: 1.14;
  letter-spacing: -1.12px;
  text-align: left;
  color: #76787a;
  margin-top: 6px;
  margin-left: 10px;
}
.last-text {
  font-size: 24px;
  line-height: 1.46;
  letter-spacing: -1.04px;
  color: #a8aaac;
  margin-top: 56px;
}
</style>
