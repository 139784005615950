<template>
  <div>
    <ArrangeList4Wrapper
      :data-list="dataList"
      :img-size="imgSize"
      grid-gap="56px"
      flex-gap="16px"
      class="category-wrapper"
      @click-event="clickEvent"
    />
  </div>
</template>

<script>
import ArrangeList4Wrapper from '@/components/common/listType/ArrangeList4Wrapper';
export default {
  name: 'Share',
  components: { ArrangeList4Wrapper },

  data() {
    return {
      dataList: [
        {
          imgUrl: require('@/assets/img/common/img_share_kakao_f.svg'),
          title: '카카오톡',
        },
        {
          imgUrl: require('@/assets/img/common/img_share_kakaostory_f.svg'),
          title: '카카오스토리',
        },
        {
          imgUrl: require('@/assets/img/common/img_share_line_f.svg'),
          title: '라인',
        },
        {
          imgUrl: require('@/assets/img/common/img_share_band_f.svg'),
          title: '밴드',
        },
        {
          imgUrl: require('@/assets/img/common/img_share_fb_f.svg'),
          title: '페이스북',
        },
        {
          imgUrl: require('@/assets/img/common/img_share_twitter_f.svg'),
          title: '트위터',
        },
        {
          imgUrl: require('@/assets/img/common/img_share_sms.svg'),
          title: 'SMS문자',
        },
        {
          imgUrl: require('@/assets/img/common/img_share_url.svg'),
          title: 'URL복사',
        },
      ],
      imgSize: {
        width: '120px',
        height: '120px',
      },
    };
  },

  methods: {
    clickEvent(sort, idx) {
      switch (sort) {
        case 'showDetail':
          // alert(idx);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-wrapper {
  margin-top: 24px;
}
</style>
