<template>
  <div class="popup-wrap">
    <div class="popup-content" v-html="content"></div>
    <div class="popup-button-wrap">
      <button class="popup-button-no" @click="close">아니오</button>
      <button class="popup-button-yes" @click="confirm">네</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'LoginPopup',
  props: {
    title: {
      type: String,
      default: '알림',
      require: false,
    },
    content: {
      type: String,
      require: true,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['getConfirmPopup']),
  },
  methods: {
    close() {
      this.$store.commit('setConfirmPopup', {});
    },
    confirm() {
      if (this.getConfirmPopup.callType === 'familyDel') {
        this.$store.commit('setPopupData', { familyDel: true, event: this.getConfirmPopup.event });
      }
      this.$store.commit('setConfirmPopup', {});
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #343534;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}

.popup-content {
  /*margin-top: 63px;*/
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  z-index: 7000;
  padding: 40px;
}
</style>
