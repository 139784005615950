<template>
  <div class="search-category-wrapper">
    <PaiSearchTitle>
      <template #left>
        <span>혜택유형</span>
      </template>
      <template #right>
        <PaiSearchArrow v-model="isShow" name="benefit" @click-event="clickEvent" />
      </template>
    </PaiSearchTitle>

    <BorderBasic height="1" background="#dadcde" class="padding-top-20" />

    <PaiSearchCheckboxWrapper v-if="isShow" class="padding-top-32" name="benefit" :checkbox-data="checkboxData" @click-event="clickEvent" />
  </div>
</template>

<script>
import PaiSearchTitle from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchTitle';
import PaiSearchArrow from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchArrow';
import BorderBasic from '@/components/common/border/BorderBasic';
import PaiSearchCheckboxWrapper from '@/components/common/popup/slidingPopupContent/pai/search/import/PaiSearchCheckboxWrapper';
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiSearchBenefit',
  components: { PaiSearchCheckboxWrapper, BorderBasic, PaiSearchArrow, PaiSearchTitle },
  data() {
    return {
      isShow: true,
      // checkboxData: [
      // 	{ name: '포인트', id: '"POINT"', id2: 'POINT', filter: true },
      // 	{ name: '적립금', id: '"RESERVES"', id2: 'RESERVES', filter: true },
      // 	// { name: '할인정보', id: '"SALE"', id2: 'SALE', filter: false },
      // 	{ name: '할인정보', id: '"DISCOUNT"', id2: 'DISCOUNT', filter: true },
      // 	{ name: '쿠폰', id: '"COUPON"', id2: 'COUPON', filter: true },
      // 	{ name: '이벤트', id: '"EVENT"', id2: 'EVENT', filter: true },
      // 	{ name: '기타', id: '"ETC"', id2: 'ETC', filter: true },
      // ],
      checkboxData: [],
    };
  },
  created() {
    if (!this.$store.state.pai.filter.benefit) {
      this.checkboxData = this.$store.state.pai.codeContentInfo ?? [];
      this.$commons.printLog('혜택유형 체크박스> ', this.checkboxData);
      this.checkboxData.unshift({ name: '전체', id: 'all' });
      for (let i = 0; i < this.checkboxData.length; i++) {
        this.checkboxData[i].checked = true;
        this.checkboxData[i].id2 = '"' + this.checkboxData[i].id + '"';
      }
    } else {
      this.checkboxData = this.$store.state.pai.filter.benefit;
    }
    // if (!this.$store.state.pai.filter.category) {
    // 	this.checkboxData.unshift({ name: '전체', id: 'all', filter: true });
    // 	this.checkboxData = this.checkboxData.map(data => {
    // 		return { ...data, ...{ checked: false } };
    // 	});
    //
    // 	this.$commons.printLog('filterList >', this.$store.state.pai.filterList.filter_conditions.content_info_type_list);
    // 	const contentType = this.$store.state.pai.filterList.filter_conditions.content_info_type_list ?? [];
    // 	for (let i = 0; i < contentType.length; i++) {
    // 		for (let j = 0; j < this.checkboxData.length; j++) {
    // 			if (contentType[i] === this.checkboxData[j].id2) {
    // 				this.checkboxData[j].filter = true;
    // 				this.checkboxData[j].checked = true;
    // 			}
    // 		}
    // 	}
    // } else {
    // 	this.checkboxData = this.$store.state.pai.filter.benefit;
    // }
    // if(this.$store.state.pai.filterList)
  },
  beforeUnmount() {
    this.$store.state.pai.filter.benefit = this.checkboxData;
  },
  methods: {
    clickEvent(sort, data) {
      switch (sort) {
        case 'arrow':
          this.isShow = data;
          break;
        case 'checkBox':
          this.checkboxData[data.index].checked = data.value;
          if (data.index === 0 && this.checkboxData[0].checked === true) {
            this.checkboxData = this.checkboxData.map(data => {
              return { ...data, ...{ checked: true } };
            });
          }
          if (
            data.index === 0 &&
            this.checkboxData[0].checked === false &&
            this.checkboxData.filter(e => e.checked === false).length === 1
          ) {
            //
            this.checkboxData = this.checkboxData.map(data => {
              return { ...data, ...{ checked: false } };
            });
          }
          if (this.checkboxData[0].checked === true && data.index !== 0 && this.checkboxData[data.index].checked === false) {
            //전체가 체크되어있고 방금 체크클릭한 체크가 전체가 아니고 해당 체크가 false로 바꼈다면
            this.checkboxData[0].checked = false;
          }
          if (
            this.checkboxData[0].checked === false &&
            this.checkboxData[data.index].checked === true &&
            data.index !== 0 &&
            this.checkboxData.filter(e => e.checked === true).length === this.checkboxData.length - 1
          ) {
            //체크된 것이 0번째(전체)가 아니고 변경된 체크가 true이고 모든항목이 체크되어있는 상태라면 전체를 true로 변경
            this.checkboxData[0].checked = true;
          }
          break;
      }
    },
    refresh() {
      for (let i = 0; i < this.checkboxData.length; i++) {
        this.checkboxData[i].checked = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-category-wrapper {
  box-sizing: border-box;
  padding-top: 40px;
}
</style>
