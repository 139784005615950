<template>
  <div>
    <div class="fee-wrap common-padding">
      <div class="fee-title">전환 수수료 안내</div>
      <EmptyPaddingBar :padding-height="130" />
      <div class="fee-container">
        <div class="fee-con-title">
          제휴사 포인트를 TV포인트로 전환 시<br />발생되는 전환 수수료에 대한 안내 입니다.<br />※ 미 기입된 제휴사는 별도의 전환 수수료가
          없습니다.
        </div>
        <div
          v-for="(fee, idx) in feeInfo"
          :key="idx"
          class="fee-component"
          :style="isScroll && idx == feeInfo.length - 1 ? 'margin-bottom: 76px' : ''"
        >
          <div class="fee-info" v-html="fee.feeInfo"></div>
        </div>
        <EmptyPaddingBar :padding-height="85" />
      </div>
    </div>
    <button class="submit-btn bottom" @click="confirm">확인</button>
  </div>
</template>

<script>
import EmptyPaddingBar from '@/components/common/EmptyPaddingBar';

export default {
  components: {
    EmptyPaddingBar,
  },

  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      feeInfo: [],
    };
  },
  beforeCreate() {
    const data = this.$store.dispatch('getFeeInfo', {});
    data.then(data => {
      if (parseInt(data.resultCode) === 0) {
        this.feeInfo = data.resultData;
        this.$parent.$forceUpdate();
      } else {
        this.$utils.errorMessage(this, data);
      }
    });
  },
  methods: {
    confirm() {
      this.$store.commit('slideShow', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.fee-con-title {
  padding-top: 20px;
  padding-bottom: 40px;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: -1.4px;
  color: #666666;
}
.fee-wrap {
  height: 100vh;
}
.fee-title {
  font-size: 34px;
  line-height: 130px;
  letter-spacing: -1.36px;
  color: #222222;
  width: 640px;
  height: 130px;
  font-weight: bold;
  background-color: white;
  position: fixed;
}
.fee-container {
  padding-bottom: 40px;
  height: calc(100vh - 130px - 180px);
  overflow: scroll;
  margin-bottom: 40px;
}
.fee-info {
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -1.4px;
  color: #343534;
  height: 80px;
}
.fee-component {
  margin-bottom: 26px;
  background-color: #f2f3f5;
  padding: 20px 26px;
  height: 80px;
  border-radius: 8px;
}
.submit-btn {
  background-color: #2b2d2f;
  color: white;
}
</style>
