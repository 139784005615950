<template>
  <div class="popup-wrap">
    <div class="popup-title">이용권 해지</div>
    <div class="popup-content">
      이용권을 해지하시겠습니까? <br />
      결제 후 7일 내 서비스 이용 이력이 없는 경우 <br />
      고객센터(1588-7758) 또는 1:1문의 신청을 <br />
      통해 결제 취소 가능합니다.
    </div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="cancel">취소</button>
      <button class="popup-button-yes" @click="agree">해지하기</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'MelonCancelPassPopup',
  computed: {
    ...mapGetters(['getAlertPopup']),
  },
  methods: {
    cancel() {
      this.$store.commit('alertPopup', { transAgree: 'N' });
    },
    agree() {
      if (this.getAlertPopup.apiType === 'setEndMelon') {
        //정기권 해지
        let params = this.$store.state.tvpay.endMelon;
        this.$store.commit('alertPopup', { transAgree: 'N' });
        this.$store.dispatch('setEndMelon', params).then(res => {
          if (res.resultCode === '0000') {
            this.$store.commit('alertPopup', { transAgree: 'N' });
            this.$store.commit('alertPopup', { popupType: 'CancelPassAgreePopup' });
          } else {
            this.$utils.errorMessage(this, res);
          }
        });
      } else if (this.getAlertPopup.apiType === 'setCancelMelon') {
        let params = this.$store.state.tvpay.cancelMelon;
        this.$store.commit('alertPopup', { transAgree: 'N' });
        this.$store.dispatch('setCancelMelon', params).then(res => {
          if (res.resultCode === '0000') {
            this.$store.commit('alertPopup', { transAgree: 'N' });
            this.$store.commit('alertPopup', { popupType: 'CancelPassAgreePopup' });
          } else {
            this.$utils.errorMessage(this, res);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #444648;
  background-color: #ffffff;
}
.popup-button-yes {
  margin-left: 46px;
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #648cff;
  font-weight: bold;
  background-color: #ffffff;
}

.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}
.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
  margin-bottom: 30px;
}
.popup-wrap {
  padding: 40px 40px 42px 40px;
}
</style>
