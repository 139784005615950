// jhkim: 추가
// 컴포넌트, 상품 정보 구성

import Logger from '@/modules/ft-logger';
import $store from '@/store';
import FTEnum from './ft-enum';

const FTComponentDispatcher = (function () {
  // 싱글톤
  let _sharedInstance;

  function init() {
    return {
      async dispatchComponentList(templateItem, params) {
        const result = await $store.dispatch('fitBasicApi', params);
        return {
          templateItem: templateItem,
          componentList: result,
        };
      },

      async dispatchComponentData(templateItem, container) {
        let dispatchList;
        let componentList = null;
        let productList = null;

        if (container) {
          if (FTEnum.isNotEmpty(container.product_components)) {
            const sorted = container.product_components.sort((a, b) => a.order - b.order);
            dispatchList = this.dispatchProductList(sorted);
            let result = await Promise.all(dispatchList);

            Logger.info('request: ', JSON.stringify(result));

            templateItem.componentList = container.product_components;
            templateItem.productList = result;
          } else if (FTEnum.isNotEmpty(container.special_magazine_components)) {
            const sorted = container.special_magazine_components.sort((a, b) => a.order - b.order);
            dispatchList = this.dispatchProductList(sorted);
            let result = await Promise.all(dispatchList);

            Logger.info('request: ', JSON.stringify(result));

            templateItem.componentList = container.special_magazine_components;
            templateItem.productList = result;
          } else if (FTEnum.isNotEmpty(container.banner_components)) {
            templateItem.componentList = container.banner_components;
            templateItem.productList = [];
          } else if (FTEnum.isNotEmpty(container.category_components)) {
            templateItem.componentList = container.category_components;
            templateItem.productList = [];
          }
        }

        return {
          templateItem: templateItem,
        };
      },

      dispatchProductList(componentList) {
        const productList = [];

        if (FTEnum.isEmpty(componentList)) {
          return productList;
        }

        for (let item of componentList) {
          switch (item.display_type) {
            case 0:
              {
                // 0: 수동에 수동 편성
                let product = item.products;
                let productId = [];

                if (FTEnum.isNotEmpty(product)) {
                  for (let i = 0; i < product.length; i++) {
                    productId[i] = product[i].id;
                  }

                  let params = `?products=${productId}&per_page=${productId.length}&sort_by=products_list_order`;
                  let dataList = $store.dispatch('bsProductList', params);
                  //Logger.info('fitBasicApi: product: %s', JSON.stringify(dataList));
                  productList.push(dataList);
                }
              }
              break;

            case 2: {
              // 2: 자동 편성
              let dataList = this.autoSellingPage(item.link.type, item.link.value);
              productList.push(dataList);
              break;
            }

            default:
              // 1: 수동에 자동 편성
              //매거진 컴포넌트 상품 데이터 가져오기
              if (item.link.type === 6) {
                let params = `?products=${item.link.value}&per_page=${item.link.value.length}&sort_by=products_list_order`;
                let dataList = $store.dispatch('bsProductList', params);
                productList.push(dataList);
              } else {
                productList.push([]);
              }
              break;
          }
        }

        return productList;
      },

      async autoSellingPage(linkType, linkValue) {
        let params = '';

        if (linkType === 4) {
          //편성 기획전 정보보기
          const res = await $store.dispatch('exhibitionDetail', linkValue);
          if (res) {
            res.special_selling_page.groups.forEach(group => {
              let product = group.products;
              let productId = [];

              if (product.length > 0) {
                for (let i = 0; i < product.length; i++) {
                  productId[i] = product[i].id;
                }
              }
              params = `?products=${productId}&per_page=20&sort_by=products_list_order`;
            });
          }
        } else if (linkType === 8) {
          //빠샵 상품목록보기(스토어)
          params = `?companys=${linkValue}&per_page=20`;
        }

        let dataList = $store.dispatch('bsProductList', params);
        return dataList;
      },
    };
  }

  return {
    sharedInstance: function () {
      if (_sharedInstance) {
        return _sharedInstance;
      }

      _sharedInstance = init();
      return _sharedInstance;
    },
  };
})().sharedInstance();

export default FTComponentDispatcher;
