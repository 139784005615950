import utils from '@/utils/tvPointcommon';

const _vue = window.app;

const tvpointMixin = {
  methods: {
    watchCollection(arr, cb) {
      arr.forEach(val => this.$watch(val, cb));
    },
    /**
     *
     * @param _vue this : vue 객체
     * @returns {*}
     */
    manageAutoTransfer() {
      utils.printLog(_vue);
      const deferred = utils.defer();
      const data = _vue.$store.dispatch('manageAutoTransfer', { manageType: 'I', termType: _vue.getTermType });

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          if (data.dataYN === 'Y') {
            _vue.isAutoTrans = 'Y';
          } else {
            _vue.isAutoTrans = 'N';
          }
          utils.printLog('manageAutoTransfer success');
          utils.printLog(data);
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
  },
};

export default tvpointMixin;
