<template>
  <swiper ref="myswiper" class="swiper" v-bind="swiperOptions" @swiper="onSwiper" @slide-change="slideChange">
    <swiper-slide v-for="(item, idx) in dataList[mainIdx]" :key="idx">
      <div :class="`board-${item.title}`" @click="clickEvent(idx)"></div>
    </swiper-slide>
    <div class="swiper-pagination"></div>
  </swiper>
</template>

<script>
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

export default {
  name: 'AppTutorial',

  data() {
    return {
      swiper: null,
      endOfSlide: false,
      swiperOptions: {
        slidesPerView: 1,
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        nested: true,
      },
      dataList: [
        [{ title: 'home1' }, { title: 'home2' }],
        [{ title: 'point1' }, { title: 'point2' }],
        [],
        [],
        [{ title: 'pai1' }, { title: 'pai2' }, { title: 'pai3' }, { title: 'pai4' }],
        [{ title: 'pay1' }, { title: 'pay2' }],
      ],
    };
  },

  computed: {
    mainIdx() {
      return this.$store.state.common.mainIdx;
    },
  },

  mounted() {},

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    slideChange() {
      this.endOfSlide = this.swiper.isEnd;

      console.log('my: ' + this.$refs.myswiper.$el.scrollHeight);
      console.log('my: ' + this.$refs.myswiper.height);
      console.log('my: ' + this.swiper.height);
    },

    clickEvent(idx) {
      this.$utils.printLog('AppTutorialModal idx', idx);
      this.endOfSlide ? this.$store.commit('setTutorialModal', { show: false, pages: '' }) : this.swiper.slideTo(idx + 1);
    },
  },
};
</script>

<style lang="scss">
.swiper-container-horizontal > .swiper-pagination-bullets {
  height: 25px;
  width: 50%;
  bottom: 54px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
  background: #fff;
  opacity: 0.3;
}

.swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.swiper {
  top: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.close-btn {
  position: absolute;
  top: 35px;
  right: 40px;
}

.board-home1 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_home_1.png') no-repeat center;
  background-size: cover;
}

.board-home2 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_home_2.png') no-repeat center;
  background-size: cover;
}
.board-point1 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_TVPoint_1.png') no-repeat center;
  background-size: cover;
}

.board-point2 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_TVPoint_2.png') no-repeat center;
  background-size: cover;
}
.board-pay1 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_TVPay_1.png') no-repeat center;
  background-size: cover;
}

.board-pay2 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_TVPay_2.png') no-repeat center;
  background-size: cover;
}
.board-pai1 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_PAI_1.png') no-repeat center;
  background-size: cover;
}
.board-pai2 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_PAI_2.png') no-repeat center;
  background-size: cover;
}
.board-pai3 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_PAI_3.png') no-repeat center;
  background-size: cover;
}
.board-pai4 {
  height: 100vh;
  background: url('../../assets/img/common/Tutorial_PAI_4.png') no-repeat center;
  background-size: cover;
}
</style>
