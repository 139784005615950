<template>
  <div class="charge-wrap common-padding">
    <div class="charge-title">충전방식 선택</div>
    <div class="charge-container popup-scroll">
      <div class="telecom">
        <input id="card" type="radio" data-name="purchase" checked class="charge-radio" name="charge" />
        <label for="card" class="label"><i class="radio-img"></i>카드결제 1% 추가적립</label>
        <div class="margin-70">
          <input id="point" type="radio" data-name="" class="charge-radio" name="charge" />
          <label for="point" class="label"><i class="radio-img"></i>숨겨진 포인트 전환</label>
        </div>

        <div v-if="getTransablePoint > 0" class="charge-text">숨겨진 TV포인트 {{ $filters.formatComma(getTransablePoint) }}P</div>
      </div>
      <button class="submit-btn radius focus" @click="goConfirm">선택</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SelectCharge',
  computed: {
    ...mapGetters(['getTransablePoint']),
  },
  methods: {
    goConfirm() {
      const selected = document.querySelector('input[name="charge"]:checked').getAttribute('data-name');
      if (selected === 'purchase') {
        this.$router.push('/tvpoint/purchase');
      } else {
        this.$router.push('/tvpoint/exchange');
      }
    },
  },
};
</script>

<style scoped>
.margin-70 {
  padding-top: 70px;
}
.charge-text {
  padding-left: 60px;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #999999;
}
.submit-btn {
  margin-top: 360px;
  margin-bottom: 40px;
}
.charge-wrap {
  height: inherit;
}
.charge-title {
  height: 128px;
  font-size: 34px;
  line-height: 128px;
  letter-spacing: -1.36px;
  color: #222222;
  font-weight: 500;
}
.charge-container {
  height: calc(100% - 128px);
  overflow: scroll;
}
.telecom {
  margin-bottom: 71px;
}
.charge-radio {
  display: none;
}
label {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #222222;
  height: 40px;
  display: flex;
  line-height: 40px;
}
.charge-radio:checked + label > i {
  background: url('../../../../assets/img/common/check_circle_on.svg') no-repeat;
}
.radio-img {
  width: 40px;
  padding-right: 20px;
  background: url('../../../../assets/img/common/check_circle_off.svg') no-repeat;
}
</style>
