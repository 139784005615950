<template>
  <transition name="fade">
    <div v-if="!$utils.isNull(getConfirmPopup.popupType)" id="layerWrap" class="layer-wrap">
      <div class="pop-layer">
        <component
          :is="getConfirmPopup.popupType"
          view="true"
          :title="getConfirmPopup.popupTitle"
          :content="getConfirmPopup.popupContent"
          :type="getConfirmPopup.popupTransType"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorPopup from '@/components/tvpoint/popup/ConfirmPopupFrame/ErrorPopup';
export default {
  components: {
    ErrorPopup,
  },
  data() {
    return {
      pages: '',
    };
  },

  computed: {
    ...mapGetters(['getConfirmPopup']),
  },

  watch: {
    pages(val) {
      if (this.$utils.isNull(val)) {
        window.removeEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      } else {
        window.addEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      }
    },
    getConfirmPopup: {
      deep: true,
      handler(newData) {},
    },
  },
  unmounted() {
    this.$store.commit('setConfirmPopup', {});
  },
  beforeUnmount() {
    this.$store.commit('setConfirmPopup', {});
  },

  methods: {
    confirm() {
      this.$store.commit('alertPopup', null);
    },
    handleTouchmove(e) {
      this.$utils.printLog('[AlertPopup] test ! ');
      e.stopPropagation();
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.pop-layer {
  display: inline-block;
  vertical-align: middle;
  width: 540px;
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 12.5px;
}

.pop-title {
  display: inline-block;
  margin: 40px 40px 0 40px;
  height: 32px;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.6px;
  text-align: left;
  width: 100%;
  color: #343534;
}
.layer-wrap {
  // display: none;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.layer-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  // margin-right: -.25em;
}
.pop-layer {
  display: inline-block;
  vertical-align: middle;
  // width: 300px;
  width: 540px;
  height: auto;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  z-index: 10;
  border-radius: 12.5px;
}
.contents-container {
  padding: 35px;
}
.txt_contents {
  width: 100%;
  height: 160px;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.btn_confirm {
  display: inline-block;
  width: 100%;
  height: 90px;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -1.5px;
  text-align: right;
  border-radius: 16px;
  background-color: #ffffff;

  color: #e42028;
}

.btn_confirm span {
  display: inline-block;
  margin-right: 40px;
}
//margin: 0 40px 40px 0;
</style>
