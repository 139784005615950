<template>
  <div ref="paddingContainer" class="empty-padding" :style="`width:100%; height:${paddingHeight}px`"></div>
</template>

<script>
export default {
  name: 'EmptyPaddingBar',
  props: {
    paddingHeight: {
      type: Number,
      default: 100,
    },
  },
  created() {},
  mounted() {
    // const element = this.$refs.paddingContainer;
    // if (element) {
    // 	element.style.height = this.paddingHeight + 'px';
    // }
  },
};
</script>

<style scoped>
.empty-padding {
  width: 100%;
}
</style>
