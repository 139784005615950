<template>
  <div>
    <div style="margin: 0 40px">
      <CommonText font-size="34" font-color="#2b2d2f" spacing="-1.7" style="font-weight: bold; margin-bottom: 56px; margin-top: 40px"
        >모바일 스트리밍클럽</CommonText
      >
      <div class="d-flex c-center r-space-between">
        <CommonText font-size="30" font-color="#2b2d2f" spacing="-1.5" style="font-weight: 500">모바일 스트리밍클럽</CommonText>
        <CommonText font-size="30" font-color="#2b2d2f" spacing="-1.2" style="font-weight: bold"
          >{{ amount.toLocaleString() }} 원<span class="vat">(VAT별도)</span></CommonText
        >
      </div>
      <BorderBasic margin="40" height="1" background="#dadcde" />
    </div>

    <!-- 약관 -->
    <div style="margin: 40px 45px 40px 40px">
      <div v-for="(agreement, index) in agreements" :key="index" class="d-flex" style="align-items: flex-start; margin-bottom: 25px">
        <img
          :src="
            agreement.checked ? require('@/assets/img/common/check_circle_on.svg') : require('@/assets/img/common/check_circle_off.svg')
          "
          style="margin-right: 16px"
          @click="toggleCheckbox(agreement)"
        />
        <common-text>
          [{{ agreement.type }}]
          <span style="text-decoration: underline" @click="clickEvent(agreement.eventName)">{{ agreement.label }}</span
          ><span>{{ agreement.content }}</span>
        </common-text>
      </div>
    </div>

    <div class="payBtnWrapper">
      <div
        class="payBtn"
        :style="{ 'background-color': isPaymentButtonEnabled ? '#2b2d2f' : '#f3f5f7', color: isPaymentButtonEnabled ? 'white' : '#a8aaac' }"
        @click="clickEvent('payment')"
      >
        결제하기
      </div>
    </div>

    <!-- <SlidingPopUp :show="showSlide" pages="MelonTerms" height="auto" @clickEvent="clickEvent"></SlidingPopUp> -->
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
import BorderBasic from '@/components/common/border/BorderBasic';
// import SlidingPopUp from '@/components/common/popup/SlidingPopUp';

export default {
  components: {
    CommonText,
    BorderBasic,
    // SlidingPopUp,
  },
  props: {
    showSlide: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['closePopup'],
  data() {
    return {
      agreements: [
        {
          type: '필수',
          label: '멜론 유료서비스약관',
          content: ' 및 상세 페이지 내 유의사항에 동의합니다.',
          eventName: 'termsSlidePopup',
          checked: false,
        },
        { type: '필수', label: '', content: '매월 정기결제 되는 것에 동의합니다.', checked: false },
      ],
      isPaymentButtonEnabled: false,
      realAmt: 0,
    };
  },
  computed: {
    areRequiredAgreementsChecked() {
      return this.agreements.filter(agreement => agreement.type === '필수' && !agreement.checked).length === 0;
    },
  },
  created() {
    // this.$store.dispatch('getMelonPrice').then(data => {
    //   if (data.resultCode === '0000') {
    //     this.realAmt = data.realAmt;
    //     // data;
    //   } else {
    //     this.$utils.errorMessage(this, data);
    //   }
    // });
  },
  methods: {
    clickEvent(sort) {
      switch (sort) {
        case 'payment':
          if (this.areRequiredAgreementsChecked) {
            this.$emit('closePopup', 'pay');
          } else {
            // 필수 약관이 모두 체크되지 않았을 때의 처리
          }
          break;
        case 'termsSlidePopup':
          this.$emit('closePopup', 'terms');
          break;
        default:
          break;
      }
    },
    // checked(target) {
    // 	this[target] = !this[target];
    // },
    toggleCheckbox(agreement) {
      agreement.checked = !agreement.checked;
      // 필수 약관이 모두 체크시 버튼 활성화
      this.isPaymentButtonEnabled = this.areRequiredAgreementsChecked;
    },
  },
};
</script>

<style lang="scss" scoped>
.vat {
  font-family: NotoSansCJKkr;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: -0.88px;
  text-align: right;
  color: #a8aaac;
  margin-left: 8px;
}
.payBtnWrapper {
  width: 100%;
  text-align: center;
}

.payBtn {
  width: 100%;
  height: 110px;
  // background-color: #2b2d2f;
  font-size: 32px;
  line-height: 110px;
  letter-spacing: -1.6px;
  text-align: center;
  color: white;
}
</style>
