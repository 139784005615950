<template>
  <div>
    <div style="margin: 0 40px">
      <CommonText font-size="34" font-color="#2b2d2f" spacing="-1.7" style="font-weight: bold; margin-bottom: 56px; margin-top: 40px"
        >쇼골프 이용권 정기결제</CommonText
      >

      <!--  결제 상품 선택  -->
      <div class="paymentInfo">
        <div v-for="(item, idx) in data.productList" :key="idx">
          <div
            class="d-flex c-center product-wrap"
            :style="{
              border: idx === selectBorder ? 'solid 5px #2b2d2f' : 'solid 3px #e6e8ea',
            }"
            @click="clickEvent('radioCheck', idx, item)"
          >
            <input
              :id="idx"
              :ref="selectBorder"
              type="radio"
              name="productCheck"
              :value="selectBorder"
              :checked="idx === selectBorder"
              class="check-all"
            />
            <label :for="idx"></label>
            <div>
              <div class="service_name">{{ item.productName }}</div>
              <!--              <div class="service_description">-->
              <!--                {{ item.service_description }}-->
              <!--              </div>-->
              <div class="amount">
                {{ Number(item.productPrice).toLocaleString() }}원&nbsp;
                <span
                  >{{ item.productCode === '12' ? '(매년' : '(매월' }} 자동결제 {{ Number(item.productPrice).toLocaleString() }}원,
                  VAT포함)</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin: 40px 45px 40px 40px">
      <div class="d-flex" style="margin-bottom: 24px">
        <img
          :src="isAgreeTerm ? require('@/assets/img/common/check_circle_on.svg') : require('@/assets/img/common/check_circle_off.svg')"
          style="margin-right: 16px"
          @click="checked('term')"
        />
        <common-text style="text-decoration: underline" @click="clickEvent('terms')">이용약관 및 상단 유의사항에 동의합니다.</common-text>
      </div>
      <div>
        <div class="d-flex">
          <img
            :src="isAgreePayment ? require('@/assets/img/common/check_circle_on.svg') : require('@/assets/img/common/check_circle_off.svg')"
            style="margin-right: 16px"
            @click="checked('payment')"
          />
          <common-text>매월 정기 결제에 동의합니다.</common-text>
        </div>
      </div>
    </div>

    <div class="payBtnWrapper">
      <button
        class="payBtn"
        :style="{
          'background-color': isAgreeTerm && isAgreePayment ? '#2b2d2f' : '#f3f5f7',
          color: isAgreeTerm && isAgreePayment ? 'white' : '#a8aaac',
        }"
        :disabled="!isAgreeTerm || !isAgreePayment"
        @click="clickEvent('payment', '', selectProduct)"
      >
        결제하기
      </button>
    </div>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
import bus from '@/utils/bus';

export default {
  components: {
    CommonText,
  },
  props: {
    showSlide: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
    },
  },
  emits: ['closePopup'],
  data() {
    return {
      isAgreeTerm: false,
      isAgreePayment: false,
      selectBorder: 0,
      tid: {},
      productList: [],
      promotionList: [],
      selectProduct: {},
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.selectProduct = this.data.productList[0];
  },
  methods: {
    clickEvent(sort, idx, item) {
      switch (sort) {
        case 'payment':
          this.$emit('closePopup', 'payment', item);
          break;
        case 'terms':
          this.$emit('closePopup', 'terms');
          break;
        case 'radioCheck':
          //라디오버튼 체크
          this.selectProduct = item;
          this.selectBorder = idx;
          this.$commons.printLog('clickEvent item : ', item);
          this.$commons.printLog('clickEvent idx : ', idx);
          break;
        default:
          break;
      }
    },
    checked(target) {
      if (target === 'term') {
        this.isAgreeTerm = !this.isAgreeTerm;
      } else if (target === 'payment') {
        this.isAgreePayment = !this.isAgreePayment;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vat {
  font-family: NotoSansCJKkr;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: -0.88px;
  text-align: right;
  color: #a8aaac;
  margin-left: 8px;
}
.payBtnWrapper {
  width: 100%;
  text-align: center;
}

.payBtn {
  width: 100%;
  height: 110px;
  font-size: 32px;
  line-height: 110px;
  letter-spacing: -1.6px;
  text-align: center;
  color: white;
}
.title {
  margin: 56px 40px;
  font-family: Pretendard;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -0.64px;
  text-align: left;
  color: #2b2d2f;
}
.product-wrap {
  height: 162px;
  border-radius: 16px;
  margin-bottom: 24px;
  width: 100%;
}
.product-wrap:checked + label {
  border: solid 5px #2b2d2f;
}
.check-all:checked + label {
  margin: 28px 16px 28px;
  float: left;
  display: inline-block;
  width: 42px;
  height: 42px;
  background-image: url('../../../../../assets/img/common/btn_radio_on.svg');
  box-sizing: border-box;
}

.check-all {
  display: none;
  float: left;
  box-sizing: border-box;
}

.check-all + label {
  margin: 28px 16px 28px;
  float: left;
  display: inline-block;
  width: 42px;
  height: 42px;
  background-image: url('../../../../../assets/img/common/btn_radio_off.svg');
  box-sizing: border-box;
}

.service_name {
  margin-top: 32px;
  margin-bottom: 8px;
  font-family: Pretendard;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -1.5px;
  text-align: left;
  color: #2b2d2f;
}

.service_description {
  width: 508px;
  font-family: Pretendard;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.84px;
  text-align: left;
  color: #76787a;
  margin: 8px 0px 24px 0px;
}

.amount {
  font-family: Pretendard;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: -1.28px;
  text-align: left;
  color: #648cff;
  margin-bottom: 32px;
}
.amount span {
  font-family: Pretendard;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.96px;
  text-align: left;
  color: #8f9193;
  vertical-align: center;
}
.caution-wrap {
  //height: 196px;
  background-color: #f2f6ff;
  padding: 32px 40px;
  margin-top: 40px;
}
.caution-title {
  font-family: Pretendard;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -1.04px;
  text-align: left;
  color: #76787a;
  margin-bottom: 8px;
}
.caution-description {
  font-family: Pretendard;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -1.04px;
  text-align: left;
  color: #8f9193;
}
.paymentBtn {
  width: 100%;
  height: 110px;
  font-size: 32px;
  line-height: 110px;
  letter-spacing: -1.6px;
  text-align: center;
  color: white;
}
//팝업 버튼
.popupTitle {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}
.popupContent {
  margin: 32px 0;
}
.popupBtn {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -1.5px;
  text-align: right;
  margin-left: 442px;
}
</style>
