<template>
  <div style="padding: 24px 40px">
    <div class="text" @click="deleteCard">결제수단 삭제</div>
    <div class="text" @click="oneClickSet">원클릭 결제 설정</div>
    <div class="text" @click="oneClick">원클릭 결제란?</div>
  </div>
</template>

<script>
export default {
  name: 'OneClickPopup',

  emits: ['removeCard', 'closePopup'],

  data() {
    return {};
  },

  methods: {
    deleteCard() {
      this.$emit('removeCard');
      this.$emit('closePopup');
    },
    oneClickSet() {
      console.log('원클릭 결제 설정');
    },
    oneClick() {
      console.log('원클릭 결제란?');
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  width: auto;
  height: 110px;
  font-size: 30px;
  line-height: 110px;
  letter-spacing: -1.2px;
  text-align: left;
  color: #222;
}
</style>
