<template>
  <div class="wrap">
    <div class="popup-title">{상품명} 결제</div>
    <!-- 복수일때 결제할 상품을 선택해주세요! 추가하기-->
    <div class="subtitle">결제할 상품을 선택해주세요!</div>
    <!-- 단수 복수 이미지 없을때 -->
    <div class="pruduct-boxs">
      <div class="pruduct-box">
        <div class="pruduct-title">결제 상품명</div>
        <div class="content">상품에 관련된 부가설명 상품에 관련된 부가설명 상품에 관련된 부가설명 상품에 관련된 부가설명</div>
        <div class="pricebox">
          <span class="discount-percentage">20%</span>
          <span class="discountPrice">13,900원</span>
          <span class="cost">15,900원</span>
        </div>
      </div>
      <!-- <div class="pruduct-box">
				<div class="pruduct-title">결제 상품명</div>
				<div class="content">상품에 관련된 부가설명 상품에 관련된 부가설명 상품에 관련된 부가설명 상품에 관련된 부가설명</div>
				<div class="pricebox">
					<span class="discount-percentage">20%</span>
					<span class="discountPrice">13,900원</span>
					<span class="cost">15,900원</span>
				</div>
			</div> -->
    </div>

    <!-- 단수 복수 이미지 있을때 -->
    <div class="pruduct-boxs imgboxs">
      <div class="pruduct-box imgbox">
        <div class="left">
          <img class="pruduct-img" src="" alt="" />
        </div>
        <div class="right">
          <div class="pruduct-title">결제 상품명</div>
          <div class="content">상품에 관련된 부가설명 상품에 관련된 부가설명 상품에 관련된 부가설명 상품에 관련된 부가설명</div>
          <div class="pricebox img-pricebox">
            <span class="discount-percentage">20%</span>
            <span class="discountPrice">13,900원</span>
            <span class="cost">15,900원</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 버튼명 fit이랑 통일 -->
    <div class="button">버튼명</div>

    <!-- 이미지 있을때-->
  </div>
</template>

<script>
// import SubHeader from "@/components/nav/header/SubHeader.vue";
export default {
  name: 'InstantPaymentComponent',
  components: {
    // SubHeader,
  },
  data() {
    return {
      //data 0 단품 이미지 없음, data 1 단품 이미지 있음, data 2 복수 이미지 없음, data 3 복수 이미지 있음
    };
  },
  methods: {},
};
</script>

<style scoped>
.wrap {
  margin: 20px;
}
.popup-title {
  width: 100%;
  font-family: NotoSansCJKkr;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: -1.7px;
  text-align: left;
  color: var(--dark-grey);
  padding-bottom: 50px;
}
.subtitle {
  width: 100%;
  padding-bottom: 20px;
  font-family: NotoSansCJKkr;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -0.6px;
  text-align: left;
  color: #2b2d2f;
}
.pruduct-box {
  margin: 20px 0 20px 0;
  border-radius: 16px;
  box-shadow: 0 2px 18px 0 rgba(43, 45, 47, 0.1);
  padding: 20px;
}
.pruduct-box:hover {
  border: 4px solid #2b2d2f;
}
.pruduct-title {
  width: 100%;
  font-family: NotoSansCJKkr;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: -1.5px;
  text-align: left;
  color: var(--dark-grey);
}
.pruduct-img {
  width: 140px;
  height: 140px;
}
.imgboxs {
}
.imgbox {
  display: flex;
  justify-content: space-between;
}
.left {
  margin: 15px 20px 0px 0px;
}
.content {
  width: 100%;
  font-family: NotoSansCJKkr;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.84px;
  text-align: left;
  color: #76787a;
  /* >> */
  padding: 30px 0 30px 0;
}
.discount-percentage {
  width: 62px;
  height: 30px;
  margin-right: 8px;
  font-family: NotoSansCJKkr;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -1.2px;
  text-align: left;
  color: #ff5b5b;
}
.discountPrice {
  width: 119px;
  height: 32px;
  margin-right: 8px;
  font-family: NotoSansCJKkr;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -1.2px;
  text-align: left;
  color: var(--dark-grey);
}
.cost {
  width: 82px;
  height: 23px;
  font-family: NotoSansCJKkr;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: -0.88px;
  text-align: left;
  text-decoration: line-through;
  color: #a8aaac;
}
.button {
  background-color: #2b2d2f;
  border-radius: 8px;
  height: 100px;
  width: 100%;
  font-size: 32px;
  line-height: 100px;
  color: #ffffff;
  text-align: center;
  letter-spacing: -1.6px;
  margin-bottom: 40px;
  position: fixed;
}
</style>
