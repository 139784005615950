<template>
  <div>
    <slot name="first"></slot>
    <slot name="second"></slot>
  </div>
</template>

<script>
export default {
  name: 'ArrangeList4',
};
</script>

<style lang="scss" scoped></style>
