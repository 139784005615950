import tvpay from '@/store/tvpay';
import $commons from '@/utils/commons';

const mutations = {
  /* 카드목록 조회 */
  setCardResult(state, data) {
    // state.mobileAccountList = data.list;
    $commons.printLog('setCardList', data, 'state.cardResult', data.listCount, typeof data.listCount);
    state.cardResult = data;
  },
  setMoCardList(state, data) {
    $commons.printLog('setMoCardList', data);
    let moCardList = [];
    for (let i in data.list) {
      if (data.list[i].isMobileUser === 'Y') {
        moCardList.push(data.list[i]);
      }
    }
    data.list = moCardList;
    data.listCount = moCardList.length;
    state.moCardList = data;
  },

  /* 카드목록 개수 체크 */
  setCardListCount(state, data) {
    $commons.printLog('setCardListCount', data);
    state.cardListCount = data;
  },

  /* 카드관리 페이지 뒤로 돌아 왔을때 기존 탭 유지하기 위함 */
  cardManagementTitleHistory(state, data) {
    state.cardManagementTitleHistory = data;
  },

  /* 결제내역 */
  setPaymentList(state, data) {
    state.payHistoryResult = data;
  },

  /* 결제 상세내역 */
  setPaymentDetailList(state, data) {
    $commons.printLog('setPaymentDetailList', data);
    state.payDetailResult = data;
  },
  resetTvpayState(state) {
    Object.assign(state, tvpay.defaultState);
  },
  /* 진입 페이지 구분 */
  setPreviousPage(state, data) {
    $commons.printLog('setPreviousPage', data);
    state.previousPage = data;
  },
  /* 인증 방식 구분 */
  setAuthType(state, data) {
    $commons.printLog('setAuthType', data);
    state.authType = data;
  },
  /* TV페이 신규 계정 등록 */
  setNewAccount(state, data) {
    $commons.printLog('setNewAccount', data);
    state.newAccount = data;
  },
  setAutoPaymentList(state, data) {
    $commons.printLog('setAutoPaymentList', data);
    state.autoPaymentList = data;
  },
  setAutoPaymentDetailIdx(state, data) {
    $commons.printLog('setAutoPaymentDetailIdx', data);
    state.autoPaymentDetailIdx = data;
  },
  setIsErrorPage(state, data) {
    $commons.printLog('setIsErrorPage', data);
    state.isErrorPage = data;
  },
  setEventId(state, data) {
    $commons.printLog('setEventId', data);
    state.eventId = data;
  },

  setAutoPaymentDetail(state, data) {
    $commons.printLog('setAutoPaymentDetail', data);
    state.autoPaymentDetail = data;
  },

  setEndMelon(state, data) {
    $commons.printLog('setEndMelon', data);
    state.endMelon = data;
  },

  setCancelMelon(state, data) {
    $commons.printLog('setCancelMelon', data);
    state.cancelMelon = data;
  },
};

export default mutations;
