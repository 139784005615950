<template>
  <div class="d-flex c-center r-flex-start">
    <div class="d-flex c-center r-center common-wrapper">
      <div
        :key="`checkbox${checkboxName}Wrap`"
        class="d-flex base-line c-center r-center"
        :style="`padding:${padding}; height: ${height}; width: ${width}`"
        style="text-align: center"
      >
        <input :id="`checkbox${checkboxName}Wrap`" v-model="isChecked" type="checkbox" :checked="isChecked" :hidden="true" />
        <!--				<label :for="`checkbox${checkboxName}Wrap`" :style="`height: ${height}; width: ${width}`" @click="clickCheckbox">-->
        <label :for="`checkbox${checkboxName}Wrap`" :style="`height: ${height}; width: ${width}`">
          <img v-if="isChecked" :src="require('@/assets/' + backgroundImageOn + '.svg')" :style="`height: ${height}; width: ${width}`" />
          <img v-else :src="require('@/assets/' + backgroundImageOff + '.svg')" :style="`height: ${height}; width: ${width}`" />
          {{ labelName }}</label
        >
      </div>
    </div>
    <slot name="text"></slot>
  </div>
</template>

<script>
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiBenefitCheckbox',
  components: {},
  props: {
    // 높이
    height: {
      type: String,
      default: '42px',
    },
    // 너비
    width: {
      type: String,
      default: '42px',
    },
    // 라디오 버튼 이름
    // 곂치지 않은 이름이여야 함
    checkboxName: {
      type: String,
      default: 'common',
    },
    // 라벨에 텍스트가 들어갈 경우
    // 라벨 이름
    labelName: {
      type: String,
      default: '',
    },
    // 체크박스 선택 값
    value: {
      type: Boolean,
      default: false,
    },
    // 패딩
    padding: {
      type: String,
      default: '0 0 0 0',
    },
    // 체크 된 이미지
    backgroundImageOn: {
      type: String,
      default: 'img/common/check_circle_on',
    },
    // 미 체크 이미지
    backgroundImageOff: {
      type: String,
      default: 'img/common/check_circle_off',
    },
  },
  emits: ['clickEvent'],
  data() {
    return {
      isChecked: false,
    };
  },
  watch: {
    value(newVal) {
      this.isChecked = newVal;
    },
    isChecked(newVal) {
      this.$emit('clickEvent', 'checkCheckbox', { name: this.checkboxName, checked: newVal });
    },
  },
  created() {
    this.isChecked = this.value;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: none;
}

label {
  border-radius: 8px;
  box-sizing: border-box;
  line-height: 2.46;
}

input[type='checkbox'] + label {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

input[type='checkbox']:checked + label {
  background-repeat: no-repeat;
  background-position: center;
}
</style>
