<template>
  <div class="popup-wrap">
    <div class="popup-title">{{ getAlertPopup.popupTitle }}</div>
    <div class="popup-content" v-html="getAlertPopup.popupContent"></div>
    <div class="sub_content" @click="phoneChange" v-html="getAlertPopup.sub_content"></div>
    <div class="popup-button-wrap">
      <button class="popup-button-yes" @click="cancel">아니요</button>
      <button class="popup-button-bold" @click="confirm">네, 전송할래요!</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
export default {
  computed: {
    ...mapGetters(['getAlertPopup', 'getRpSession', 'getViewAccountDetail']),
  },
  methods: {
    cancel() {
      this.$store.commit('alertPopup', {});
    },
    confirm() {
      // this.$store.commit("alertPopup", { returnValue: true });

      // this.$store.commit('alertPopup', {});
      // this.$router.push('/tvpay/register/payselfauth');

      const _vue = this;
      bus.$emit('start:loading');
      this.$utils.sequenceEx([
        _vue.tvpaySendData,
        function () {
          bus.$emit('end:loading');
          _vue.$store.commit('alertPopup', {});
          _vue.$router.push('/tvpay/register/payselfauth');
        },
      ]);
    },
    tvpaySendData() {
      const _vue = this;
      _vue.$utils.printLog('tvpaySendData');
      const deferred = this.$utils.defer();
      const params = {
        // userNo: _vue.$store.state.tvpay.cardResult.list[0].userNo
        // termId: _vue.$store.state.tvpay.cardResult.list[0].termId
        // userNo: _vue.getViewAccountDetail.userNo,
        // termId: _vue.getViewAccountDetail.termId,
        paramTermIdYN: 'Y',
      };

      if (_vue.$store.state.tvpoint.setPwdState === 'pointSetPwd') {
        params.termId = _vue.$store.state.tvpoint.viewAccountDetail.termId;
        params.userNo = _vue.$store.state.tvpoint.viewAccountDetail.userNo;
        if (_vue.$store.state.tvpoint.viewAccountDetail.isMobileUser === 'Y') {
          params.tvpaySubUrl = `/cert2/mobileSendData.mv;jsessionid=${this.getRpSession}`;
          params.phoneNo = _vue.$store.state.tvpoint.userCel;
        } else {
          params.tvpaySubUrl = `/cert2/tvpaySendData.mv;jsessionid=${this.getRpSession}`;
          params.acntNo = _vue.$store.state.tvpoint.viewAccountDetail.acntNo;
          params.phoneNo = _vue.$store.state.tvpoint.userCel;
        }
      } else {
        params.termId = _vue.$store.state.tvpay.cardResult.list[0].termId;
        params.userNo = _vue.$store.state.tvpay.cardResult.list[0].userNo;
        if (_vue.$store.state.tvpay.cardResult.list[0].isMobileUser === 'Y') {
          params.tvpaySubUrl = `/cert2/mobileSendData.mv;jsessionid=${this.getRpSession}`;
          params.phoneNo = _vue.$store.state.tvpoint.userCel;
        } else {
          params.tvpaySubUrl = `/cert2/tvpaySendData.mv;jsessionid=${this.getRpSession}`;
          params.acntNo = _vue.$store.state.tvpay.cardResult.list[0].acntNo;
          params.phoneNo = _vue.$store.state.tvpoint.userCel;
        }
      }

      // if (_vue.getViewAccountDetail.isMobileUser === 'Y') {
      // 	// if (_vue.$store.state.tvpay.cardResult.list[0].isMobileUser === 'Y') {
      // 	params.tvpaySubUrl = `/cert2/mobileSendData.mv;jsessionid=${this.getRpSession}`;
      // 	params.phoneNo = _vue.$store.state.tvpoint.userCel;
      // } else {
      // 	params.tvpaySubUrl = `/cert2/tvpaySendData.mv;jsessionid=${this.getRpSession}`;
      // 	params.acntNo = _vue.getViewAccountDetail.acntNo;
      // 	params.phoneNo = _vue.$store.state.tvpoint.userCel;
      // }

      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('tvpaySendData resultCode', data.resultCode);
        _vue.$utils.printLog('tvpaySendData data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('tvpaySendData resultCode');
          deferred.resolve();
        } else {
          bus.$emit('end:loading');
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    phoneChange() {
      this.$store.commit('alertPopup', {});
      this.$router.push('/tvpay/register/CardPhoneChange');
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-top: 2px;
  font-size: 28px;
  letter-spacing: -1.4px;
  color: #2b2d2f;
  background-color: #ffffff;
}

.popup-button-bold {
  margin-left: 44px;
  font-size: 30px;
  letter-spacing: -1.5px;
  font-weight: 500;
  color: #648cff;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 28px;
  letter-spacing: -1.12px;
  text-align: right;
  color: #343534;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  margin-top: 30px;
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #2b2d2f;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #2b2d2f;
}
.popup-wrap {
  padding: 40px;
}
.sub_content {
  font-size: 26px;
  letter-spacing: -1.3px;
  color: #8f9193;
  text-align: left;
  padding-top: 20px;
  text-decoration: underline;
}
</style>
