// jhkim: 추가
// 템플릿 구성

import Logger from '@/modules/ft-logger';
import FTEnum from './ft-enum';

const FTTemplateParser = (function () {
  // 싱글톤
  let _sharedInstance;

  function init() {
    return {
      parse(group, callback) {
        if (FTEnum.isEmpty(group.component_templates)) {
          return null;
        }

        const componentTemplates = group.component_templates;

        componentTemplates.forEach(template => {
          const templageItem = this.convert(group.id, group.group_type, template);
          Logger.info('parse: %s', JSON.stringify(templageItem));
          callback(templageItem);
        });
      },

      convert(groupId, groupType, template) {
        let templateType = '';
        let componentType = '';
        let componentTypeNo = template.type;

        switch (template.type) {
          case '': {
            break;
          }
          case 0: {
            // 상품롤링형
            templateType = 'products';
            componentType = 'ProductRolling';
            break;
          }
          case 1: {
            // 상품격자형
            templateType = 'products';
            componentType = 'ProductGrid';
            break;
          }
          case 2: {
            // 상품목록형
            templateType = 'products';
            componentType = 'ProductList';
            break;
          }
          case 3: {
            // 상품 디자인형
            templateType = 'products';
            componentType = 'ProductDesign';
            break;
          }
          case 4: {
            // 상품 이미지 롤링형
            templateType = 'products';
            componentType = 'ProductRolling';
            break;
          }
          case 5: {
            // 상품 이미지 목록형
            templateType = 'products';
            componentType = 'ProductImageList';
            break;
          }
          case 6: {
            // 상품편성(자동편성불가능)
            templateType = 'products';
            componentType = 'BSProductList';
            break;
          }
          case 7: {
            // 기획전A형
            templateType = 'special-magazines';
            componentType = 'ExhibitionA';
            break;
          }
          case 8: {
            // 기획전B형
            templateType = 'special-magazines';
            componentType = 'ExhibitionB';
            break;
          }
          case 9: {
            // 매거진A형
            templateType = 'special-magazines';
            componentType = 'MagazineA';
            break;
          }
          case 10: {
            // 매거진B형
            templateType = 'special-magazines';
            componentType = 'MagazineB';
            break;
          }
          case 11: {
            // 카테고리롤링형
            templateType = 'categories';
            // componentType = 'CategoryRolling';
            break;
          }
          case 12: {
            // 카테고리목록형
            templateType = 'categories';
            componentType = 'CategoryList';
            break;
          }
          case 13: {
            // 카테고리스크롤형
            templateType = 'categories';
            // componentType = 'CategoryScrollList';
            break;
          }
          case 14: {
            templateType = 'banners';
            componentType = 'BannerSwiper';
            break;
          }
          case 15: {
            // 배너 스크롤형
            templateType = 'banners';
            componentType = 'EventBannerList';
            break;
          }
          case 16: {
            // 배너 격자형
            templateType = 'banners';
            componentType = 'BannerGrid';
            break;
          }
          case 17: {
            templateType = 'vods';
            componentType = '';
            break;
          }
          case 18: {
            templateType = 'benefits';
            componentType = '';
            break;
          }
          case 19: {
            templateType = 'banners';
            componentType = 'HomeNewSlider';
            break;
          }
        }

        // template data
        let result = {
          id: template.id,
          groupId: groupId,
          groupType: groupType,
          type: templateType,
          componentType: componentType,
          componentTypeNo: componentTypeNo,
          title: template.title,
          caption: template.caption,
          order: template.order,
          maxItems: template.max_items,
          componentList: [], // 각 컴포넌트에서 받아올 데이터 저장할 배열
          productList: [], // 각 컴포넌트에 상품이 있으면 받아와 저장할 배열
        };

        return result;
      },
    };
  }

  return {
    sharedInstance: function () {
      if (_sharedInstance) {
        return _sharedInstance;
      }

      _sharedInstance = init();
      return _sharedInstance;
    },
  };
})().sharedInstance();

export default FTTemplateParser;
