<template>
  <div class="d-flex c-center r-space-between">
    <CommonText font-size="32" font-color="#222222" font-weight="700" spacing="-1.28"><slot name="left"></slot></CommonText>

    <slot name="right"></slot>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
/**
 * 컴포넌트 설명
 * @see @/assets/explain/설명파일.png
 * @param props : props설명 props설명
 * @param props : props설명 props설명
 */
export default {
  name: 'PaiSearchTitle',
  components: { CommonText },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
