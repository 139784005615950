// jhkim: 추가
// Enumerable 유틸리티

const FTEnum = (function () {
  // 싱글톤
  let _sharedInstance;

  function init() {
    return {
      isEmpty(...args) {
        let result = true;

        for (let x of args) {
          result = x?.length ? false : x?.size ? false : true;
          if (result == false) {
            break;
          }
        }

        return result;
      },

      isNotEmpty(...args) {
        let result = false;

        for (let x of args) {
          result = x?.length ? true : x?.size ? true : false;
          if (result) {
            break;
          }
        }

        return result;
      },

      size(enumerable) {
        return enumerable?.length ? enumerable.length : enumerable?.size ? enumerable.size : 0;
      },
    };
  }

  return {
    sharedInstance: function () {
      if (_sharedInstance) {
        return _sharedInstance;
      }

      _sharedInstance = init();
      return _sharedInstance;
    },
  };
})().sharedInstance();

export default FTEnum;
