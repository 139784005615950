<template>
  <div>
    <div class="common-padding">
      <div class="tmo-title">{{ getTmoDetail.storeName }}</div>
      <div class="tmo-subtitle">{{ getTmoDetail.commodityName }}</div>
      <div class="content-wrap">
        <div class="flex-middle-align-wrap">
          <div class="amount-title">총 결제액(+VAT)</div>
          <div class="amount-content">{{ $filters.formatComma(getTmoDetail.amount) }}원(부가세포함)</div>
        </div>
        <div class="flex-middle-align-wrap margin-10">
          <div class="amount-title">결제요청 일</div>
          <div class="amount-content">{{ getTmoDetail.regDate }}</div>
        </div>
        <p class="bar"></p>
        <div class="flex-middle-align-wrap">
          <div class="amount-use">사용가능 포인트</div>
          <div class="amount-point">{{ $filters.formatComma(getTmoDetail.avlBalance) }}P</div>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div class="amount-text">
            <span>※ 결제를 요청한 가맹점에따라</span>
            <span>사용가능 포인트가 상이할 수 있습니다.</span>
          </div>
          <div v-if="parseInt(getTmoDetail.avlBalance) < parseInt(getTmoDetail.amount)" class="amount-error">
            ({{ $filters.formatComma(parseInt(getTmoDetail.amount) - parseInt(getTmoDetail.avlBalance)) }} 부족)
          </div>
        </div>

        <p class="bar"></p>
        <div class="flex-middle-align-wrap">
          <div class="amount-use-text">사용 포인트</div>
          <div class="amount-use-point">{{ $filters.formatComma(getTmoDetail.amount) }}P</div>
        </div>
      </div>
    </div>
    <div class="btn-wrap">
      <button class="button-left" @click="closePop">취소</button>
      <button v-if="parseInt(getTmoDetail.avlBalance) >= parseInt(getTmoDetail.amount)" class="button-right" @click="goConfirm">
        결제하기
      </button>
      <button v-else class="button-right" @click="goCharge">부족한 포인트 충전하기</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';

export default {
  name: 'TmoComponent',

  computed: {
    ...mapGetters(['getTmoDetail', 'getUserPoint', 'getTermType', 'getUserNo']),
  },

  methods: {
    goConfirm() {
      this.$store.commit('loading', true);
      const data = this.$store.dispatch('approve', {
        termType: this.getTermType,
        param1: this.getTmoDetail.paymentCode,
        param2: this.getUserNo,
      });
      data.then(data => {
        this.$utils.printLog(data);
        if (parseInt(data.resultCode) === 0) {
          this.$utils.printLog('goConfirm success');
          bus.$emit('end:loading');
          this.$store.commit('slideShow', false);
          this.$router.push('/tvpoint/tmo/orderEnd');
        } else {
          this.$utils.errorMessage(this, data);
        }
      });
    },
    async goCharge() {
      await this.$store.commit('slideShow', false);
      await this.$store.commit('slideShow', { slideShow: true, slideType: 'SelectCharge' });
    },
    closePop() {
      this.$store.commit('slideShow', false);
    },
  },
};
</script>

<style scoped>
.amount-error {
  font-size: 24px;
  line-height: 1.75;
  letter-spacing: -0.96px;
  text-align: right;
  color: #fc6e73;
}

.bot-fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.margin-10 {
  margin-top: 10px;
}
.btn-wrap {
  margin-top: 50px;
  display: flex;
}
.button-right {
  width: 68%;
  height: 110px;
  background-color: #2b2d2f;

  font-size: 34px;
  line-height: 1.71;
  letter-spacing: -1.7px;
  text-align: center;
  color: #ffffff;
  box-sizing: border-box;
}

.button-left {
  width: 32%;
  height: 110px;
  background-color: #dadcde;

  font-size: 34px;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: center;
  color: #76787a;
  box-sizing: border-box;
}
.amount-use-point {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.31;
  letter-spacing: -1.6px;
  text-align: right;
  color: #e42028;
}
.amount-use-text {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -1.5px;
  text-align: left;
  color: #2b2d2f;
}
.amount-text span {
  display: block;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.42;
  letter-spacing: -0.96px;
  text-align: left;
  color: #a8aaac;
}
.amount-point {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: right;
  color: #2b2d2f;
}

.amount-use {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #2b2d2f;
}
.bar {
  margin: 36px 0 39px 0;
  border: 2px solid #e9e9e9;
}
.amount-content {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: right;
  color: #343534;
}

.amount-title {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #5d5f61;
}
.content-wrap {
  background-color: #f3f5f7;
  margin-top: 47px;
  padding: 34px 30px 38px 30px;
}

.tmo-subtitle {
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.tmo-title {
  margin-top: 40px;
  font-size: 28px;
  line-height: 1.71;
  letter-spacing: -1.4px;
  text-align: left;
  color: #999999;
}
.flex-middle-align-wrap {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
</style>
