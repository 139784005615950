const getDefaultState = () => {
  return {
    newAddr: {},
    deliveryAddr: {},
    tvPayUserNo: '',
    payCardList: {},
    addrUpdate: {},
    getOrderTrans: [],
    addrUpdateIdx: 0,
    getOrderDetail: [],
    selectCard: {},
    selectDiscount: {},
    getOrderStore: [],
    callPopup: {},
    clickCount: 0,
    fastPayPopup: false,
    cardCompanyList: [],
    cardSelected: {},
    cancelReq: false,
    shopContinue: false,
    bsPayment: false,
    smartConfirmParams: {},
    orderSaveParams: {},
  };
};

export default getDefaultState();
