<template>
  <div id="secureKeypad" :style="hasHeader ? 'background: rgba(0, 0, 0, 0.5)' : 'background: rgba(0, 0, 0, 0)'" @click="hideKeypad($event)">
    <transition name="slideY">
      <div v-if="transitionState" id="content" class="keypad-content">
        <span v-if="hasPwd" class="find_password" @click="findPwd()"> 비밀번호 재설정 </span>
        <!--키패드 헤더 영역-->
        <div v-if="hasHeader" id="keypadHeader" class="header_style1">
          <!--          <img class="img_close" src="assets/img/close.png" />-->
          <!--키패드 타이틀-->
          <div class="d-flex c-center r-center title-keypad">
            <div class="title_img"><img :src="require('@/assets/img/tvpoint/img_tvpay_w.svg')" alt="" /></div>
            <div class="title-text">{{ title }}</div>
            <div v-show="hasPwd && tryCount > 0" class="try_count">({{ tryCount }}/{{ maxTryCount }})</div>
          </div>
          <div class="try_count_wrap">
            <div v-show="hasPwd && tryCount > 0" class="try_count_er">입력하신 비밀번호가 일치하지 않습니다.</div>
          </div>
          <!--키패드 서브 타이틀-->
          <div
            v-if="subTitle.length > 0"
            ref="subTitleWrap"
            :style="{ color: state === 'FAIL' || fontColor ? ' #fffdb2' : '#ffffff' }"
            class="keypad-sub-title"
            v-html="subTitle"
          ></div>

          <div class="input_pwd">
            <span :class="{ pwd_full: encryptedBase64.length > 0 }">●</span>
            <span :class="{ pwd_full: encryptedBase64.length > 1 }">●</span>
            <span :class="{ pwd_full: encryptedBase64.length > 2 }">●</span>
            <span :class="{ pwd_full: encryptedBase64.length > 3 }">●</span>
          </div>
        </div>

        <div v-else id="keypadHeader" class="header_style2">
          <div id="hideKeypad" @click="closePad()">닫기</div>
        </div>

        <div id="keypadBody">
          <div v-for="i in MAX" :key="i" class="key">
            <div class="num" @click="clickPwd(i)">{{ displayNum(i - 1) }}</div>
          </div>
          <div class="action_text" @click="shuffleKeypad()">재배열</div>
          <div class="action_img d-flex c-center r-center" @click="deleteNum($event)">
            <img :src="require('@/assets/img/tvpoint/ic_backspace.svg')" alt="" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SecureKeypad',
  props: {
    hasHeader: {
      type: Boolean,
      default: true,
    },
    hasPwd: {
      type: Boolean,
      default: false,
    },
    tryCount: {
      type: Number,
      default: 0,
    },
    maxTryCount: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: '결제 비밀번호 설정',
    },
    subTitle: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
    fontColor: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['findPwd'],

  data() {
    return {
      // 패스워드 타입(reg 등록, use 사용)
      passType: 'reg',
      // 패스워드 단계
      passSeq: 0,
      // 트랜지션 상태값
      transitionState: false,
      // 키배열 랜덤
      MAX: 12,
      numbers: [],
      // 암호화
      reg1: '설정하신 TV페이 비밀번호 확인을 위하여<br/>비밀번호를 한 번 더 입력해주세요!',
      reg2: '보안상 생일 4자리, 휴대폰번호 뒤 4자리,<br/>동일/연속 숫자 4자리(예 : 0000/1234)는 설정하실 수 없습니다.',
      keypadSession: '',
      encryptedBase64: [],
      encryptedHex: '',
      publicKey: '',
      cryptoPublic: '',
      accessCheckValue: encodeURIComponent(
        `${location.hostname}$dKljfTFgmGp81sTA/7qzbdgwGBvDCyhbxGPz82KruuLDL/RyHxc9MbBA+cCa4MuT2QaVB4RKHKWu9nPF91GHDQ==`,
      ),
    };
  },
  computed: {
    combine() {
      const str = this.passType + this.passSeq;
      return this[str];
    },
    displayNum() {
      return index => {
        if (this.numbers[index] === 11 || this.numbers[index] === 12) {
          return '';
        } else if (this.numbers[index] === 10) {
          return 0;
        } else {
          return this.numbers[index];
        }
      };
    },
  },
  watch: {
    encryptedBase64: {
      deep: true,
      handler(newVal) {
        if (newVal.length === 4) {
          this.$utils.printLog('비밀번호 입력 완료!');
          // this.emitCipherText(newVal);

          this.$parent.setPwd(this.encryptedBase64.join(''));

          this.encryptedBase64 = [];
          this.encryptedHex = '';

          // if (!this.success) {
          //   // 비밀번호 오류
          //   this.pwd = "";
          //   this.tryCount += 1;
          // } else {
          //   // TODO jskim - 수정끝나면 원복
          //   // this.$emit('smartConfirm')
          // }
        }
      },
    },
  },
  created() {
    this.numbers = this.shuffleNumber(this.MAX);
    this.keypadInit();
  },
  mounted() {
    this.transitionState = true;
  },
  methods: {
    keypadInit() {
      // 통신을 보내는 경우에만 암호화해서 보낸다
      // // RSA 키페어 생성
      // this.$store
      //   .dispatch("keypadInit", {})
      //   .then(res => {
      //     this.publicKey = res.PublicKey_BASE64;
      //     this.keypadSession = res.jsessionId;
      //   })
      //   .then(() => {
      //     this.createPublicKey();
      //   });
    },
    createPublicKey() {
      // jsencrypt 의존성을 갖는다(npm i -D jsencrypt)
      const JSEncrypt = require('jsencrypt');

      this.cryptoPublic = new JSEncrypt.JSEncrypt({
        default_key_size: 2048,
      });

      let PEMString = '-----BEGIN PUBLIC KEY-----\n';
      PEMString += this.publicKeyWordWrap(this.publicKey) + '\n';
      PEMString += '-----END PUBLIC KEY-----';

      this.cryptoPublic.setPublicKey(PEMString);
    },
    clickPwd(pwdIdx) {
      let val = this.numbers[pwdIdx - 1];

      switch (val) {
        case 10:
          val = 0;
          break;
        case 11:
        case 12:
          return;
      }

      if (this.hasHeader) {
        // 암호화 1차오픈 제거
        this.encryptedBase64.push('' + val);
        //
      } else {
        // 헤더 정보가 없는경우 상위 컴포넌트에 클릭 된 키패드 값을 전달 한다.
        this.$utils.printLog('SecureKeyPad clickPwd!!!!!!!!!!!!!!');
        this.$parent.keypadClick('' + val);
      }

      // 암호화 된 값으로 해야 한다.
      // 유효성 체크11

      // if (typeof val === "number") {
      //   this.pwd = this.pwd + val;
      // } else {
      //   //지우기
      //   this.pwd = this.pwd.substring(0, this.pwd.length - 1);
      // }
    },
    emitCipherText(pwdArray) {
      let encryptedHex = '';

      for (let i = 0; i < 4; i++) {
        encryptedHex += encodeURIComponent(this.base64ToHex(pwdArray[i]) + '&');
      }

      const pwdDecryptParams = {
        encryptedPwd: encryptedHex,
        licenseData: this.accessCheckValue,
        jsessionId: this.keypadSession,
      };

      this.$parent.pwdInputComplete(pwdDecryptParams);
      // this.$emit("pwdInputComplete", pwdDecryptParams);
    },
    findPwd() {
      // 비밀번호 찾기
      this.$emit('findPwd');
    },
    deleteNum() {
      // 지우기
      // this.encryptedBase64.pop();
      if (this.hasHeader) {
        this.encryptedBase64.pop();
      } else {
        this.$parent.keypadClick('');
      }
    },
    hideKeypad($event) {
      if (this.hasHeader) {
        if ($event.target.getAttribute('id') === 'secureKeypad') {
          this.transitionState = false;
          setTimeout(() => {
            this.$parent.keypadToggle();
          }, 500); // TODO: 시간을 부모컴포넌트에서 조절할수있게 해줘야되나..?
        }
      }
    },
    closePad() {
      this.transitionState = false;
      setTimeout(() => {
        this.$parent.keypadToggle();
      }, 500); // TODO: 시간을 부모컴포넌트에서 조절할수있게 해줘야되나..?
    },
    // 랜덤 키배열 관련 함수
    shuffleNumber(max) {
      const num = [];
      for (let i = 0; i < max; ++i) {
        num[i] = i + 1;
      }
      for (let i = 0; i < max; ++i) {
        const dest = Math.floor(Math.random() * 12);
        const temp = num[i];
        num[i] = num[dest];
        num[dest] = temp;
      }
      return num;
    },
    shuffleKeypad() {
      this.numbers = this.shuffleNumber(this.MAX);
    },
    // 공개키 생성 관련 함수
    publicKeyWordWrap(str, width) {
      width = width || 64;
      if (!str) {
        return str;
      }
      const regex = '(.{1,' + width + '})( +|$\n?)|(.{1,' + width + '})';
      return str.match(RegExp(regex, 'g')).join('\n');
    },
    base64ToHex(str) {
      const raw = atob(str);
      let result = '';
      for (let i = 0; i < raw.length; i++) {
        const hex = raw.charCodeAt(i).toString(16);
        result += hex.length === 2 ? hex : '0' + hex;
      }
      return result.toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.keypad-content {
  z-index: 300;
}

.title-text {
  padding-left: 10px;
}
.keypad-sub-title {
  margin-top: 20px;
  opacity: 0.8;
  font-size: 26px;
  line-height: 1.38;
  letter-spacing: -1.56px;
  text-align: center;
  color: #ffffff;
}

.title_img {
  width: 94px;
  height: 40px;
  object-fit: contain;
}

#secureKeypad {
  position: fixed;
  display: table;
  z-index: 3000;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

#secureKeypad * {
  box-sizing: border-box;
}

#secureKeypad input[type='button'] {
  background-color: white;
}
#content {
  position: absolute;
  width: 100%;
  z-index: 35;
  bottom: 0;
}

.header_style1 {
  margin-top: 28px;
  width: 100%;
  height: 390px;
  background-color: #2b2d2f;
  border-radius: 50px 50px 0 0;
  text-align: center;
}

.header_style2 {
  height: 100%;
  height: 60px;
  background-color: #e5e5e5;
}

#hideKeypad {
  position: relative;
  float: right;
  width: 20%;
  font-size: 28px;
  color: #333333;
  letter-spacing: -1.4px;
  line-height: 60px;
  text-align: center;
}

#keypadBody {
  height: 536px;
  background-color: #ffffff;
}

.text_keypad {
  width: 40px;
  height: 66px;
  font-size: 45px;
  font-weight: bold;
  line-height: 1.71;
  letter-spacing: -1.8px;
  text-align: center;
  color: #333333;
}

.input_pwd {
  width: auto;
  margin-top: 45px;
  //height: 47px;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.8);
  font-size: 40px;
  line-height: 1.69;
  letter-spacing: 10px;
  color: #2b2d2f;
  //margin-bottom: 38px;
}
.input_pwd span {
  margin: 0 10px;
}

.pwd_full {
  color: #ffffff !important;
}

.img_close {
  position: absolute;
  top: 24px;
  right: 24px;
}

.header_style1 .title-keypad {
  height: 111px;
  padding-top: 64px;

  color: #ffffff;
  font-size: 34px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: -1.7px;
  text-align: center;
}

.header_style1 .try_count {
  padding-left: 12px;
  font-size: 34px;
  font-weight: 500;
  letter-spacing: -1.7px;
  text-align: center;
  color: #fffdb2;
}
.try_count_wrap {
  padding-top: 24px;
  height: 40px;
}
.header_style1 .try_count_er {
  font-size: 28px;
  letter-spacing: -1.68px;
  text-align: center;
  color: #fffdb2;
}

.find_password {
  margin: 0 0 28px 40px;
  height: 28px;
  font-size: 28px;
  line-height: 1.18;
  letter-spacing: -1.4px;
  text-align: left;
  color: #ffffff;
  text-decoration: underline;
  /*width: auto;*/
  /*height: 41px;*/
  /*opacity: 0.7;*/
  /*font-size: 28px;*/
  /*line-height: 1.18;*/
  /*letter-spacing: -1.4px;*/
  /*text-align: center;*/
  /*color: #ffffff;*/
  /*text-decoration: underline;*/
  /*white-space: pre;*/
}

.key {
  position: relative;
  float: left;
  width: 25%;
  height: 136px;
  background-color: white;
  color: black;
  border: 2px solid #e5e5e5;
}

.num {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 50px;
  line-height: 132px;
  font-weight: bold;
  text-align: center;
}

.action_text {
  position: relative;
  float: left;
  width: 50%;
  height: 128px;
  font-size: 36px;
  text-align: center;
  line-height: 128px;
  background-color: white;
  color: black;
  font-weight: 500;
  border: 2px solid #e5e5e5;
}

.action_img {
  /*position: relative;*/
  /*float: left;*/
  /*width: 50%;*/
  /*height: 128px;*/
  /*font-size: 36px;*/
  /*text-align: center;*/
  /*line-height: 150px;*/
  /*background-color: white;*/
  /*color: black;*/
  /*font-weight: 500;*/
  /*border: 2px solid #e5e5e5;*/

  height: 128px;
  border: 2px solid #e5e5e5;

  img {
    width: 50px;
  }
}

/* 트랜지션 변경 필요할경우 수정 */
.slideY-enter-active {
  transition: all 0.5s;
}
.slideY-leave-active {
  transition: all 0.5s;
}
.slideY-enter,
.slideY-leave-to {
  transform: translateY(100%);
}
</style>
