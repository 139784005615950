<template>
  <div class="newTerms-wrap common-padding">
    <div class="newTerms-title">
      <div class="newTerms-title-content">고객님, 안녕하세요!</div>
      <div class="newTerms-content">더 편한 서비스를 위해 핏콜라보 약관이 변경되었습니다.</div>
    </div>
    <div class="newTerms-container">
      <div
        v-for="(term, idx) in termsList"
        :key="term.title"
        class="term"
        :data-id="idx"
        :style="idx === termsList.length - 1 ? 'margin-bottom:48px' : ''"
      >
        <input
          :id="'termsCheck' + idx"
          :ref="`check${idx}`"
          v-model="allCheck"
          type="checkbox"
          name=""
          class="terms-check"
          @click="checkInd"
        />
        <label :for="'termsCheck' + idx"></label>
        <span class="term-text" :for="'termsCheck' + idx" @click="showTerms(term)">[필수]{{ term.title }}</span>
        <!--				<img-->
        <!--					:src="-->
        <!--						termCheck ? require('../../../../assets/img/tvpoint/btn_chk_on.svg') : require('../../../../assets/img/tvpoint/check_off.svg')-->
        <!--					"-->
        <!--					class="term_img"-->
        <!--					@click="showCheck"-->
        <!--				/>-->
        <!--				<span class="term-text" :for="'termsCheck' + idx" @click="showTerms(term)">[필수]{{ term.title }}</span>-->
        <!--				<span class="term-text" :for="'termsCheck' + idx" @click="showTerms(term)">[필수]{{ term.title }}</span>-->
      </div>
      <button class="submit-btn radius" :class="checkAll ? 'focus' : ''" @click="goNext">동의하고 계속하기</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
export default {
  data() {
    return {
      prevPath: '',
      termCheck: false,
      successYn: false, // 테스트 true 추후 false로
      termsList: [],
      checkCount: 0,
      checkAll: false,
    };
  },
  computed: {
    ...mapGetters(['getDeviceName', 'getRedirect', 'getInitialTerms', 'getRpSession']),
  },
  created() {
    this.$store.commit('setInitialTerms', false);
    // this.prevPath = this.$nuxt.context.from.path;

    // this.$utils.sequenceEx([this.saveData, this.getOrderTerms]);
    this.saveData();
  },
  beforeUnmount() {
    // 약관 상세 이동 후 돌아왔을 때 처리 부분
    if (!this.successYn && !this.getInitialTerms) {
      // 로그인 정보 초기화
      this.removeUserInfo();
      // this.$utils.sequenceEx([
      //   this.logout,
      //   function () {
      //     this.removeUserInfo();
      //   },
      // ]);
    }
  },
  methods: {
    allCheck($event) {},
    saveData() {
      const _vue = this;
      _vue.$utils.printLog('saveData');
      const deferred = this.$utils.defer();
      const params = {
        tvpaySubUrl: '/api/saveData.mv',
        termId: 'A1298616210',
        storeId: 'MOBILETP01',
        // termId: _vue.getTermId,
        // storeId: _vue.getStoreId,
      };

      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('saveData resultCode', data.resultCode);
        _vue.$utils.printLog('saveData data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('saveData resultCode');
          _vue.$store.commit('setRpSession', data.resultData.jSession);
          this.getOrderTerms();
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    async getOrderTerms() {
      const _vue = this;
      const deferred = this.$utils.defer();
      const params = {
        tvpaySubUrl: `/api/getOrderTerms.mv;jsessionid=${_vue.getRpSession}`,
        cardCode: '1011',
        methodType: 'SMART_PAY',
        userType: 'mobile_pay',
        formType: 'tv_card_new',
        clientType: 'mobile',
      };
      const data = _vue.$store.dispatch('callRelayRpServer', params);

      await data.then(data => {
        _vue.$utils.printLog('getOrderTerms resultCode', data.resultCode);
        _vue.$utils.printLog('getOrderTerms data', data);
        if (parseInt(data.resultCode) === 0) {
          bus.$emit('end:loading');
          _vue.$utils.printLog('getOrderTerms resultData', data.resultData);
          for (let i = 0; i < data.resultData.list.length; i++) {
            if (data.resultData.list[i].userType == `mobile_pay`) {
              _vue.termsList.push(data.resultData.list[i]);
            }
          }
          _vue.$store.commit('setTermsTitleList', _vue.termsList);
          _vue.$utils.printLog('setTermsTitleList', _vue.termsList);
          // deferred.resolve();
        } else {
          // deferred.reject([_vue, data]);
          _vue.$utils.errorMessage(_vue, data);
        }
      });
    },
    showCheck($event) {
      // this.termCheck = !this.termCheck;
      const check = $event.target.checked;
      if (this.showTerms) {
        for (const [key, value] of Object.entries(this.$refs)) {
          value[0].checked = check;
          if (check) {
            this.checkCount = this.termsList.length;
          } else {
            this.checkCount = 0;
          }
        }
      } else {
        this.$utils.printLog('!this.showTerms');
      }
    },
    showTerms(type) {
      let termsTitle = '';
      let termsContent = '';

      const _vue = this;
      _vue.$utils.printLog('showTerms');
      const params = {
        tvpaySubUrl: `/api/getCardTerms.mv;jsessionid=${_vue.getRpSession}`,
        methodType: type.methodId,
        userType: type.userType,
        termType: type.termType,
      };
      const data = _vue.$store.dispatch('callRelayRpServer', params);

      data.then(data => {
        _vue.$utils.printLog('getCardTerms resultCode', data.resultCode);
        _vue.$utils.printLog('getCardTerms data', data);
        if (parseInt(data.resultCode) === 0) {
          _vue.$utils.printLog('getCardTerms resultData', data.resultData);
          termsTitle = data.resultData.termsTitle;
          termsContent = data.resultData.termsContents;
          this.$store.commit('setInitialTerms', true);
          this.$store.commit('slideShow', {
            slideShow: true,
            slideType: 'NewTermsComponent',
            slideContent: { termsTitle, termsContent },
          });
          bus.$emit('end:loading');
        } else {
          _vue.$utils.errorMessage(_vue, data);
        }
      });

      // const termsTitle = '핏콜라보 통합 이용약관';
      // const termsContent = require('~/assets/text/new_terms.txt').default;
      // this.$store.commit('setInitialTerms', true);
      // this.$store.commit('slideShow', { slideShow: true, slideType: 'NewTermsComponent', slideContent: { termsTitle, termsContent } });
    },
    goNext() {
      const _vue = this;
      // if (this.termCheck) {
      //   const redirect = this.$utils.isNull(this.getRedirect) ? "/main" : this.getRedirect;
      //   this.$store.commit("loading", false);
      //   this.$router.push(redirect);
      // }
      if (this.termCheck) {
        // this.$store.commit('loading', true);

        bus.$emit('start:loading');
        if (Object.keys(this.$store.getters.getTvInfo).length !== 0) {
          this.$utils.sequenceEx([
            this.setAgrment,
            function () {
              _vue.$store.commit('slideShow', { slideShow: false });
              _vue.successYn = true;
              const tvInfo = _vue.$store.getters.getTvInfo;
              bus.$emit('end:loading');
              // _vue.$router.push(redirect);
              _vue.$store.commit('setCommonToastPopup', { content: 'TV에 회원등록이 완료되었습니다.' });
              _vue.$store.commit('setTvInfo', {});
              _vue.$store.commit('setSelfAuthData', {});

              _vue.$utils.printLog('☆ redirectPage', tvInfo.redirectPage);
              _vue.$utils.printLog('☆ userRegType', _vue.$store.state.tvpoint.userRegType);

              if (!_vue.$utils.isNull(tvInfo.redirectPage)) {
                if (tvInfo.redirectPage !== '/tvmobile/inquireinfo') {
                  _vue.$utils.printLog('☆ deviceType ===>', _vue.$store.state.common.deviceType);
                  // _vue.$store.commit('setTvLogin', true);
                  if (_vue.$store.state.tvpoint.userRegType === 'appCard') {
                    _vue.$store.commit('setIsBack', true);
                    _vue.$store.commit('setUserRegType', '');
                    _vue.$router.go(-3);
                  } else {
                    _vue.$store.commit('setUserRegType', '');
                    _vue.$store.commit('setIsBack', true);
                    _vue.$router.go(-1);
                  }
                } else {
                  _vue.$router.push('/initPage');
                }
              }
            },
          ]);
        } else {
          this.$utils.sequenceEx([
            this.setAgrment,
            function () {
              _vue.$store.commit('slideShow', { slideShow: false });
              if (_vue.prevPath === '/initPage') {
                _vue.successYn = true;
                // const redirect = this.$utils.isNull(this.getRedirect) ? "/main" : this.getRedirect;
                // _vue.$store.commit("loading", false);
                // _vue.$router.push(redirect);
                _vue.$router.go(-1);
              } else {
                _vue.successYn = true;
                if (_vue.$store.state.common.fromPath === '/bbarshop/orderList') {
                  _vue.$store.commit('setProductDetailIsBack', true);
                  _vue.$router.push('/bbarshop/orderList');
                } else {
                  _vue.$router.push('/initPage');
                }
              }
            },
          ]);
        }
      }
    },
    logout() {
      const _vue = this;
      const deferred = _vue.$utils.defer();
      const data = _vue.$store.dispatch('logout');

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          deferred.resolve();
        } else {
          deferred.resolve();
        }
      });
      return deferred.promise;
    },
    async removeUserInfo() {
      // const _vue = this;
      // await _vue.$store.commit("isLogin", false);
      // await _vue.$store.commit("isAutoCharge", "N");
      // await _vue.$store.commit("isAutoTrans", "N");
      // 로그인 정보 초기화
      await this.$store.commit('resetPointState');
      await this.$store.commit('resetRpState');
      await this.$store.commit('resetUserState');
      await this.$store.commit('resetPaiState');
    },
    setAgrment() {
      const _vue = this;
      _vue.$utils.printLog('setAgrment');
      const deferred = this.$utils.defer();
      const data = _vue.$store.dispatch('setAgrment', { code: 'AGR_0001', clientType: _vue.getDeviceName, isYN: 'Y' });

      data.then(data => {
        _vue.$utils.printLog('setAgrment resultCode', data.resultCode);
        _vue.$utils.printLog('setAgrment data', data);
        if (parseInt(data.resultCode) === 0) {
          deferred.resolve();
        } else {
          deferred.reject([_vue, data]);
        }
      });
      return deferred.promise;
    },
    checkInd($event) {
      this.$utils.printLog('this.checkCount', this.checkCount);
      if ($event.target.checked) {
        this.checkCount++;
        if (this.termsList.length == this.checkCount) {
          this.checkAll = true;
          this.termCheck = true;
        }
      } else {
        this.checkCount--;
        if (this.termsList.length > this.checkCount) {
          this.checkAll = false;
          this.termCheck = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.newTerms-wrap {
  height: 100%;
}
.newTerms-title {
  height: 178px;
  margin-bottom: 38px;
  display: table-cell;
  vertical-align: middle;
}
.newTerms-title-content {
  height: 48px;
  line-height: 48px;
  font-size: 34px;
  letter-spacing: -1.7px;
  color: #343534;
  font-weight: bold;
}
.newTerms-content {
  height: 48px;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -1.4px;
  color: #666666;
}
.newTerms-container {
  height: 100%;
}
.term {
  margin-bottom: 28px;
}
.term_img {
  display: inline-block;
  vertical-align: middle;
}
.term-text {
  height: 27px;
  line-height: 27px;
  font-size: 26px;
  letter-spacing: -1.3px;
  color: #343534;
  vertical-align: middle;
  border-bottom: 2px solid #343534;
}
.terms-agree-btn {
  background-color: #e42028;
  height: 100px;
  width: 100%;
  font-size: 32px;
  line-height: 100px;
  color: #ffffff;
  text-align: center;
  letter-spacing: -1.6px;
  border-radius: 10px;
  margin-bottom: 40px;
}
.submit-btn {
  margin-bottom: 40px;
}
.terms-check {
  display: none;
  float: left;
  box-sizing: border-box;
}
.terms-check + label {
  float: left;
  width: 42px;
  height: 42px;
  line-height: 42px;
  padding-bottom: 2px;
  background: url('../../../../assets/img/tvpoint/check_off.svg') no-repeat;
  box-sizing: border-box;
}
.terms-check:checked + label {
  display: inline-block;
  float: left;
  width: 42px;
  height: 42px;
  line-height: 42px;
  padding-bottom: 2px;
  background: url('../../../../assets/img/tvpoint/btn_chk_on.svg') no-repeat;
  box-sizing: border-box;
}
</style>
