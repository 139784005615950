import {
  paiLogin,
  renewToken,
  paiLogout,
  paiChannelList,
  channelDetailInfo,
  channelUseList,
  fetchHiddenAsset,
  paiBenefitList,
  getFilter,
  subChannelList,
  subKeyList,
  hiddenChannelList,
  channelUnHide,
  storeRoomInfo,
  contentsCount,
  subscriptionCount,
  readAllList,
  channelHide,
  storedBenefitList,
  storeBenefit,
  deleteBenefit,
  channelSub,
  channelUnSub,
  keySub,
  keyUnSub,
  subChannelState,
  notificationList,
  channelPostSetting,
  guestBenefitList,
  pushAlarmList,
  setChannelTop,
  unSetChannelTop,
  checkChannelTop,
  fetchAssociatePoint,
  alarmCount,
  newCount,
  updateAlarms,
  deleteAlarms,
  getAlarmsCount,
  getAlarmsList,
  paiBenefitListFilter,
  setPostSettings,
  setSubsChannelsAlarm,
  getCodeCategoryMain,
  getCodeContentInfo,
} from '@/apis/pai';
import $store from '@/store';
import $commons from '@/utils/commons';
import $bridge from '@/utils/FTBridgeShared';

const actions = {
  async paiLogin({ commit }, params) {
    const { data } = await paiLogin(params);
    $commons.printLog(' Pai Login action data typeof', typeof data);
    $commons.printLog(' Pai Login action data', data);

    if (!$commons.isNull(data.access_token)) {
      const { access_token, renewal_token } = data;

      // deviceType 1로 분기 되어있던 코드 통합 espark
      await $bridge.shared().setStringValue('access_token', access_token);
      await $bridge.shared().setStringValue('renewal_token', renewal_token);
    }
    $commons.printLog(' Pai Login action data return', data);
    return data;
  },
  async renewToken({ commit }) {
    const { data } = await renewToken();
    const { access_token, renewal_token } = data;

    // deviceType 1로 분기 되어있던 코드 통합 espark
    await $bridge.shared().setStringValue('access_token', access_token);
    await $bridge.shared().setStringValue('renewal_token', renewal_token);
    return data;
  },
  async paiLogout({ commit }, params) {
    const { data } = await paiLogout(params);
    return data;
  },
  async paiChannelList({ commit }) {
    const { data } = await paiChannelList();
    commit('paiChannelList', data);
  },
  async channelDetailInfo({ commit }, params) {
    const { data } = await channelDetailInfo(params);
    commit('channelDetailInfo', data.channel);
    commit('channelMenus', data.channel.channel_menus);
  },
  async subChannelList({ commit }) {
    const { data } = await subChannelList();
    return { data };
  },
  async subKeyList({ commit }) {
    const { data } = await subKeyList();
    commit('subKeyList', data.subs_keywords);
  },
  async channelUseList({ commit }, params) {
    const { data } = await channelUseList(params);
    commit('channelUseList', data);
  },
  async fetchHiddenAsset({ commit }) {
    const { data } = await fetchHiddenAsset();
    return data;
  },
  async paiBenefitList({ commit }, params) {
    const { data } = await paiBenefitList(params);
    // commit('benefitList', data.contents);
    return data;
  },
  async paiBenefitListFilter({ commit }, params) {
    const { data } = await paiBenefitListFilter(params);
    // commit('benefitList', data.contents);
    return data;
  },
  async getFilter({ commit }, params) {
    const { data } = await getFilter(params);
    commit('filterList', data);
  },
  async hiddenChannelList({ commit }) {
    const { data } = await hiddenChannelList();
    commit('hiddenChannelList', data);
  },
  async channelUnHide({ commit }, params) {
    return await channelUnHide(params);
  },
  async channelHide({ commit }, params) {
    return await channelHide(params);
  },
  async storedBenefitList({ commit }, params) {
    const { data } = await storedBenefitList(params);
    commit('storedBenefitList', data);
    return data;
  },
  async storeBenefit({ commit }, params) {
    const data = await storeBenefit(params);
    return data;
  },
  async deleteBenefit({ commit }, params) {
    const data = await deleteBenefit(params);
    return data;
  },
  async storeRoomInfo({ commit }, params) {
    const { data } = await storeRoomInfo(params);
    commit('storeRoomInfo', data);
    return data;
  },
  async contentsCount({ commit }, params) {
    const { data } = await contentsCount(params);
    commit('contentsCountList', data);
    return data;
  },
  async subscriptionCount({ commit }) {
    const { data } = await subscriptionCount();
    return data;
  },
  async readAllList({ commit }, params) {
    const data = await readAllList(params);
    return data;
  },
  async channelSub({ commit }, params) {
    return await channelSub(params);
    // commit('channelSub', data);
  },
  async channelUnSub({ commit }, params) {
    return await channelUnSub(params);
  },
  async keySub({ commit }, params) {
    const { data } = await keySub(params);
    return { data };
  },
  async keyUnSub({ commit }, params) {
    const { data } = await keyUnSub(params);
    return { data };
  },
  async subChannelState({ commit }, id) {
    const { data } = await subChannelState(id);
    commit('subChannelState', data.subs_channel);
  },
  async notificationList({ commit }) {
    const { data } = await notificationList();
    commit('notificationList', data);
  },
  async channelPostSetting({ commit }, id) {
    const { data } = await channelPostSetting(id);
    commit('channelPostSetting', data);
  },

  async guestBenefitList({ commit }, params) {
    const { data } = await guestBenefitList(params);
    return data;
  },
  async pushAlarmList({ commit }) {
    const { data } = await pushAlarmList();
    commit('pushAlarmList', data);
  },

  async setChannelTop({ commit }, params) {
    const { data } = await setChannelTop(params);
    return data;
  },

  async unSetChannelTop({ commit }, params) {
    return await unSetChannelTop(params);
  },

  async checkChannelTop({ commit }) {
    const { data } = await checkChannelTop();
    return data;
  },

  async fetchAssociatePoint({ commit }, params) {
    const { data } = await fetchAssociatePoint(params);
    return data;
  },

  async alarmCount({ commit }, params) {
    const { data } = await alarmCount(params);
    commit('alarmCount', data.counts);
  },

  async newCount({ commit }) {
    const { data } = await newCount();
    commit('newCount', data.counts);
  },

  async updateAlarms({ commit }, params) {
    const { data } = await updateAlarms(params);
    return { data };
  },

  async deleteAlarms({ commit }, params) {
    const { data, resultCode } = await deleteAlarms(params);

    return { data, resultCode };
  },

  async getAlarmsList({ commit }, params) {
    const { data } = await getAlarmsList(params);
    return { data };
  },

  async getAlarmsCount({ commit }, params) {
    const { data } = await getAlarmsCount(params);
    return { data };
  },

  async setPostSettings({ commit }, params) {
    const data = await setPostSettings(params);
    return data;
  },
  async setSubsChannelsAlarm({ commit }, params) {
    const data = await setSubsChannelsAlarm(params);
    return data;
  },
  async getCodeCategoryMain({ commit }) {
    const { data } = await getCodeCategoryMain();
    commit('codeCategoryMain', data.codes);
  },
  async getCodeContentInfo({ commit }) {
    const { data } = await getCodeContentInfo();
    commit('codeContentInfo', data.codes);
  },
};

export default actions;
