<template>
  <div>
    <transition name="fade">
      <div v-if="slideShow" id="layer" ref="layer" class="dim_layer" @click="closeSlide"></div>
    </transition>
    <transition name="slide">
      <div v-if="slideShow" ref="slideContainer" class="container2">
        <component :is="slideType" :is-scroll="isScroll" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckFamilyFrame from '@/components/tvpoint/family/CheckFamilyFrame';
// import ExchangeVat from '@/components/tvpoint/popup/SlidingPopupFrame/ExchangeVat'; // 사용하지 않음
import SelectAmountSlideComponent from '@/components/tvpoint/common/SelectAmountSlideComponent';
import SelectDateSlideComponent from '@/components/tvpoint/common/SelectDateSlideComponent';
import SelectCardSlideComponent from '@/components/tvpoint/popup/SlidingPopupFrame/SelectCardSlideComponent.vue';
// import TermsContentsComponent from '@/components/common/TermsContentsComponent.vue';
import CouponComponent from '@/components/tvpoint/CouponComponent';
import PointPassword from '@/components/tvpoint/PointPassword';
// import MainNoticeComponent from '@/components/popup/SlidingPopupFrame/MainNoticeComponent';
// import ExitComponent from '@/components/popup/SlidingPopupFrame/ExitComponent';
import FeeComponent from '@/components/tvpoint/popup/SlidingPopupFrame/FeeComponent';
import AskTypeComponent from '@/components/tvpoint/popup/SlidingPopupFrame/AskTypeComponent';
import TelecomSlideComponent from '@/components/tvpoint/popup/SlidingPopupFrame/TelecomSlideComponent';
import TermsSlideComponent from '@/components/tvpoint/popup/SlidingPopupFrame/TermsSlideComponent';
import NewTermsSlideComponent from '@/components/tvpoint/popup/SlidingPopupFrame/NewTermsSlideComponent';
import TmoComponent from '@/components/tvpoint/popup/SlidingPopupFrame/TmoComponent';
import SelectCharge from '@/components/tvpoint/popup/SlidingPopupFrame/SelectCharge';
import BasicTermsComponent from '@/components/tvpoint/popup/SlidingPopupFrame/BasicTermsComponent';
import TelecomBasicTermsComponent from '@/components/tvpoint/popup/SlidingPopupFrame/TelecomBasicTermsComponent';
import NewTermsComponent from '@/components/tvpoint/popup/SlidingPopupFrame/NewTermsComponent';
// import StoreAmountComponent from '~/components/popup/SlidingPopupFrame/StoreAmountComponent';
import AuthNumInfoComponent from '@/components/tvpoint/popup/SlidingPopupFrame/AuthNumInfoComponent';
// import AppCardListComponent from '~/components/popup/SlidingPopupFrame/AppCardListComponent';
import AppCardTermsSlideComponent from '@/components/tvpoint/common/AppCardTermsSlideComponent';
import AppCardTermsComponent from '@/components/tvpoint/popup/SlidingPopupFrame/AppCardTermsComponent';
//--dh
import InstantPaymentComponent from '@/components/tvpoint/popup/SlidingPopupFrame/InstantPaymentComponent.vue';
import ShopPointInfo from '@/components/orderPayment/shopPointInfo.vue';
import bus from '@/utils/bus';
import ContentPopup from '@/components/user/ContentPopup';

export default {
  components: {
    TelecomSlideComponent,
    TermsSlideComponent,
    NewTermsSlideComponent,
    AskTypeComponent,
    CheckFamilyFrame,
    // ExchangeVat,               // 포인트 전환 수수료 안내 팝업, FeeComponent로 사용하고 있음.
    SelectAmountSlideComponent,
    SelectDateSlideComponent,
    SelectCardSlideComponent,
    TmoComponent,
    SelectCharge,
    // TermsContentsComponent,    // TV포인트 운영에서 사용하지 않음.
    CouponComponent,
    PointPassword,
    // MainNoticeComponent,       // 첫 진입 시 뜨는 팝업, FIT에서 사용하지 않음. ExecutionBanner 대체함.
    // ExitComponent,             // 첫 진입 시 뜨는 팝업과 동일하지만 앱 종료하기 버튼 있는 팝업.
    BasicTermsComponent,
    TelecomBasicTermsComponent,
    NewTermsComponent,
    FeeComponent,
    // StoreAmountComponent,      // 사용처 별 사용가능 포인트 팝업. TV포인트 운영에서 사용하지 않음.
    AuthNumInfoComponent,
    // AppCardListComponent,      // TV포인트 운영에서 사용하지 않음.
    AppCardTermsSlideComponent,
    AppCardTermsComponent,
    //--dh
    InstantPaymentComponent,
    ShopPointInfo,
    ContentPopup,
  },
  data() {
    return {
      height: 0,
      pageYOffset: 0,
      isScroll: false,
    };
  },
  computed: {
    ...mapGetters(['slideShow', 'slideType', 'lastScrollTop']),
  },
  watch: {
    slideShow(slideShow) {
      this.$utils.printLog('sliding popup watch' + this.slideShow);
      if (slideShow) {
        this.$nextTick(() => {
          this.pageYOffset = window.scrollY;
          const container = document.querySelector('.container2');
          const winSize = document.documentElement.clientHeight * 0.9;
          const comSize = container.children[0].clientHeight;

          if (this.slideType === 'ExitComponent' || this.slideType === 'MainNoticeComponent') {
            // document.getElementById("__nuxt").style.top = "-" + this.pageYOffset + "px";
            container.style.height = comSize + 'px';
            this.isScroll = false;
          } else if (comSize >= winSize) {
            container.style.height = winSize + 'px';
            this.isScroll = true;
          } else {
            this.isScroll = false;
            // document.getElementById('__nuxt').style.width = '-webkit-fill-available';
            // document.getElementById('__nuxt').style.position = 'fixed';
            // document.getElementById('__nuxt').style.top = '-' + this.pageYOffset + 'px';

            document.getElementById('app').style.width = '-webkit-fill-available';
            document.getElementById('app').style.position = 'fixed';
            document.getElementById('app').style.top = '-' + this.pageYOffset + 'px';
            document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
          }

          // this.pageYOffset = window.scrollY;
          // document.getElementById("__nuxt").style.width = "-webkit-fill-available";
          // document.getElementById("__nuxt").style.position = "fixed";
          // document.getElementById("__nuxt").style.top = "-" + this.pageYOffset + "px";
          // document.getElementsByTagName("body")[0].style.overflowY = "scroll";
          // const container = document.querySelector(".container2");
          //
          // const winSize = document.documentElement.clientHeight * 0.9;
          // const comSize = container.children[0].clientHeight;
          //
          // if (comSize >= winSize) {
          //   container.style.height = winSize + "px";
          //   this.isScroll = true;
          // } else if (this.slideType === "ExitComponent" || this.slideType === "MainNoticeComponent") {
          //   container.style.height = comSize + "px";
          //   this.isScroll = false;
          // } else {
          //   this.isScroll = false;
          // }
        });
      } else {
        document.getElementsByTagName('body')[0].style.overflowY = '';
        document.getElementById('app').style.width = '100%';
        document.getElementById('app').style.position = '';
        // document.getElementById('__nuxt').style.width = '100%';
        // document.getElementById('__nuxt').style.position = '';
        window.scrollTo({ top: this.pageYOffset });
      }
    },
  },
  mounted() {
    bus.$emit('end:loading');
  },
  updated() {
    this.$utils.printLog('updated' + this.slideShow);
    if (this.slideShow) {
      this.$nextTick(() => {
        this.$utils.printLog('sliding Updated children height', document.querySelector('.container2').children[0].clientHeight);
        const container = document.querySelector('.container2');

        const winSize = document.documentElement.clientHeight * 0.9;
        const comSize = container.children[0].clientHeight;
        if (comSize >= winSize) {
          container.style.height = winSize + 'px';
          this.isScroll = true;
        } else if (this.slideType === 'ExitComponent' || this.slideType === 'MainNoticeComponent') {
          container.style.height = comSize + 'px';
        }
      });
    } else {
      // this.$utils.printLog("updated false" + this.slideShow);
      // document.getElementsByTagName("body")[0].style.overflowY = "";
      // document.getElementById("__nuxt").style.width = "100%";
      // document.getElementById("__nuxt").style.position = "";
      // window.scrollTo({ top: this.pageYOffset });
    }
  },
  methods: {
    async closeSlide() {
      this.$commons.printLog('closeSiled!!!!!!!!!!!!!!');
      // // 이전버튼을 컨트롤 하기위해 히스토리를 추가
      // window.history.pushState('forward', null, '');
      // history back 이벤트 핸들러 삭제
      window.removeEventListener('popstate', this.backBtnHandler);
      // 필수 약관 상세 이전 처리
      if (this.slideType === 'NewTermsComponent') {
        await this.$store.commit('slideShow', { slideShow: false });
        await this.$store.commit('slideShow', { slideShow: true, slideType: 'NewTermsSlideComponent' });
        return;
      }
      if (this.slideType === 'AppCardTermsComponent') {
        await this.$store.commit('slideShow', { slideShow: false });
        await this.$store.commit('slideShow', { slideShow: true, slideType: 'AppCardTermsSlideComponent' });
        return;
      }

      // 필수 약관 받기
      if (this.slideType === 'NewTermsSlideComponent') {
        await this.$store.commit('slideShow', { slideShow: false });
        return;
      }

      if (this.slideType === 'BasicTermsComponent') {
        await this.$store.commit('isPrivacyTerms', false);
      }

      const slideType = this.slideType;
      // document.getElementById("__nuxt").style.marginTop = "";
      // document.getElementById("__nuxt").style.marginTop = "";
      // await this.scrollable();
      await this.$store.commit('isHeader', true);
      await this.$store.commit('slideShow', false);

      // 약관 이전 처리
      if (slideType === 'TelecomBasicTermsComponent') {
        this.$store.commit('slideShow', { slideShow: true, slideType: 'TermsSlideComponent' });
      }
    },
    // scrollable() {
    //   document.documentElement.style.overflow = "auto";
    //   document.getElementsByTagName("body")[0].style.overflow = "auto";
    //   document.getElementById("__nuxt").style.marginTop = "";

    //   setTimeout(() => {
    //     window.scrollTo({ top: this.pageYOffset, left: 0, behavior: "smooth" });
    //   }, 1000);
    // },
    blockOverlay(event) {},
  },
};
</script>

<style lang="scss" scoped>
.container2 {
  display: grid;
  position: fixed;
  width: 100%;
  //   height: 80%;
  max-height: 90%;
  left: 0;
  bottom: 0;
  //   justify-items: center;
  z-index: 1001;
  background-color: #ffffff;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
}

.dim_layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #333333;
  opacity: 0.8;

  z-index: 1000;
}
.slide-leave-active {
  transition: all 0.5s;
}
.slide-enter-active {
  transition: all 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
.fade-enter-active {
  transition: all 0.5s;
}
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
