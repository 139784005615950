<template>
  <div class="popup-wrap">
    <div class="external_payment_title">결제 카드 선택</div>
    <img
      style="position: absolute; top: 25px; right: 25px; z-index: 1"
      src="@/assets/img/fitcol/btn_clse.png"
      alt=""
      @click="clickEvent('close')"
    />

    <div style="display: flex">
      <div class="external_payment_btn" style="margin-right: 24px" @click="clickAppCard('wrcard', '0020', '우리카드')">
        <img v-if="selCard && selCard != 'wrcard'" src="@/assets/img/fitcol/logo_wrcard_g.png" style="border-radius: 28px" alt="" />
        <img
          v-else
          src="@/assets/img/fitcol/logo_wrcard.png"
          style="border-radius: 28px"
          :style="selCard && selCard == 'wrcard' ? 'box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.26);' : ''"
          alt=""
        />
      </div>
      <div class="external_payment_btn" @click="clickAppCard('bccard', '2050', 'BC카드')">
        <img v-if="selCard && selCard != 'bccard'" src="@/assets/img/fitcol/logo_wrbccard_g.png" style="border-radius: 28px" alt="" />
        <img
          v-else
          src="@/assets/img/fitcol/logo_wrbccard.png"
          style="border-radius: 28px"
          :style="selCard && selCard == 'bccard' ? 'box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.26);' : ''"
          alt=""
        />
      </div>
    </div>

    <button :class="{ btn_sel: selCard }" class="btn" @click="clickEvent('pay')">확인</button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    showSlide2: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
    },
  },
  emits: ['closePopup'],
  data() {
    return {
      selCard: '',
      cardCode: '',
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    clickEvent(sort, idx, item) {
      switch (sort) {
        case 'close':
          this.$emit('closePopup', 'wooriCard', '');
          break;
        case 'pay':
          if (!this.$commons.isNull(this.selCard)) {
            if (this.cardCode === '0020') {
              this.$commons.printLog('WooriCard cardCode : ', this.cardCode);
              this.$emit('closePopup', '', '');
              window.open('https://newapp.xgolf.com:443/subscription/auth.asp?compCode=41&cardCode=CCWR');
            } else {
              this.$commons.printLog('BCCard cardCode : ', this.cardCode);
              this.$emit('closePopup', 'bcPayment');
            }
          }
          break;
        default:
          break;
      }
    },
    clickAppCard(str, cardCode, cardName) {
      if (this.selCard !== str) {
        this.selCard = str;
        this.cardCode = cardCode;
      } else {
        this.selCard = '';
        this.cardCode = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  padding: 0px 40px 0px 40px;
}
.external_payment_title {
  height: 64px;
  flex-grow: 0;
  font-family: NotoSansCJKKR;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -1.2px;
  text-align: left;
  color: #222;
  margin-top: 56px;
  margin-bottom: 35px;
}

.external_payment_btn {
  border-radius: 28px;
  border: 2px #dadcde solid;
  margin-bottom: 76px;
}

.btn {
  width: calc(100% - 80px);
  height: 106px;
  margin: 25px 0 30px 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.46;
  letter-spacing: -0.56px;
  text-align: center;
  border-radius: 10px;
  opacity: 0.4;
  color: #fff;
  background-color: #4a5cff;
}

.btn_sel {
  opacity: 1;
  color: #fff;
  background-color: #4a5cff;
}
</style>
