import FTBridgeAosService from '@/modules/ft-bridge-aos-service'; // new
import FTBridgeAAosService from '@/modules/ft-bridge-aaos-service'; // old
import FTBridgeIosService from '@/modules/ft-bridge-ios-service';
import FTBridgeWebService from '@/modules/ft-bridge-web-service';

const bridge = (function () {
  // 싱글톤
  let _sharedInstance;

  let _deviceType = 2;

  function init() {
    return {
      setup(deviceType) {
        _deviceType = deviceType;
      },
      shared() {
        let shared = null;
        switch (_deviceType) {
          case 1:
            // Vue.prototype.$bridge = FTBridgeAosService.shared(); // new
            shared = FTBridgeAAosService.shared(); // old
            break;
          case 3:
            shared = FTBridgeIosService.shared();
            break;
          default:
            shared = FTBridgeWebService.shared();
            break;
        }

        return shared;
      },
    };
  }

  return {
    sharedInstance: function () {
      if (_sharedInstance) {
        return _sharedInstance;
      }

      _sharedInstance = init();

      return _sharedInstance;
    },
  };
})().sharedInstance();

export default bridge;
