<template>
  <div style="padding: 24px 40px">
    <span class="d-flex c-center">
      <img :src="require('@/assets/img/common/ic_plus1.svg')" style="margin-right: 10px; margin-top: 3px" alt="" />
      <div class="text" @click="addCard">카드 등록</div>
    </span>
    <!--  계좌이슈 때문에 이번 배포에서 미뤄짐 230427 espark  -->
    <!--		<span class="d-flex c-center">-->
    <!--			<img :src="require('@/assets/img/common/ic_plus1.svg')" style="margin-right: 10px; margin-top: 3px" alt="" />-->
    <!--			<div class="text" @click="addAccount">계좌 등록</div>-->
    <!--		</span>-->
    <object
      v-if="isShow"
      id="appCard"
      :data="appUrl"
      type="text/html"
      style="z-index: 9998; position: fixed; bottom: 0; left: 0; width: 100%; height: 100%"
    ></object>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import urlConfig from '@/config/urlConfig';

export default {
  name: 'TvPayPopup',

  emits: ['closePopup'],

  data() {
    return {
      orderNo: '',
      isShow: false,
      tid: '',
      appUrl: '',
      nativeFocus: true,
    };
  },

  computed: {
    ...mapGetters(['getTermType', 'getUserCel', 'getDeviceType', 'getDeviceName', 'getStoreId', 'getPopupData']),
  },

  methods: {
    addCard() {
      const _vue = this;
      bus.$emit('start:loading');
      this.$utils.sequenceEx([
        this.insSessionData,
        this.getSessionData,
        function () {
          bus.$emit('end:loading');
          _vue.$store.commit('setPreviousPage', 'payCardReg');
          _vue.$router.push('/tvpay/register');
        },
      ]);
      this.$emit('closePopup');
    },
    addAccount() {
      let orderNo = '';
      const year = new Date().getFullYear();
      const month = (new Date().getMonth() + 1).toString().length == 1 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1;
      const date = new Date().getDate().toString().length == 1 ? '0' + new Date().getDate() : new Date().getDate();
      const hour = new Date().getHours().toString().length == 1 ? '0' + new Date().getHours() : new Date().getHours();
      const minutes = new Date().getMinutes().toString().length == 1 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      const second = new Date().getSeconds().toString().length == 1 ? '0' + new Date().getSeconds() : new Date().getSeconds();

      this.$utils.printLog(`간편계좌 orderNo ---> ${year}${month}${date}${hour}${minutes}${second}`);

      orderNo = `${year}${month}${date}${hour}${minutes}${second}`;
      this.orderNo = orderNo;

      this.makeTid();
      this.$emit('closePopup');
    },
    async makeTid() {
      // let params = {
      // 	storeId: 'MBTMPTH201',
      // 	amount: '0',
      // 	orderNo: '0',
      // 	goodsName: 'test',
      // 	pgType: '9',
      // 	instType: '1',
      // 	installment: '00',
      // 	cardCode: 'P088',
      // 	storeUserNo: this.$store.state.tvpoint.userNo,
      // 	userName: this.$store.state.tvpoint.userName,
      // 	payType: '1',
      // };
      let params = {
        storeId: 'MBTMPTH201',
        userName: this.$store.state.tvpoint.userName,
        storeUserNo: this.$store.state.orderPayment.tvPayUserNo
          ? this.$store.state.orderPayment.tvPayUserNo
          : await this.$bridge.stringValue('mUserNo'),
        // deviceType 1로 분기 되어있던 코드 통합 espark,
        installment: '00',
        instType: '1',
        pgType: '9',
        cardCode: 'P088',
        oneOffTidYN: 'Y',
      };
      this.$store.dispatch('tvPayMakeTid', params).then(data => {
        if (data.resultCode === '0000') {
          this.tid = data.tid;
          this.appUrl =
            urlConfig.APPCARD_MODULE_BASE_URL +
            '?tid=' +
            this.tid +
            '&storeId=MBTMPTH201&cardCode=P088&instType=1&installment=00&storeUserNo=' +
            this.$store.state.tvpoint.userNo +
            '&oneOffTidYN=Y&payType=1';
          this.isShow = true;
        } else {
          this.$utils.printLog('makeTid fail');
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.text {
  width: auto;
  height: 110px;
  font-size: 30px;
  line-height: 110px;
  letter-spacing: -1.2px;
  text-align: left;
  color: #222;
}
</style>
