import axios from './common/apiConfig';
import urlConfig from '@/config/urlConfig';

function requestPartner(params) {
  return axios.postQs(`${urlConfig.FITSCH_BASE_URL}requestPartner`, params);
}

/**
 * 순서 :
 * 페에지 목록보기 -> 컴포넌트 템플릿 페이지 목록 보기 ->
 */

//slidingPopup 으로 실행팝업 노출 api
async function executionBannerList() {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}getPopup`);
}

// 1. fit 페이지 전체 리스트 조회
async function fitPageList() {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}pages`);
}

// 2. 컴포넌트 템플릿 페이지 목록
async function componentTemplateGroupList(params) {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}pages/${params}/groups?template=true`);
}

// 2_1. 컴포넌트 템플릿 페이지 목록(핏콜 탭)
async function componentTemplateGroupListFitcol(params) {
  return await axios.get(
    params.uri === '/fit/pages/fitcall'
      ? `${urlConfig.FITSCH_BASE_URL}pages/${params.id}/groups?template=true&fitcol_code=FIT00FIT01`
      : `${urlConfig.FITSCH_BASE_URL}pages/${params.id}/groups?template=true`,
  );
}

// 3. fit 세부 디테일 가져오는 api
async function fitBasicApi(params) {
  return await axios.get(
    params.fitcolId
      ? `${urlConfig.FITSCH_BASE_URL}pages/${params.pageId}/groups/${params.groupId}/components/${params.componentId}/${params.groupType}?fitcol_code=${params.fitcolId}`
      : `${urlConfig.FITSCH_BASE_URL}pages/${params.pageId}/groups/${params.groupId}/components/${params.componentId}/${params.groupType}`,
  );
}

//dh---
async function fitGroupsApi(params) {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}pages/${params.pageId}/groups/${params.groupId}/components/`);
}

// vod 이벤트 디테일 정보
async function fitBasicApiDetail(params) {
  return await axios.get(
    `${urlConfig.FITSCH_BASE_URL}pages/${params.pageId}/groups/${params.groupId}/components/${params.componentId}/${params.groupType}/${params.detailId}`,
  );
}

//포인트 / 페이 전체 조회 (간략)
async function fitPointUse(params) {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}common/point-use?simple=true&type=${params}`);
}

//포인트 / 페이 전체 조회 (디테일)
async function fitPointUseDetail(params) {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}common/point-use?type=${params}`);
}

//이벤트 배너 디테일
async function eventDetail(params) {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}epages/${params}`);
}

//파이 혜택 모아보기 물으표에서 이동 하는 이벤트 찾기 위해
async function eventList() {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}epages`);
}

// 기획전 정보보기
async function exhibitionDetail(params) {
  return await axios.get(`${urlConfig.FITSCH_BASE_URL}sspages/${params}`);
}

export {
  executionBannerList,
  fitPageList,
  requestPartner,
  componentTemplateGroupList,
  fitBasicApi,
  fitGroupsApi,
  fitBasicApiDetail,
  fitPointUse,
  fitPointUseDetail,
  eventDetail,
  eventList,
  exhibitionDetail,
  componentTemplateGroupListFitcol,
};
