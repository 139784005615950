<template>
  <div class="content-wrapper">
    <CommonText font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2" style="margin-bottom: 10px"
      ><span class="detail-bold"><span class="content-margin">※</span>TV포인트 적립일 :</span> [배송완료] 상태가 된 후 15일 경과 시
      적립</CommonText
    >
    <CommonText font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2"
      ><span class="detail-bold"><span class="content-margin">※</span>적립 TV포인트 유효기간 :</span> 1년</CommonText
    >

    <CommonText font-size="26" font-weight="normal" font-color="#ff5b5b" spacing="38px" style="margin: 54px 0 16px 0">유의사항</CommonText>
    <div class="notice-box">
      <div class="d-flex margin4">
        <CommonText class="content-margin" font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2">※</CommonText>
        <CommonText font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2"
          >배송비는 고객 부담입니다. 배송비를 지불하지 않았을 경우에는 적립되는 TV포인트에서 차감될 수 있습니다.</CommonText
        >
      </div>
      <div class="d-flex margin4">
        <CommonText class="content-margin" font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2">※</CommonText>
        <CommonText font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2"
          >TV포인트는 상품구매 시 실제 카드로 결제한 금액 기준으로 적립됩니다.</CommonText
        >
      </div>
      <div class="d-flex">
        <CommonText class="content-margin" font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2">※</CommonText>
        <CommonText font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2"
          >적립 제외 대상 : 무형상품(여행, 렌탈, 보험, 휴대폰 가입 등) 일부상품 결제, 주문/결제취소 거래, 배송비</CommonText
        >
      </div>
      <div class="d-flex margin4">
        <CommonText class="content-margin" font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2">※</CommonText>
        <CommonText font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2"
          >TV포인트가 적립된 이후 취소 시 적립된 TV포인트는 회수됩니다.</CommonText
        >
      </div>
      <div class="d-flex margin4">
        <CommonText class="content-margin" font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2">※</CommonText>
        <CommonText font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2"
          >해당 이벤트는 카드사 또는 당사 사정에 의해 사전 별도 고지 없이 변경되거나 조기 종료될 수 있습니다.</CommonText
        >
      </div>
      <div class="d-flex">
        <CommonText class="content-margin" font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2">※</CommonText>
        <CommonText font-size="24" font-weight="normal" font-color="#8f9193" spacing="-1.2"
          >카드사에서 제공되는 무이자 할부, 청구할인 등 혜택은 동일하게 유지됩니다.</CommonText
        >
      </div>
    </div>
    <div class="btn-box d-flex c-center r-center" @click="clickEvent('hideSlide')">
      <CommonText font-size="32" font-color="#ffffff" spacing="-1.6" line-height="110">확인했어요!</CommonText>
    </div>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
export default {
  name: 'BenefitDetail',
  components: {
    CommonText,
  },

  emits: ['closePopup'],

  data() {
    return {};
  },
  computed: {},
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },
    clickEvent(sort, link) {
      switch (sort) {
        case 'hideSlide':
          this.$commons.printLog('click', sort);
          this.closePopup();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  padding-right: 40px;
  padding-left: 40px;
  width: fit-content !important;
  word-break: keep-all;
}
.detail-bold {
  font-weight: 500;
  color: #666666;
}
.content-margin {
  margin-right: 8px;
}
.notice-box {
  margin-bottom: 56px;
}
.btn-box {
  width: 100%;
  height: 100px;
  background-color: #2b2d2f;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 40px;
}
.margin4 {
  margin-bottom: 4px;
}
</style>
