<template>
  <div class="header-swiper-wrapper" :style="isShowScrollNavbar ? 'top:120px' : 'top:0'">
    <swiper class="swiper" v-bind="swiperOptions" @swiper="onSwiper">
      <swiper-slide
        v-for="(menu, idx) in !$route.fullPath.includes('/bbarshop') ? fitMenuList : bbarshopMenuList"
        :key="idx"
        style="width: fit-content"
        :style="idx < fitMenuList.length - 1 ? 'padding-right: 15px' : idx === 4 ? 'padding-right: 100px' : ''"
      >
        <input
          :id="menu.id"
          :ref="'menu' + idx"
          v-model="selectTab"
          type="radio"
          :value="idx"
          :hidden="true"
          :name="'tab' + idx"
          @click="onClickTab(idx)"
        />
        <label :for="menu.id" class="pay-store normal">
          {{ menu.title }}
        </label>
      </swiper-slide>
    </swiper>
    <BorderBasic height="2" background="#dadcde" class="border" />
  </div>
</template>

<script>
import 'swiper/css';

import bus from '@/utils/bus';
import BorderBasic from '@/components/common/border/BorderBasic';
import $store from '@/store';

export default {
  name: 'HeaderSwiper',

  components: { BorderBasic },

  props: {
    isShowScrollNavbar: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Number,
      default: 0,
    },
  },

  emits: ['changePage'],

  data() {
    return {
      swiper: null,
      selectTab: -1,
      fitMenus: [],
      bbarshopMenus: [],
      swiperOptions: {
        freeMode: true,
        slidesPerView: 'auto',
      },
    };
  },

  computed: {
    mainIdx() {
      return this.$store.state.common.mainIdx;
    },
    fitMenuList() {
      return this.fitMenus;
    },
    bbarshopMenuList() {
      return this.bbarshopMenus;
    },
  },

  watch: {
    value(newValue) {
      this.selectTab = newValue;
      // jhkim: vue3: 인터벌 없이 스와이퍼 위치가 변경되지 않음
      setTimeout(() => {
        this.$commons.setCenteredSwiper(this.swiper, newValue, 300);
      }, 10);
    },
  },

  created() {
    bus.$emit('set:init');

    this.fitMenus = $store.state.fit.fitMenus;
    this.bbarshopMenus = $store.state.bbarshop.bbarshopMenus;
  },

  mounted() {
    // jhkim: vue3:
    this.selectTab = this.$store.state.common.mainIdx;
    this.$commons.setCenteredSwiper(this.swiper, this.selectTab, 0);
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    async onClickTab(idx) {
      if (idx === this.$store.state.common.mainIdx) return;
      await this.$store.commit('setLastScrollPosition', 0);
      await this.$emit('changePage', idx);
    },

    // jhkim: vue3: service 변경 시 홈 -> 홈으로 이동하면 swiper 위치가 초기화 되지 않아 구현
    init() {
      this.selectTab = 0;
      setTimeout(() => {
        this.$commons.setCenteredSwiper(this.swiper, 0, 0);
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  /*border: 2px solid blue;*/
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.header-swiper-wrapper {
  position: fixed;
  //width: calc(100% - 40px);
  height: 70px;
  z-index: 15;
  box-sizing: border-box;
  background-color: #fff;
}
.swiper {
  padding-left: 40px;
  padding-right: 40px;
}

.dark {
  background-color: #2b2d2f;
}
.normal {
  color: #999999;
}
.pay-store {
  padding-right: 17px;
  padding-left: 17px;
  font-size: 30px;
  font-weight: normal;
  letter-spacing: -1.5px;
  line-height: 54px;
  display: inline-block;
  height: 70px;
  &.light {
    color: #f2f3f5;
  }
}

input:checked + .pay-store {
  box-sizing: border-box;
  color: #343534;
  border-bottom: 6px solid #343534;
  font-weight: 500;

  &.light {
    color: #ffffff;
    border-bottom: 6px solid #ffffff;
  }
}
</style>
