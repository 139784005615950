<template>
  <transition name="fade">
    <div
      v-if="isSaved"
      style="position: fixed; width: 100%; height: 100%; top: 0px; left: 0px; z-index: 9999; align-items: center"
      @click.prevent.stop=""
    >
      <div class="pop-wrap">
        <div class="dim-layer"></div>
        <div class="save-popup-wrap">
          <div class="save-popup">
            <img src="@/assets/img/tvpoint/img_congrats.svg" class="save-popup-img" />
            <p class="save-popup-text">{{ getVodPoint }}P이 적립되었습니다!</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['isSaved', 'getVodPoint']),
  },
  watch: {
    isSaved(isSaved) {
      if (isSaved) {
        window.addEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
        setTimeout(() => {
          this.$store.commit('isSaved', false);
        }, 1500);
      } else {
        window.removeEventListener('touchmove', this.handleTouchmove, {
          passive: false,
        });
      }
    },
  },
  methods: {
    handleTouchmove(e) {
      this.$utils.printLog('[AlertPopup] test ! ');
      e.preventDefault();
      e.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.dim-layer {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
}
.save-popup-wrap {
  // width: calc(100% - 140px);
  width: 580px;
  background-color: #ffffff;
  border-radius: 16px;
  padding-top: 46px;
  padding-bottom: 40px;
  // margin-left: 70px;
  // margin-right: 70px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  top: 40%;
  // position: fixed;
  z-index: 9999;
  // transform: translateX(40%);
}
.pop-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.save-popup-text {
  height: 42px;
  font-size: 28px;
  line-height: 52px;
  color: #343534;
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.save-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
