<template>
  <div class="popup-wrap">
    <div class="popup-title"></div>
    <div class="popup-content" v-html="content"></div>
    <div class="popup-button-wrap flex-right-align-wrap">
      <button class="popup-button-no" @click="confirm">확인</button>
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus';
import { fitMixin } from '@/utils/fitMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'TagsConfirmPopup',
  mixins: [fitMixin],
  props: {
    title: {
      type: String,
      default: '알림',
      require: false,
    },
    content: {
      type: String,
      require: true,
      default: '',
    },
  },

  computed: {
    ...mapGetters(['getAlertPopup']),
  },

  methods: {
    confirm() {
      this.$store.commit('alertPopup', {});
    },
  },
};
</script>

<style scoped>
.popup-button-no {
  font-size: 30px;
  letter-spacing: -1.12px;
  text-align: right;
  /* color: #444648; */
  color: #648cff;
  background-color: #ffffff;
  font-weight: bold;
}
.popup-button-wrap {
  margin-top: 56px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px 40px 42px 40px;
}
</style>
