<template>
  <div class="popup-wrap">
    <!--		<div class="popup-title">자동전환 신청 해지</div>-->
    <div class="popup-content" v-html="getAlertPopup.popupContent"></div>
    <div class="popup-button-wrap">
      <button class="popup-button-no" @click="cancel">취소</button>
      <button class="popup-button-yes" @click="confirm">해지하기</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['getAlertPopup']),
  },
  methods: {
    cancel() {
      this.$store.commit('alertPopup', {});
    },
    confirm() {
      this.$store.commit('alertPopup', { returnValue: true });
      this.$store.commit('setAutoTransDetail', {}); //값 초기화
    },
  },
};
</script>

<style scoped>
.popup-button-yes {
  margin-left: 44px;
  font-size: 30px;
  letter-spacing: -1.5px;
  text-align: right;
  color: #648cff;
  font-weight: 500;
  background-color: #ffffff;
}

.popup-button-no {
  font-size: 30px;
  letter-spacing: -1.5px;
  text-align: right;
  color: #343534;
  background-color: #ffffff;
}
.popup-button-wrap {
  margin-top: 40px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}

.popup-content {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: -1.4px;
  text-align: left;
  color: #343534;
}

.popup-title {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.24;
  letter-spacing: -1.7px;
  text-align: left;
  color: #343534;
}
.popup-wrap {
  padding: 40px 40px 43px 40px;
}
</style>
