import axios from './common/apiConfig';
import urlConfig from '@/config/urlConfig';
import $store from '@/store';

function getSession(jsession) {
  return ';jsessionid=' + jsession;
}

function mobileCardList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/getMobileAccount.tvp${getSession($store.getters.getSessionId)}`, params);
}

function tvCardList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/getTVAccount.tvp${getSession($store.getters.getSessionId)}`, params);
}

function payHistory(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/paymentHistory.tvp${getSession($store.getters.getSessionId)}`, params);
}

function payDetailHistory(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/paymentDetailHistory.tvp${getSession($store.getters.getSessionId)}`, params);
}

function removePaymentHistory(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/removePayHistory.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 앱카드 결제서버 거래번호(TID) 발급 요청
function tvPayMakeTid(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/makeTid.tvp${getSession($store.getters.getSessionId)}`, params);
}

function tvPayRemoveAccount(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/removeAccount.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 통합RP 거래번호(TID) 발급 요청
function prepareTid(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/tvpay/tvpayMakeTid.tvp${getSession($store.getters.getSessionId)}`, params);
}

// 모바일 간편결제 승인
function tvpaySmartConfirm(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/tvpaySmartConfirm.tvp${getSession($store.getters.getSessionId)}`, params);
}

//정기 결제 이용권 목록 조회
function getAutoPaymentList(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/getAutoPaymentList.tvp${getSession($store.getters.getSessionId)}`, params);
}

//핏콜 스포티비 이용권 취소
function cancelSpotvService(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/cancelSpotvService.tvp${getSession($store.getters.getSessionId)}`, params);
}

//멜론 이용권 가입가능 여부
function getMelonJoin(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/getMelonJoin.tvp${getSession($store.getters.getSessionId)}`, params);
}

//앱카드 인증 결과 조회
function tvpayReqAuthData(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/reqAuthData.tvp${getSession($store.getters.getSessionId)}`, params);
}

//멜론 이용권 가입
function setMelonJoin(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/setMelonJoin.tvp${getSession($store.getters.getSessionId)}`, params);
}

//멜론 이용권 중도 해지 가능 여부 조회
function getCancelMelonYN(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/getCancelMelonYN.tvp${getSession($store.getters.getSessionId)}`, params);
}

//멜론 이용권 중도해지요청
function setCancelMelon(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/setCancelMelon.tvp${getSession($store.getters.getSessionId)}`, params);
}

//멜론 이용권 정기결제 해지요청
function setEndMelon(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/setEndMelon.tvp${getSession($store.getters.getSessionId)}`, params);
}

//멜론 이용권 정기결제 해지취소요청
function setEndCancelMelon(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/setEndCancelMelon.tvp${getSession($store.getters.getSessionId)}`, params);
}

//멜론 이용권 가격 조회
function getMelonPrice(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/fit/getMelonPrice.tvp${getSession($store.getters.getSessionId)}`, params);
}

//인증 결과 조회
function authPass(params) {
  return axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/authPass.tvp`, params);
}

//쇼골프 상품 조회
function getXGolfPrice(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/fit/getXGolfPrice.tvp`, {
    ...params,
    loginCheck: 'false',
  });
}

//쇼골프 정기 구독 이용권 요청
function setXGolfJoin(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/fit/setXGolfJoin.tvp`, {
    ...params,
    loginCheck: 'false',
  });
}

//쇼골프 정기 구독 대상자 조회
function getXGolfUser(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/fit/getXGolfUser.tvp`, {
    ...params,
    loginCheck: 'false',
  });
}

//쇼골프 롯데카드 신규 발급 카드 프로모션 여부 조회
function getXGolfPromotionYN(params) {
  return axios.postQs(`${urlConfig.FIT_BASE_URL}/appcard/getPromotionYN.tvp`, {
    ...params,
    loginCheck: 'false',
  });
}

export {
  mobileCardList,
  tvCardList,
  payHistory,
  payDetailHistory,
  getSession,
  removePaymentHistory,
  tvPayMakeTid,
  tvPayRemoveAccount,
  prepareTid,
  tvpaySmartConfirm,
  getAutoPaymentList,
  cancelSpotvService,
  getMelonJoin,
  tvpayReqAuthData,
  setMelonJoin,
  getCancelMelonYN,
  setCancelMelon,
  setEndMelon,
  setEndCancelMelon,
  getMelonPrice,
  authPass,
  getXGolfPrice,
  setXGolfJoin,
  getXGolfUser,
  getXGolfPromotionYN,
};
