// jhkim: 추가

const FTBridgeAAosService = (function () {
  // 싱글톤
  let _shared;

  function init() {
    function _createGetter(getter) {
      const promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve(getter());
        }, 1);
      });

      return promise;
    }

    function _createSetter(command, payload) {
      const promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
          const params = command.split(':');
          window.Android.setStringPreference(params[1], payload);
          let result = {
            result: 'success',
          };
          resolve(result);
        }, 1);
      });

      return promise;
    }

    function _call(command, payload = null) {
      const promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
          const params = command.split(':');
          switch (params[1]) {
            case 'splashOff':
              window.Android.splashOff();
              break;

            case 'permission':
              window.Android.checkPermission(true, payload);
              break;

            case 'appExit':
              window.Android.appExit();
              break;

            default:
              break;
          }

          let result = {
            result: 'success',
          };
          resolve(result);
        }, 1);
      });

      return promise;
    }

    return {
      deviceId() {
        return _createGetter(() => {
          return window.Android.getUniqueID();
        });
      },

      versionName() {
        return _createGetter(() => {
          return window.Android.checkCurrentVersion();
        });
      },

      pushToken() {
        return _createGetter(() => {
          return window.Android.getToken();
        });
      },

      isFirstLoad() {
        return _createGetter(() => {
          return false;
        });
      },

      userId() {
        return _createGetter(() => {
          return window.Android.getStringPreference('userId');
        });
      },
      setUserId(value) {
        _createSetter('set:userId', value).then(result => {});
      },

      isAutoLogin() {
        return _createGetter(() => {
          return window.Android.getStringPreference('autoLoginYn');
        });
      },
      setAutoLogin(value) {
        _createSetter('set:autoLoginYn', value).then(result => {});
      },

      preferences() {
        const promise = new Promise(function (resolve, reject) {
          setTimeout(async function () {
            const result = {
              deviceId: await _shared.deviceId(),
              versionName: await _shared.versionName(),
              pushToken: await _shared.pushToken(),
              firstLoad: await _shared.isFirstLoad(),
              userId: await _shared.userId(),
              autoLogin: await _shared.isAutoLogin(),
            };
            resolve(result);
          }, 1);
        });

        return promise;
      },

      boolValue(key) {
        return window.Android.getStringPreference(key) == 'Y' ||
          window.Android.getStringPreference(key) == 'y' ||
          window.Android.getStringPreference(key) == 'True' ||
          window.Android.getStringPreference(key) == 'true'
          ? true
          : false;
      },
      setBoolValue(key, value) {
        _createSetter('set:' + key, value == true ? 'Y' : 'N').then(result => {});
      },

      stringValue(key) {
        return _createGetter(() => {
          if (key === 'extractPhoneNumber') {
            // aos 뉴버전에는 'get:' + key + ':string' 값으로 들어가야함
            return window.Android.extractPhoneNumber();
          } else if (key === 'requestToken') {
            return window.Android.requestToken();
          } else if (key === 'permission') {
            window.Android.returnPermission();
          } else {
            return window.Android.getStringPreference(key);
          }
        });
      },
      setStringValue(key, value) {
        _createSetter('set:' + key, value).then(result => {});
      },

      // ISSUE: 네이티브 구현 전까지 사용하지 말 것
      objectValue(key) {
        return _createGetter('get:' + key + ':object');
      },
      setObjectValue(key, value) {
        _createSetter('set:' + key + ':object', value).then(result => {});
      },

      call(method, param = null) {
        _call('call:' + method, param).then(result => {});
      },

      exec(callback) {
        callback();
      },

      splashOff() {
        _call('call:splashOff').then(result => {});
      },

      clear() {
        _shared.setStringValue('userPhone', '');
        _shared.setStringValue('userId', '');
        _shared.setStringValue('session', '');
        _shared.setStringValue('reqDate', '');
        _shared.setStringValue('access_token', '');
        _shared.setStringValue('renewal_token', '');
        _shared.setStringValue('autoLoginYn', 'N');
        _shared.setStringValue('mUserNo', ''); // mjkim 230615
      },

      console(content) {
        _createSetter('call:console', { log: content }).then(result => {});
      },
    };
  }

  return {
    shared: function () {
      if (_shared) {
        return _shared;
      }

      _shared = init();

      return _shared;
    },
  };
})();

export default FTBridgeAAosService;
