// jhkim: 추가
// Object 유틸리티

const FTObject = (function () {
  // 싱글톤
  let _sharedInstance;

  function init() {
    return {
      isNil: (...args) => {
        let result = true;

        for (let x of args) {
          result = x != null && x != undefined ? false : true;
          if (result == false) {
            break;
          }
        }

        return result;
      },

      isNotNil: (...args) => {
        let result = false;

        for (let x of args) {
          result = x != null && x != undefined ? true : false;
          if (result) {
            break;
          }
        }

        return result;
      },
    };
  }

  return {
    sharedInstance: function () {
      if (_sharedInstance) {
        return _sharedInstance;
      }

      _sharedInstance = init();
      return _sharedInstance;
    },
  };
})().sharedInstance();

export default FTObject;
