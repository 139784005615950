import mitt from 'mitt';

const eventBus = mitt();

// ISSUE: 기존 소스대로 매번 생성해야 하는 것인지 확인 필요
export default {
  $on: (...args) => eventBus.on(...args),
  $off: (...args) => eventBus.off(...args),
  $emit: (...args) => eventBus.emit(...args),
};
