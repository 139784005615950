<template>
  <div class="footerBtn">
    <div
      v-for="(item, idx) in dataList"
      :key="idx"
      class="d-flex grid c-center r-center"
      @click="clickEvent(item.route ? item.route : '', idx)"
    >
      <img :src="item.imgUrl" alt="" class="btn_img" />
      <CommonText
        :font-color="idx === selectedIndex ? '#2b2d2f' : '#8f9193'"
        font-size="24"
        font-weight="500"
        spacing="-0.96"
        class="btn_title"
        >{{ item.title }}</CommonText
      >
    </div>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
import $store from '@/store';
import urlConfig from '@/config/urlConfig';
// jhkim: vue3:
import { Service, Key } from '@/modules/ft-constants';

export default {
  name: 'FooterBtn',
  components: { CommonText },

  // jhkim: vue3:
  emits: ['changeService'],

  data() {
    return {
      dataList: [
        {
          imgUrl: require('@/assets/img/common/btn_homeshop_off.svg'),
          title: '홈숍',
        },
        {
          imgUrl: require('@/assets/img/common/btn_tab_fit_on.svg'),
          title: '핏콜라보',
          route: '/initPage',
        },
        {
          imgUrl: require('@/assets/img/common/btn_bbarshop_off.svg'),
          title: '빠숍',
          route: '/bbarshop',
        },
      ],
      selectedIndex: -1,
    };
  },

  watch: {
    $route(to, from) {
      if (to) {
        if (to.fullPath.includes('/bbarshop')) {
          this.dataList = [
            {
              imgUrl: require('@/assets/img/common/btn_homeshop_off.svg'),
              title: '홈숍',
            },
            {
              imgUrl: require('@/assets/img/common/btn_tab_fit_off.svg'),
              title: '핏콜라보',
              route: '/initPage',
            },
            {
              imgUrl: require('@/assets/img/common/btn_bbarshop_on.svg'),
              title: '빠숍',
              route: '/bbarshop',
            },
          ];
          this.selectedIndex = 2;
        } else if (to.fullPath.includes('/initPage')) {
          this.dataList = [
            {
              imgUrl: require('@/assets/img/common/btn_homeshop_off.svg'),
              title: '홈숍',
            },
            {
              imgUrl: require('@/assets/img/common/btn_tab_fit_on.svg'),
              title: '핏콜라보',
              route: '/initPage',
            },
            {
              imgUrl: require('@/assets/img/common/btn_bbarshop_off.svg'),
              title: '빠숍',
              route: '/bbarshop',
            },
          ];
          this.selectedIndex = 1;
        }
      }
    },
  },

  created() {
    if (this.$route.fullPath === '/bbarshop') {
      this.dataList = [
        {
          imgUrl: require('@/assets/img/common/btn_homeshop_off.svg'),
          title: '홈숍',
        },
        {
          imgUrl: require('@/assets/img/common/btn_tab_fit_off.svg'),
          title: '핏콜라보',
          route: '/initPage',
        },
        {
          imgUrl: require('@/assets/img/common/btn_bbarshop_on.svg'),
          title: '빠숍',
          route: '/bbarshop',
        },
      ];
      this.selectedIndex = 2;
    } else if (this.$route.fullPath === '/initPage') {
      this.dataList = [
        {
          imgUrl: require('@/assets/img/common/btn_homeshop_off.svg'),
          title: '홈숍',
        },
        {
          imgUrl: require('@/assets/img/common/btn_tab_fit_on.svg'),
          title: '핏콜라보',
          route: '/initPage',
        },
        {
          imgUrl: require('@/assets/img/common/btn_bbarshop_off.svg'),
          title: '빠숍',
          route: '/bbarshop',
        },
      ];
      this.selectedIndex = 1;
    }
  },
  methods: {
    async clickEvent(route, idx) {
      if (route) {
        // jhkim: vue3:
        let serviceType = Service.FIT;

        if (route === '/bbarshop') {
          serviceType = Service.BSHOP;
          await $store.commit('setLastScrollPosition', 0);
          window.scrollTo(0, 0);
          this.dataList = [
            {
              imgUrl: require('@/assets/img/common/btn_homeshop_off.svg'),
              title: '홈숍',
            },
            {
              imgUrl: require('@/assets/img/common/btn_tab_fit_off.svg'),
              title: '핏콜라보',
              route: '/initPage',
            },
            {
              imgUrl: require('@/assets/img/common/btn_bbarshop_on.svg'),
              title: '빠숍',
              route: '/bbarshop',
            },
          ];
          this.selectedIndex = idx;
        } else if (route === '/initPage') {
          this.dataList = [
            {
              imgUrl: require('@/assets/img/common/btn_homeshop_off.svg'),
              title: '홈숍',
            },
            {
              imgUrl: require('@/assets/img/common/btn_tab_fit_on.svg'),
              title: '핏콜라보',
              route: '/initPage',
            },
            {
              imgUrl: require('@/assets/img/common/btn_bbarshop_off.svg'),
              title: '빠숍',
              route: '/bbarshop',
            },
          ];
          this.selectedIndex = idx;
        }
        if (!this.$route.fullPath.includes(route)) {
          await $store.commit('setMainIdx', 0);
          await this.$router.push(route);
          // jhkim: vue3:
          this.$emit('changeService', serviceType);
        }
      } else {
        // window.open('http://www.homebbar.com/hband');
        if (this.$store.state.tvpoint.isLogin) {
          window.open(`${urlConfig.HOME_BBAR_URL}` + '?fit_token=' + `${encodeURIComponent(this.$store.state.tvpoint.loginToken)}`);
        } else {
          window.open(`${urlConfig.HOME_BBAR_URL}`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footerBtn {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: fixed;
  background: #ffffff;
  width: 100%;
  padding-bottom: 28px;
  padding-top: 24px;
  bottom: 0;
  box-sizing: border-box;
  z-index: 15;
  box-shadow: 0 -2px 6px 0 rgba(68, 68, 68, 0.08);
  margin-bottom: -1px;
}
.btn_img {
  height: 48px;
  width: 48px;
}
</style>
