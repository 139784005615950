<template>
  <section ref="arrangeList4Wrapper" class="category common-padding" :style="{ rowGap: gridGap }">
    <ArrangeList4 v-for="(item, index) in dataList" :key="index" class="d-flex grid c-center sale" :style="{ gap: flexGap }">
      <template #first>
        <img :src="item.imgUrl" alt="" :style="imgSize" @click="clickEvent(index)" />
      </template>
      <template #second>
        <CommonText font-size="24" font-weight="500" spacing="-0.96">
          <span v-if="item.title == '최저가 도전'" class="title-text">HOT</span>{{ item.title }}</CommonText
        >
      </template>
    </ArrangeList4>
  </section>
</template>

<script>
import ArrangeList4 from '@/components/common/listType/ArrangeList4';
import CommonText from '@/components/common/text/CommonText';

/**
 * 카테고리 리스트 형 컴포넌트
 * @see @/assets/explain/ArrangeList4Wrapper.png
 * @param imgUrl : 아이콘이미지 아이콘 이미지
 * @param title : 아이콘제목 아이콘 제목
 * @param imgSize : size 부모에서 이미지 영역 사이즈 내려주기
 * @param gridGap : rowGap 부모에서 gap 사이즈 내려주기
 * @param flexGap : gap 부모에서 gap 사이즈 내려주기
 *
 */
export default {
  components: { CommonText, ArrangeList4 },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    imgSize: {
      type: Object,
      default: () => {},
    },
    gridGap: {
      type: String,
      default: '',
    },
    flexGap: {
      type: String,
      default: '',
    },
    dataType: {
      type: String,
      default: '',
    },
  },
  emits: ['clickEvent'],

  mounted() {
    //props로 내려오는 데이터가 없을경우에 display:none 처리
    if (Object.keys(this.dataList).length === 0) {
      this.$refs.arrangeList4Wrapper.style.display = 'none';
    }
  },
  methods: {
    clickEvent(idx) {
      this.$emit('clickEvent', 'showDetail', idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.title-text {
  position: absolute;
  right: 15px;
  top: -15px;
  font-size: 20px;
  width: 64px;
  height: 30px;
  border-radius: 40px;
  color: #ffffff;
  background-color: #e42028;
}
.sale {
  position: relative;
}
</style>
