<template>
  <div class="newTerms-wrap common-padding">
    <div class="newTerms-title">
      <div class="newTerms-title-content">{{ termsTitle }}</div>
      <!--      <div class="newTerms-title-content">{{ this.getSlideContent.termsTitle }}</div>-->
    </div>

    <div v-if="$store.state.tvpoint.isPrivacyTerms" class="revision-wrap" style="margin-bottom: 40px">
      <div class="fixed-wrap">
        <input id="acntRdo" v-model="showList" type="checkbox" style="display: none" />
        <label for="acntRdo" class="radio-label d-flex c-center r-space-between">
          <div class="lefter">
            <CommonText font-size="28" font-color="#76787a" font-weight="" line-height="72" style="margin-left: 24px"
              >개정 이력 별 개인정보처리방침</CommonText
            >
          </div>
          <img :src="require('@/assets/img/fit/img_arr_low2.svg')" style="margin-right: 24px" alt="" />
        </label>
      </div>

      <div v-if="showList" class="show-wrap" style="max-height: 234px">
        <div ref="showPart" class="scroll-wrap">
          <div
            v-for="(revision, i) in $store.state.common.privacyRevisionList"
            :key="i"
            class="revisionList-wrap"
            @click="clickEvent(revision)"
          >
            <CommonText font-size="28" font-color="#76787a" font-weight="" style="margin-left: 24px">{{
              revision.parseRevisionDate
            }}</CommonText>
          </div>
        </div>
      </div>
    </div>

    <div class="newterms-container">
      <div class="term-text" v-html="termsContent"></div>
      <!--      <div class="term-text" v-html="this.termsContent"></div>-->
    </div>
  </div>
</template>

<script>
import CommonText from '@/components/common/text/CommonText';
import { mapGetters } from 'vuex';
export default {
  components: { CommonText },
  data() {
    return {
      // termsContent: '',
      showList: false,
    };
  },
  computed: {
    ...mapGetters(['getSlideContent']),
    termsTitle() {
      return this.$store.state.tvpoint.slideContent.termsTitle;
    },
    termsContent: {
      get() {
        return this.$store.state.tvpoint.slideContent.termsContent.replace(/\n/g, '<br>');
      },
      set() {},
    },
  },
  watch: {
    showCard(val) {
      if (val) {
        setTimeout(() => {
          const height =
            document.getElementsByClassName('revisionList-wrap')[0].offsetHeight * this.$store.state.common.privacyRevisionList.length;
          this.$refs.showPart.style.height = height + 'px';
        }, 100);
      }
    },
  },
  created() {
    this.termsContent = this.getSlideContent.termsContent.replace(/\n/g, '<br>');
  },
  methods: {
    clickEvent(sort) {
      this.$utils.printLog('sort', sort);

      const params = {
        platformId: 'IPTV-ALL',
        methodId: 'SMART_PAY',
        userType: 'mobile_p_policy',
        revisionDate: sort.revisionDate,
      };

      const data = this.$store.dispatch('getTermsOfUse', params);

      data.then(data => {
        if (parseInt(data.resultCode) === 0) {
          const termsTitle = data.termsTitle;
          const termsContent = data.contents;
          this.$store.commit('slideContent', { termsTitle, termsContent });
          this.termsContent = data.contents;
          this.showList = !this.showList;
        } else {
          this.$utils.errorMessage(this, data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  background: url('../../../../assets/img/tvpoint/ic_back.svg') no-repeat;
  width: 38px;
  height: 38px;
  line-height: 38px;
  box-sizing: border-box;
  border: 0;
  display: inline-block;
  vertical-align: middle;
}
.newTerms-wrap {
  height: inherit;
  margin-bottom: 20px;
}
.newTerms-title {
  height: 128px;
  //margin-bottom: 38px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.newTerms-title-content {
  height: 48px;
  line-height: 48px;
  font-size: 34px;
  letter-spacing: -1.7px;
  color: #343534;
  font-weight: bold;
}
.newTerms-content {
  height: 48px;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -1.4px;
  color: #666666;
}
.newterms-container {
  width: 100%;
  height: calc(100% - 178px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.term {
  margin-bottom: 45px;
}
.term_img {
  display: inline-block;
  vertical-align: middle;
}
.term-text {
  height: 100%;
  width: 100%;
  overflow: scroll;
  font-size: 26px;
  letter-spacing: -1.3px;
  color: #343534;
  vertical-align: middle;
  box-sizing: border-box;
  padding-bottom: 60px;
}
.terms-agree-btn {
  background-color: #e42028;
  height: 100px;
  width: 100%;
  font-size: 32px;
  line-height: 100px;
  color: #ffffff;
  text-align: center;
  letter-spacing: -1.6px;
  border-radius: 10px;
  margin-bottom: 40px;
}
.revision-wrap {
  border: solid 1px #a8aaac;
  border-radius: 8px;
}
.fixed-wrap {
  border: solid 1px #a8aaac;
  height: 72px;
  border-radius: 8px;
}
.revisionList-wrap {
  height: 44px;
  vertical-align: middle;
  margin-top: 10px;
  margin-bottom: 10px;
}
.show-wrap {
  overflow-y: auto;
  margin-top: 10px;
}
</style>
