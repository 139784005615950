import $store from '@/store';
import $commons from '@/utils/commons';
import $bridge from '@/utils/FTBridgeShared';

const common = [
  {
    path: '/user/login',
    name: 'login',
    component: () => import('@/views/user/login'),
    async beforeEnter(to, from, next) {
      //dh !== 으로 바꾸면 조건문에 안걸림
      if (from.fullPath === '/user/celLogin' || from.fullPath === '/user/SignUpPage') {
        $commons.printLog('저장되면 안됨 :>>>>> ', $store.state.common.fromPath);
      } else {
        $store.commit('setFromPath', from.path);
        $commons.printLog('setFromPath :>>>>>>>>>> ', $store.state.common.fromPath);
      }

      next();
    },
  },
  {
    path: '/user/deleteAccount',
    name: 'deleteAccount',
    component: () => import('@/views/user/deleteAccount'),
  },
  {
    path: '/user/celLogin',
    name: 'celLogin',
    component: () => import('@/views/user/celLogin'),
  },
  {
    path: '/user/SignUpPage',
    name: 'SignUpPage',
    component: () => import('@/views/user/SignUpPage'),
  },
  {
    path: '/user/UserAuthPage',
    name: 'UserAuthPage',
    component: () => import('@/views/user/UserAuthPage'),
  },
  {
    path: '/user/SelectAuthPage',
    name: 'SelectAuthPage',
    component: () => import('@/views/user/SelectAuthPage'),
  },
  {
    path: '/user/UserResetPwd',
    name: 'UserResetPwd',
    component: () => import('@/views/user/UserResetPwd'),
  },
  {
    path: '/user/ChangePhonePage',
    name: 'ChangePhonePage',
    component: () => import('@/views/user/ChangePhonePage'),
  },
  {
    path: '/user/SetPwdPage',
    name: 'SetPwdPage',
    component: () => import('@/views/user/SetPwdPage'),
  },
  {
    path: '/user/changeUserPwd',
    name: 'changeUserPwd',
    component: () => import('@/views/user/changeUserPwd'),
  },
  {
    path: '/user/settings',
    name: 'userSettings',
    component: () => import('@/views/user/settings'),
  },
  {
    path: '/user/changeInformation',
    name: 'userChangeInformation',
    component: () => import('@/views/user/changeInformation'),
    async beforeEnter(to, from, next) {
      let tvPayUserNo = $store.state.orderPayment.tvPayUserNo
        ? $store.state.orderPayment.tvPayUserNo
        : await $bridge.shared().stringValue('mUserNo');
      // deviceType 1로 분기 되어있던 코드 통합 espark
      const params = {
        tvpayUserNo: tvPayUserNo,
        reqCount: 0,
        reqPage: 1,
      };
      const data = await $store.dispatch('fitGetAddr', params);
      $store.commit('setDeliveryAddr', data);
      next();
    },
  },
  {
    path: '/user/userPhone',
    name: 'userPhoneChange',
    component: () => import('@/views/user/UserPhoneChange'),
  },
  {
    path: '/user/GiftAuthPage',
    name: 'userGiftAuthPage',
    component: () => import('@/views/user/GiftAuthPage'),
  },
  {
    path: '/user/SelectAuthPage',
    name: 'userSelectAuthPage',
    component: () => import('@/views/user/SelectAuthPage'),
  },
  {
    path: '/user/faq',
    name: 'faq',
    component: () => import('@/views/user/customerService/faq'),
  },
  {
    path: '/user/oneOnOneInquiry',
    name: 'oneOnOneInquiry',
    component: () => import('@/views/user/customerService/oneOnOneInquiry'),
  },
  {
    path: '/user/notice',
    name: 'notice',
    component: () => import('@/views/user/customerService/notice'),
  },
  {
    path: '/user/askOpenStore',
    name: 'askOpenStore',
    component: () => import('@/views/initPage/nav/askingOpenStore'),
    beforeEnter(to, from, next) {
      if ($store.state.tvpoint.isLogin === false) {
        $store.commit('alertPopup', { popupType: 'LoginPopup' });
        next(false);
      } else {
        next();
      }
    },
  },
  {
    path: '/user/alarmSetting',
    name: 'alarmSetting',
    component: () => import('@/views/notifications/notificationSettings'),
  },
  {
    path: '/user/transferSetting',
    name: 'transferSetting',
    component: () => import('@/views/user/transferSetting'),
  },
];

export default common;
