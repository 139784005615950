// jhkim: 추가
// 비동기 처리 로직
class FTAsyncExecutor {
  constructor() {
    this.promiseList = [];
  }

  add(...args) {
    const method = args.pop();
    const promise = new Promise((resolve, reject) => {
      const result = method(args);
      resolve(result);
    });

    this.promiseList.push(promise);
  }

  push(item) {
    this.promiseList.push(item);
  }

  exec() {
    return Promise.all(this.promiseList);
  }

  clear() {
    this.promiseList = [];
  }

  getPromiseList() {
    return this.promiseList;
  }
}

export default FTAsyncExecutor;
