// jhkim: 추가
// 메모리로만 데이터 관리

const FTCache = (function () {
  // 싱글톤
  let _sharedInstance;

  function init() {
    return new Map();
  }

  return {
    sharedInstance: function () {
      if (_sharedInstance) {
        return _sharedInstance;
      }

      _sharedInstance = init();
      return _sharedInstance;
    },
  };
})().sharedInstance();

export default FTCache;
