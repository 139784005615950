import { createApp } from 'vue';
import App from './App.vue';
import store from '@/store';
import router from '@/router';
import axios from '@/apis/common/axiosInterceptors';

import FTSettings from '@/config/ft-settings';
import FTLogger from '@/modules/ft-logger';
import FTBridgeShared from '@/utils/FTBridgeShared';

import VueDirectiveMask from 'vue-the-mask';
import { Calendar, DatePicker } from 'v-calendar';
import { Swiper, SwiperSlide } from 'swiper/vue';

import commons from '@/utils/commons';
import tvPointcommon from '@/utils/tvPointcommon';
import tvpointMixin from '@/utils/tvpointMixin';
import filters from '@/utils/filters';

// log level 설정
FTLogger.level = FTSettings.logger.level;
// axios.defaults.timeout = FTSettings.deployMode !== 'PRD' ? 30000 : 10000;
axios.defaults.timeout = 45000;

var deviceType = 2; // 1: aos, 2: web, 3: ios

if (window.Android) {
  console.log('os: android');
  deviceType = 1;
} else if (window.webkit) {
  if (window.webkit.messageHandlers) {
    if (window.webkit.messageHandlers.bridge) {
      console.log('os: ios');
      deviceType = 3;
    }
  }
}
// bridge setup
store.commit('setDeviceType', deviceType);
FTBridgeShared.setup(deviceType);

// 앱 생성, 스와이퍼 전역 컴포넌트 등록
const app = createApp(App);

app.component('Swiper', Swiper).component('SwiperSlide', SwiperSlide);
app.component('Calendar', Calendar);
app.component('DatePicker', DatePicker);

app.mixin(tvpointMixin);

// 전역 변수 설정
app.config.globalProperties.$logger = FTLogger;
app.config.globalProperties.$deviceType = deviceType;
app.config.globalProperties.$bridge = FTBridgeShared.shared();
app.config.globalProperties.$commons = commons;
app.config.globalProperties.$utils = tvPointcommon;
app.config.globalProperties.$filters = filters;

app.use(router).use(store).use(VueDirectiveMask).mount('#app');
