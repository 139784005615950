import pai from '@/store/pai';

const mutations = {
  paiChannelList(state, payload) {
    state.paiChannelList = payload;
  },
  channelDetailInfo(state, payload) {
    state.channelDetailInfo = payload;
  },
  channelMenus(state, payload) {
    state.channelMenus = payload;
  },
  storeRoomInfo(state, payload) {
    state.storeRoomInfo = payload;
  },
  subChannelList(state, payload) {
    state.subChannelList = payload;
  },
  subKeyList(state, payload) {
    state.subKeyList = payload;
  },
  channelUseList(state, payload) {
    state.channelUseList = payload;
  },
  fetchHiddenAsset(state, payload) {
    state.fetchHiddenAsset = payload;
  },
  nonMemberHiddenAsset(state, payload) {
    state.nonMemberHiddenAsset = payload;
  },
  benefitList(state, payload) {
    state.benefitList = payload;
  },
  benefitOpenTargetCondition(state, payload) {
    state.benefitOpenTargetCondition = payload;
  },
  filterList(state, payload) {
    state.filterList = payload;
  },
  hiddenChannelList(state, payload) {
    state.hiddenChannelList = payload;
  },
  contentsCountList(state, payload) {
    state.contentsCountList = payload;
  },
  storedBenefitList(state, payload) {
    state.storedBenefitList = payload;
  },
  subChannelState(state, payload) {
    state.subChannelState = payload;
  },
  notificationList(state, payload) {
    state.notificationList = payload;
  },
  channelPostSetting(state, payload) {
    state.channelPostSetting = payload;
  },
  pushAlarmList(state, payload) {
    state.pushAlarmList = payload;
  },
  setChannelTop(state, payload) {
    state.channelTop = payload;
  },
  saveAssociatePoint(state, payload) {
    state.saveAssociatePoint = payload;
  },
  channelAccount(state, payload) {
    state.channelAccount = payload;
  },
  alarmCount(state, payload) {
    state.alarmCount = payload;
  },
  newCount(state, payload) {
    state.newCount = payload;
  },
  hiddenAssetTopLength(state, payload) {
    state.hiddenAssetTopLength = payload;
  },
  codeCategoryMain(state, payload) {
    state.codeCategoryMain = payload;
  },
  codeContentInfo(state, payload) {
    state.codeContentInfo = payload;
  },
  resetPaiState(state) {
    Object.assign(state, pai.defaultState);
  },
};

export default mutations;
