// jhkim: 추가
import Logger from '@/modules/ft-logger';

const FTBridgeWebService = (function () {
  // 싱글톤
  let _shared;

  function init() {
    function _createGetter(command) {
      const promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
          const params = command.split(':');
          const result = localStorage.getItem(params[1]);
          resolve(result);
        }, 1);
      });

      return promise;
    }

    function _createSetter(command, payload) {
      const promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
          const params = command.split(':');
          localStorage.setItem(params[1], payload);
          let result = {
            result: 'success',
          };
          resolve(result);
        }, 1);
      });

      return promise;
    }

    function _call(command, param = null) {
      const promise = new Promise(function (resolve, reject) {
        setTimeout(function () {
          const params = command.split(':');
          switch (params[1]) {
            case 'console':
              Logger.debug('console: ' + param);
              break;

            default:
              break;
          }

          let result = {
            result: 'success',
          };
          resolve(result);
        }, 1);
      });

      return promise;
    }

    return {
      deviceId() {
        return _createGetter('get:deviceId');
      },

      versionName() {
        return _createGetter('get:versionName');
      },

      pushToken() {
        return _createGetter('get:pushToken');
      },

      isFirstLoad() {
        return _createGetter('is:firstLoad:bool');
      },

      userId() {
        return _createGetter('get:userId');
      },
      setUserId(value) {
        _createSetter('set:userId', value).then(result => {});
      },

      isAutoLogin() {
        return _createGetter('is:autoLogin');
      },
      setAutoLogin(value) {
        _createSetter('set:autoLogin', value).then(result => {});
      },

      preferences() {
        const promise = new Promise(function (resolve, reject) {
          setTimeout(function () {
            const result = {
              deviceId: localStorage.getItem('deviceId'),
              versionName: localStorage.getItem('versionName'),
              pushToken: localStorage.getItem('pushToken'),
              firstLoad: localStorage.getItem('firstLoad'),
              userId: localStorage.getItem('userId'),
              autoLogin: localStorage.getItem('autoLogin'),
            };
            resolve(result);
          }, 1);
        });
        return promise;
      },

      boolValue(key) {
        return _createGetter('get:' + key + ':bool');
      },

      setBoolValue(key, value) {
        _createSetter('set:' + key + ':bool', value).then(result => {});
      },

      stringValue(key) {
        return _createGetter('get:' + key + ':string');
      },

      setStringValue(key, value) {
        _createSetter('set:' + key + ':string', value).then(result => {});
      },

      objectValue(key) {
        return _createGetter('get:' + key + ':object');
      },

      setObjectValue(key, value) {
        _createSetter('set:' + key + ':object', value).then(result => {});
      },

      call(method, param = null) {
        _call(method, param).then(result => {});
      },

      exec(callback) {
        callback();
      },

      splashOff() {
        _call('call:splashOff').then(result => {});
      },

      clear() {
        _shared.setStringValue('userPhone', '');
        _shared.setStringValue('userId', '');
        _shared.setStringValue('session', '');
        _shared.setStringValue('reqDate', '');
        _shared.setStringValue('access_token', '');
        _shared.setStringValue('renewal_token', '');
        _shared.setStringValue('autoLoginYn', 'N');
        _shared.setStringValue('mUserNo', ''); // mjkim 230615
      },

      console(content) {
        _call('call:console', { log: content }).then(result => {});
      },
    };
  }

  return {
    shared: function () {
      if (_shared) {
        return _shared;
      }

      _shared = init();

      return _shared;
    },
  };
})();

export default FTBridgeWebService;
