import {
  sendFCMToken,
  getHomePointData,
  fetchSubscribeList,
  fcmSubscribe,
  fcmUnSubscribe,
  fcmSubscribeList,
  // buUserLogin,
} from '@/apis/user';
import $commons from '@/utils/commons';

const actions = {
  async sendFCMToken({ commit }, params) {
    return await sendFCMToken(params);
  },

  async getHomePointData({ commit }, params) {
    const { data } = await getHomePointData(params);
    commit('getHomePointData', data);
  },
  async fetchSubscribeList({ commit }) {
    const { data } = await fetchSubscribeList();
    commit('fetchSubscribeList', data);
    return data;
  },
  async fcmSubscribe({ commit }, params) {
    const { data } = await fcmSubscribe(params);
    $commons.printLog('☆ after fcm subscribe data : ', JSON.stringify(data));
  },
  async fcmUnSubscribe({ commit }, params) {
    const { data } = await fcmUnSubscribe(params);
    $commons.printLog('☆ after fcm un subscribe data : ', JSON.stringify(data));
  },
  async fcmSubscribeList({ commit }) {
    await fcmSubscribeList();
  },
  // async buUserLogin({ commit }, params) {
  //   const { data } = await buUserLogin(params);
  //   return data;
  // },
};

export default actions;
