<template>
  <SlidingContentWrapper class="common-padding">
    <template #content>
      <div class="padding-top-24 d-flex c-center r-flex-start">
        <PaiBenefitWrapper />
      </div>
    </template>
  </SlidingContentWrapper>
</template>

<script>
import SlidingContentWrapper from '@/components/common/popup/slidingPopupContent/SlidingContentWrapper';
import PaiBenefitWrapper from '@/components/common/popup/slidingPopupContent/pai/setting/importData/PaiBenefitRadioWrapper';
export default {
  name: 'PaiSettingIndex',
  components: {
    PaiBenefitWrapper,
    SlidingContentWrapper,
  },
  data() {
    return {};
  },
  methods: {
    search() {
      this.$commons.printLog('정렬 :' + this.$refs.searchOrder.$data.order);
      this.$commons.printLog(
        '카테고리 :' +
          this.$refs.searchCategory.$data.checkboxData
            .filter(data => {
              return data.checked;
            })
            .map(data => data.name),
      );
      this.$commons.printLog(
        '혜택유형 :' +
          this.$refs.searchBenefit.$data.checkboxData
            .filter(data => {
              return data.checked;
            })
            .map(data => data.name),
      );
    },
  },
};
</script>

<style scoped lang="scss">
.sliding-wrap {
  margin-top: 40px;
  box-sizing: border-box;
}

.button-wrapper {
  position: fixed;
  bottom: 0;
}
.refresh-wrapper {
  position: absolute;
  width: 48px;
  height: 48px;
  background: red;

  margin-top: -1px;
  left: calc(100% - 80px);
}
</style>
