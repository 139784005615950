import { createRouter, createWebHashHistory, useRoute } from 'vue-router';
import common from '@/router/common';
import tvpay from '@/router/tvpay';
import pai from '@/router/pai';
import shoppingPoint from '@/router/shoppingPoint';
import tvpoint from '@/router/tvpoint';
import event from '@/router/event';
import fitcol from '@/router/fitcol';
import bbarshop from '@/router/bbarshop';
import orderPayment from '@/router/orderPayment';
import user from '@/router/user';
import tvmobile from '@/router/tvmobile';
import $store from '@/store';
import $commons from '@/utils/commons';

const routes = [
  ...common,
  ...tvpay,
  ...pai,
  ...shoppingPoint,
  ...tvpoint,
  ...event,
  ...user,
  ...tvmobile,
  ...fitcol,
  ...bbarshop,
  ...orderPayment,
];

const router = createRouter({
  history: createWebHashHistory(), //해쉬값 제거 방식
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // 슬라이딩 팝업이 열린경우 닫는 로직 추가
  // TODO : 슬라이딩 팝업이 2개 존재, 다른거 닫는거 확인 필요
  $store.commit('slideShow', { slideShow: false });

  $commons.printLog('☆ from.path===>', from.path);
  $commons.printLog('☆ from.fullPath===>', from.fullPath);
  $commons.printLog('☆ to.fullPath===>', to.fullPath);
  $commons.printLog('☆ to.path===>', to.path);
  $commons.printLog('☆ router.currentRoute.fullPath===>', useRoute().fullPath);

  // 웹에서 즐겨찾기 및 직접 URL 작성하여 진입할 경우 기프티콘 에러 방지를 위해 return. 2023-11-23, jslim
  $commons.printLog('☆ router.window.location.href ===> ', window.location.href);
  if (window.location.href.includes('https://www.fitpai.co.kr')) {
    return;
  }

  $commons.printLog('☆ router.index tvLogin===> ', $store.state.tvpoint.tvLogin);
  if (from.path !== '/' && to.path === '/' && $store.state.tvpoint.tvLogin) {
    router.push('/initPage');
    return;
  }

  // 푸시알림 initPage를 거치지 않고 해당 페이지로 바로 진입했을 경우 뒤로 가기 시 처리.jslim
  if (from.path !== '/' && to.path === '/' && !$commons.isNull($store.state.common.alarmId)) {
    if (from.fullPath.includes('/bbarshop')) {
      $commons.printLog('☆ /bbarshop');
      router.push('/bbarshop');
      // ios 알람 재진입 위해 alarmId 리셋
      $store.commit('setAlarmId', '');
    } else {
      $commons.printLog('☆ /initPage');
      router.push('/initPage');
      // ios 알람 재진입 위해 alarmId 리셋
      $store.commit('setAlarmId', '');
    }
    return;
  }

  if (from.fullPath === '/') {
    if (to.fullPath !== '/' && to.fullPath !== '/initPage' && to.fullPath !== '/index.html') {
      $commons.printLog('☆ router index.js setRefreshYN 진입');
      $store.commit('setRefreshYN', true);
    }
  }

  if (to.path === '/bbarshop/recntView') {
    $store.commit('setRecntViewPath', true);
  } else {
    $store.commit('setRecntViewPath', false);
  }

  $store.commit('setToPath', to.path);

  let iosFlag = false;
  //history 뒤로 가기 설정.
  switch (to.path) {
    //공유하기로 채널 소식 방 들어와서 로그인 하고 다시 채널 소식으로 보내기 위해.
    case '/':
      if (from.path === '/tvpoint/purchase/purchasecomplete' && $store.getters.getIsBack === true) {
        router.go(-1);
      } else if (from.path === '/tvpay/register' && $store.getters.getIsBack === true) {
        router.go(-1);
      } else if (from.path === '/tvpoint/purchase/tvpaySetPwd' && $store.getters.getIsBack === true) {
        router.go(-1);
      } else if (from.path === '/user/SetPwdPage') {
        $commons.printLog('☆ router index.js from.path === /user/SetPwdPage 진입');
        router.go(-2);
      } else {
        if ($store.state.common.deviceType === 3) {
          iosFlag = true;
        }
      }
      break;
    case '/initPage':
      if (from.path === '/user/oneOnOneInquiry') {
        router.replace('/menu');
      }
      break;
    case '/user/login':
      if (from.path === '/user/celLogin' && !$commons.isNull($store.state.common.userRequestRedirectPage)) {
        router.replace($store.state.common.userRequestRedirectPage);
        $store.commit('userRequestRedirectPage', '');
      } else if (from.path === '/user/celLogin' && $store.getters.getIsBack === true) {
        router.go(-1);
      } else if (from.path === '/user/SignUpPage' && $store.getters.getIsBack === true) {
        router.go(-1);
      } else if (from.path === '/user/SetPwdPage' && $store.getters.getIsBack === true) {
        $commons.printLog('☆ router index.js 1111111111111111111 진입');
        router.go(-1);
      }
      break;
    case '/menu':
      if (from.path === '/user/login' && $store.getters.getIsBack === true) {
        $store.commit('setIsBack', false);
        router.go(-1);
      }
      break;
    case '/tvpoint/exchange/SingleTransView':
      if (from.path === '/tvpoint/exchange/SingleTransComplete' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/tvpoint/exchange':
      if (from.path === '/tvpoint/exchange/SingleTransView' && $store.getters.getIsBack === true) {
        $store.commit('setIsBack', false);
        router.go(-1);
      }
      break;
    case '/tvpoint/gift/giftdetail':
      if (from.path === '/tvpoint/exchange/singletranscomplete' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/tvpoint/gift/selectgift':
      if (from.path === '/tvpoint/gift/giftdetail' && $store.getters.getIsBack === true) {
        $store.commit('setIsBack', false);
        router.go(-1);
      }
      break;
    case '/tvpoint/pay/register/card':
      if (
        (from.path === '/tvpoint/purchase/purchasecomplete' || from.path === '/tvpoint/purchase/tvpaySetPwd') &&
        $store.getters.getIsBack === true
      ) {
        router.go(-1);
      }
      break;
    case '/tvpoint/pay/register':
      if (from.path === '/tvpoint/purchase/purchasecomplete' && $store.getters.getIsBack === true) {
        $store.commit('setIsBack', false);
        router.go(-1);
      } else if (from.path === '/tvpoint/pay/register/card' && $store.getters.getIsBack === true) {
        $store.commit('setIsBack', false);
        router.go(-1);
      } else if (from.path === '/' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/tvpay/register':
      if (from.path === '/tvpoint/pay/register/card' && $store.getters.getIsBack === true) {
        router.go(-1);
      } else if (from.path === '/' && $store.getters.getIsBack === true) {
        $store.commit('setIsBack', false);
        router.go(-1);
      } else if (from.path === '/tvpay/register' && $store.getters.getIsBack === true) {
        $commons.printLog('☆ /tvpay/register');
        $store.commit('setIsBack', false);
        router.go(-2);
      }
      break;
    case '/tvpay/management':
      if ((from.path === '/tvpay/register' || from.path === '/tvpoint/purchase/tvpayChangePwd') && $store.getters.getIsBack === true) {
        $store.commit('setIsBack', false);
        router.go(-1);
      }
      break;
    case '/user/SelectAuthPage':
      if (
        (from.path === '/tvpay/register/payselfauth' || from.path === '/tvpoint/purchase/tvpaySetPwd') &&
        $store.getters.getIsBack === true
      ) {
        router.go(-1);
      }
      // else if (from.path === '/tvpoint/purchase/tvpaySetPwd' && $store.getters.getIsBack === true) {
      // 	$store.commit('setIsBack', false);
      // 	router.go(-1);
      // }
      break;
    case '/tvpoint/purchase/tvpayChangePwd':
      if (from.path === '/user/SelectAuthPage' && $store.getters.getIsBack === true) {
        $store.commit('setIsBack', false);
        router.go(-1);
      }
      break;
    case '/tvpay/register/payselfauth':
      if (from.path === '/tvpoint/purchase/tvpaySetPwd' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/tvpoint/purchase/tvpaySetPwd':
      if (from.path === '/tvpoint/purchase/purchaseComplete' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/user/UserAuthPage':
      if (from.path === '/user/SetPwdPage' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/user/SignUpPage':
      if (from.path === '/user/UserAuthPage' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/user/UserResetPwd':
      if (from.path === '/user/UserAuthPage' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/user/celLogin':
      if (from.path === '/user/UserResetPwd' && $store.getters.getIsBack === true) {
        router.go(-1);
      }
      break;
    case '/bbarshop/zzim':
      $store.commit('setReZzimProduct', true);
      break;
  }

  if (iosFlag) {
    setTimeout(() => {
      if ($store.state.common.deviceType === 3) {
        $commons.printLog('iosPush 증가');
        let num = $store.state.common.iosPush;
        $store.commit('setIosPush', num + 1);
        iosFlag = false;
      }
    }, 50);
  }

  next();
});

export default router;
