// jhkim: 20230720: 추가
class FTTimer {
  constructor() {
    this.id = -1;
    this.begin = 0;
    this.delay = 0;
    this.callback = null;
  }

  elapse() {
    return Math.max(Date.now() - this.begin, 0);
  }

  pause() {
    if (this.callback == null) {
      return;
    }

    clearTimeout(this.id);
    this.delay -= this.elapse();
  }

  resume() {
    if (this.callback == null) {
      return;
    }

    clearTimeout(this.id);
    this.begin = Date.now();
    this.id = setTimeout(this.callback, this.delay);
  }

  start(callback, delay) {
    clearTimeout(this.id);

    if (callback == null) {
      return;
    }

    this.begin = Date.now();
    this.delay = delay;
    this.callback = callback;
    this.id = setTimeout(callback, delay);
  }

  cancel() {
    clearTimeout(this.id);
    this.id = -1;
    this.callback = null;
  }
}

export default FTTimer;
