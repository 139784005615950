import getDefaultState from './state';
import mutations from './mutation';
import actions from './action';

const state = Object.assign({}, getDefaultState);

export default {
  defaultState: getDefaultState,
  state,
  mutations,
  actions,
};
