import axios from './common/apiConfig';
import urlConfig from '@/config/urlConfig';
import $store from '@/store';

async function fitReqRelay(params) {
  // return await axios.postQs(`${urlConfig.FIT_BASE_URL2}/appcard/reqRelay.tvp`, params);
  return await axios.postQs(`${urlConfig.FIT_BASE_URL}/appcard/reqRelay.tvp`, params);
}
async function fitGetAddr(params) {
  return await axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/getAddr.tvp`, params);
  // return await axios.postLoginQs(`${urlConfig.FIT_BASE_URL2}/appcard/getAddr.tvp`, params);
}
async function getPayCardList(params) {
  return await axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/getPayCardList.tvp${getSession($store.getters.getSessionId)}`, params);
}
async function cardCompanyList(params) {
  return await axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/getCardList.tvp${getSession($store.getters.getSessionId)}`, params);
}

async function appCardSmartConfirm(params) {
  return await axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/smartConfirm.tvp${getSession($store.getters.getSessionId)}`, params);
}
async function orderSave(params) {
  return await axios.postLoginQs(`${urlConfig.FIT_BASE_URL}/appcard/orderSave.tvp${getSession($store.getters.getSessionId)}`, params);
}

function getSession(jsession) {
  return ';jsessionid=' + jsession;
}

export { fitReqRelay, getPayCardList, fitGetAddr, appCardSmartConfirm, orderSave, cardCompanyList };
