<template>
  <div class="authNumInfo-wrap">
    <div class="common-padding">
      <div class="authNumInfo-title">인증번호가 아직 전송되지 않으셨나요?</div>
      <div class="authNumInfo-container-1">
        <div class="info">①</div>
        <div>스팸 메시지함으로 수신되었는지 확인이 필요합니다.</div>
      </div>
      <div class="authNumAnd">· 안드로이드</div>
      <div class="and-content">
        메시지 > 설정 > 번호 및 메시지 차단 ><br />수신차단 > ☎02-708-1000 또는 ☎1588-7758<br />차단해제 후 재발송
      </div>

      <div class="authNumIos">· 아이폰</div>
      <div class="ios-content">설정 > 메시지 > 차단된 연락처 > ☎02-708-1000<br />또는 ☎1588-7758 차단에서 해제 후 재발송</div>

      <div class="authNumInfo-container-2">
        <div class="info">②</div>
        <div>
          이동통신사에 특정 부가서비스가 가입되어 있는 경우 통신사 고객센터를 통해 스팸 차단해제 요청이 필요합니다.<br />(후후, T전화 어플,
          통신사 스팸문자 차단 서비스, 번호도용 차단 서비스 등 가입된 경우)
        </div>
      </div>
    </div>
    <button class="confirm-btn bottom" @click="connfirm">확인</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      termsContent: '',
    };
  },
  computed: {
    ...mapGetters(['getSlideContent']),
  },
  created() {},
  methods: {
    connfirm() {
      this.$store.commit('slideShow', { slideShow: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.authNumInfo-wrap {
  height: 100%;
}
.authNumInfo-title {
  //height: 50px;
  font-size: 36px;
  letter-spacing: -1.08px;
  color: #222222;
  padding-top: 40px;
  padding-bottom: 40px;
  font-weight: bold;
}
.authNumInfo-container-1 {
  display: flex;
  //height: 40px;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.84px;
  color: #343534;
}
.info {
  margin-right: 12px;
}
.authNumAnd {
  margin-left: 40px;
  //height: 40px;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.84px;
  color: #343534;
  margin-top: 26px;
}
.and-content {
  //height: 108px;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.56px;
  color: #343534;
  margin-top: 12px;
  margin-left: 60px;
}
.authNumIos {
  margin-top: 26px;
  margin-left: 40px;
  //height: 28px;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.84px;
  color: #343534;
}
.ios-content {
  //height: 68px;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.56px;
  color: #343534;
  margin-top: 12px;
  margin-left: 60px;
}
.authNumInfo-container-2 {
  display: flex;
  height: 100%;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: -0.84px;
  color: #343534;
  margin-top: 40px;
}
.confirm-btn {
  width: 100%;
  height: 110px;
  background-color: #2b2d2f;
  font-size: 34px;
  color: #ffffff;
  margin-top: 40px;
}
</style>
