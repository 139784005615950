<template>
  <div class="coupon-wrap padding common-padding">
    <div class="coupon-title-wrap">
      <div class="coupon-title">금액 권종 선택</div>
    </div>
    <div class="coupon-container">
      <div
        v-for="(price, idx) in getCouponPrice"
        :key="price"
        class="coupon-com"
        :class="isScroll && idx == getCouponPrice.length - 1 ? 'scroll-padding' : ''"
      >
        <input :id="`coupon_${price}`" type="raddio" name="coupon" :value="price" @click="priceSelected" />
        <label :for="`coupon_${price}`"><i class="radio-img"></i>{{ $filters.withComma(price) }}{{ ` P` }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    isScroll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['getCouponPrice']),
  },
  methods: {
    priceSelected($event) {
      const price = $event.target.value;
      this.$store.commit('setPopupData', { price });
      this.$store.commit('slideShow', { slideShow: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-wrap {
  height: inherit;
}
.coupon-com {
  height: 110px;
  display: flex;
  align-items: center;
}
.coupon-title {
  height: 128px;
  font-size: 34px;
  line-height: 128px;
  letter-spacing: -1.36px;
  color: #222222;
  font-weight: 500;
}
.coupon-container {
  height: 100%;
  overflow: scroll;
}
label {
  font-size: 30px;
  letter-spacing: -1.5px;
  color: #222222;
  height: 40px;
  display: flex;
  line-height: 37px;
  width: 100%;
}
input[type='radio']:checked + label > i {
  background: url('../../assets/img/tvpoint/rdo_on.svg') no-repeat;
}
.radio-img {
  width: 40px;
  padding-right: 20px;
  background: url('../../assets/img/tvpoint/rdo_off.svg') no-repeat;
}
input {
  display: none;
}
</style>
