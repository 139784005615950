// jhkim: 추가
// 컴포넌트 템플릿 정보 구성

import Logger from '@/modules/ft-logger';
import FTTemplateParser from '@/modules/ft-template-parser.js';
import FTAsyncExecutor from '@/modules/ft-async-executor.js';
import FTComponentDispatcher from '@/modules/ft-component-dispatcher.js';
import FTEnum from '../modules/ft-enum';

import $store from '@/store';

const FTTemplateDispatcher = (function () {
  // 싱글톤
  let _sharedInstance;

  function init() {
    return {
      async dispatchInformEvent(pageMenu) {
        $store.state.fit.pageInform.prevPageInfo = $store.state.fit.pageInform.pageInfo;
        $store.state.fit.pageInform.pageInfo = {};
        $store.state.fit.pageInform.pageInfo.page_id = pageMenu.id;
        $store.state.fit.pageInform.pageInfo.title = pageMenu.title;
        $store.state.fit.pageInform.pageInfo.url = pageMenu.uri;
        $store.state.fit.pageInform.pageInfo.depth = pageMenu.depth;
        $store.commit('setBannerId', '');
        $store.commit('setComponentType', '');
        if ($store.state.common.clickBack) {
          $store.state.fit.pageInform.pageInfo.click_back = true;
          $store.commit('setClickBack', false);
        } else {
          $store.state.fit.pageInform.pageInfo.click_back = false;
        }
        if (pageMenu.uri === '/fit/pages/fitcall') {
          $store.commit('setFitSourceId', 'FIT00FIT01');
        } else {
          $store.commit('setFitSourceId', 'BBARS00001');
        }
        $store.dispatch('informEvent', $store.state.fit.pageInform);
        $store.commit('setMallCode', pageMenu.uri);
      },

      // jhkim: 추가. 그룹 리스트 요청시 통계 정보 같이 실행
      async dispatchTemplateGroupList(pageMenu) {
        _sharedInstance.dispatchInformEvent(pageMenu);

        const pageId = pageMenu.id;
        // 템플릿 그룹 요청
        const promiseList = [];
        // promiseList.push(_sharedInstance.dispatchInformEvent(pageMenu));
        promiseList.push($store.dispatch('componentTemplateGroupListFitcol', pageMenu));

        const promiseResult = await Promise.all(promiseList);
        const templateGroupList = promiseResult[0];

        return templateGroupList;
      },

      async dispatchTemplateData(pageMenu) {
        const pageId = pageMenu.id;
        const pagePath = pageMenu.uri;

        // jhkim: 비동기 런처, 템플릿 그룹 파서, 컴포넌트 디스패처 추가
        let asyncResult;
        const asyncExecutor = new FTAsyncExecutor();
        const categoryList = [];
        const templateGroupList = await _sharedInstance.dispatchTemplateGroupList(pageMenu);

        templateGroupList.group_templates.forEach(group => {
          FTTemplateParser.parse(group, templateItem => {
            const params = {
              pageId: pageId,
              groupId: group.id,
              componentId: templateItem.id, // jhkim: 손 볼데가 많아 수정 안 함. templateId로 변경 -> api 및 기타
              groupType: templateItem.type, // jhkim: templateType으로 변경 -> api 및 기타
              fitcolId: pagePath.endsWith('/fitcall') ? 'FIT00FIT01' : '', // 핏콜만 있어야 하는 id
            };

            asyncExecutor.push(FTComponentDispatcher.dispatchComponentList(templateItem, params));
          });
        });
        asyncResult = await asyncExecutor.exec();

        asyncExecutor.clear();
        for (let i = 0; i < asyncResult?.length; i++) {
          asyncExecutor.push(FTComponentDispatcher.dispatchComponentData(asyncResult[i].templateItem, asyncResult[i].componentList));
        }
        asyncResult = await asyncExecutor.exec();

        // jhkim: 비동기로 구성된 컴포넌트 템플릿 정렬. 마지막에 정렬하지 않으면 의미 없음
        // 컴포넌트 정보는 정렬되어 있음
        // 상품 정보는 정렬되어 있음
        // 동적컴포 카테고리 저장
        const staticTemplateList = [];
        const dynamicTemplateList = [];

        for (let result of asyncResult) {
          if (FTEnum.isNotEmpty(result.templateItem.componentList)) {
            if (result.templateItem.groupType == 0) {
              staticTemplateList.push(result.templateItem);
            } else {
              categoryList.push(result.templateItem.caption);
              dynamicTemplateList.push(result.templateItem);
            }
          }
        }

        return {
          pageId: pageId,
          staticTemplateList: staticTemplateList.sort((a, b) => a.order - b.order),
          dynamicTemplateList: dynamicTemplateList.sort((a, b) => a.order - b.order),
          categoryList: categoryList,
        };
      },
    };
  }

  return {
    sharedInstance: function () {
      if (_sharedInstance) {
        return _sharedInstance;
      }

      _sharedInstance = init();
      return _sharedInstance;
    },
  };
})().sharedInstance();

export default FTTemplateDispatcher;
