<template>
  <ArrangeList1Wrapper :data-list="dataList" :seq="seq" :img-size="imgSize" class="event-content" @click-event="clickEvent" />
</template>

<script>
import ArrangeList1Wrapper from '@/components/common/listType/ArrangeList1Wrapper';
import { fitMixin } from '@/utils/fitMixin';

export default {
  name: 'EventPopupBannerList',

  components: { ArrangeList1Wrapper },
  mixins: [fitMixin],

  data() {
    return {
      seq: 10,
      imgSize: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
      },
    };
  },

  computed: {
    dataList() {
      return !this.$commons.isNull(this.$store.state.fit.rollingBannerList?.banner_components)
        ? this.$store.state.fit.rollingBannerList.banner_components
        : [];
    },
  },

  methods: {
    clickEvent(sort, idx) {
      switch (sort) {
        case 'showDetail':
          this.sendEventLink(this.dataList[idx].link.value, this.dataList[idx].link.type);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event-content {
  margin-top: 40px;
}
</style>
