const Key = {
  CURRENT_MENU: 'currentMenu',
  HAS_MENU: 'hasMenu',
};

const Service = {
  FIT: 1,
  HSHOP: 2,
  BSHOP: 3,
};

module.exports = { Key, Service };
