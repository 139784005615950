<template>
  <div>
    <div :style="`margin-top:${margin}px; height:${height}px; background:${background}`"></div>
  </div>
</template>

<script>
/**
 * 기본 보더 설정
 * @see @/assets/explain/BorderBasic.png
 * @param height : 보더높이 보더 높이
 * @param background : 보더색상 보더 색상
 */
export default {
  name: 'BorderBasic',
  props: {
    margin: {
      type: String,
      default: '0',
    },
    height: {
      type: String,
      default: '16',
    },
    background: {
      type: String,
      default: '#f3f3f3',
    },
  },
};
</script>

<style scoped lang="scss"></style>
